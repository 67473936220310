import React from 'react';
import PropTypes from 'prop-types';
import './floatingLoginInput.scss';

class FloatingLabelLoginInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isInputFocused: props.isFocused ? props.isFocused : false,
    };

    this.inputRef = React.createRef();
  }

  handleFocus = () => this.setState({ isInputFocused: true });

  handleBlur = () => {
    this.setState({ isInputFocused: false });
  };

  focusField = () => {
    this.inputRef.current.focus();
  };

  render() {
    const { label, isFocused, ...props } = this.props;
    const { isInputFocused, autoFill } = this.state;
    const classNames = isInputFocused
      ? 'input-label-is-focused input-label-with-value blue-border'
      : this.props.value !== ''
      ? 'input-label-with-value'
      : autoFill
      ? 'input-label-with-value'
      : 'input-label-without-value';

    return (
      <>
        <div style={{ position: 'relative', top: '32px', left: '4px' }}>
          {isInputFocused && (
            <label className='label_style'>
              {label}
              <span className='mandatory-field'>*</span>
            </label>
          )}
        </div>
        <div
          className={`input-container pos-rel input-border ${
            isInputFocused ? 'input-border-blue' : ''
          }`}
        >
          <input
            className='input-login'
            style={{ position: 'relative', top: '8px' }}
            {...props}
            placeholder={isInputFocused ? this.props.value : label}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            ref={this.inputRef}
            autoFocus={isFocused}
          />
          {this.props.name === 'password' && (
            <span
              role='button'
              tabIndex='-1'
              className={`password-show ${
                this.props.value.length === 0 ? 'disabled' : ''
              }`}
              onClick={this.props.showHide}
            >
              {this.props.type === 'input' ? 'Hide' : 'Show'}
            </span>
          )}
        </div>
      </>
    );
  }
}

FloatingLabelLoginInput.propTypes = {
  isFocused: PropTypes.bool,
  label: PropTypes.any,
  value: PropTypes.any,
};

export default FloatingLabelLoginInput;

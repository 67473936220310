import camelcaseKeys from 'change-case-object';
import { setAuthHeaders, deleteAuthHeaders } from 'services/base';
import { showToaster } from 'common/toasterActions';

import { SIGN_IN_URL, PATIENT_SIGN_IN_URL } from 'services/constants';
import * as StorageService from 'services/storage';
import {
  registerUserService,
  signInUserService,
  signOutService,
  getTermsAndConditionUrlService,
  generateOtpUserService,
} from 'services/deviceAuth';

import {
  getUserInfo as getUserInfoCall,
  updateUserProfile as updateUserProfileCall,
  updateUserEmailCall,
  updateUserProfilePicture as updateUserProfilePictureCall,
  deleteAccountService,
  getCurrentUserInfo as getCurrentUserInfoCall,
} from 'services/users';

import { startHeartBeats, stopHeartBeats } from 'services/heartbeats';
import Actions from './authActions';
import {
  RESET_GLOBAL_REDUCERS,
  USER_TYPE_TRADITIONAL_SITE_USER,
} from '../constants';
import history from '../configureHistory';
import { getItemFromStorage } from 'services/storage';

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const registerUser = userRegistrationDetails => dispatch => {
  dispatch(Actions.registrationRequestSent());
  registerUserService(userRegistrationDetails)
    .then(response => {
      setAuthHeaders(response.headers);
      dispatch(
        Actions.registrationRequestSucceeded(
          camelcaseKeys.camelCase(response.data.data)
        )
      );
      StorageService.persistAuthHeadersInDeviceStorage(response.headers);
    })
    .catch(error => {
      if (error.message === 'Network Error') {
        dispatch(
          showToaster({
            message:
              'No internet connection. Please check your internet settings',
            type: 'error',
          })
        );
      }
      dispatch(Actions.registrationRequestFailed());
    });
};

export const getTermsAndConditionsUrl = emailOrContactNumber => dispatch => {
  dispatch(Actions.getTermsAndConditionUrlRequestSent());
  getTermsAndConditionUrlService({
    email_or_contact_number: emailOrContactNumber,
  })
    .then(response => {
      dispatch(
        Actions.getTermsAndConditionUrlRequestSucceeded(
          camelcaseKeys.camelCase(response.data)
        )
      );
    })
    .catch(error => {
      if (error.message === 'Network Error') {
        dispatch(
          showToaster({
            message: 'Could not reach server. Please try again.',
            type: 'error',
          })
        );
      }
      dispatch(Actions.getTermsAndConditionUrlRequestFailed());
      let message = 'Something went wrong';
      if (error.response && error.response.data && error.response.data.error) {
        message = error.response.data.error;
      }
      dispatch(showToaster({ message, type: 'error' }));
    });
};

export const generateOtp = userSignInCredentials => dispatch => {
  dispatch(Actions.generateOtpRequestSent());
  generateOtpUserService(userSignInCredentials)
    .then(response => {
      const data = camelcaseKeys.camelCase(response.data);
      dispatch(Actions.generateOtpRequestSucceeded(data));
    })
    .catch(error => {
      let message = 'Something went wrong';
      dispatch(Actions.generateOtpRequestFailed());
      if (error.response && error.response.data) {
        if (error.response.data.errors) {
          message =
            error.response.data.errors.length > 0
              ? error.response.data.errors[0]
              : 'Something went wrong';
        }
      }
      if (error.message === 'Network Error') {
        message = 'No internet connection. Please check your internet settings';
      }
      dispatch(showToaster({ message, type: 'error' }));
    });
};

export const closeOtpModal = () => dispatch => {
  dispatch(Actions.closeOtpModalRequestSucceeded());
};

export const signInUser = userSignInCredentials => dispatch => {
  dispatch(Actions.signInRequestSent());
  signInUserService(userSignInCredentials)
    .then(response => {
      setAuthHeaders(response.headers, response.data.access_token);
      StorageService.persistAuthHeadersInDeviceStorage(
        response.headers,
        response.data.access_token
      );
      const currentUser = camelcaseKeys.camelCase(response.data.data);
      StorageService.setItemToStorage(
        'currentUser',
        JSON.stringify(currentUser)
      );
      dispatch(Actions.signInRequestSucceeded(currentUser));
    })
    .catch(error => {
      let message = 'Something went wrong';
      dispatch(Actions.signInRequestFailed());
      if (error.response && error.response.data) {
        if (error.response.data.errors) {
          message =
            error.response.data.errors.length > 0
              ? error.response.data.errors[0]
              : 'Something went wrong';
        }
      }
      if (error.message === 'Network Error') {
        message = 'No internet connection. Please check your internet settings';
      }
      dispatch(showToaster({ message, type: 'error' }));
    });
};

export const signOutUser = () => dispatch => {
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  dispatch(Actions.signOutRequestSent());
  signOutService()
    .then(() => {
      handlePostLogoutForPatientAndOthers(dispatch, currentUser);
    })
    .catch(() => {
      dispatch(Actions.signOutRequestFailed());
    });
};

export const getCurrentUserInformation = () => dispatch => {
  dispatch(Actions.getCurrentUserInfoRequestSent());
  getCurrentUserInfoCall()
    .then(response => {
      StorageService.setItemToStorage(
        'currentUser',
        JSON.stringify(camelcaseKeys.camelCase(response.data.data))
      );
      dispatch(
        Actions.getCurrentUserInfoRequestSucceeded(
          camelcaseKeys.camelCase(response.data.data)
        )
      );
    })
    .catch(() => {
      dispatch(Actions.getCurrentUserInfoRequestFailed());
    });
};

export const getCurrentUserInfo = store => {
  if (StorageService.getItemFromStorage('token')) {
    let currentUser = JSON.parse(getItemFromStorage('currentUser'));
    getItemFromStorage('currentUser') &&
    currentUser &&
    currentUser.type === USER_TYPE_TRADITIONAL_SITE_USER
      ? null
      : store.dispatch(getCurrentUserInformation());
  } else {
    store.dispatch(Actions.setHasVerificationBeenAttempted(true));
  }
};

export const sendHeartBeatsAgain = () => {
  const currentHeartBeats = JSON.parse(
    localStorage.getItem('currentHeartBeats')
  );
  if (currentHeartBeats === undefined || currentHeartBeats === null) {
    const currentHeartBeat = [];
    localStorage.setItem('currentHeartBeats', JSON.stringify(currentHeartBeat));
  } else {
    for (const currentHeartBeat of currentHeartBeats) {
      startHeartBeats(currentHeartBeat);
    }
  }
};

export const getUserInfo = () => dispatch => {
  dispatch(Actions.getProfileRequestSent());
  getUserInfoCall()
    .then(response => {
      dispatch(Actions.getProfileRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(Actions.getProfileRequestFailed());
    });
};

export const updateUserInfo = params => dispatch => {
  dispatch(Actions.updateUserProfileRequestSent());
  updateUserProfileCall(params)
    .then(response => {
      updateLocalUserStore(dispatch);
      dispatch(Actions.updateUserProfileRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(Actions.updateUserProfileRequestFailed(error));
    });
};

export const updateUserProfilePicture = params => dispatch => {
  dispatch(Actions.updateUserProfilePictureRequestSent());
  updateUserProfilePictureCall(params)
    .then(response => {
      updateLocalUserStore(dispatch);
      dispatch(getUserInfo());
      dispatch(Actions.updateUserProfilePictureRequestSucceeded(response.data));
      dispatch(
        showToaster({ message: 'Profile picture updated successfully.' })
      );
    })
    .catch(error => {
      dispatch(Actions.updateUserProfilePictureRequestFailed(error));
    });
};

export const updateUserEmail = params => dispatch => {
  dispatch(Actions.updateUserEmailRequestSent());
  updateUserEmailCall(params)
    .then(response => {
      updateLocalUserStore(dispatch);
      dispatch(Actions.updateUserEmailRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(Actions.updateUserEmailRequestFailed());
    });
};

export const updateLocalUserStore = dispatch => {
  if (StorageService.getItemFromStorage('token')) {
    dispatch(getCurrentUserInformation());
  }
};

export const deleteUserAccount = params => dispatch => {
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  dispatch(Actions.deleteAccountRequestSent());
  deleteAccountService(params)
    .then(() => {
      dispatch(Actions.deleteAccountRequestSucceeded());
      handlePostLogoutForPatientAndOthers(dispatch, currentUser);
    })
    .catch(() => {
      dispatch(Actions.deleteAccountRequestFailed());
    });
};

export const handlePostLogoutForPatientAndOthers = (
  dispatch,
  currentUser = null
) => {
  if (currentUser.type === 'Patient' || currentUser.type === 'Caregiver') {
    dispatch(handlePostLogoutAction(PATIENT_SIGN_IN_URL));
  } else {
    dispatch(handlePostLogoutAction());
  }
};

export const handlePostLogoutAction =
  (postLogoutRedirectUrl = null) =>
  dispatch => {
    stopHeartBeats({});
    deleteAuthHeaders();
    StorageService.deleteAuthHeadersFromDeviceStorage();
    dispatch(Actions.signOutRequestSucceeded());
    StorageService.clearDefaultStorage();
    dispatch({ type: RESET_GLOBAL_REDUCERS });
    if (postLogoutRedirectUrl) {
      window.location.href = postLogoutRedirectUrl;
    } else {
      history.replace(SIGN_IN_URL);
    }
  };

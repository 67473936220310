import 'whatwg-fetch';
import axios from './base';

import {
  GET_DEPARTMENT_PROCEDURES,
  GET_DEPARTMENT_PROVIDERS,
  SAVE_SEARCH_FILTER,
  UPDATE_SEARCH_FILTER,
  DELETE_SEARCH_FILTER,
  GET_DEPARTMENT_ONLY_PROVIDERS,
  GET_PROCEDURE_SET_PROVIDERS,
  GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS,
  ADD_NEW_PATIENT_PROCEDURE,
  GET_LOCATION,
  GET_TIMEZONE,
  GET_LOCATION_TIMEZONE,
  GET_DEPARTMENT_SUPPORTED_KEYS,
  VALIDATE_EMAIL_UNIQUNESS,
  VALIDATE_PHONE_UNIQUNESS,
  ADD_SUPPORTED_ATTRIBUTE_VALUE,
  UPDATE_SUPPORTED_ATTRIBUTE_VALUE,
  GET_SEARCH_CATEGORY,
} from './constants';

import { MAP_KEY } from 'envConstants';

export const getDepartmentProcedures = () =>
  axios.get(GET_DEPARTMENT_PROCEDURES);

export const getDepartmentProviders = () => axios.get(GET_DEPARTMENT_PROVIDERS);

export const getDepartmentOnlyProviders = () =>
  axios.get(GET_DEPARTMENT_ONLY_PROVIDERS);

export const getProcedureSetProviders = id =>
  axios.get(`${GET_PROCEDURE_SET_PROVIDERS}${id}`);

export const getDepartmentOnlyGeneralCardiologists = () =>
  axios.get(GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS);

export const saveFilterSelection = params =>
  axios.post(SAVE_SEARCH_FILTER, params);

export const updateFilterSelection = params => {
  const url = UPDATE_SEARCH_FILTER.replace(':id', params.id);
  return axios.put(url, params);
};

export const deleteCustomFilter = id => {
  const url = DELETE_SEARCH_FILTER.replace(':id', id);
  return axios.delete(url);
};

export const addNewPatientProcedure = params =>
  axios.post(ADD_NEW_PATIENT_PROCEDURE, params);

export const getLocation = params =>
  fetch(
    `${GET_LOCATION}?components=country%3AUS|postal_code%3A${params.postal_code}&key=${MAP_KEY}`
  );

export const getTimezone = params =>
  fetch(
    `${GET_TIMEZONE}?location=${params.location}&timestamp=${params.timestamp}&key=${MAP_KEY}`
  );

export const getLocationTimezone = params =>
  axios.get(GET_LOCATION_TIMEZONE, { params });

export const getDepartmentSupportedKeys = () =>
  axios.get(GET_DEPARTMENT_SUPPORTED_KEYS);

export const isEmailUnique = params =>
  axios.get(VALIDATE_EMAIL_UNIQUNESS, { params });

export const isPhoneUnique = params =>
  axios.get(VALIDATE_PHONE_UNIQUNESS, { params });

export const addSupportedAttributeValue = params =>
  axios.post(ADD_SUPPORTED_ATTRIBUTE_VALUE, params);

export const updateSupportedAttributeValue = params => {
  const url = UPDATE_SUPPORTED_ATTRIBUTE_VALUE.replace(
    ':id',
    params.supported_attribute_value.id
  );
  return axios.put(url, params);
};

export const getSearchCategoryList = () => axios.get(GET_SEARCH_CATEGORY);

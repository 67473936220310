import React from 'react';
import validate, { clearErrorsForField } from 'common/validator';
import PropTypes from 'prop-types';
import HelpBlock from 'components/HelpBlock';
import AddAttachmentModal from 'containers/AddAttachmentModal';
import { connect } from 'react-redux';

const validationConfig = {
  fields: ['newMessage'],
  rules: {
    newMessage: [{ rule: 'isRequired', message: 'Message is required' }],
  },
};

class MessageResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      newMessage: '',
      showAttachmentModal: false,
      showAttachments: true,
    };
  }

  onSendMessage = () => {
    validate(
      validationConfig,
      this.state,
      this.onSendMessageValidationFailure,
      this.onSendMessageValidationSuccess
    );
  };

  onSendMessageValidationSuccess = () => {
    this.setState({ errors: {} });
    this.props.onSentMessage(this.state.newMessage);
    this.setState({ newMessage: '', showAttachments: false }, () => {});
  };

  onSendMessageValidationFailure = errors => {
    this.setState({ errors });
  };

  changeFieldHandler = e => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  // Attachment Code
  onAddAttachment = () => {
    this.setState({ showAttachmentModal: true, showAttachments: true });
  };

  onAttachmentsAdded = attachments => {
    this.setState({
      showAttachmentModal: false,
    });
    this.props.onAttachmentsAdded(attachments);
  };

  onAttachmentRemoved = () => {
    // this.props.onAttachmentRemoved(attachmentId);
  };

  render() {
    return (
      <div>
        <div
          className={`form-group ${
            this.state.errors.newMessage !== undefined ? 'has-error' : ''
          }`}
        >
          <textarea
            name='newMessage'
            id='newMessage'
            className='form-control message-text-area-control'
            placeholder='Type your answer here'
            value={this.state.newMessage}
            onChange={this.changeFieldHandler}
          />
          <HelpBlock value={this.state.errors.newMessage} />
          <AddAttachmentModal
            onAttachmentsAdded={this.onAttachmentsAdded}
            showModal={this.state.showAttachmentModal}
            onAttachmentRemoved={this.onAttachmentRemoved}
            showAddedAttachments={this.state.showAttachments}
          />
          <div className='attachment-btn-container'>
            <span
              className='icon attachment-btn icon-font-a-attachment-circle'
              onClick={this.onAddAttachment}
            />
            <button
              className='btn btn-primary float-right'
              onClick={this.onSendMessage}
              style={{ cursor: 'pointer' }}
            >
              Send
            </button>
          </div>
        </div>
        <div className='clearfix' />
      </div>
    );
  }
}

MessageResponse.propTypes = {
  onSentMessage: PropTypes.func,
  onAttachmentsAdded: PropTypes.func,
};

const mapStateToProps = state => ({
  isLoading: state.patientDetails
    ? state.patientDetails.conversations.isLoading
    : state.patientMessages.conversation_details.isLoading,
});

export default connect(mapStateToProps)(MessageResponse);

import * as Constants from '../constants';

const initialState = {
  isLoading: false,
  messages: [],
  conversation: {},
  resource: {},
  question: {},
  isMessageSent: false,
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_CONVERSATION_MESSAGES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        messageDisclaimertext: action.data.messageDisclaimerText,
        messages: action.data.messages,
        conversation: action.data.conversation,
        resource: action.data.resource,
        question: action.data.question,
      };
    case Constants.GET_CONVERSATION_MESSAGES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    /// //////////////////
    case Constants.CREATE_CONVERSATIONS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.CREATE_CONVERSATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        isMessageSent: true,
      };
    case Constants.CREATE_CONVERSATIONS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.ALTER_SEND_MESSAGE_FLAG:
      return {
        ...state,
        isMessageSent: false,
      };
    default:
      return state;
  }
};

export default messagesReducer;

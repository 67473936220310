export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SHOW_OUTCOME_CHARTS = process.env.REACT_APP_SHOW_OUTCOME_CHARTS;
export const MAP_KEY = process.env.REACT_APP_MAP_KEY;
export const PORT = process.env.REACT_APP_PORT || '8080';
export const HOST = process.env.REACT_APP_HOST;
export const PROFILE_CHANGES = process.env.REACT_APP_DISABLE_PROFILE_CHANGES;
export const DISABLE_PATIENT_MESSAGES =
  process.env.REACT_APP_DISABLE_PATIENT_MESSAGES;
export const DISABLE_PATIENT_MANAGEMENT =
  process.env.REACT_APP_DISABLE_PATIENT_MANAGEMENT;
export const HEARTBEAT_INTERVAL_TIME =
  process.env.REACT_APP_HEARTBEAT_INTERVAL_TIME;
export const DISABLE_FUNCTIONALITY =
  process.env.REACT_APP_DISABLE_FUNCTIONALITY;
export const BASE_AUTH_URL = `${API_URL}/users`;
export const APP_STORE_URL =
  process.env.REACT_APP_APP_STORE_URL ||
  'https://apps.apple.com/us/app/insightec-connect/id1633249556';
export const GOOGLE_PLAY_STORE_URL =
  process.env.REACT_APP_GOOGLE_PLAY_STORE_URL ||
  'https://play.google.com/store/apps/details?id=com.higgsboson.health.mms.ins';

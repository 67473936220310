import {
  fetchTraditionalSiteUserInfo,
  fetchTraditionalSitePatients,
  fetchPatientDetailsCall,
  archivePatientCall,
  getLoggedInStatus,
} from 'services/traditionalSite/dashboardApiCalls';
import { showToaster } from 'common/toasterActions';
import {
  FETCH_TRADITIONAL_SITE_USER_INFO_REQUEST_SUCCEEDED,
  FETCH_ALL_PATIENTS_REQUEST_SUCCEEDED,
  FETCH_PATIENT_DETAILS_REQUEST_SUCCEEDED,
  ARCHIVE_PATIENT_REQUEST_SUCCEEDED,
  SET_ACTIVE_TAB_ON_SIDEBAR,
  RESET_PATIENT_DETAILS_DATA,
  UPDATE_ARCHIEVED_PATIENT,
  SET_PATIENT_SORT_DATA,
  RESET_ALL_PATIENTS_AFTER_LOGOUT,
  GET_LOGGED_IN_STATUS_CHECK_REQUEST_SUCCEEDED,
} from './constants';

const fetchTraditionalSiteUserInfoRequestSucceeded = data => ({
  type: FETCH_TRADITIONAL_SITE_USER_INFO_REQUEST_SUCCEEDED,
  data,
});

const fetchAllPatientsAtLocationRequestSucceeded = data => ({
  type: FETCH_ALL_PATIENTS_REQUEST_SUCCEEDED,
  data,
});

export const setPatientSortData = (sort, direction) => ({
  type: SET_PATIENT_SORT_DATA,
  sort,
  direction,
});

export const resetPatientDetailsData = () => ({
  type: RESET_PATIENT_DETAILS_DATA,
});

const fetchPatientDetailsRequestSucceeded = data => ({
  type: FETCH_PATIENT_DETAILS_REQUEST_SUCCEEDED,
  data,
});

const archivePatientRequestSucceeded = data => ({
  type: ARCHIVE_PATIENT_REQUEST_SUCCEEDED,
  data,
});

export const setActiveTabForSideBar = (activeTabId, activeTabKey) => ({
  type: SET_ACTIVE_TAB_ON_SIDEBAR,
  activeTabId,
  activeTabKey,
});

const updateArchievedPatient = id => ({
  type: UPDATE_ARCHIEVED_PATIENT,
  data: id,
});

export const resetAllPatientData = () => ({
  type: RESET_ALL_PATIENTS_AFTER_LOGOUT,
});

export const getLoggedInStatusCheckRequestSucceeded = () => ({
  type: GET_LOGGED_IN_STATUS_CHECK_REQUEST_SUCCEEDED,
});

export const fetchTraditionalSiteUserDetails =
  (page, term = null) =>
  dispatch => {
    fetchTraditionalSiteUserInfo({ page, term })
      .then(response => {
        dispatch(fetchTraditionalSiteUserInfoRequestSucceeded(response.data));
      })
      .catch(() => {
        // dispatch(fetchTraditionalSiteUserInfoRequestFailed);
      });
  };

export const fetchAllPatients = params => dispatch => {
  const sort = params.archived === 'archive' ? 'patient_date_archived' : 'patient_created_at';

  const params_fetch_patients = {
    archived: params.archived === 'archive' ? true : false,
    page: params.page || 1,
    term: params.term || null,
    direction: params.direction || 'desc',
    sort: params.sort || sort,
  };
  
  fetchTraditionalSitePatients(params_fetch_patients)
    .then(response => {
      dispatch(setPatientSortData(params_fetch_patients.sort, params_fetch_patients.direction));
      dispatch(fetchAllPatientsAtLocationRequestSucceeded(response.data));
    })
    .catch(() => {});
};

export const fetchPatientDetail =
  (id, call_back = null) =>
  dispatch => {
    fetchPatientDetailsCall({ id })
      .then(response => {
        dispatch(
          fetchPatientDetailsRequestSucceeded(response.data.patientDetails)
        );

        if (call_back) call_back();
      })
      .catch(error => {});
  };

export const archivePatient =
  (id, call_back = null) =>
  dispatch => {
    archivePatientCall(id)
      .then(response => {
        dispatch(
          fetchPatientDetailsRequestSucceeded(response.data.patientDetails)
        );
        dispatch(showToaster({ message: response.data.message }));
        if (call_back) call_back();
        dispatch(updateArchievedPatient(id));
      })
      .catch(error => {});
  };

export const getLoggedInStatusCheck = () => dispatch => {
  getLoggedInStatus()
    .then(response => {
      dispatch(getLoggedInStatusCheckRequestSucceeded(response.data));
    })
    .catch(() => {});
};

/*
 *
 * Notifications Constants
 *
 */

export const GET_NOTIFICATIONS_REQUEST_SENT =
  'app/NavNotificationMenu/GET_NOTIFICATIONS_REQUEST_SENT';
export const GET_NOTIFICATIONS_REQUEST_SUCCEED =
  'app/NavNotificationMenu/GET_NOTIFICATIONS_REQUEST_SUCCEED';
export const GET_NOTIFICATIONS_REQUEST_FAILED =
  'app/NavNotificationMenu/GET_NOTIFICATIONS_REQUEST_FAILED';

export const GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SENT =
  'app/NavNotificationMenu/GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SENT';
export const GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SUCCEED =
  'app/NavNotificationMenu/GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SUCCEED';
export const GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_FAILED =
  'app/NavNotificationMenu/GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_FAILED';

export const MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SENT =
  'app/NavNotificationMenu/MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SENT';
export const MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SUCCEED =
  'app/NavNotificationMenu/MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SUCCEED';
export const MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_FAILED =
  'app/NavNotificationMenu/MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_FAILED';

import { data } from 'jquery';
import {
  FETCH_TRADITIONAL_SITE_USER_INFO_REQUEST_SUCCEEDED,
  FETCH_ALL_PATIENTS_REQUEST_SUCCEEDED,
  FETCH_PATIENT_DETAILS_REQUEST_SUCCEEDED,
  SET_ACTIVE_TAB_ON_SIDEBAR,
  RESET_PATIENT_DETAILS_DATA,
  UPDATE_ARCHIEVED_PATIENT,
  SET_PATIENT_SORT_DATA,
  RESET_ALL_PATIENTS_AFTER_LOGOUT,
} from './constants';

const sideBarInitialState = {
  activeTabId: 1,
  activeTabKey: 'inbox',
};

export const sideBarReducer = (state = sideBarInitialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB_ON_SIDEBAR:
      return {
        ...state,
        activeTabId: action.activeTabId,
        activeTabKey: action.activeTabKey,
      };
    default:
      return state;
  }
};

const patientDetailsTemplate = {
  id: '',
  fullName: '',
  email: '',
  phoneNumber: '',
  zipCode: '',
  surveyQuestions: [],
  survey: {}
};

const initialState = {
  traditionalSiteUserData: {},
  siteName: '',
  isLoading: false,
  pagination: {},
  patientDetails: { ...patientDetailsTemplate },
  all_patients: [],
  empty_message: '',
  sort: "",
  direction: ""
};

export const traditionalSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRADITIONAL_SITE_USER_INFO_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        traditionalSiteUserData: action.data.traditionalSiteUserInfo,
        siteName: action.data.siteName,
      };

    case FETCH_ALL_PATIENTS_REQUEST_SUCCEEDED:
      return {
        ...state,
        all_patients: action.data.patients,
        pagination: action.data.pagination,
        empty_message: action.data.emptyMessage,
      };
    case FETCH_PATIENT_DETAILS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        patientDetails: {
          ...state.patientDetails,
          ...action.data,
        },
      };
    case RESET_PATIENT_DETAILS_DATA:
      return {
        ...state,
        patientDetails: {
          ...patientDetailsTemplate,
        },
      };
    case UPDATE_ARCHIEVED_PATIENT:
      return {
        ...state,
        all_patients: [
          ...state.all_patients.filter(usr => usr.id !== action.data),
        ],
        pagination: {
          ...state.pagination,
          endNumber: state.pagination.endNumber - 1,
          total: state.pagination.total - 1,
        },
      };
    case RESET_ALL_PATIENTS_AFTER_LOGOUT:
      return {
        ...initialState,
      };
    case SET_PATIENT_SORT_DATA:
      return {
        ...state,
        sort: action.sort,
        direction: action.direction,
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import './masqueradingHeader.scss';

const MasqueradingHeader = props => {
  const { proxyUser } = props;
  return (
    <div className='masquerading-header'>
      <span>
        {`${proxyUser.firstName} ${proxyUser.lastName}'s Tremor Companion`}
      </span>
    </div>
  );
};

MasqueradingHeader.propTypes = {
  proxyUser: PropTypes.string.isRequired,
};

export default MasqueradingHeader;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getActiveCompositeProcedureId } from 'services/storage';
import Media from 'react-media';
import WatchClickOutside from '../../components/WatchClickOutside';
import {
  getPatientProcedureRequest,
  switchActiveProcedureRequest,
} from '../../common/actions';
import './manageProcedureSection.scss';
// import $ from 'jquery';
import ReactTooltip from 'react-tooltip';

class ManageProcedureSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      currentStage: this.props.currentStage,
      selectedSite: this.props.selectedSite,
      ctCenterLocation: this.props.selectedCtCenter,
    };
  }

  componentDidMount() {
    this.props.fetchPatientProcedures(true);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentStage: nextProps.currentStage,
      selectedSite: nextProps.selectedSite,
    });
  }

  render() {
    const activeCompositeProcedureDetails = this.props.compositeProcedures.find(
      item => item.id === this.props.compositeProcedureId
    );
    return (
      <div className='section-container' ref='manageProcedureSection'>
        <ReactTooltip />
        <div className='col-md-8 col-md-offset-2'>
          <div className='procedure-header'>
            {this.state.currentStage && (
              <div
                data-tip={this.state.currentStage}
                className='stage-header body'
              >
                <span className='header-key'>Current Stage:&nbsp;</span>
                <span className='header-value current-stage-header-value'>
                  {this.state.currentStage}{' '}
                </span>
              </div>
            )}
            {this.state.selectedSite && (
              <div
                data-tip={this.state.selectedSite}
                className='site-header hidden-xs body'
              >
                <span className='header-key'>Treatment Center:&nbsp;</span>
                <span
                  className='header-value site-header-value'
                  ref={ref => (this.fooRef = ref)}
                >
                  {this.state.selectedSite}
                </span>
              </div>
            )}

            {/* code to show ct center on top Header of Patient Dashboard */}

            {/* {
              this.state.ctCenterLocation && (
                <div data-tip={this.state.ctCenterLocation} className="ct-center-header  hidden-xs body">
                  <span className="header-key">CT Center:&nbsp;</span>
                  <span className="header-value ct-center-header-value">
                    {this.state.ctCenterLocation}
                  </span>
                </div>
              )
            } */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.profile.userDetails,
  isLoading: state.activeProcedureDetails.isLoading,
  isRequestInProgress: state.patientProcedures.isLoading,
  compositeProcedures: state.patientProcedures.compositeProcedures,
  compositeProcedureId:
    state.activeProcedureDetails.compositeProcedureId ||
    parseInt(getActiveCompositeProcedureId(), 10),
  currentStage: state.patientProcedures.currentStage,
  selectedSite: state.patientProcedures.selectedSite,
});

const mapDispatchToProps = dispatch => ({
  fetchPatientProcedures: shouldRedirect =>
    dispatch(getPatientProcedureRequest(shouldRedirect)),
  putSwitchActiveProcedure: userProcedureId =>
    dispatch(switchActiveProcedureRequest(userProcedureId)),
});

ManageProcedureSection.propTypes = {
  fetchPatientProcedures: PropTypes.func.isRequired,
  putSwitchActiveProcedure: PropTypes.func.isRequired,
  compositeProcedures: PropTypes.array,
  compositeProcedureId: PropTypes.number.isRequired,
  history: PropTypes.any,
  currentStage: PropTypes.string,
  selectedSite: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProcedureSection);

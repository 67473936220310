import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap/lib';
import isEmpty from 'lodash/isEmpty';
import { getItemFromStorage, setItemToStorage } from 'services/storage';
import Strings from '../../../strings';
import './styles.scss';

export default class WelcomeModal extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  onClose = () => {
    setItemToStorage('isWelcomeModalShown', '1');
    this.setState({ show: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const currentUser = isEmpty(getItemFromStorage('currentUser'))
      ? null
      : JSON.parse(getItemFromStorage('currentUser'));
    return this.state.show ? (
      <Modal
        show={this.state.show}
        onHide={this.onClose}
        container={document.body}
        autoFocus
        backdrop='static'
        aria-labelledby='contained-modal-title'
        className='primary-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title'>
            <div className='welcome-modal-header'>
              <div className='welcome-header'>{Strings.WELCOME_HEADER}</div>
              {!this.props.isUserInvited && (
                <React.Fragment>
                  <div className='welcome-header-separator' />
                  <div className='welcome-header-body'>
                    {Strings.WELCOME_HEADER_BODY}
                  </div>
                </React.Fragment>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='welcome-modal-body'>
            {this.props.isUserInvited ? (
              <React.Fragment>
                <div className='welcome-modal-body-text'>
                  Hi,
                  {currentUser.firstName}
                </div>
                <div className='welcome-modal-body-text'>
                  {Strings.WELCOME_MSG_1_INVITED_USER}
                </div>
                <div className='welcome-modal-body-text'>
                  {Strings.WELCOME_MSG_2_INVITED_USER}
                </div>
                <div className='welcome-modal-body-text last'>
                  {Strings.WELCOME_MSG_FOOTER}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='welcome-modal-body-text'>
                  {Strings.WELCOME_MSG_1}
                </div>
                <div className='welcome-modal-body-text'>
                  {Strings.WELCOME_MSG_2}
                </div>
                <div className='welcome-modal-body-text last'>
                  {Strings.WELCOME_MSG_FOOTER}
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
      </Modal>
    ) : null;
  }
}

WelcomeModal.propTypes = {
  isUserInvited: PropTypes.bool,
  onClose: PropTypes.func,
};

WelcomeModal.defaultProps = {
  isUserInvited: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import SucessViewImage from 'assets/images/patient-signup-success-img-2.png';
import MobileAppQrCodeImg from 'assets/images/mobile_app_qr_code.png';

const SuccessView = props => {
  const firstName = useSelector(state => state.patientRegister.firstName);
  const lastName = useSelector(state => state.patientRegister.lastName);

  return (
    <div className='sucess-view-container'>
      <div className='image-sucess-container'>
        <img src={SucessViewImage} alt='success-img' />
      </div>
      <div className='row success-section text-align-center'>
        <div className='col-xs-10 col-xs-offset-1 success-text'>
          <span className='success-text-title'>
            {firstName && lastName
              ? `Hello, ${firstName + ' ' + lastName}! `
              : 'Hello! '}
            Thank you for signing up for INSIGHTEC Connect. We look forward to
            providing you with edcuational resources about essential tremor and
            focused ultrasound to help you navigate your journey to tremor
            relief.
          </span>
          <span className='success-text-scan-qr-code'>
            Scan the QR code below with your mobile device to download the
            INSIGHTEC Connect app and login.
          </span>
        </div>
        <div className='button-container text-align-center'>
          <img
            className='app-listing-logo'
            src={MobileAppQrCodeImg}
            alt='google-play-store'
          />
        </div>
      </div>
    </div>
  );
};

SuccessView.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

SuccessView.defaultProps = {
  firstName: '',
  lastName: '',
};
export default SuccessView;

/*
 *
 * SelectCategory Constants
 *
 */

export const GET_SEARCH_CATEGORY_LIST_REQUEST_SENT =
  'src/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_SENT';
export const GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED =
  'src/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED';
export const GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED =
  'src/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED';

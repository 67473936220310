/*
 *
 * Patient Search Reducers
 *
 */
import {
  PATIENT_SEARCH_REQUEST_SENT,
  PATIENT_SEARCH_REQUEST_SUCCEED,
  PATIENT_SEARCH_REQUEST_FAILED,
} from './constants';

import { RESET_GLOBAL_REDUCERS } from '../../constants';

const initialState = {
  isSearching: false,
  facets: [],
  patients: [],
  pagination: {
    total: 0,
    page: 1,
    totalPages: 0,
    prevPage: null,
    nextPage: null,
    isFirstPage: true,
    isLastPage: true,
    startNumber: null,
    endNumber: null,
  },
};

export const patientSearchReducer = (state = initialState, action) => {
  let data = null;
  switch (action.type) {
    case PATIENT_SEARCH_REQUEST_FAILED:
      return {
        ...state,
        isSearching: false,
      };
    case PATIENT_SEARCH_REQUEST_SENT:
      return {
        ...state,
        isSearching: true,
      };
    case PATIENT_SEARCH_REQUEST_SUCCEED:
      ({ data } = action);
      return {
        ...state,
        isSearching: false,
        facets: data.facets,
        patients: data.patients,
        pagination: {
          total: data.total,
          page: data.page,
          totalPages: data.totalPages,
          prevPage: data.prevPage,
          nextPage: data.nextPage,
          isFirstPage: data.firstPage,
          isLastPage: data.lastPage,
          startNumber: data.startNumber,
          endNumber: data.endNumber,
        },
      };
    case RESET_GLOBAL_REDUCERS:
      return initialState;
    default:
      return state;
  }
};

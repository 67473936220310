import filter from 'lodash/filter';
import { CLEAR_HAS_PENDING_CONSENT_VALUE } from 'patientApp/containers/Appointments/constants';
import lodash from 'lodash';
import * as Constants from './constants';
import { RESET_GLOBAL_REDUCERS } from '../../../constants';

const initialState = {
  isLoading: false,
  results: [],
  isDeleteRequestInProgress: false,
  isMarkTaskCompleteRequestInProgress: false,
  isCreateTaskRequestInProgress: false,
  createdTask: {},
  recurringTaskDetails: {
    isLoading: false,
    data: {},
  },
  isAddTaskAttachmentRequestInProgress: false,
  isEventRequestInProgress: false,
  events: [],
  isSiteSelected: false,
  isCtCenterSelected: false,
  treatment_sites_data: null,
  treatment_sites_states: [],
  treatment_sites_cities: [],
  city_centers_states: [],
  city_centers_cities: [],
  ct_centers_data: null,
  procedureId: 0,
  procedureName: '',
  departmentName: '',
  userProcedureId: 0,
  total: 0,
  eventQuestions: [],
  hasError: false,
  hasPendingConsent: false,
  pendingSurveysCount: 0,
};

const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_ALL_TASKS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        createdTask: {},
      };
    case Constants.GET_ALL_TASKS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        results: action.data.taskResults,
        miniUserProcedures: action.data.miniUserProcedures,
      };
    case Constants.GET_ALL_TASKS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.POST_DELETE_TASK_REQUEST_SENT:
      return {
        ...state,
        isDeleteRequestInProgress: true,
      };
    case Constants.POST_DELETE_TASK_REQUEST_SUCCEED:
      return {
        ...state,
        isDeleteRequestInProgress: false,
        results: state.results.map(item => ({
          ...item,
          tasks: filter(item.tasks, task => task.id !== action.taskId),
        })),
      };
    case Constants.POST_DELETE_TASK_REQUEST_FAILED:
      return {
        ...state,
        isDeleteRequestInProgress: false,
      };
    case Constants.POST_MARK_TASK_COMPLETE_REQUEST_SENT:
      return {
        ...state,
        isMarkTaskCompleteRequestInProgress: true,
      };
    case Constants.POST_MARK_TASK_COMPLETE_REQUEST_SUCCEED:
      return {
        ...state,
        isMarkTaskCompleteRequestInProgress: false,
      };
    case Constants.POST_MARK_TASK_COMPLETE_REQUEST_FAILED:
      return {
        ...state,
        isMarkTaskCompleteRequestInProgress: false,
      };
    case Constants.POST_CREATE_TASK_REQUEST_SENT:
      return {
        ...state,
        isCreateTaskRequestInProgress: true,
      };
    case Constants.POST_CREATE_TASK_REQUEST_SUCCEED:
      return {
        ...state,
        isCreateTaskRequestInProgress: false,
        createdTask: action.data,
      };
    case Constants.POST_CREATE_TASK_REQUEST_FAILED:
      return {
        ...state,
        isCreateTaskRequestInProgress: true,
        createdTask: {},
      };
    case Constants.GET_TASKS_DETAILS_REQUEST_SENT:
      return {
        ...state,
        recurringTaskDetails: {
          ...state.recurringTaskDetails,
          isLoading: true,
        },
      };
    case Constants.GET_TASKS_DETAILS_REQUEST_SUCCEED:
      return {
        ...state,
        recurringTaskDetails: {
          ...state.recurringTaskDetails,
          isLoading: false,
          data: action.data.recurringTask,
        },
      };
    case Constants.GET_TASKS_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        recurringTaskDetails: {
          ...state.recurringTaskDetails,
          isLoading: false,
        },
      };
    case Constants.TASK_DETAILS_CLEAR_STATE:
      return {
        ...state,
        recurringTaskId: 0,
        recurringTaskDetails: {
          ...state.recurringTaskDetails,
          isLoading: false,
          data: {},
        },
      };
    case Constants.POST_ADD_TASK_ATTACHMENT_REQUEST_SENT:
      return {
        ...state,
        isAddTaskAttachmentRequestInProgress: true,
      };
    case Constants.POST_ADD_TASK_ATTACHMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isAddTaskAttachmentRequestInProgress: false,
      };
    case Constants.POST_ADD_TASK_ATTACHMENT_REQUEST_FAILED:
      return {
        ...state,
        isAddTaskAttachmentRequestInProgress: false,
      };

    case Constants.GET_APPOINTMENTS_REQUEST_SENT:
    case Constants.UPDATE_APPOINTMENT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        eventQuestions: [],
      };

    case Constants.GET_APPOINTMENTS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.GET_EVENT_QUESTIONS_REQUEST_SENT:
      return {
        ...state,
        isEventRequestInProgress: true,
        eventQuestions: [],
      };

    case Constants.GET_APPOINTMENTS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        events: [...action.appointments.events],
        total: action.appointments.total,
        procedureName: action.appointments.procedureName,
        departmentName: action.appointments.departmentName,
        userProcedureId: action.appointments.userProcedureId,
        hasPendingConsent: action.appointments.hasPendingConsent,
        pendingSurveysCount: action.appointments.pendingSurveysCount,
      };
    case Constants.GET_EVENT_QUESTIONS_REQUEST_FAILED:
      return {
        ...state,
        isEventRequestInProgress: false,
      };
    case Constants.GET_EVENT_QUESTIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isEventRequestInProgress: false,
        eventQuestions: [...action.data.questions],
      };

    case Constants.UPDATE_APPOINTMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      };
    case Constants.UPDATE_APPOINTMENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case CLEAR_HAS_PENDING_CONSENT_VALUE:
      return {
        ...state,
        hasPendingConsent: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return initialState;

    case Constants.GET_TREMOR_SITES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        treatment_sites_data: null,
      };
    case Constants.GET_TREMOR_SITES_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        treatment_sites_data: action.data,
      };
    case Constants.GET_TREMOR_SITES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    case Constants.GET_TREMOR_SITES_STATES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        treatment_sites_states: [],
      };
    case Constants.GET_TREMOR_SITES_STATES_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        treatment_sites_states: {
          states: action.data.states.map(state => ({
            name: state.name,
            cities: state.cities.map(city => lodash.startCase(city)),
          })),
        },
      };
    case Constants.GET_TREMOR_SITES_STATES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    case Constants.GET_CITY_CENTERS_STATES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        city_centers_states: [],
      };
    case Constants.GET_CITY_CENTERS_STATES_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        city_centers_states: {
          states: action.data.states.map(state => ({
            name: state.name,
            cities: state.cities.map(city => lodash.startCase(city)),
          })),
        },
      };
    case Constants.GET_CITY_CENTERS_STATES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    case Constants.GET_CITY_CENTERS_CITIES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        city_centers_cities: [],
      };
    case Constants.GET_CITY_CENTERS_CITIES_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        city_centers_cities: {
          cities: action.data.cities.map(city => lodash.startCase(city)),
        },
      };
    case Constants.GET_CITY_CENTERS_CITIES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    case Constants.GET_CITY_CENTERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        ct_centers_data: null,
      };
    case Constants.GET_CITY_CENTERS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        ct_centers_data: action.data,
      };
    case Constants.GET_CITY_CENTERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    case Constants.GET_TREMOR_SITES_CITIES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        treatment_sites_cities: [],
      };
    case Constants.GET_TREMOR_SITES_CITIES_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        treatment_sites_cities: {
          cities: action.data.cities.map(city => lodash.startCase(city)),
        },
      };
    case Constants.GET_TREMOR_SITES_CITIES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    case Constants.GET_ALL_TASKS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    case Constants.SITE_SELECT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.SITE_SELECT_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isSiteSelected: true,
      };
    case Constants.SITE_SELECT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSiteSelected: false,
      };

    case Constants.CT_CENTERS_SELECT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.CT_CENTERS_SELECT_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isCtCenterSelected: true,
      };
    case Constants.CT_CENTERS_SELECT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isCtCenterSelected: false,
      };
    case Constants.SITE_SELECTION_DONE_BY_USER:
      return {
        ...state,
        isSiteSelected: false,
      };
    default:
      return state;
  }
};

export default tasksReducer;

import axios from '../base';

import {
  FETCH_TRADITIONAL_SITE_USER_INFO,
  ARCHIVE_PATIENT,
  FETCH_PATIENT_DETAILS,
  FETCH_TRADITIONAL_SITE_PATIENTS,
  GET_LOGGED_IN_STATUS_CHECK,
} from './constants';

export const fetchTraditionalSiteUserInfo = params =>
  axios.get(FETCH_TRADITIONAL_SITE_USER_INFO);

export const getLoggedInStatus = params =>
  axios.get(GET_LOGGED_IN_STATUS_CHECK);

export const fetchPatientDetailsCall = params =>
  axios.get(FETCH_PATIENT_DETAILS + '/' + params.id);

export const archivePatientCall = id => axios.put(ARCHIVE_PATIENT, { id: id });

export const fetchTraditionalSitePatients = params => {
  return axios.post(FETCH_TRADITIONAL_SITE_PATIENTS, params);
};

import React from 'react';
import PropTypes from 'prop-types';
import './patientRegister.scss';

const NextButton = ({ onNext, enable, text }) => {
  return (
    <div>
      <button
        className='btn btn-primary next-btn '
        onClick={onNext}
        disabled={!enable}
        type='submit'
      >
        {text}
      </button>
    </div>
  );
};

NextButton.propTypes = {
  onNext: PropTypes.func.isRequired,
  enable: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default NextButton;

import axios from './base';
import { HEARTBEAT_INTERVAL_TIME } from 'envConstants';

export const startHeartBeats = options => {
  const currentHeartBeats = JSON.parse(
    localStorage.getItem('currentHeartBeats')
  );
  if (currentHeartBeats === undefined || currentHeartBeats === null) {
    const currentHeartBeat = [];
    localStorage.setItem('currentHeartBeats', JSON.stringify(currentHeartBeat));
  }
  const heartbeat_time = HEARTBEAT_INTERVAL_TIME
    ? HEARTBEAT_INTERVAL_TIME
    : 60000;
  const intervalId = setInterval(
    sendHeartBeats.bind(null, options),
    heartbeat_time
  );
  const option = currentHeartBeats.find(option => option.id === options.id);
  if (!option) {
    options.interval_id.push(intervalId);
    currentHeartBeats.push(options);
    localStorage.setItem(
      'currentHeartBeats',
      JSON.stringify(currentHeartBeats)
    );
  } else {
    currentHeartBeats.pop(option);
    option.interval_id.push(intervalId);
    currentHeartBeats.push(option);
    localStorage.setItem(
      'currentHeartBeats',
      JSON.stringify(currentHeartBeats)
    );
  }
};

export const stopHeartBeats = options => {
  const currentHeartBeats = JSON.parse(
    localStorage.getItem('currentHeartBeats')
  );
  const option = currentHeartBeats.find(option => option.id === options.id);
  if (option) {
    option.interval_id.map((inetrval, index) => clearInterval(inetrval));
    currentHeartBeats.pop(option);
    localStorage.setItem(
      'currentHeartBeats',
      JSON.stringify(currentHeartBeats)
    );
  } else if (
    Object.keys(options).length === 0 &&
    options.constructor === Object
  ) {
    for (const currentHeartBeat of currentHeartBeats) {
      for (const inetrval of currentHeartBeat.interval_id) {
        clearInterval(inetrval);
      }
      currentHeartBeats.pop(currentHeartBeat);
    }
    localStorage.setItem(
      'currentHeartBeats',
      JSON.stringify(currentHeartBeats)
    );
  }
};

let sendHeartBeats = options => {
  const response = axios
    .get(options.url, {
      params: { options },
    })
    .then(result => {
      if (result.data.shouldEnd) {
        stopHeartBeats(options);
      }
    })
    .catch(error => {
      console.log(error.response.data);
    });
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import './backNavigation.scss';

class BackNavigation extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div className='back-navigation-container text-align-center'>
        <span
          role='button'
          tabIndex='0'
          className='back-navigation primary-text-color float-left'
          onClick={() => this.props.history.goBack()}
        >
          <span className='icon icon-font-a-left-chevron' />
          {/* <span className="back-navigation-text">Back</span> */}
        </span>
        <span className='back-navigation-title'>{this.props.title}</span>
        <div className='clearfix' />
      </div>
    );
  }
}

BackNavigation.propTypes = {
  history: PropTypes.object,
  title: PropTypes.string,
};

export default withRouter(BackNavigation);

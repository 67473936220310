import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import './confirmationModal.scss';

export default class ConfirmationModal extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      show: props.show ? props.show : false,
    };
    this.onModalClose = this.onModalClose.bind(this);
    this.onConfirmed = this.onConfirmed.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show,
    });
  }

  onModalClose() {
    this.props.onModalClose();
    this.setState({
      show: false,
    });
  }

  onConfirmed() {
    console.log(this.props.onConfirmed);
    this.setState({
      show: false,
    });
    this.props.onConfirmed();
  }

  renderRenderButtons() {
    return this.props.isDeleteConfirmation ? (
      <div className='buttons-center'>
        {this.props.okBtnText && (
          <div>
            <button className='btn btn-danger' onClick={this.onConfirmed}>
              {this.props.okBtnText}
            </button>
          </div>
        )}
        {this.props.cancelBtnText && (
          <div>
            <button className='btn btn-default' onClick={this.onModalClose}>
              {this.props.cancelBtnText}
            </button>
          </div>
        )}
      </div>
    ) : (
      <div className='buttons-center'>
        {this.props.cancelBtnText && (
          <div>
            <button className='btn btn-default' onClick={this.onModalClose}>
              {this.props.cancelBtnText}
            </button>
          </div>
        )}
        {this.props.okBtnText && (
          <div>
            <button
              className='btn btn-primary-conformation-modal'
              onClick={this.onConfirmed}
            >
              {this.props.okBtnText}
            </button>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.onModalClose}
        container={document.body}
        autoFocus
        aria-labelledby='contained-modal-title'
        className='primary-modal'
      >
        {this.props.title && (
          <Modal.Header closeButton={this.props.showCrossBtn != false}>
            <Modal.Title id='contained-modal-title'>
              {this.props.title}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className='modal-text'>{this.props.text}</div>
          <div className='confirm-modal_btns_container'>
            {this.renderRenderButtons()}
          </div>
          <div className='clr' />
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  okBtnText: PropTypes.string.isRequired,
  isDeleteConfirmation: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  isDeleteConfirmation: false,
};

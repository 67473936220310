import React from 'react';
import PropTypes from 'prop-types';
import './floatingInput.scss';

class FloatingLabelInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isInputFocused: props.isFocused ? props.isFocused : false,
    };

    this.inputRef = React.createRef();
  }

  handleFocus = () => this.setState({ isInputFocused: true });

  handleBlur = () => {
    this.setState({ isInputFocused: false });
  };

  focusField = () => {
    this.inputRef.current.focus();
  };

  render() {
    const { label, isFocused, ...props } = this.props;
    const { isInputFocused, autoFill } = this.state;
    const classNames = isInputFocused
      ? 'input-label-is-focused input-label-with-value'
      : this.props.value !== ''
      ? 'input-label-with-value'
      : autoFill
      ? 'input-label-with-value'
      : 'input-label-without-value';

    return (
      <div className='input-container pos-rel'>
        <span
          role='presentation'
          onClick={this.focusField}
          className={classNames}
        >
          {label}
        </span>
        <input
          {...props}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          ref={this.inputRef}
          autoFocus={isFocused}
        />
      </div>
    );
  }
}

FloatingLabelInput.propTypes = {
  isFocused: PropTypes.bool,
  label: PropTypes.any,
  value: PropTypes.any,
};

export default FloatingLabelInput;

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { useDispatch, useSelector } from 'react-redux';
import './patient-archive-modal.scss';
import { getFormatedPhoneNumber } from 'utils/stringUtils';

const PatientDetailsModal = props => {
  const patientDetails = useSelector(
    store => store.traditionalSiteReducer.patientDetails
  );

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop='static'
        aria-labelledby='contained-modal-title'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title'>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='order-detail-modal'>
            <div className='patient-name'>
              {patientDetails.fullName ?? 'N/A'}
            </div>
            <div className='order-details'>
              <div className='details-outer-wrapper'>
                <div className='title'>Contact Info</div>
                <div className='details-wrapper'>
                  <div className='details'>
                    <div className='key-value'>
                      <span className='key'>Phone Number</span>
                      <span className='value'>
                        {patientDetails.phoneNumber &&
                        patientDetails.phoneNumber !== ''
                          ? getFormatedPhoneNumber(patientDetails.phoneNumber)
                          : 'N/A'}
                      </span>
                    </div>
                    <div className='key-value'>
                      <span className='key'>Email Address</span>
                      <span className='value'>
                        {patientDetails.email ?? 'N/A'}
                      </span>
                    </div>
                    <div className='key-value'>
                      <span className='key'>Zip Code</span>
                      <span className='value'>
                        {patientDetails.zipCode ?? 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='details-outer-wrapper'>
                <div className='title'>Patient Responses</div>
                <div className='survey-details-wrapper'>
                  {patientDetails.surveyQuestions.map(question => {
                    return (
                      <div className='survey-details'>
                        <div className='key-value'>
                          <span className='key'>{question.text ?? 'N/A'}</span>
                          <span className='value'>
                            {patientDetails.survey[question.questionId] ??
                              'N/A'}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatientDetailsModal;

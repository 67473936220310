export const GET_PATIENT_PROCEDURES_REQUEST_SENT =
  'app/PatientApp/GET_PATIENT_PROCEDURES_REQUEST_SENT';
export const GET_PATIENT_PROCEDURES_REQUEST_SUCCEED =
  'app/PatientApp/GET_PATIENT_PROCEDURES_REQUEST_SUCCEED';
export const GET_PATIENT_PROCEDURES_REQUEST_FAILED =
  'app/PatientApp/GET_PATIENT_PROCEDURES_REQUEST_FAILED';

export const PUT_SWITCH_ACTIVE_PROCEDURE_SENT =
  'app/PatientApp/PUT_SWITCH_ACTIVE_PROCEDURE_SENT';
export const PUT_SWITCH_ACTIVE_PROCEDURE_SUCCEED =
  'app/PatientApp/PUT_SWITCH_ACTIVE_PROCEDURE_SUCCEED';
export const PUT_SWITCH_ACTIVE_PROCEDURE_FAILED =
  'app/PatientApp/PUT_SWITCH_ACTIVE_PROCEDURE_FAILED';

export const DELETE_PROCEDURE_REQUEST_SENT =
  'app/patientApp/Caregivers/DELETE_PROCEDURE_REQUEST_SENT';
export const DELETE_PROCEDURE_REQUEST_SUCCEED =
  'app/patientApp/Caregivers/DELETE_PROCEDURE_REQUEST_SUCCEED';
export const DELETE_PROCEDURE_REQUEST_FAILED =
  'app/patientApp/Caregivers/DELETE_PROCEDURE_REQUEST_FAILED';

export const VERIFY_INVITE_CODE_REQUEST_SENT =
  'app/patientApp/Caregivers/VERIFY_INVITE_CODE_REQUEST_SENT';
export const VERIFY_INVITE_CODE_REQUEST_SUCCEED =
  'app/patientApp/Caregivers/VERIFY_INVITE_CODE_REQUEST_SUCCEED';
export const VERIFY_INVITE_CODE_REQUEST_FAILED =
  'app/patientApp/Caregivers/VERIFY_INVITE_CODE_REQUEST_FAILED';
export const VERIFY_INVITE_CODE_CLEAR_STATE =
  'app/patientApp/Caregivers/VERIFY_INVITE_CODE_CLEAR_STATE';

export const CLEAR_PATIENT_PROCEDURES =
  'app/patientApp/CLEAR_PATIENT_PROCEDURES';

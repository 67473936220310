import { showToaster } from 'common/toasterActions';
import {
  fetchAllLocationsOfTheDepartment,
  addNewLocation,
  updateLocation,
  fetchTimezones,
} from 'services/locationAdministration';

import {
  GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SENT,
  GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
  GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_FAILED,
  ADD_NEW_LOCATION_REQUEST_SENT,
  ADD_NEW_LOCATION_REQUEST_SUCCEED,
  ADD_NEW_LOCATION_REQUEST_FAILED,
  UPDATE_LOCATION_REQUEST_SENT,
  UPDATE_LOCATION_REQUEST_SUCCEED,
  UPDATE_LOCATION_REQUEST_FAILED,
  GET_TIMEZONES_REQUEST_SENT,
  GET_TIMEZONES_REQUEST_SUCCEED,
  GET_TIMEZONES_REQUEST_FAILED,
} from './constants';

export const fetchAllLocationsOfTheDepartmentRequestSent = () => ({
  type: GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SENT,
});

export const fetchAllLocationsOfTheDepartmentSucceeded = data => ({
  type: GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
  data,
});

export const fetchAllLocationsOfTheDepartmentRequestFailed = data => ({
  type: GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_FAILED,
  data,
});

export const addNewLocationRequestSent = () => ({
  type: ADD_NEW_LOCATION_REQUEST_SENT,
});

export const addNewLocationSucceeded = () => ({
  type: ADD_NEW_LOCATION_REQUEST_SUCCEED,
});

export const addNewLocationRequestFailed = () => ({
  type: ADD_NEW_LOCATION_REQUEST_FAILED,
});

export const updateLocationRequestSent = () => ({
  type: UPDATE_LOCATION_REQUEST_SENT,
});

export const updateLocationSucceeded = () => ({
  type: UPDATE_LOCATION_REQUEST_SUCCEED,
});

export const updateLocationRequestFailed = () => ({
  type: UPDATE_LOCATION_REQUEST_FAILED,
});

export const fetchTimezonesRequestSent = () => ({
  type: GET_TIMEZONES_REQUEST_SENT,
});

export const fetchTimezonesSucceeded = data => ({
  type: GET_TIMEZONES_REQUEST_SUCCEED,
  data,
});

export const fetchTimezonesRequestFailed = data => ({
  type: GET_TIMEZONES_REQUEST_FAILED,
  data,
});

export const onfetchAllLocationsOfTheDepartmentRequest = params => dispatch => {
  dispatch(fetchAllLocationsOfTheDepartmentRequestSent());
  fetchAllLocationsOfTheDepartment(params)
    .then(response => {
      dispatch(fetchAllLocationsOfTheDepartmentSucceeded(response.data));
    })
    .catch(error => {
      dispatch(
        fetchAllLocationsOfTheDepartmentRequestFailed(error.response.data)
      );
    });
};

export const onAddNewLocationRequest = (params, pagination) => dispatch => {
  dispatch(addNewLocationRequestSent());
  addNewLocation(params)
    .then(() => {
      dispatch(addNewLocationSucceeded());
      dispatch(showToaster({ message: 'Location added successfully.' }));
      dispatch(onfetchAllLocationsOfTheDepartmentRequest(pagination));
    })
    .catch(() => {
      dispatch(addNewLocationRequestFailed());
    });
};

export const updateLocationAction = (params, pagination) => dispatch => {
  dispatch(updateLocationRequestSent());
  updateLocation(params)
    .then(() => {
      dispatch(updateLocationSucceeded());
      dispatch(showToaster({ message: 'Location updated successfully.' }));
      dispatch(onfetchAllLocationsOfTheDepartmentRequest(pagination));
    })
    .catch(() => {
      dispatch(updateLocationRequestFailed());
    });
};

export const onfetchTimezonesRequest = params => dispatch => {
  dispatch(fetchTimezonesRequestSent());
  fetchTimezones(params)
    .then(response => {
      dispatch(fetchTimezonesSucceeded(response.data));
    })
    .catch(error => {
      dispatch(fetchTimezonesRequestFailed(error.response.data));
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import './flash_message.scss';

class FlashMessage extends React.PureComponent {
  render() {
    const iconClass =
      this.props.type === 'success'
        ? 'icon-font-a-notification-filled'
        : 'icon-font-a-error-message';

    return (
      <div className={`flash-message ${this.props.type}`}>
        <div className='float-left col-xs-10 col-sm-12 col-md-12 col-lg-12 message-content'>
          <span
            className={`icon icon-font-a-check-mark flash-message-icon ${iconClass} ${this.props.type}`}
          />
          <span>{this.props.message}</span>
        </div>
        <div
          className={`float-right icon icon-font-a-close close-flash-icon cursor-pointer ${this.props.type}`}
          onClick={event => this.props.onRemoveToast(event, this.props.id)}
        />
      </div>
    );
  }
}

FlashMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  onRemoveToast: PropTypes.func.isRequired,
  id: PropTypes.any,
};

export default FlashMessage;

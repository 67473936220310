import { deleteProcedure } from 'services/patient/patientDashboard';
import { showToaster } from 'common/toasterActions';
import history from '../../configureHistory';
import {
  getPatientProceduresService,
  switchActiveProcedure,
  verifyInviteCode,
} from '../../services/procedures';

import {
  GET_PATIENT_PROCEDURES_REQUEST_SENT,
  GET_PATIENT_PROCEDURES_REQUEST_SUCCEED,
  GET_PATIENT_PROCEDURES_REQUEST_FAILED,
  PUT_SWITCH_ACTIVE_PROCEDURE_SENT,
  PUT_SWITCH_ACTIVE_PROCEDURE_SUCCEED,
  PUT_SWITCH_ACTIVE_PROCEDURE_FAILED,
  DELETE_PROCEDURE_REQUEST_SENT,
  DELETE_PROCEDURE_REQUEST_SUCCEED,
  DELETE_PROCEDURE_REQUEST_FAILED,
  VERIFY_INVITE_CODE_REQUEST_SENT,
  VERIFY_INVITE_CODE_REQUEST_SUCCEED,
  VERIFY_INVITE_CODE_REQUEST_FAILED,
  VERIFY_INVITE_CODE_CLEAR_STATE,
  CLEAR_PATIENT_PROCEDURES,
} from './constants';

export const getPatientProcedureRequestSent = () => ({
  type: GET_PATIENT_PROCEDURES_REQUEST_SENT,
});

export const getPatientProcedureRequestSucceeded = (data, shouldRedirect) => ({
  type: GET_PATIENT_PROCEDURES_REQUEST_SUCCEED,
  data,
  shouldRedirect,
});

export const getPatientProcedureRequestFailed = () => ({
  type: GET_PATIENT_PROCEDURES_REQUEST_FAILED,
});

export const putSwitchProcedureRequestSent = () => ({
  type: PUT_SWITCH_ACTIVE_PROCEDURE_SENT,
});

export const putSwitchProcedureRequestSucceeded = (
  compositeProcedureId,
  response
) => ({
  type: PUT_SWITCH_ACTIVE_PROCEDURE_SUCCEED,
  compositeProcedureId,
  departmentConfigurations: response.departmentConfigurations,
});

export const putSwitchProcedureRequestFailed = () => ({
  type: PUT_SWITCH_ACTIVE_PROCEDURE_FAILED,
});

export const fetchDeleteRequestSent = () => ({
  type: DELETE_PROCEDURE_REQUEST_SENT,
});

export const fetchDeleteSucceeded = (data, compositeProcedureIds) => ({
  type: DELETE_PROCEDURE_REQUEST_SUCCEED,
  data,
  compositeProcedureIds,
});

export const fetchDeleteRequestFailed = data => ({
  type: DELETE_PROCEDURE_REQUEST_FAILED,
  data,
});

export const verifyInviteCodeRequestSent = () => ({
  type: VERIFY_INVITE_CODE_REQUEST_SENT,
});

export const verifyInviteCodeRequestSucceeded = (data, inviteCode) => ({
  type: VERIFY_INVITE_CODE_REQUEST_SUCCEED,
  data,
  inviteCode,
});

export const verifyInviteCodeRequestFailed = () => ({
  type: VERIFY_INVITE_CODE_REQUEST_FAILED,
});

export const clearInviteCodeStatesRequest = () => ({
  type: VERIFY_INVITE_CODE_CLEAR_STATE,
});

export const clearPatientProceduresRequest = () => ({
  type: CLEAR_PATIENT_PROCEDURES,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const switchActiveProcedureRequest =
  (compositeProcedureId, isFromManageProcedures = false) =>
  dispatch => {
    dispatch(putSwitchProcedureRequestSent());
    switchActiveProcedure(compositeProcedureId)
      .then(response => {
        dispatch(
          putSwitchProcedureRequestSucceeded(
            compositeProcedureId,
            response.data
          )
        );
        history.replace(
          `/mydashboard/${compositeProcedureId}/procedures/appointments`
        );
        if (isFromManageProcedures) {
          dispatch(
            showToaster({ message: 'Procedure switched successfully.' })
          );
        }
      })
      .catch(() => {
        dispatch(putSwitchProcedureRequestFailed());
      });
  };

export const getPatientProcedureRequest =
  (shouldRedirect = false) =>
  dispatch => {
    dispatch(getPatientProcedureRequestSent());
    getPatientProceduresService()
      .then(response => {
        dispatch(
          getPatientProcedureRequestSucceeded(response.data, shouldRedirect)
        );
        if (shouldRedirect) {
          const activeCompositeProcedure =
            response.data.compositeProcedures.find(item => item.isActive);
          if (activeCompositeProcedure) {
            // history.replace(`/mydashboard/${activeCompositeProcedure.id}/procedures`);
          }
        }
      })
      .catch(() => {
        dispatch(getPatientProcedureRequestFailed());
      });
  };

export const deleteProcedureRequest = compositeProcedureIds => dispatch => {
  dispatch(fetchDeleteRequestSent());
  deleteProcedure(compositeProcedureIds)
    .then(response => {
      dispatch(fetchDeleteSucceeded(response.data, compositeProcedureIds));
      dispatch(showToaster({ message: 'Procedure deleted successfully.' }));
      // getPatientProcedureRequest();
    })
    .catch(error => {
      dispatch(fetchDeleteRequestFailed(error.response.data));
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const verifyInviteCodeRequest =
  (inviteCode, isForAddProcedure = false) =>
  dispatch => {
    dispatch(verifyInviteCodeRequestSent());
    verifyInviteCode(inviteCode)
      .then(response => {
        dispatch(verifyInviteCodeRequestSucceeded(response.data, inviteCode));
        if (!isForAddProcedure) {
          history.replace('/signup');
          dispatch(clearInviteCodeStates());
        }
      })
      .catch(() => {
        dispatch(verifyInviteCodeRequestFailed());
        dispatch(
          showToaster({ message: 'Invalid invite code', type: 'error' })
        );
      });
  };

export const clearInviteCodeStates = () => dispatch => {
  dispatch(clearInviteCodeStatesRequest());
};

export const clearPatientProcedures = () => dispatch => {
  dispatch(clearPatientProceduresRequest());
};

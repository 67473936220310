import React from 'react';

import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from 'envConstants';
import AppStore from '../../assets/images/app-store.png';
import GooglePlayStoreImg from '../../assets/images/google-play-store.png';

const AppUrls = () => {
  return (
    <div className='button-container text-align-center'>
      <a href={GOOGLE_PLAY_STORE_URL}>
        <img
          className='app-listing-logo'
          src={GooglePlayStoreImg}
          alt='app-store'
        />
      </a>
      <a href={APP_STORE_URL}>
        <img className='app-listing-logo' src={AppStore} alt='app-store' />
      </a>
    </div>
  );
};

export default AppUrls;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Media from 'react-media';

import validate, { clearErrorsForField } from 'common/validator';
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from 'envConstants';
import WelcomeView from '../../components/Authorization/WelcomeView';
import TreatmentOptionsView from '../../components/Authorization/TreatmentOptionsView';
import Wrapper from '../../components/Authorization/Wrapper';
import * as Constants from './constants';
import NameView from '../../components/Authorization/NameView';
import ContactView from '../../components/Authorization/ContactView';
import OtpVerificationView from '../../components/Authorization/OtpVerificationView';
import PasswordView from '../../components/Authorization/PasswordView';
import SuccessView from '../../components/Authorization/SuccessView';
import AppUrlsView from '../../components/Authorization/AppUrlsView';

import {
  generateOtp,
  verifyOtp,
  pageChange,
  createPatient,
  getFlowId,
  patientSetResetPasswordRequest,
} from './action';
import {
  nameValidationConfig,
  phoneValidationConfig,
  phoneOtpValidationConfig,
  passwordValidationConfig,
} from './validators';
import './patientRegister.scss';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  zipCode: '',
  phone: '',
  phoneOtp: '',
  errors: {},
  password: '',
  diagnosedDisease: '',
};

const PatientRegister = () => {
  const dispatch = useDispatch();
  const page = useSelector(state => state.patientRegister.page);
  const flowId = useSelector(state => state.patientRegister.flowId);
  const userData = useSelector(state => state.patientRegister.userInfo);
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (
      userData &&
      (userData.first_name ||
        userData.last_name ||
        userData.email ||
        userData.zipcode)
    ) {
      setState(prevState => ({
        ...prevState,
        firstName: userData.first_name ? userData.first_name : '',
        lastName: userData.last_name ? userData.last_name : '',
        email: userData.email ? userData.email : '',
        zipCode: userData.zipcode ? userData.zipcode : '',
      }));
    }
  }, [userData]);

  useEffect(() => {
    dispatch(pageChange(1));
  }, []);

  const validationConfig = () => {
    let config = {};
    switch (page) {
      case Constants.PHONE_VIEW:
        config = phoneValidationConfig;
        break;
      case Constants.NAME_VIEW:
        config = nameValidationConfig;
        break;
      case Constants.OTP_VERIFICATION_VIEW:
        config = phoneOtpValidationConfig;
        break;
      case Constants.PASSWORD_VIEW:
        config = passwordValidationConfig;
        break;
      default:
        break;
    }
    return config;
  };

  const handleResendOtp = () => {
    const { phone } = state;
    if (page === Constants.OTP_VERIFICATION_VIEW) {
      const params = {
        flow_type: Constants.FLOW_TYPE,
        flow_id: flowId,
        contact_number: phone,
      };
      dispatch(generateOtp(params, Constants.OTP_VERIFICATION_VIEW));
    }
  };

  const onValidationFailure = errors => {
    const obj = { ...state };
    obj.errors = errors;
    setState(obj);
  };

  const onNextValidationSuccess = () => {
    const {
      phone,
      firstName,
      lastName,
      phoneOtp,
      zipCode,
      email,
      password,
      diagnosedDisease,
    } = state;

    let nextSuccessPage = 1;
    if (page === Constants.PHONE_VIEW) {
      const params = {
        flow_type: Constants.FLOW_TYPE,
        flow_id: flowId,
        contact_number: phone,
        page,
      };
      nextSuccessPage = Constants.OTP_VERIFICATION_VIEW;
      dispatch(generateOtp(params, nextSuccessPage));
    } else if (page === Constants.OTP_VERIFICATION_VIEW) {
      const params = {
        verification_code: phoneOtp,
        flow_type: Constants.FLOW_TYPE,
        flow_id: flowId,
        contact_number: phone,
      };
      nextSuccessPage = Constants.TREATMENT_OPTIONS_VIEW;
      dispatch(verifyOtp(params, nextSuccessPage, Constants.PASSWORD_VIEW));
    } else if (page === Constants.NAME_VIEW) {
      nextSuccessPage = Constants.PASSWORD_VIEW;
      dispatch(pageChange(nextSuccessPage));
    } else if (page === Constants.PASSWORD_VIEW) {
      nextSuccessPage = Constants.SUCCESS_VIEW;

      const isAnyFieldEmpty = Object.values(state).some(
        value => typeof value === 'string' && value.trim() === ''
      );

      if (isAnyFieldEmpty) {
        const params = {
          otp: phoneOtp,
          contact_number: phone,
          flow_id: flowId,
          flow_type: Constants.FLOW_TYPE,
          has_accepted_tnc: true,
          password,
        };
        dispatch(patientSetResetPasswordRequest(params));
        dispatch(pageChange(nextSuccessPage));
      } else {
        const params = {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          phone,
          email,
          phoneOtp,
          flowId,
          zipCode,
          password,
          flowType: Constants.FLOW_TYPE,
          diagnosedDisease,
          signupPlatform: 'web',
        };
        dispatch(createPatient(params, nextSuccessPage));
      }
    }
  };

  const handleNext = val => {
    let nextSuccessPage = 1;
    const config = validationConfig();

    if (page === Constants.WELCOME_VIEW) {
      const params = {
        flow_type: Constants.FLOW_TYPE,
      };
      nextSuccessPage = Constants.PHONE_VIEW;
      dispatch(getFlowId(params, nextSuccessPage));
    } else if (page === Constants.TREATMENT_OPTIONS_VIEW) {
      const newState = { ...state };
      newState.diagnosedDisease = val;
      setState(newState);
      nextSuccessPage = Constants.NAME_VIEW;
      dispatch(pageChange(nextSuccessPage));
    } else if (
      page === Constants.NAME_VIEW ||
      page === Constants.PHONE_VIEW ||
      page === Constants.OTP_VERIFICATION_VIEW ||
      page === Constants.PASSWORD_VIEW
    ) {
      validate(config, state, onValidationFailure, onNextValidationSuccess);
    }
  };

  useEffect(() => {
    if (state.phoneOtp.length === 6) {
      handleNext();
    }
  }, [state.phoneOtp]);

  const handleChange = e => {
    const obj = { ...state };
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(state.errors, e.target.name);
    setState(obj);
  };

  const handleOtpChange = otp => {
    if (state.phoneOtp.length === 6 && otp === state.phoneOtp) {
      handleNext();
    } else {
      setState(prevState => ({
        ...prevState,
        phoneOtp: otp,
      }));
    }
  };

  const handlePasswordChange = e => {
    const obj = { ...state };
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(state.errors, e.target.name);
    setState(obj);
  };

  const handleBack = () => {
    if (page === Constants.PASSWORD_VIEW) {
      setState(prevState => ({
        ...prevState,
        password: '',
      }));
      dispatch(pageChange(Constants.PHONE_VIEW));
    } else if (page === Constants.TREATMENT_OPTIONS_VIEW) {
      dispatch(pageChange(page - 2));
    } else {
      dispatch(pageChange(page - 1));
    }
  };

  const renderPatientSignUp = () => {
    const {
      phone,
      errors,
      phoneOtp,
      firstName,
      lastName,
      zipCode,
      email,
      password,
      diagnosedDisease,
    } = state;

    switch (page) {
      case Constants.PHONE_VIEW:
        return (
          <Wrapper
            page={page}
            handleBack={handleBack}
            hasProgressBar
            heroImageNo={2}
            flowType='sign_up'
          >
            <ContactView
              contact={phone}
              onChange={handleChange}
              onNext={handleNext}
              errors={errors}
              flowType='sign_up'
            />
          </Wrapper>
        );

      case Constants.OTP_VERIFICATION_VIEW:
        return (
          <Wrapper
            page={page}
            handleBack={handleBack}
            hasProgressBar
            heroImageNo={2}
            flowType='sign_up'
          >
            <OtpVerificationView
              phone={phone}
              phoneOtp={phoneOtp}
              onNext={handleOtpChange}
              errors={errors}
              handleResendOtp={handleResendOtp}
              flowType='sign_up'
            />
          </Wrapper>
        );

      case Constants.TREATMENT_OPTIONS_VIEW:
        return (
          <Wrapper
            page={page}
            handleBack={handleBack}
            hasProgressBar
            heroImageNo={2}
            flowType='sign_up'
          >
            <TreatmentOptionsView
              onNext={handleNext}
              errors={errors}
              diagnosedDisease={diagnosedDisease}
              condition={userData ? userData.condition : null}
              flowType='sign_up'
            />
          </Wrapper>
        );

      case Constants.NAME_VIEW:
        return (
          <Wrapper
            page={page}
            handleBack={handleBack}
            hasProgressBar
            heroImageNo={2}
            flowType='sign_up'
          >
            <NameView
              phone={phone}
              onChange={handleChange}
              onNext={handleNext}
              errors={errors}
              firstName={firstName}
              lastName={lastName}
              zipCode={zipCode}
              email={email}
              flowType='sign_up'
            />
          </Wrapper>
        );

      case Constants.PASSWORD_VIEW:
        return (
          <Wrapper
            page={page}
            handleBack={handleBack}
            hasProgressBar
            heroImageNo={2}
            flowType='sign_up'
          >
            <PasswordView
              password={password}
              errors={errors}
              onChange={handlePasswordChange}
              onNext={handleNext}
              email={email}
              phone={phone}
              flowType='sign_up'
            />
          </Wrapper>
        );

      case Constants.SUCCESS_VIEW:
        return (
          <div className='dashboard-container-fluid app-theme patient-register-container'>
            <SuccessView firstName={firstName} lastName={lastName} />;
          </div>
        );

      default:
        return '';
    }
  };

  const handleMobileDeepLinking = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (/android/.test(userAgent)) {
      // Redirect to Play Store for Android devices
      window.location.replace(GOOGLE_PLAY_STORE_URL);
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      // Redirect to App Store for iOS devices
      window.location.replace(APP_STORE_URL);
    }

    return (
      <div className='mobile-deep-linking'>
        Please use a mobile app or a tablet app to access the signup feature.
        <AppUrlsView />
      </div>
    );
  };

  if (Constants.WELCOME_VIEW === page) {
    return (
      <div className='dashboard-container-fluid app-theme patient-register-container'>
        <WelcomeView onNext={handleNext} />
      </div>
    );
  }

  return (
    <Media
      queries={{
        screenIsSmall: '(max-width: 1024px)',
        deviceOrientation: '(orientation: landscape)',
        screenIsMedium: '(max-width: 1260px)',
      }}
    >
      {matches =>
        (matches.deviceOrientation && matches.screenIsMedium) ||
        matches.screenIsSmall
          ? handleMobileDeepLinking()
          : renderPatientSignUp()
      }
    </Media>
  );
};

export default PatientRegister;

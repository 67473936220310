export const GET_CONVERSATIONS_REQUEST_SENT =
  'app/patientApp/Conversations/GET_CONVERSATIONS_REQUEST_SENT';
export const GET_CONVERSATIONS_REQUEST_SUCCEED =
  'app/patientApp/Conversations/GET_CONVERSATIONS_REQUEST_SUCCEED';
export const GET_CONVERSATIONS_REQUEST_FAILED =
  'app/patientApp/Conversations/GET_CONVERSATIONS_REQUEST_FAILED';

export const CREATE_CONVERSATIONS_REQUEST_SENT =
  'app/patientApp/Conversations/CREATE_CONVERSATIONS_REQUEST_SENT';
export const CREATE_CONVERSATIONS_REQUEST_SUCCEED =
  'app/patientApp/Conversations/CREATE_CONVERSATIONS_REQUEST_SUCCEED';
export const CREATE_CONVERSATIONS_REQUEST_FAILED =
  'app/patientApp/Conversations/CREATE_CONVERSATIONS_REQUEST_FAILED';

export const GET_PROCEDURE_MANAGERS_REQUEST_SUCCEED =
  'app/ManageProcedureManagers/GET_PROCEDURE_MANAGERS_REQUEST_SUCCEED';

export const GET_CONVERSATION_MESSAGES_REQUEST_SENT =
  'app/patientApp/Conversations/GET_CONVERSATION_MESSAGES_REQUEST_SENT';
export const GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED =
  'app/patientApp/Conversations/GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED';
export const GET_CONVERSATION_MESSAGES_REQUEST_FAILED =
  'app/patientApp/Conversations/GET_CONVERSATION_MESSAGES_REQUEST_FAILED';

export const SEND_MESSAGE_REQUEST_SENT =
  'app/patientApp/Conversations/SEND_MESSAGE_REQUEST_SENT';
export const SEND_MESSAGE_REQUEST_SUCCEED =
  'app/patientApp/Conversations/SEND_MESSAGE_REQUEST_SUCCEED';
export const SEND_MESSAGE_REQUEST_FAILED =
  'app/patientApp/Conversations/SEND_MESSAGE_REQUEST_FAILED';

export const ALTER_SEND_MESSAGE_FLAG =
  'app/patientApp/Conversations/ALTER_SEND_MESSAGE_FLAG';

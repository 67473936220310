import * as Constants from '../constants';

const initialState = {
  isLoading: false,
  conversations: [],
  pagination: {
    page: 1,
  },
  procedureManagers: [],
};

const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_CONVERSATIONS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_CONVERSATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        conversations: action.data.conversations,
        pagination: action.data.pagination,
        disclaimerText: action.data.disclaimerText,
      };
    case Constants.GET_CONVERSATIONS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.GET_PROCEDURE_MANAGERS_REQUEST_SUCCEED:
      return {
        ...state,
        procedureManagers: action.data.providerManagers.concat(
          action.data.patientAdvocateManagers
        ),
      };
    default:
      return state;
  }
};

export default conversationsReducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectReducer from 'utils/injectReducer';
import { isiOS } from 'utils/common';
import { dateStringToCustomDate } from 'utils/dateUtils';
import PassiveModeFooter from 'patientApp/components/PassiveModeFooter';
import FloatingLabelInput from 'components/FloatingLabelInput';
import './styles.scss';
import validate from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import viewShareOutcomesRequestReducer from './reducers';
import { verifyPinRequest } from './actions';
import { STATUSES } from './constants';

const validationConfig = {
  fields: ['pin'],
  rules: {
    pin: [{ rule: 'isRequired', message: 'PIN is required' }],
  },
};

class ViewShareOutcomesRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      key: '',
      pin: '',
    };
  }

  componentDidMount() {
    this.setState({
      key: this.props.match.params.key,
    });
  }

  onPinChange = e => {
    this.setState({
      pin: e.target.value.trim(),
    });
  };

  onContinue = event => {
    event.preventDefault();
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  onFormValidationSuccess = () => {
    this.setState({
      errors: {},
    });
    this.props.verifyPin(this.state.key, this.state.pin);
  };

  downloadFile = downloadLink => {
    let win = null;
    if (isiOS) {
      win = window.open('');
    }
    if (isiOS && win) {
      win.location = downloadLink;
    } else {
      win = window.location.assign(downloadLink, '_blank');
    }
    if (!(win === undefined || win === null)) {
      win.focus();
    }
  };

  renderStatusText = status => {
    let textString = null;
    switch (status) {
      case STATUSES.PREPARED:
        textString = 'Your download is ready';
        break;
      case STATUSES.REQUESTED:
        textString = 'Your surveys report is being prepared for download';
        break;
      case STATUSES.INPROGESS:
        textString = 'Your surveys report is being prepared for download';
        break;
      case STATUSES.FAILED:
        textString = 'Something went wrong';
        break;
      default:
        textString = null;
    }
    return textString ? <div>{textString}</div> : null;
  };

  renderShareOutcomesRequest(data) {
    return (
      <div className='view-share-outcomes-request-container'>
        <div className='col-xs-12'>
          {data !== null ? (
            <React.Fragment>
              <div className='welcome-header'>
                {this.renderStatusText(data.status)}
              </div>
              {data.status === STATUSES.PREPARED ? (
                <React.Fragment>
                  <div className='row request-data-status'>
                    {data.aggregation_type === 'per_patient' ? (
                      <div className='data-container'>
                        <span className='lbl-name'>Patient:&nbsp;</span>
                        <span className='value'>{data.patient_name}</span>
                      </div>
                    ) : data.performer_name ? (
                      <div className='data-container'>
                        <span className='lbl-name'>Provider:&nbsp;</span>
                        <span className='value'>{data.performer_name}</span>
                      </div>
                    ) : null}

                    <div className='data-container'>
                      <span className='lbl-name'>Procedure:&nbsp;</span>
                      <span className='value'>{data.procedure_name}</span>
                    </div>
                    <div className='data-container'>
                      <span className='lbl-name'>Report Type:&nbsp;</span>
                      <span className='value'>{data.chart_type}</span>
                    </div>
                    <div className='data-container'>
                      <span className='lbl-name'>Shared By:&nbsp;</span>
                      <span className='value'>{data.shared_by_name}</span>
                    </div>
                    <div className='data-container'>
                      <button
                        className='btn btn-primary download'
                        onClick={() => this.downloadFile(data.download_link)}
                      >
                        {' '}
                        Download &nbsp;
                      </button>
                      <div className='expiring-text'>{`(Expiring on: ${dateStringToCustomDate(
                        data.expired_at
                      )})`}</div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
        <PassiveModeFooter />
      </div>
    );
  }

  render() {
    if (this.props.shareOutcomesRequest) {
      return this.renderShareOutcomesRequest(this.props.shareOutcomesRequest);
    }

    return (
      <div className='view-share-outcomes-request-container'>
        <div className='welcome-header'>Please Enter Your PIN code: &nbsp;</div>
        <div className='row'>
          <div className='col-lg-offset-4 col-md-offset-4  col-sm-offset-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 login-form-container no-padding'>
            <div className='login-form-view text-center'>
              <div className=''>
                <br />
                We sent a PIN code to your email / mobile number:
              </div>
              <form name='form' onSubmit={this.onContinue}>
                <div
                  className={`form-group pos-rel ${
                    this.state.errors.verificationCode !== undefined
                      ? 'has-error'
                      : ''
                  }`}
                >
                  <FloatingLabelInput
                    type='text'
                    className='form-control'
                    value={this.state.pin}
                    label='PIN'
                    name='pin'
                    onChange={this.onPinChange}
                  />
                  <HelpBlock value={this.state.errors.pin} />
                </div>

                <div className='clearfix'></div>

                <div className='text-center'>
                  <button className='btn btn-primary' value='Continue'>
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <PassiveModeFooter />
      </div>
    );
  }
}

ViewShareOutcomesRequest.propTypes = {
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  verifyPin: PropTypes.func,
  shareOutcomesRequest: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  isLoading: state.viewShareOutcomesRequest.isLoading,
  shareOutcomesRequest: state.viewShareOutcomesRequest.shareOutcomesRequest,
});

const mapDispatchToProps = dispatch => ({
  verifyPin: (key, pin) => dispatch(verifyPinRequest(key, pin)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'viewShareOutcomesRequest',
  reducer: viewShareOutcomesRequestReducer,
});

export default compose(withReducer, withConnect)(ViewShareOutcomesRequest);

import React from 'react';
import PropTypes from 'prop-types';

import './patientRegister.scss';
import NextButton from 'components/Authorization/NextButton';
import HelpBlock from 'components/HelpBlock';
import SignupInput from './SignupInput';

const ContactView = props => {
  const {
    type,
    onNext,
    onChange,
    errors,
    contact,
    password,
    onForgotPasswordEvent,
    flowType,
    subtitleText,
    onPasswordChange,
    showHide,
  } = props;

  const handlePhoneChange = e => {
    e.target.value = e.target.value.replaceAll('-', '');
    onChange(e);
  };

  const mask = '999-999-9999';

  return (
    <div className='child-view-container contact-view'>
      <h2 className='input-heading'>Welcome!</h2>
      <p className='input-text'>{subtitleText}</p>
      <div className='contact-input-container'>
        {flowType === 'educator_sign_up' ? (
          <div
            className={`form-group ${
              (errors.emailOrMobileNumber || errors.password) !== undefined
                ? 'has-error'
                : ''
            }`}
          >
            <div className='input-box'>
              <SignupInput
                name='emailOrMobileNumber'
                onChange={onChange}
                className={`form-control ${
                  errors.emailOrMobileNumber !== undefined
                    ? 'single-select__control'
                    : ''
                } `}
                onNext={onNext}
                placeholder='Mobile Number or Email'
                value={contact}
                label='Mobile Number or Email'
              />
              <HelpBlock value={errors.emailOrMobileNumber} />
            </div>
            <div className='input-box'>
              <SignupInput
                type={type}
                name='password'
                onChange={onPasswordChange}
                onNext={onNext}
                className={`form-control ${
                  errors.password !== undefined ? 'single-select__control' : ''
                } `}
                showHide={showHide}
                placeholder='Password'
                value={password}
                label='Password'
              />
              <HelpBlock value={errors.password} />
            </div>
          </div>
        ) : (
          <div
            className={`form-group ${
              errors.phone !== undefined ? 'has-error' : ''
            }`}
          >
            <SignupInput
              name='phone'
              onChange={handlePhoneChange}
              onNext={onNext}
              className={`form-control ${
                errors.phone !== undefined ? 'single-select__control' : ''
              } `}
              placeholder='Enter Mobile Number'
              value={contact}
              label='Mobile Number'
              mask={mask}
            />
            <HelpBlock value={errors.phone} />
          </div>
        )}
        <div className='forgot-password float-right'>
          {flowType !== 'sign_up' && (
            <span
              role='button'
              tabIndex='0'
              className='cursor-pointer patient-forgot-password'
              onClick={onForgotPasswordEvent}
              aria-hidden='true'
            >
              Forgot password?
            </span>
          )}
        </div>
      </div>
      <NextButton
        onNext={onNext}
        text={
          flowType === 'sign_up'
            ? 'Next : Verification'
            : flowType === 'educator_sign_up'
            ? 'Sign In'
            : 'Next'
        }
        enable={contact !== ''}
      />
    </div>
  );
};

ContactView.propTypes = {
  onForgotPasswordEvent: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  errors: PropTypes.shape({ phone: PropTypes.string }).isRequired,
  contact: PropTypes.string.isRequired,
  flowType: PropTypes.string,
};

ContactView.defaultProps = {
  flowType: '',
  onForgotPasswordEvent: null,
};

export default ContactView;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import Media from 'react-media';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { getItemFromStorage } from 'services/storage';
import { showToaster } from 'common/toasterActions';
import validate, { clearErrorsForField } from 'common/validator';
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from 'envConstants';
import { signInUser } from 'common/authThunkActions';
import { Redirect } from 'react-router';
import { defaultLandingPageRouteGenerator } from 'common/authGuard';
import Wrapper from '../../components/Authorization/Wrapper';
import * as Constants from './constants';
import ContactView from '../../components/Authorization/ContactView';
import OtpVerificationView from '../../components/Authorization/OtpVerificationView';
import PasswordView from '../../components/Authorization/PasswordView';
import AppUrlsView from '../../components/Authorization/AppUrlsView';

import {
  pageChange,
  patientForgotPasswordRequest,
  verifyPatientRequest,
  patientSetResetPasswordRequest,
  verifyFirstTimeLogin,
  patientResendOtpRequest,
  setFlowType
} from './action';

import {
  phoneValidationConfig,
  phoneOtpValidationConfig,
  passwordValidationConfig,
  verifyPasswordValidationConfig,
} from './validators';
import './patientRegister.scss';

const INITIAL_STATE = {
  phone: '',
  phoneOtp: '',
  errors: {},
  password: '',
};

const PatientLogin = props => {
  const dispatch = useDispatch();
  const [state, setState] = useState(INITIAL_STATE);
  const page = useSelector(reduxState => reduxState.patientRegister.page);
  const flowId = useSelector(reduxState => reduxState.patientRegister.flowId);
  const flowType = useSelector(
    reduxState => reduxState.patientRegister.flowType
  );
  const tncUrl = useSelector(state => state.currentUser.termsAndConditionsUrl);
  const queryStringObject = props.location.search
    ? queryString.parse(props.location.search)
    : {};
  const redirectUri = queryStringObject.redirect_uri;
  const subtitleText = 'Please enter your mobile number';
  const contactNumber = useSelector(reduxState => reduxState.patientRegister.phone) || '';

  const validationConfig = () => {
    let config = {};
    switch (page) {
      case Constants.PHONE_VIEW:
        config = phoneValidationConfig;
        break;
      case Constants.OTP_VERIFICATION_VIEW:
        config = phoneOtpValidationConfig;
        break;
      case Constants.PASSWORD_VIEW:
        config = passwordValidationConfig;
        break;
      case Constants.PASSWORD_VERIFICATION_VIEW:
        config = verifyPasswordValidationConfig;
      default:
        break;
    }
    return config;
  };

  useEffect(() => {
    if (page === 1) {
      dispatch(pageChange(2));
    }

    setState({
      ...state,
      phone: contactNumber
    });

  }, []);

  useEffect(() => {
    if (window.location.hash) {
      dispatch(pageChange(Constants.PHONE_VIEW));
    }
  }, [state.page]);

  const handleResendOtp = () => {
    const { phone } = state;
    if (page === Constants.OTP_VERIFICATION_VIEW) {
      const params = {
        contact_number: phone,
        flow_id: flowId,
        flow_type: flowType,
      };
      dispatch(patientResendOtpRequest(params));
    }
  };

  const onValidationFailure = errors => {
    const obj = { ...state };
    obj.errors = errors;
    setState(obj);
  };

  const onNextValidationSuccess = () => {
    const { phone, phoneOtp, password } = state;

    if (page === Constants.PHONE_VIEW) {
      const params = {
        contact_number: phone,
        flow_type: flowType,
      };
      dispatch(verifyFirstTimeLogin(params));
    } else if (page === Constants.OTP_VERIFICATION_VIEW) {
      const params = {
        otp: phoneOtp,
        contact_number: phone,
        flow_id: flowId,
        flow_type: flowType,
      };
      dispatch(verifyPatientRequest(params, Constants.PASSWORD_VIEW));
    } else if (page === Constants.PASSWORD_VIEW) {
      const params = {
        otp: phoneOtp,
        contact_number: phone,
        flow_id: flowId,
        flow_type: flowType,
        has_accepted_tnc: true,
        password,
      };
      dispatch(patientSetResetPasswordRequest(params));
    } else if (page === Constants.PASSWORD_VERIFICATION_VIEW) {
      const params = {
        contact_number: phone,
        has_accepted_terms_and_condition: true,
        password,
        flow_type: flowType,
        tnc_url: tncUrl,
        login_type: 'Patient Login',
      };
      dispatch(signInUser(params));
    }
  };

  const handleNext = () => {
    const config = validationConfig();
    validate(config, state, onValidationFailure, onNextValidationSuccess);
  };

  useEffect(() => {
    if (state.phoneOtp.length === 6) {
      handleNext();
    }
  }, [state.phoneOtp]);

  const handleChange = e => {
    const obj = { ...state };
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(state.errors, e.target.name);
    setState(obj);
  };

  const handleOtpChange = otp => {
    if (state.phoneOtp.length === 6 && otp === state.phoneOtp) {
      handleNext();
    } else {
      setState(prevState => ({
        ...prevState,
        phoneOtp: otp,
      }));
    }
  };

  const handlePasswordChange = e => {
    const obj = { ...state };
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(state.errors, e.target.name);
    setState(obj);
  };

  const handleBack = () => {
    if (
      page === Constants.PASSWORD_VIEW ||
      page === Constants.OTP_VERIFICATION_VIEW ||
      page === Constants.PASSWORD_VERIFICATION_VIEW
    ) {
      setState(prevState => ({
        ...prevState,
        password: '',
      }));
    }

    dispatch(setFlowType('sign_in'));
    dispatch(pageChange(Constants.PHONE_VIEW));
  };

  const checkForgotPassword = () => {
    dispatch(
      patientForgotPasswordRequest(
        { contact_number: state.phone },
        Constants.OTP_VERIFICATION_VIEW
      )
    );
  };

  const handleForgotPassword = () => {
    if (page === Constants.PHONE_VIEW) {
      const config = validationConfig();
      validate(config, state, onValidationFailure, checkForgotPassword);
    } else {
      checkForgotPassword();
    }
  };

  const renderPatientLogin = () => {
    const { phone, errors, phoneOtp, password } = state;

    if (props.isSignedIn) {
      if (redirectUri) {
        return (
          <Redirect
            to={{
              pathname: redirectUri,
              hash: props.location.hash,
            }}
            replace
          />
        );
      }
      return <Redirect to={defaultLandingPageRouteGenerator()} replace />;
    }

    switch (page) {
      case Constants.PHONE_VIEW:
        return (
          <Wrapper page={page} heroImageNo={2}>
            <ContactView
              contact={phone}
              onChange={handleChange}
              onNext={handleNext}
              errors={errors}
              flowType={flowType}
              onForgotPasswordEvent={handleForgotPassword}
              subtitleText={subtitleText}
            />
          </Wrapper>
        );

      case Constants.OTP_VERIFICATION_VIEW:
        return (
          <Wrapper page={page} handleBack={handleBack} heroImageNo={2}>
            <OtpVerificationView
              phone={phone}
              phoneOtp={phoneOtp}
              onNext={handleOtpChange}
              errors={errors}
              handleResendOtp={handleResendOtp}
            />
          </Wrapper>
        );

      case Constants.PASSWORD_VIEW:
      case Constants.PASSWORD_VERIFICATION_VIEW:
        return (
          <Wrapper page={page} handleBack={handleBack} heroImageNo={2}>
            <PasswordView
              password={password}
              errors={errors}
              onChange={handlePasswordChange}
              onNext={handleNext}
              phone={phone}
              flowType={flowType}
              tncUrl={tncUrl}
              onForgotPasswordEvent={handleForgotPassword}
            />
          </Wrapper>
        );

      default:
        return '';
    }
  };

  const handleMobileDeepLinking = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (/android/.test(userAgent)) {
      // Redirect to Play Store for Android devices
      window.location.replace(GOOGLE_PLAY_STORE_URL);
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      // Redirect to App Store for iOS devices
      window.location.replace(APP_STORE_URL);
    }

    return (
      <div className='mobile-deep-linking'>
        Please use a mobile app or a tablet app to access the login feature.
        <AppUrlsView />
      </div>
    );
  };

  return (
    <Media
      queries={{
        screenIsSmall: '(max-width: 1024px)',
        deviceOrientation: '(orientation: landscape)',
        screenIsMedium: '(max-width: 1260px)',
      }}
    >
      {matches =>
        (matches.deviceOrientation && matches.screenIsMedium) ||
        matches.screenIsSmall
          ? handleMobileDeepLinking()
          : renderPatientLogin()
      }
    </Media>
  );
};

PatientLogin.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isSignedIn:
    state.currentUser.isSignedIn || !isEmpty(getItemFromStorage('token')),
});

const mapDispatchToProps = dispatch => ({
  signInUser: params => dispatch(signInUser(params)),
  showToaster: params => dispatch(showToaster(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientLogin);

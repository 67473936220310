import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, combineReducers } from 'redux';
import injectReducer from 'utils/injectReducer';
import { withRouter } from 'react-router';
import Datetime from 'react-datetime';
import TimePicker from 'components/TimePicker';
import Modal from 'react-bootstrap/lib/Modal';
import { setItemToStorage } from 'services/storage';
import UserConsentForm from 'patientApp/containers/UserConsentForm';
import { SingleSelectWithoutBorder as Select } from 'components/SelectV2';
import WelcomeModal from 'patientApp/components/WelcomeModal';

import {
  getProcedureLocationsReducer,
  getProcedureProvidersReducer,
  addNewProcedureReducer,
} from 'common/reducer';
import {
  getProcedureLocationsRequest,
  getProcedureProvidersRequest,
  addNewPatientProcedureRequest,
  clearAddProcedureStates,
} from 'common/actions';
import HelpBlock from 'components/HelpBlock';
import validate, { clearErrorsForField } from 'common/validator';
import { MMS_DATE_FORMAT } from '../../../utils/dateUtils';
import Strings from '../../../strings';

import './addProcedure.scss';

const validationConfig = {
  fields: ['procedureId', 'providerId', 'locationId'],
  rules: {
    procedureId: [{ rule: 'isRequired', message: 'Please select Procedure' }],
    providerId: [{ rule: 'isRequired', message: 'Please select Provider' }],
    locationId: [{ rule: 'isRequired', message: 'Please select Location' }],
  },
};
class AddProcedure extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState = props => {
    const initialState = {
      locations: [],
      providers: props.providers ? props.providers : [],
      procedureId: -1,
      locationId: -1,
      providerId: !props.canSelectPerformer
        ? props.selectedProvider
          ? props.selectedProvider.id
          : -1
        : -1,
      procedureTime: null,
      procedureDate: null,
      errors: {},
      showUserConsent: false,
      showWelcomeModal: props.showWelcomeModal,
      isDateSelected: false,
    };
    return initialState;
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      locations: nextProps.locations,
      providers: nextProps.providers,
    });
  }

  componentWillUnmount() {
    this.props.clearAddProcedureState();
  }

  onSelectProcedure = ({ value }) => {
    const procedureId = value;
    if (procedureId !== null) {
      this.props.getProcedureLocationsRequest({ id: procedureId });
      this.props.getProcedureProvidersRequest(
        { id: procedureId },
        this.props.userType
      );
    }

    this.setState({
      procedureId,
      locations: [],
      providers: [],
      providerId: !this.props.canSelectPerformer
        ? this.props.selectedProvider
          ? this.props.selectedProvider.id
          : -1
        : -1,
      locationId: -1,
      errors: clearErrorsForField(this.state.errors, 'procedureId'),
    });
  };

  onSelectLocation = ({ value }) => {
    this.setState({
      locationId: value,
      errors: clearErrorsForField(this.state.errors, 'locationId'),
    });
  };

  onSelectProvider = ({ value }) => {
    let providerId = null;
    if (value !== '') {
      providerId = value;
    }

    this.setState({
      providerId,
      errors: clearErrorsForField(this.state.errors, 'providerId'),
    });
  };

  onAddProcedure = () => {
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onFormValidationSuccess = () => {
    this.setState({ errors: {} });
    const selectedProcedure = this.props.procedures.find(
      procedure => procedure.id === parseInt(this.state.procedureId, 10)
    );
    if (selectedProcedure.needsPatientConsent) {
      this.setState({
        showUserConsent: true,
      });
    } else {
      this.doAddProcedure();
    }
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  onConsentAccepted = () => {
    this.setState({
      showUserConsent: false,
    });
    this.doAddProcedure();
  };

  onConsentDeclined = () => {
    this.setState(this.getInitialState(this.props));
    this.setState({ showWelcomeModal: false });
  };

  onWelcomeModalClose = () => {
    setItemToStorage('isWelcomeModalShown', '1');
    this.setState({
      showWelcomeModal: false,
    });
  };

  doAddProcedure = () => {
    const {
      providerId,
      procedureId,
      locationId,
      procedureDate,
      procedureTime,
    } = this.state;
    const selectedProcedureDate =
      procedureDate !== null ? procedureDate.format('YYYY-MM-DD') : '';
    const selectedProcedureTime =
      procedureTime !== null ? procedureTime.format('HH:mm A') : '';
    const data = {
      provider_id: providerId,
      procedure_id: procedureId,
      location_id: locationId,
      date: selectedProcedureDate,
      date_known: selectedProcedureDate !== '' ? '1' : '0',
      time: selectedProcedureTime,
      time_known: selectedProcedureTime !== '' ? '1' : '0',
    };
    this.props.addProcedure(data);
  };

  renderWelcomeModal = () => (
    <Modal
      show={this.props.showWelcomeModal}
      onHide={this.onWelcomeModalClose}
      container={document.body}
      autoFocus
      backdrop='static'
      aria-labelledby='contained-modal-title'
      className='primary-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title'>
          <div className='welcome-modal-header'>
            <div className='welcome-header'>Welcome to INSIGHTEC Connect! </div>
            <div className='welcome-header-separator' />
            <div className='welcome-header-body'>Thank you for signing up</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='welcome-modal-body'>
          <div className='welcome-modal-body-text'>
            To begin, we will need to gather some information relating to your
            procedure.
          </div>
          <div className='welcome-modal-body-text'>
            This will allow us to send you relevant notifications and tasks at
            specific times throughout your surgical journey.
          </div>
          <div className='welcome-modal-body-text last'>Best of luck!</div>
        </div>
      </Modal.Body>
    </Modal>
  );

  render() {
    const locationOptions =
      this.state.locations &&
      this.state.locations.map(item => ({
        value: item.id,
        label: item.name,
      }));

    const procedureOptions = this.props.procedures.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const providerOptions =
      this.state.providers &&
      this.state.providers.map(item => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      }));

    const { locationId, procedureId, providerId } = this.state;

    const locationValue =
      locationId && locationOptions.length > 0
        ? locationOptions.find(item => item.value === parseInt(locationId, 10))
        : null;
    const procedureValue =
      procedureId && procedureOptions.length > 0
        ? procedureOptions.find(
            item => item.value === parseInt(procedureId, 10)
          )
        : null;
    const providerValue =
      providerId && providerOptions.length > 0
        ? providerOptions.find(item => item.value === parseInt(providerId, 10))
        : null;

    if (this.props.procedures) {
      return (
        <React.Fragment>
          {this.state.showUserConsent ? (
            <UserConsentForm
              procedureId={parseInt(this.state.procedureId, 10)}
              onConsentAccepted={this.onConsentAccepted}
              onConsentDeclined={this.onConsentDeclined}
            />
          ) : (
            <div>
              {/*  {
                    this.state.showWelcomeModal && <WelcomeModal onClose={this.onWelcomeModalClose} />
                  } */}
              {!this.props.shownInModal && (
                <div className='add-procedure-header text-center'>
                  Add Procedure
                </div>
              )}
              {
                <div
                  className={`${
                    this.props.shownInModal
                      ? 'add-procedure-container-modal'
                      : 'add-procedure-container'
                  }`}
                >
                  <div
                    className={`${
                      this.props.shownInModal
                        ? 'add-procedure-data-modal'
                        : 'add-procedure-data'
                    }`}
                  >
                    <div
                      className={`form-group ${
                        this.state.errors.procedureId !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label htmlFor='procedure' className='custom-form-label'>
                        Select Procedure
                      </label>
                      <Select
                        id='select-procedure'
                        onChange={this.onSelectProcedure}
                        placeholder='Select Procedure'
                        options={procedureOptions}
                        value={procedureValue}
                      />
                      <HelpBlock value={this.state.errors.procedureId} />
                    </div>

                    <div className='form-group'>
                      <div className='row procedure-date'>
                        <div
                          className={`col-xs-12 col-sm-6 form-group ${
                            this.state.errors.procedureDate !== undefined
                              ? 'has-error'
                              : ''
                          }`}
                        >
                          <label
                            htmlFor='procedure-date'
                            className='custom-form-label'
                          >
                            Select Date
                          </label>
                          <Datetime
                            timeFormat={false}
                            className='date-picker-field float-left date-field form-control'
                            dateFormat={MMS_DATE_FORMAT}
                            inputProps={{
                              placeholder: 'Appointment Date',
                              readOnly: true,
                            }}
                            defaultValue='Appointment Date'
                            closeOnSelect
                            closeOnTab
                            onChange={date =>
                              this.setState({
                                procedureDate: date,
                                isDateSelected: true,
                                errors: clearErrorsForField(
                                  this.state.errors,
                                  'procedureDate'
                                ),
                              })
                            }
                          />
                          <HelpBlock value={this.state.errors.procedureDate} />
                        </div>
                        <div
                          className={`col-xs-12 col-sm-6 procedure-time form-group ${
                            this.state.errors.procedureTime !== undefined
                              ? 'has-error'
                              : ''
                          }`}
                        >
                          <label
                            htmlFor='procedure-time'
                            className='custom-form-label'
                          >
                            Select Time
                          </label>
                          <TimePicker
                            disabled={!this.state.isDateSelected}
                            placeholder='Appointment Time'
                            onChange={time =>
                              this.setState({
                                procedureTime: time,
                                errors: clearErrorsForField(
                                  this.state.errors,
                                  'procedureDate'
                                ),
                              })
                            }
                          />
                          <HelpBlock value={this.state.errors.procedureTime} />
                        </div>
                      </div>
                      <div className='procedure-date-note'>
                        <b>{Strings.LBL_EDIT_APPT}</b>
                        &nbsp;
                        {Strings.MSG_EDIT_APPT}
                        <br />
                        <br />
                        {Strings.MSG_EDIT_APPT_SMALL}
                      </div>
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.locationId !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label
                        htmlFor='procedure-location'
                        className='custom-form-label'
                      >
                        Select Location
                      </label>
                      <Select
                        id='select-location'
                        onChange={this.onSelectLocation}
                        isLoading={this.props.isLocationLoading}
                        placeholder='Select Location'
                        value={locationValue}
                        options={locationOptions}
                      />
                      <HelpBlock value={this.state.errors.locationId} />
                    </div>

                    {this.props.canSelectPerformer && (
                      <div
                        className={`form-group ${
                          this.state.errors.providerId !== undefined
                            ? 'has-error'
                            : ''
                        }`}
                      >
                        <label
                          htmlFor='procedure-physician'
                          className='custom-form-label select-physician-label'
                        >{`Select ${this.props.userProfileName}`}</label>
                        <Select
                          id='select-provider'
                          onChange={this.onSelectProvider}
                          isLoading={this.props.isProviderLoading}
                          placeholder={`Select ${this.props.userProfileName}`}
                          options={providerOptions}
                          value={providerValue}
                        />
                        <HelpBlock value={this.state.errors.providerId} />
                      </div>
                    )}

                    <button
                      className='btn btn-primary float-right add-procedure-button'
                      onClick={this.onAddProcedure}
                    >
                      Add
                    </button>
                    <div className='clearfix' />
                  </div>
                </div>
              }
            </div>
          )}
        </React.Fragment>
      );
    }
    return <div>No Procedures to display</div>;
  }
}

AddProcedure.propTypes = {
  procedures: PropTypes.array.isRequired,
  getProcedureLocationsRequest: PropTypes.func,
  getProcedureProvidersRequest: PropTypes.func,
  addProcedure: PropTypes.func,
  locations: PropTypes.array,
  providers: PropTypes.array,
  isLoading: PropTypes.bool,
  isProviderLoading: PropTypes.bool,
  isLocationLoading: PropTypes.bool,
  clearAddProcedureState: PropTypes.func,
  shownInModal: PropTypes.bool,
  selectedProvider: PropTypes.object,
  canSelectPerformer: PropTypes.bool,
  userType: PropTypes.string,
  showWelcomeModal: PropTypes.bool,
  userProfileName: PropTypes.string,
};

AddProcedure.defaultProps = {
  shownInModal: false,
  showWelcomeModal: false,
};

const mapStateToProps = state => ({
  providers: state.prov.providers
    ? state.prov.providers
    : state.patientProcedures.selectedProvider
    ? [state.patientProcedures.selectedProvider]
    : [],
  locations: state.loc.locations,
  isAdded: state.addProcedureRequest.isAdded,
  isLoading: state.addProcedureRequest.isLoading,
  isProviderLoading: state.prov.isLoading,
  isLocationLoading: state.loc.isLoading,
  selectedProvider:
    state.patientProcedures && state.patientProcedures.selectedProvider
      ? state.patientProcedures.selectedProvider
      : state.profile &&
        state.profile.userDetails &&
        state.profile.userDetails.provider,
  canSelectPerformer: state.patientProcedures.departmentConfiguration
    ? state.patientProcedures.departmentConfiguration.canPatientSelectPerformer
    : state.profile &&
      state.profile.userDetails &&
      state.profile.userDetails.departmentConfigurations
    ? state.profile.userDetails.departmentConfigurations[0]
        .canPatientSelectPerformer
    : true,
  userType: state.patientProcedures.userType
    ? state.patientProcedures.userType
    : 'Provider',
  userProfileName: state.patientProcedures.departmentConfiguration
    ? state.patientProcedures.departmentConfiguration.userProfileName
    : 'Physician',
});

const mapDispatchToProps = dispatch => ({
  getProcedureLocationsRequest: params =>
    dispatch(getProcedureLocationsRequest(params)),
  getProcedureProvidersRequest: (params, userType) =>
    dispatch(getProcedureProvidersRequest(params, userType)),
  addProcedure: params => dispatch(addNewPatientProcedureRequest(params)),
  clearAddProcedureState: () => dispatch(clearAddProcedureStates()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'addProcedure',
  reducer: combineReducers({
    prov: getProcedureProvidersReducer,
    loc: getProcedureLocationsReducer,
    addProcedureRequest: addNewProcedureReducer,
  }),
});

export default compose(withRouter, withReducer, withConnect)(AddProcedure);

// Patient Search sort by constants
export const sortOptions = [
  {
    label: 'ID: Most Recent First',
    value: 'patient_id#desc',
  },
  {
    label: 'ID: Oldest First',
    value: 'patient_id#asc',
  },
  {
    label: 'Last Name: A-Z',
    value: 'patient_last_name#asc',
  },
  {
    label: 'Last Name: Z-A',
    value: 'patient_last_name#desc',
  },
  {
    label: 'Past Procedure: Most Recent First',
    value: 'procedure_date#desc#past_procedure',
  },
  {
    label: 'Past Procedure: Oldest First',
    value: 'procedure_date#asc#past_procedure',
  },
  {
    label: 'Future Procedure: Soonest First',
    value: 'procedure_date#asc#future_procedure',
  },
  {
    label: 'Future Procedure: Furthest First',
    value: 'procedure_date#desc#future_procedure',
  },
  {
    label: 'Notes entry time',
    value: 'latest_patient_log_entry_time#desc',
  },
];

/*
 *
 * Patient Search Constants
 *
 */

export const PATIENT_SEARCH_REQUEST_SENT =
  'app/PatientSearch/PATIENT_SEARCH_REQUEST_SENT';
export const PATIENT_SEARCH_REQUEST_SUCCEED =
  'app/PatientSearch/PATIENT_SEARCH_REQUEST_SUCCEED';
export const PATIENT_SEARCH_REQUEST_FAILED =
  'app/PatientSearch/PATIENT_SEARCH_REQUEST_FAILED';

export const GET_VERIFICATION_CODE_REQUEST_SENT =
  'app/patientApp/PassiveModeInfoWithOTP/GET_VERIFICATION_CODE_REQUEST_SENT';
export const GET_VERIFICATION_CODE_REQUEST_SUCCEED =
  'app/patientApp/PassiveModeInfoWithOTP/GET_VERIFICATION_CODE_REQUEST_SUCCEED';
export const GET_VERIFICATION_CODE_REQUEST_FAILED =
  'app/patientApp/PassiveModeInfoWithOTP/GET_VERIFICATION_CODE_REQUEST_FAILED';

export const VERIFY_LOGIN_CODE_REQUEST_SENT =
  'app/patientApp/PassiveModeInfoWithOTP/VERIFY_LOGIN_CODE_REQUEST_SENT';
export const VERIFY_LOGIN_CODE_REQUEST_SUCCEED =
  'app/patientApp/PassiveModeInfoWithOTP/VERIFY_LOGIN_CODE_REQUEST_SUCCEED';
export const VERIFY_LOGIN_CODE_REQUEST_FAILED =
  'app/patientApp/PassiveModeInfoWithOTP/VERIFY_LOGIN_CODE_REQUEST_FAILED';

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FloatingLabelInput from 'components/FloatingLabelInput';
import validate, { clearErrorsForField } from 'common/validator';
import { verifyInviteCodeRequest } from '../../common/actions';
import HelpBlock from '../../../components/HelpBlock';

import './inviteCode.scss';

const validationConfig = {
  fields: ['inviteCode'],
  rules: {
    inviteCode: [{ rule: 'isRequired', message: 'Invite Code is required' }],
  },
};
class InviteCode extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      inviteCode: '',
      errors: {},
    };
    this.onInviteCodeVerifyClicked = this.onInviteCodeVerifyClicked.bind(this);
  }

  onInviteCodeChange = e => {
    this.setState({
      inviteCode: e.target.value.trim(),
      errors: clearErrorsForField(this.state.errors, 'inviteCode'),
    });
  };

  onInviteCodeVerifyClicked() {
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  }

  onFormValidationSuccess = () => {
    this.setState({ errors: {} });
    this.props.verifyInviteCode(this.state.inviteCode);
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onInviteCodeVerifyClicked();
    }
  };

  render() {
    return (
      <div className='invite-code-container'>
        <div className='invite-code-header'>
          {this.props.header && this.props.header !== ''
            ? this.props.header
            : 'If you received an invitation code from the clinic please provide it below.'}
        </div>
        <div></div>
        <div
          className={`form-group ${
            this.state.errors.inviteCode !== undefined ? 'has-error' : ''
          }`}
        >
          <div className='invite-code-text-input'>
            <FloatingLabelInput
              type='text'
              label='Invite Code'
              className='form-control'
              name='inviteCode'
              value={this.state.inviteCode}
              onChange={this.onInviteCodeChange}
              onKeyPress={this.handleKeyPress}
              isFocused
            />
          </div>
          <HelpBlock value={this.state.errors.inviteCode} />
          <div className='invite-code-footer'>
            Please check with your clinic if you don&apos;t have an invite code.
          </div>
          <button
            className='btn btn-primary invite-code-action-button'
            onClick={this.onInviteCodeVerifyClicked}
          >
            Next
          </button>
          <div className='clearfix'></div>
        </div>
      </div>
    );
  }
}

InviteCode.propTypes = {
  verifyInviteCode: PropTypes.func,
  isVerifyInviteCodeRequestInProgress: PropTypes.bool,
  header: PropTypes.string,
};

const mapStateToProps = state => ({
  isVerifyInviteCodeRequestInProgress:
    state.patientProcedures.isVerifyInviteCodeRequestInProgress,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  verifyInviteCode: inviteCode =>
    dispatch(verifyInviteCodeRequest(inviteCode, ownProps.isForAddProcedure)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteCode);
// export default InviteCode;

import React, { useState } from 'react';
import './EmailInput.scss';
import PropTypes from 'prop-types';

import HelpBlock from 'components/HelpBlock';
import RadioButton from 'components/RadioButton';
import PanelCollapse from 'react-bootstrap/lib/PanelCollapse';

export default function EmailInput(props) {
  const [isFocused, setisFocused] = useState(false);
  return (
    <div
      className={`form-group names no-padding ${
        isFocused ? `border-type-email` : ''
      } ${
        props.errors.emailOrMobileNumber !== undefined
          ? 'has-error-self-signup '
          : ''
      }`}
      style={{ marginBottom: '28px', height: '45px' }}
    >
      {isFocused && (
        <label className='label_style_email'>
          {props.label}
          <span className='mandatory-field'>*</span>
        </label>
      )}

      <input
        className={`input-text-email ${
          isFocused ? `input-control_before` : `input_control_after`
        }`}
        value={props.value}
        placeholder={`${isFocused ? props.value : props.label}`}
        {...props}
        onChange={props.onChange}
        name='emailOrMobileNumber'
        onFocus={() => setisFocused(true)}
        onBlur={() => setisFocused(false)}
      />
      <HelpBlock value={props.errors.emailOrMobileNumber} />
    </div>
  );
}

EmailInput.propTypes = {
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};

EmailInput.defaultProps = {
  name: '',
  label: '',
  value: '',
};

import { getSearchCategoryList } from 'services/common';
import * as Constants from './constants';

export const getSearchCategoryListRequestSucceeded = data => ({
  type: Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED,
  data,
});

export const getSearchCategoryListRequest = () => dispatch => {
  getSearchCategoryList()
    .then(response => {
      dispatch(getSearchCategoryListRequestSucceeded(response.data));
    })
    .catch(() => {});
};

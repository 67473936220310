import { isTBD, currentTimezoneName } from 'utils/dateUtils';
import { CLEAR_HAS_PENDING_CONSENT_VALUE } from 'patientApp/containers/Appointments/constants';
import * as Constants from './constants';

import { showToaster } from '../../../common/toasterActions';

import { getPatientProcedureRequest } from '../../common/actions';
import {
  getAllTaskService,
  postMarkTaskCompleteService,
  createTask,
  updateTaskAttachment,
  getAppointments,
  getTremorSites,
  selectTremorSite,
  getCityCenter,
  getTremorSitesStates,
  getTremorSitesCities,
  getCityCenterStates,
  getCityCenterCities,
  selectCtCenter,
} from '../../../services/patient/patientDashboard';
import {
  deleteSelectedTask,
  getPatientTaskDetails,
  getEventQuestions as getQuestions,
  updateAppointment,
} from '../../../services/patientDetails';

export const getPatienAppointmentsRequestSent = () => ({
  type: Constants.GET_APPOINTMENTS_REQUEST_SENT,
});

export const getPatientAppointmentsRequestSucceeded = data => ({
  type: Constants.GET_APPOINTMENTS_REQUEST_SUCCEED,
  appointments: data,
});

export const getPatientAppointmentsRequestFailed = () => ({
  type: Constants.GET_APPOINTMENTS_REQUEST_FAILED,
});

export const getAllTasksRequestSent = () => ({
  type: Constants.GET_ALL_TASKS_REQUEST_SENT,
});

export const getAllTasksRequestSucceeded = data => ({
  type: Constants.GET_ALL_TASKS_REQUEST_SUCCEED,
  data,
});

export const getAllTasksRequestFailed = () => ({
  type: Constants.GET_ALL_TASKS_REQUEST_FAILED,
});

export const deleteTaskRequestSent = data => ({
  type: Constants.POST_DELETE_TASK_REQUEST_SENT,
  data,
});

export const deleteTaskRequestSucceeded = taskId => ({
  type: Constants.POST_DELETE_TASK_REQUEST_SUCCEED,
  taskId,
});

export const deleteTaskRequestFailed = () => ({
  type: Constants.POST_DELETE_TASK_REQUEST_FAILED,
});

export const markTaskCompleteRequestSent = () => ({
  type: Constants.POST_MARK_TASK_COMPLETE_REQUEST_SENT,
});

export const markTaskCompleteRequestSucceeded = taskId => ({
  type: Constants.POST_MARK_TASK_COMPLETE_REQUEST_SUCCEED,
  taskId,
});

export const markTaskCompleteRequestFailed = () => ({
  type: Constants.POST_MARK_TASK_COMPLETE_REQUEST_FAILED,
});

export const createTaskRequestSent = () => ({
  type: Constants.POST_CREATE_TASK_REQUEST_SENT,
});

export const createTaskRequestSucceeded = data => ({
  type: Constants.POST_CREATE_TASK_REQUEST_SUCCEED,
  data,
});

export const createTaskRequestFailed = () => ({
  type: Constants.POST_CREATE_TASK_REQUEST_FAILED,
});

export const getPatientTaskDetailsRequestSent = () => ({
  type: Constants.GET_TASKS_DETAILS_REQUEST_SENT,
});

export const getPatientTaskDetailsRequestSucceeded = data => ({
  type: Constants.GET_TASKS_DETAILS_REQUEST_SUCCEED,
  data,
});

export const getPatientTaskDetailsRequestFailed = () => ({
  type: Constants.GET_TASKS_DETAILS_REQUEST_FAILED,
});

export const clearTaskDetailsState = () => ({
  type: Constants.TASK_DETAILS_CLEAR_STATE,
});

export const addTaskAttachmentRequestSent = () => ({
  type: Constants.POST_ADD_TASK_ATTACHMENT_REQUEST_SENT,
});

export const addTaskAttachmentRequestSucceeded = data => ({
  type: Constants.POST_ADD_TASK_ATTACHMENT_REQUEST_SUCCEED,
  data,
});

export const addTaskAttachmentRequestFailed = () => ({
  type: Constants.POST_ADD_TASK_ATTACHMENT_REQUEST_FAILED,
});

export const updateAppointmentRequestSent = () => ({
  type: Constants.UPDATE_APPOINTMENT_REQUEST_SENT,
});

export const updateAppointmentRequestSucceeded = data => ({
  type: Constants.UPDATE_APPOINTMENT_REQUEST_SUCCEED,
  data,
});

export const updateAppointmentRequestFailed = () => ({
  type: Constants.UPDATE_APPOINTMENT_REQUEST_FAILED,
});

export const clearHasPendingConsentValue = () => ({
  type: CLEAR_HAS_PENDING_CONSENT_VALUE,
});
export const getEventQuestionsRequestSent = () => ({
  type: Constants.GET_EVENT_QUESTIONS_REQUEST_SENT,
});

export const getEventQuestionsRequestSucceeded = data => ({
  type: Constants.GET_EVENT_QUESTIONS_REQUEST_SUCCEED,
  data,
});

export const getEventQuestionsRequestFailed = () => ({
  type: Constants.GET_EVENT_QUESTIONS_REQUEST_FAILED,
});

export const getTremorSitesRequestSent = () => ({
  type: Constants.GET_TREMOR_SITES_REQUEST_SENT,
});

export const getTremorSitesRequestSucceeded = data => ({
  type: Constants.GET_TREMOR_SITES_REQUEST_SUCCEEDED,
  data,
});

export const getTremorSitesRequestFailed = () => ({
  type: Constants.GET_TREMOR_SITES_REQUEST_FAILED,
});

export const getTremorSitesStatesRequestSent = () => ({
  type: Constants.GET_TREMOR_SITES_STATES_REQUEST_SENT,
});

export const getTremorSitesStatesRequestSucceeded = data => ({
  type: Constants.GET_TREMOR_SITES_STATES_REQUEST_SUCCEEDED,
  data,
});

export const getTremorSitesStatesRequestFailed = () => ({
  type: Constants.GET_TREMOR_SITES_STATES_REQUEST_FAILED,
});

export const getTremorSitesCitiesRequestSent = () => ({
  type: Constants.GET_TREMOR_SITES_CITIES_REQUEST_SENT,
});

export const getTremorSitesCitiesRequestSucceeded = data => ({
  type: Constants.GET_TREMOR_SITES_CITIES_REQUEST_SUCCEEDED,
  data,
});

export const getTremorSitesCitiesRequestFailed = () => ({
  type: Constants.GET_TREMOR_SITES_CITIES_REQUEST_FAILED,
});

export const siteSelectRequestSent = () => ({
  type: Constants.SITE_SELECT_REQUEST_SENT,
});

export const siteSelectRequestSucceeded = () => ({
  type: Constants.SITE_SELECT_REQUEST_SUCCEEDED,
});

export const siteSelectRequestFailed = () => ({
  type: Constants.SITE_SELECT_REQUEST_FAILED,
});

export const siteSelectionDoneByUser = () => ({
  type: Constants.SITE_SELECTION_DONE_BY_USER,
});

export const getCityCentersStatesRequestSent = () => ({
  type: Constants.GET_CITY_CENTERS_STATES_REQUEST_SENT,
});

export const getCityCentersStatesRequestSucceeded = data => ({
  type: Constants.GET_CITY_CENTERS_STATES_REQUEST_SUCCEEDED,
  data,
});

export const getCityCentersStatesRequestFailed = () => ({
  type: Constants.GET_CITY_CENTERS_STATES_REQUEST_FAILED,
});

export const getCityCentersCitiesRequestSent = () => ({
  type: Constants.GET_CITY_CENTERS_CITIES_REQUEST_SENT,
});

export const getCityCentersCitiesRequestSucceeded = data => ({
  type: Constants.GET_CITY_CENTERS_CITIES_REQUEST_SUCCEEDED,
  data,
});

export const getCityCentersCitiesRequestFailed = () => ({
  type: Constants.GET_CITY_CENTERS_CITIES_REQUEST_FAILED,
});

export const getCityCentersRequestSent = () => ({
  type: Constants.GET_CITY_CENTERS_REQUEST_SENT,
});

export const getCityCentersRequestSucceeded = data => ({
  type: Constants.GET_CITY_CENTERS_REQUEST_SUCCEEDED,
  data,
});

export const getCityCentersRequestFailed = () => ({
  type: Constants.GET_CITY_CENTERS_REQUEST_FAILED,
});

export const ctCenterSelectRequestSent = () => ({
  type: Constants.CT_CENTERS_SELECT_REQUEST_SENT,
});

export const ctCenterSelectRequestSucceeded = () => ({
  type: Constants.CT_CENTERS_SELECT_REQUEST_SUCCEEDED,
});

export const ctCenterSelectRequestFailed = () => ({
  type: Constants.CT_CENTERS_SELECT_REQUEST_FAILED,
});

export const ctCenterSelectionDoneByUser = () => ({
  type: Constants.CT_CENTER_SELECTION_DONE_BY_USER,
});
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPatientAppointmentsRequest = timezone => dispatch => {
  dispatch(getPatienAppointmentsRequestSent());
  getAppointments(timezone)
    .then(response => {
      dispatch(getPatientAppointmentsRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getPatientAppointmentsRequestFailed());
    });
};

export const updateAppointmentRequest =
  (eventId, params, timezone) => dispatch => {
    dispatch(updateAppointmentRequestSent());
    updateAppointment(eventId, params)
      .then(response => {
        dispatch(showToaster({ message: 'Appointment updated successfully' }));
        dispatch(updateAppointmentRequestSucceeded(response.data));
        dispatch(getPatientAppointmentsRequest(timezone));
      })
      .catch(error => {
        let msg = 'Something went wrong';
        dispatch(updateAppointmentRequestFailed(error.response.data));
        if (error.response) {
          msg = error.response.data.error;
        }
        dispatch(showToaster({ type: 'error', message: msg }));
      });
  };

export const getEventQuestions = eventId => dispatch => {
  dispatch(getEventQuestionsRequestSent());
  getQuestions(eventId)
    .then(response => {
      dispatch(getEventQuestionsRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getEventQuestionsRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const clearHasPendingConsentValueRequest = () => dispatch => {
  dispatch(clearHasPendingConsentValue());
};

export const getAllTasksRequest = () => dispatch => {
  dispatch(getAllTasksRequestSent());
  return getAllTaskService()
    .then(response => {
      dispatch(getAllTasksRequestSucceeded(response.data));
      dispatch(getPatientProcedureRequest());
      dispatch(getPatientAppointmentsRequest(currentTimezoneName));
    })
    .catch(() => {
      dispatch(getAllTasksRequestFailed());
    });
};

export const deleteCreatedTask = taskId => dispatch => {
  dispatch(deleteTaskRequestSent());
  deleteSelectedTask(taskId)
    .then(response => {
      if (response.data) {
        dispatch(deleteTaskRequestSucceeded(taskId));
        dispatch(getAllTasksRequest());
        dispatch(showToaster({ message: 'Task deleted successfully' }));
      }
    })
    .catch(error => {
      dispatch(addTaskAttachmentRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const markTaskCompleteRequest = (reqObj, isRecurring) => dispatch => {
  dispatch(markTaskCompleteRequestSent());
  postMarkTaskCompleteService(reqObj)
    .then(response => {
      if (response.data) {
        dispatch(markTaskCompleteRequestSucceeded());
        dispatch(
          showToaster({
            message: `Task marked as ${
              reqObj.completed_on !== '' ? 'complete' : 'incomplete'
            }`,
          })
        );
        dispatch(getAllTasksRequest());
        if (isRecurring) {
          dispatch(getTaskDetails(reqObj.id));
        }
        dispatch(getPatientAppointmentsRequest(currentTimezoneName));
      }
    })
    .catch(error => {
      dispatch(markTaskCompleteRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const createTaskRequest = reqObj => dispatch => {
  dispatch(createTaskRequestSent());
  createTask(reqObj)
    .then(response => {
      if (response.data) {
        dispatch(createTaskRequestSucceeded());
        dispatch(showToaster({ message: 'Task created successfully' }));
        dispatch(getAllTasksRequest());
      }
    })
    .catch(error => {
      dispatch(createTaskRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const getTaskDetails = taskId => dispatch => {
  dispatch(getPatientTaskDetailsRequestSent());
  getPatientTaskDetails(taskId)
    .then(response => {
      dispatch(getPatientTaskDetailsRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getPatientTaskDetailsRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const sendTaskAttachmentRequest = (taskId, reqObj) => dispatch => {
  dispatch(addTaskAttachmentRequestSent());
  updateTaskAttachment(taskId, reqObj)
    .then(response => {
      if (response.data) {
        dispatch(addTaskAttachmentRequestSucceeded());
        dispatch(showToaster({ message: 'Attachments updated successfully.' }));
        dispatch(getAllTasksRequest());
      }
    })
    .catch(() => {
      dispatch(addTaskAttachmentRequestFailed());
    });
};

export const sendGetTremorSitesRequest = data => dispatch => {
  dispatch(getTremorSitesRequestSent());
  getTremorSites(data)
    .then(response => {
      dispatch(getTremorSitesRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getTremorSitesRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const sendGetTremorSitesStatesRequest = () => dispatch => {
  dispatch(getTremorSitesStatesRequestSent());
  getTremorSitesStates()
    .then(response => {
      dispatch(getTremorSitesStatesRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getTremorSitesStatesRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const sendGetTremorSitesCitiesRequest = () => dispatch => {
  dispatch(getTremorSitesCitiesRequestSent());
  getTremorSitesCities()
    .then(response => {
      dispatch(getTremorSitesCitiesRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getTremorSitesCitiesRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const siteSelectRequest = data => dispatch => {
  dispatch(siteSelectRequestSent());
  selectTremorSite(data)
    .then(response => {
      dispatch(siteSelectRequestSucceeded());
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(error => {
      dispatch(siteSelectRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const siteSelectionDone = () => dispatch => {
  dispatch(siteSelectionDoneByUser());
};

export const sendGetCityCenterStatesRequest = () => dispatch => {
  dispatch(getCityCentersStatesRequestSent());
  getCityCenterStates()
    .then(response => {
      dispatch(getCityCentersStatesRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getCityCentersStatesRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const sendGetCityCenterCitiesRequest = () => dispatch => {
  dispatch(getCityCentersCitiesRequestSent());
  getCityCenterCities()
    .then(response => {
      dispatch(getCityCentersCitiesRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getCityCentersCitiesRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const sendGetCityCenterRequest = data => dispatch => {
  dispatch(getCityCentersRequestSent());
  getCityCenter(data)
    .then(response => {
      dispatch(getCityCentersRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getCityCentersRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const ctCenterSelectRequest = data => dispatch => {
  dispatch(ctCenterSelectRequestSent());
  selectCtCenter(data)
    .then(response => {
      dispatch(ctCenterSelectRequestSucceeded());
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(error => {
      dispatch(ctCenterSelectRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};
export const ctCenterSelectionDone = () => dispatch => {
  dispatch(ctCenterSelectionDoneByUser());
};

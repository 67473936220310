export const FETCH_PATIENT_DETAILS_REQUEST_SUCCEEDED =
  'src/traditionalSiteApp/common/constants/FETCH_PATIENT_DETAILS_REQUEST_SUCCEEDED';

export const FETCH_TRADITIONAL_SITE_USER_INFO_REQUEST_SUCCEEDED =
  'src/traditionalSiteApp/common/constants/FETCH_TRADITIONAL_SITE_USER_INFO_REQUEST_SUCCEEDED';

export const FETCH_ALL_PATIENTS_REQUEST_SUCCEEDED =
  'src/traditionalSiteApp/common/constants/FETCH_ALL_PATIENTS_REQUEST_SUCCEEDED';

export const ARCHIVE_PATIENT_REQUEST_SUCCEEDED =
  'src/traditionalSiteApp/common/constants/ARCHIVE_PATIENT_REQUEST_SUCCEEDED';

export const SET_ACTIVE_TAB_ON_SIDEBAR =
  'src/traditionalSiteApp/common/constants/SET_ACTIVE_TAB_ON_SIDEBAR';

export const RESET_PATIENT_DETAILS_DATA =
  'src/traditionalSiteApp/common/constants/RESET_PATIENT_DETAILS_DATA';

export const UPDATE_ARCHIEVED_PATIENT =
  'src/traditionalSiteApp/common/constants/UPDATE_ARCHIEVED_PATIENT';

export const RESET_ALL_PATIENTS_AFTER_LOGOUT =
  'src/traditionalSiteApp/common/constants/RESET_ALL_PATIENTS_AFTER_LOGOUT';

export const SET_PATIENT_SORT_DATA = 
  'src/traditionalSiteApp/common/constants/SET_PATIENT_SORT_DATA';

export const GET_LOGGED_IN_STATUS_CHECK_REQUEST_SUCCEEDED =
  'src/traditionalSiteApp/common/constants/GET_LOGGED_IN_STATUS_CHECK_REQUEST_SUCCEEDED';

import React from 'react';
import PropTypes from 'prop-types';

import { BackButton } from 'components/UtilityComponents';
import backgroundImg1 from 'assets/images/patient-signup-hero-image-1.png';
import backgroundImg2 from 'assets/images/patient-signup-hero-img-2.png';
import Logo from '../../assets/images/ins_logos/ins_logo_image.png';

const HeroThemeSelector = props => {
  const { handleBack, flowType, themeSelected } = props;
  return (
    <div className='hero-container'>
      <div className='hero-img-conatiner'>
        <div className='back-button-container'>
          {handleBack ? <BackButton goBack={handleBack} /> : null}
        </div>
        <div className='img-container-inner'>
          {themeSelected != 'patientTheme' ? (
            <div className='title-text'>
              <span className='heading'>NAVIGATING YOUR</span>
              <p className='heading2'>PATIENT'S JOURNEY</p>
            </div>
          ) : null}
          <img className='hero-img' src={backgroundImg2} alt='hero-img' />
        </div>
      </div>
      {themeSelected === 'patientTheme' ? (
        <div className='hero-containt'>
          <span className='heading1'>Looking for tremor relief?</span>
          <span className='heading2'>We have an app for that.</span>

          {flowType === 'sign_up' ? (
            <p className='heading3'>
              <b>INSIGHTEC Connect</b> gives you the information <br />
              you need as you navigate your journey to tremor <br /> relief.
            </p>
          ) : (
            <p className='heading3'>
              As a patient or caregiver, INSIGHTEC Connect <br />
              gives you the information to help navigate your <br />
              journey to tremor relief.
            </p>
          )}
        </div>
      ) : (
        <div className='hero-text'>
          <span className='logo'>
            <img src={Logo} className='mms-logo' alt='INS' />
          </span>
          <span className='heading'>
            INSIGHTEC Connect gives you visibility into your patient's journey
            to tremor relief.
          </span>
        </div>
      )}
    </div>
  );
};

HeroThemeSelector.propTypes = {
  handleBack: PropTypes.func,
  heroImageNo: PropTypes.number.isRequired,
  flowType: PropTypes.string.isRequired,
};

HeroThemeSelector.defaultProps = {
  handleBack: null,
};

export default HeroThemeSelector;

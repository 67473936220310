import React, { PureComponent } from 'react';
import './style.scss';
import strings from '../../../strings';

class PassiveModeFooter extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div className='passive-mode-footer'>
        <div className='title'>The INSIGHTEC Connect Team</div>
        <div className='subtitle'>Questions and technical support:</div>
        <div>
          <a href={`tel:${strings.CONTACT_FOR_QUERIES}`}>{ strings.CONTACT_FOR_QUERIES }</a>
          &nbsp;|&nbsp;
          <a href='mailto:support@managemyheart.app'>
            support@insightecconnect.com
          </a>
        </div>
      </div>
    );
  }
}

export default PassiveModeFooter;

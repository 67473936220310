import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetAllPatientData } from 'traditionalSiteApp/common/actions';

const useHeaderViewModel = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    dispatch(resetAllPatientData());
    history.push('/logout');
  };

  return {
    onLogout,
  };
};

export default useHeaderViewModel;

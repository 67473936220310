import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getTermsAndConditionsUrl } from 'common/authThunkActions';
import NextButton from 'components/Authorization/NextButton';
import HelpBlock from 'components/HelpBlock';
import CheckedLogo from 'assets/images/eds_logos/green_checked.png';
import CancelLogo from 'assets/images/cancel.png';
import SignupInput from './SignupInput';
import show from 'assets/images/show.png';
import hide from 'assets/images/hide.png';
import './patientRegister.scss';

const PasswordView = props => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    onChange,
    password,
    onNext,
    errors,
    phone,
    flowType,
    tncUrl,
    onForgotPasswordEvent,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTermsAndConditionsUrl(phone));
  }, []);

  const passwordInstructions = [
    {
      text: '1 uppercase',
      className: 'uppercase',
      case: '1Up',
    },
    {
      text: '1 number',
      className: 'number',
      case: '1Num',
    },
    {
      text: '1 lowercase',
      className: 'lowercase',
      case: '1Low',
    },
    {
      text: '10 characters',
      className: 'characters',
      case: '10Char',
    },
  ];

  const checkPasswordInstructions = val => {
    switch (val) {
      case '1Up':
        return /[A-Z]/.test(password);

      case '1Low':
        return /[a-z]/.test(password);

      case '10Char':
        return password.length >= 10;

      case '1Num':
        return /[0-9]/.test(password);

      default:
        return '';
    }
  };

  const renderPasswordValidator = () => {
    return (
      <div className='password-instruction-container-outer'>
        <div className='password-instruction-heading'>
          Password must have at least
        </div>
        <div className='password-instruction-container-inner'>
          {passwordInstructions.map(obj => (
            <div key={obj.text} className={`password-instruction ${obj.text}`}>
              <span className='check-icon'>
                <img
                  src={
                    checkPasswordInstructions(obj.case)
                      ? CheckedLogo
                      : CancelLogo
                  }
                  height='20px;'
                  width='20px'
                  alt='checked-logo'
                />
              </span>
              <span className='password-instruction-text'>{obj.text}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPasswordHeader = () => {
    if (flowType === 'set_password' || flowType === 'sign_up') {
      return (
        <div>
          <h2 className='input-heading'>Final step</h2>
          <p className='input-text'>Please set your password</p>
        </div>
      );
    } else if (flowType === 'reset_password') {
      return (
        <div>
          <h2 className='input-heading'>Reset Password</h2>
          <p className='input-text'>Please reset your password</p>
        </div>
      );
    } else {
      return (
        <div>
          <p className='input-text'>Please enter your password</p>
        </div>
      );
    }
  };

  return (
    <div className='child-view-container password-view'>
      {renderPasswordHeader()}
      <div className='password-view-container'>
        <div className='password-input-container'>
          <SignupInput
            className='password-input'
            value={password}
            label='Password'
            onChange={onChange}
            onNext={onNext}
            name='password'
          />
          {flowType === 'sign_in' ? (
            <div className='forgot-password float-right'>
              <span
                role='button'
                tabIndex='0'
                className='cursor-pointer patient-forgot-password'
                onClick={onForgotPasswordEvent}
                aria-hidden='true'
              >
                Forgot password?
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <HelpBlock value={errors.password} />
        {flowType !== 'sign_in' ? renderPasswordValidator() : null}
      </div>
      <NextButton text='Next' onNext={onNext} enable={password !== ''} />
      <div className='terms-and-conditions'>
        By tapping Next, you agree to our <br />{' '}
        <a href={tncUrl} target='_blank' rel='noreferrer'>
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a href={tncUrl} target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

PasswordView.propTypes = {
  onChange: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  password: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  errors: PropTypes.shape({ password: PropTypes.string }).isRequired,
};

PasswordView.defaultProps = {
  password: '',
};

export default PasswordView;

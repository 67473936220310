export const GET_ALL_TASKS_REQUEST_SENT =
  'app/patientApp/Tasks/GET_ALL_TASKS_REQUEST_SENT';
export const GET_ALL_TASKS_REQUEST_SUCCEED =
  'app/patientApp/Tasks/GET_ALL_TASKS_REQUEST_SUCCEED';
export const GET_ALL_TASKS_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_ALL_TASKS_REQUEST_FAILED';

export const POST_DELETE_TASK_REQUEST_SENT =
  'app/patientApp/Tasks/POST_DELETE_TASK_REQUEST_SENT';
export const POST_DELETE_TASK_REQUEST_SUCCEED =
  'app/patientApp/Tasks/POST_DELETE_TASK_REQUEST_SUCCEED';
export const POST_DELETE_TASK_REQUEST_FAILED =
  'app/patientApp/Tasks/POST_DELETE_TASK_REQUEST_FAILED';

export const POST_MARK_TASK_COMPLETE_REQUEST_SENT =
  'app/patientApp/Tasks/POST_MARK_TASK_COMPLETE_REQUEST_SENT';
export const POST_MARK_TASK_COMPLETE_REQUEST_SUCCEED =
  'app/patientApp/Tasks/POST_MARK_TASK_COMPLETE_REQUEST_SUCCEED';
export const POST_MARK_TASK_COMPLETE_REQUEST_FAILED =
  'app/patientApp/Tasks/POST_MARK_TASK_COMPLETE_REQUEST_FAILED';

export const POST_CREATE_TASK_REQUEST_SENT =
  'app/patientApp/Tasks/POST_CREATE_TASK_REQUEST_SENT';
export const POST_CREATE_TASK_REQUEST_SUCCEED =
  'app/patientApp/Tasks/POST_CREATE_TASK_REQUEST_SUCCEED';
export const POST_CREATE_TASK_REQUEST_FAILED =
  'app/patientApp/Tasks/POST_CREATE_TASK_REQUEST_FAILED';

export const GET_TASKS_DETAILS_REQUEST_SENT =
  'app/patientApp/Tasks/GET_TASKS_DETAILS_REQUEST_SENT';
export const GET_TASKS_DETAILS_REQUEST_SUCCEED =
  'app/patientApp/Tasks/GET_TASKS_DETAILS_REQUEST_SUCCEED';
export const GET_TASKS_DETAILS_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_TASKS_DETAILS_REQUEST_FAILED';
export const TASK_DETAILS_CLEAR_STATE =
  'app/patientApp/Tasks/TASK_DETAILS_CLEAR_STATE';

export const POST_ADD_TASK_ATTACHMENT_REQUEST_SENT =
  'app/patientApp/Tasks/POST_ADD_TASK_ATTACHMENT_REQUEST_SENT';
export const POST_ADD_TASK_ATTACHMENT_REQUEST_SUCCEED =
  'app/patientApp/Tasks/POST_ADD_TASK_ATTACHMENT_REQUEST_SUCCEED';
export const POST_ADD_TASK_ATTACHMENT_REQUEST_FAILED =
  'app/patientApp/Tasks/POST_ADD_TASK_ATTACHMENT_REQUEST_FAILED';

export const GET_APPOINTMENTS_REQUEST_SENT =
  'app/patientApp/Tasks/GET_APPOINTMENTS_REQUEST_SENT';
export const GET_APPOINTMENTS_REQUEST_SUCCEED =
  'app/patientApp/Tasks/GET_APPOINTMENTS_REQUEST_SUCCEED';
export const GET_APPOINTMENTS_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_APPOINTMENTS_REQUEST_FAILED';

export const GET_EVENT_QUESTIONS_REQUEST_SENT =
  'app/patientApp/Tasks/GET_EVENT_QUESTIONS_REQUEST_SENT';
export const GET_EVENT_QUESTIONS_REQUEST_SUCCEED =
  'app/patientApp/Tasks/GET_EVENT_QUESTIONS_REQUEST_SUCCEED';
export const GET_EVENT_QUESTIONS_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_EVENT_QUESTIONS_REQUEST_FAILED';

export const UPDATE_APPOINTMENT_REQUEST_SENT =
  'app/patientApp/Tasks/UPDATE_APPOINTMENT_REQUEST_SENT';
export const UPDATE_APPOINTMENT_REQUEST_SUCCEED =
  'app/patientApp/Tasks/UPDATE_APPOINTMENT_REQUEST_SUCCEED';
export const UPDATE_APPOINTMENT_REQUEST_FAILED =
  'app/patientApp/Tasks/UPDATE_APPOINTMENT_REQUEST_FAILED';

export const GET_TREMOR_SITES_REQUEST_SENT =
  'app/patientApp/Tasks/GET_TREMOR_SITES_REQUEST_SENT';

export const GET_TREMOR_SITES_REQUEST_SUCCEEDED =
  'app/patientApp/Tasks/GET_TREMOR_SITES_REQUEST_SUCCEEDED';

export const GET_TREMOR_SITES_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_TREMOR_SITES_REQUEST_FAILED';

export const GET_TREMOR_SITES_STATES_REQUEST_SENT =
  'app/patientApp/Tasks/GET_TREMOR_SITES_STATES_REQUEST_SENT';

export const GET_TREMOR_SITES_STATES_REQUEST_SUCCEEDED =
  'app/patientApp/Tasks/GET_TREMOR_SITES_STATES_REQUEST_SUCCEEDED';

export const GET_TREMOR_SITES_STATES_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_TREMOR_SITES_STATES_REQUEST_FAILED';

export const GET_TREMOR_SITES_CITIES_REQUEST_SENT =
  'app/patientApp/Tasks/GET_TREMOR_SITES_CITIES_REQUEST_SENT';

export const GET_TREMOR_SITES_CITIES_REQUEST_SUCCEEDED =
  'app/patientApp/Tasks/GET_TREMOR_SITES_CITIES_REQUEST_SUCCEEDED';

export const GET_TREMOR_SITES_CITIES_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_TREMOR_SITES_CITIES_REQUEST_FAILED';

export const SITE_SELECT_REQUEST_SENT =
  'app/patientApp/Tasks/SITE_SELECT_REQUEST_SENT';

export const SITE_SELECT_REQUEST_SUCCEEDED =
  'app/patientApp/Tasks/SITE_SELECT_REQUEST_SUCCEEDED';

export const SITE_SELECT_REQUEST_FAILED =
  'app/patientApp/Tasks/SITE_SELECT_REQUEST_FAILED';

export const SITE_SELECTION_DONE_BY_USER =
  'app/patientApp/Tasks/SITE_SELECTION_DONE_BY_USER';

export const GET_CITY_CENTERS_STATES_REQUEST_SENT =
  'app/patientApp/Tasks/GET_CITY_CENTERS_STATES_REQUEST_SENT';

export const GET_CITY_CENTERS_STATES_REQUEST_SUCCEEDED =
  'app/patientApp/Tasks/GET_CITY_CENTERS_STATES_REQUEST_SUCCEEDED';

export const GET_CITY_CENTERS_STATES_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_CITY_CENTERS_STATES_REQUEST_FAILED';

export const GET_CITY_CENTERS_CITIES_REQUEST_SENT =
  'app/patientApp/Tasks/GET_CITY_CENTERS_CITIES_REQUEST_SENT';

export const GET_CITY_CENTERS_CITIES_REQUEST_SUCCEEDED =
  'app/patientApp/Tasks/GET_CITY_CENTERS_CITIES_REQUEST_SUCCEEDED';

export const GET_CITY_CENTERS_CITIES_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_CITY_CENTERS_CITIES_REQUEST_FAILED';

export const GET_CITY_CENTERS_REQUEST_SENT =
  'app/patientApp/Tasks/GET_CITY_CENTERS_REQUEST_SENT';

export const GET_CITY_CENTERS_REQUEST_SUCCEEDED =
  'app/patientApp/Tasks/GET_CITY_CENTERS_REQUEST_SUCCEEDED';

export const GET_CITY_CENTERS_REQUEST_FAILED =
  'app/patientApp/Tasks/GET_CITY_CENTERS_REQUEST_FAILED';

export const CT_CENTERS_SELECT_REQUEST_SENT =
  'app/patientApp/Tasks/CT_CENTERS_SELECT_REQUEST_SENT';

export const CT_CENTERS_SELECT_REQUEST_SUCCEEDED =
  'app/patientApp/Tasks/CT_CENTERS_SELECT_REQUEST_SUCCEEDED';

export const CT_CENTERS_SELECT_REQUEST_FAILED =
  'app/patientApp/Tasks/CT_CENTERS_SELECT_REQUEST_FAILED';

export const CT_CENTER_SELECTION_DONE_BY_USER =
  'app/patientApp/Tasks/CT_CENTER_SELECTION_DONE_BY_USER';

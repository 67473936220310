export default {
  control: base => ({
    ...base,
    minHeight: 34,
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: base => ({
    ...base,
    padding: 4,
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px 6px',
  }),
  multiValue: base => ({
    ...base,
    border: '1px solid  #0646B4',
    backgroundColor: '#fff',
    borderRadius: '20px',
    color: '#0646B4',
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

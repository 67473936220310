export default {
  fields: [
    'name',
    'title',
    'latitude',
    'longitude',
    'timezone',
    'city',
    'state',
    'zipcode',
    'salesforceSiteName'
  ],
  rules: {
    name: [{ rule: 'isRequired', message: 'Name is required' }],
    title: [{ rule: 'isRequired', message: 'Address is required' }],
    latitude: [{ rule: 'isRequired', message: 'Latitude is required' }],
    longitude: [{ rule: 'isRequired', message: 'Longitude is required' }],
    timezone: [{ rule: 'isRequired', message: 'Timezone is required' }],
    city: [{ rule: 'isRequired', message: 'City is required' }],
    state: [{ rule: 'isRequired', message: 'State is required' }],
    zipcode: [{ rule: 'isRequired', message: 'Zipcode is required' }],
    salesforceSiteName: [{ rule: 'isRequired', message: 'Salesforce Site Name is required' }],
  },
};

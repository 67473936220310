import React from 'react';
// import LogoutIcon from '@mui/icons-material/Logout';
import './collapsing-side-bar.scss';
import { useCollapsingSideBarViewModel } from './viewModel';
import Logo from '../../../assets/images/ic_app_brand_logo_light.png';

const CollapsingSideBar = props => {
  
  const { barTextShowStatus, barOpenStatus, setBarOpenStatus, onItemClick } =
    useCollapsingSideBarViewModel(props);

  const renderMenuItems = items => {
    return items.map(item => {
      return (
        <div
          key={item.id}
          className={`item ${item.id === props.activeTab ? 'active' : ''}`}
          onClick={() => onItemClick(item.id, item.key)}
        >
          <span className='icon'>{item.icon}</span>
          <span className={`text ${barTextShowStatus}`}>{item.name}</span>
        </div>
      );
    });
  };

  return (
    <div className='collapsing-sidbar-wrapper'>
      <div className={`bar ${barOpenStatus ? 'bar-close' : 'bar-open'}`}>
        {!barOpenStatus ? (
          <div className='logo'>
            <img src={Logo} className='mms-logo' alt='INS' />
          </div>
        ) : (
          <></>
        )}
        <div className='menu-items'>
          {renderMenuItems(props.menu.slice(0, 5))}
        </div>
        <div
          className='back-button'
          onClick={() => setBarOpenStatus(!barOpenStatus)}
        >
          <span
            className={`icon ${
              barOpenStatus
                ? 'icon-font-a-right-chevron'
                : 'icon-font-a-left-chevron'
            }`}
          ></span>
        </div>
        <div className='menu-items'>
          {renderMenuItems(props.menu.slice(5, props.menu.length))}
        </div>
      </div>
    </div>
  );
};

export default CollapsingSideBar;

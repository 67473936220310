/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import authReducer, { userInfoReducer } from 'common/authReducers';
import { toasts } from 'common/toasterReducers';
import patientInfo from 'containers/PatientDetails/reducer';
import patientProceduresReducer, {
  switchProcedureReducer,
} from 'patientApp/common/reducers';
import passiveModeReducer from 'patientApp/containers/PassiveMode/reducers';
import patientCardReducer from 'common/patientCardReducer';
import shareOutcomesReportReducer from 'common/shareOutcomesReportReducer';
import {
  getProcedureLocationsReducer,
  getTreatmentCenterLocationsReducer,
  getProcedureProvidersReducer,
  addNewProcedureReducer,
  saveSearchFiltersReducer,
  getCtCentersReducer,
  loadingReducer,
  getProcedureModifiersReducer,
} from 'common/reducer';
import tasksReducer from 'patientApp/containers/Tasks/reducers';
import {
  traditionalSiteReducer,
  sideBarReducer,
} from 'traditionalSiteApp/common/reducers';
import patientRegisterReducer from 'containers/Authorization/reducers';
import { patientSearchReducer } from 'containers/PatientSearch/reducer';
import { connectRouter } from 'connected-react-router';
import getSearchCategoryListReducer from 'components/SelectCategory/reducer';
import history from './configureHistory';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    toasters: toasts,
    router: connectRouter(history),
    currentPatientInfo: patientInfo,
    currentUser: authReducer,
    profile: userInfoReducer,
    patientProcedures: patientProceduresReducer,
    activeProcedureDetails: switchProcedureReducer,
    prov: getProcedureProvidersReducer,
    loc: getProcedureLocationsReducer,
    treatmentCenterLocations: getTreatmentCenterLocationsReducer,
    ct: getCtCentersReducer,
    addProcedureRequest: addNewProcedureReducer,
    search: patientSearchReducer,
    customFilter: saveSearchFiltersReducer,
    passiveMode: passiveModeReducer,
    patientCard: patientCardReducer,
    shareOutcomesReport: shareOutcomesReportReducer,
    getProcedureModifiers: getProcedureModifiersReducer,
    patientRegister: patientRegisterReducer,
    taskReducer: tasksReducer,
    selectCategory: getSearchCategoryListReducer,
    loadingReducer,
    traditionalSiteReducer,
    sideBarReducer,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  return rootReducer;
}

export const nameValidationConfig = {
  fields: ['firstName', 'lastName', 'email', 'zipCode'],
  rules: {
    firstName: [{ rule: 'isRequired', message: 'First name is required' }],
    lastName: [{ rule: 'isRequired', message: 'Last name is required' }],
    email: [
      { rule: 'isEmail', message: 'Email is invalid' },
      { rule: 'isRequired', message: 'Email is required' },
    ],
    zipCode: [
      { rule: 'isZipCode', message: 'Zip Code is invalid' },
      { rule: 'isRequired', message: 'Zip Code is required' },
    ],
  },
};

export const phoneValidationConfig = {
  fields: ['phone'],
  rules: {
    phone: [
      { rule: 'isPhone', message: 'Mobile Number is invalid' },
      { rule: 'isRequired', message: 'Mobile Number is required' },
    ],
  },
};

export const emailOtpValidationConfig = {
  fields: ['emailOtp'],
  rules: {
    emailOtp: [{ rule: 'isRequired', message: 'OTP is required' }],
  },
};

export const phoneOtpValidationConfig = {
  fields: ['phoneOtp'],
  rules: {
    phoneOtp: [{ rule: 'isRequired', message: 'OTP is required' }],
  },
};

export const passwordValidationConfig = {
  fields: ['password'],
  rules: {
    password: [
      { rule: 'isPassword', message: 'Password is invalid' },
      { rule: 'isRequired', message: 'Password is required' },
    ],
  },
};

export const verifyPasswordValidationConfig = {
  fields: ['password'],
  rules: {
    password: [{ rule: 'isRequired', message: 'Password is required' }],
  },
};

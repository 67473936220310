import axios from '../base';
import { PATIENT_CREATE_CONVERSATION } from '../constants';

export const createConversation = conversationParams => {
  const {
    user_procedure_id,
    subject,
    message_body,
    question_id,
    resource_id,
    mediaResources,
  } = conversationParams;

  const params = {
    user_procedure_id,
    subject,
    message_body,
    question_id,
    resource_id,
  };

  if (mediaResources !== undefined || mediaResources !== null) {
    const mediaResourcesParams = [];
    for (let cnt = 0; cnt < mediaResources.length; cnt += 1) {
      const mediaResourceParam = {};
      mediaResourceParam.id = mediaResources[cnt].id;
      mediaResourceParam.duration = mediaResources[cnt].duration;
      mediaResourceParam.size = mediaResources[cnt].size;
      mediaResourceParam.name = mediaResources[cnt].name;
      mediaResourcesParams.push(mediaResourceParam);
    }
    params.media_resources = mediaResourcesParams;
  }
  return axios.post(PATIENT_CREATE_CONVERSATION, params);
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOutUser } from 'common/authThunkActions';
import LightBoxModal from 'components/LightboxModal';

class Logout extends Component {
  componentDidMount() {
    this.props.signOut();
  }

  render() {
    return <LightBoxModal show />;
  }
}

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(signOutUser()),
});

Logout.propTypes = {
  signOut: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Logout);

export const VERIFY_PIN_REQUEST_SENT =
  'app/patientApp/ViewDataDownloadRequest/VERIFY_PIN_REQUEST_SENT';
export const VERIFY_PIN_REQUEST_SUCCEED =
  'app/patientApp/ViewDataDownloadRequest/VERIFY_PIN_REQUEST_SUCCEED';
export const VERIFY_PIN_REQUEST_FAILED =
  'app/patientApp/ViewDataDownloadRequest/VERIFY_PIN_REQUEST_FAILED';

export const STATUSES = {
  PREPARED: 'prepared',
  REQUESTED: 'requested',
  INPROGESS: 'in_progress',
  FAILED: 'failed',
};

export const patientInfoValidationConfig = {
  fields: ['firstName', 'lastName', 'email', 'contactNumber', 'age'],
  rules: {
    firstName: [{ rule: 'isRequired', message: 'First name is required' }],
    lastName: [{ rule: 'isRequired', message: 'Last name is required' }],
    email: [
      { rule: 'isEmail', message: 'Email is invalid' },
      { rule: 'isRequired', message: 'Email is required' },
      // { rule: 'requiredDifferentValue', fieldsName: ['email', 'caregiverEmail'], message: "Patient's email should not be same as Caregiver's email" },
    ],
    contactNumber: [
      { rule: 'isPhone', message: 'Contact number is invalid' },
      { rule: 'isRequired', message: 'Contact number is required' },
      // { rule: 'requiredDifferentValue', fieldsName: ['contactNumber', 'caregiverContactNumber'], message: "Patient's phone number should not be same as Caregiver's phone number" },
    ],
    age: [{ rule: 'isRequired', message: 'Age is required' }],
    diagnosedDisease: [
      {
        rule: 'isCheckboxRequired',
        message: 'Selecting age Tremor information is compulsory',
      },
    ],
  },
};

export const procedureValidationConfig = {
  fields: [
    'procedureId',
    'contactNumberReferringPhysician',
    'locationId',
    'valveManufacturerId',
    'procedureDate',
  ],
  rules: {
    procedureId: [{ rule: 'isRequired', message: 'Procedure is required' }],
    contactNumberReferringPhysician: [
      { rule: 'isPhone', message: 'Contact number is invalid' },
    ],
    locationId: [{ rule: 'isRequired', message: 'Location is required' }],
    procedureDate: [
      {
        rule: 'isDependentRequired',
        dependOnKey: 'procedureTime',
        message: 'Date is required',
      },
    ],
    valveManufacturerId: [
      { rule: 'isRequired', message: 'Diagnosis is required' },
    ],
  },
};

export const caregiverValidationConfig = {
  fields: [
    'caregiverFirstName',
    'caregiverLastName',
    'caregiverEmail',
    'caregiverContactNumber',
    'complianceObtained',
  ],
  rules: {
    caregiverFirstName: [
      { rule: 'isRequired', message: 'First name is required' },
    ],
    caregiverLastName: [
      { rule: 'isRequired', message: 'Last name is required' },
    ],
    caregiverEmail: [
      { rule: 'isRequired', message: 'Email is required' },
      { rule: 'isEmail', message: 'Email is invalid' },
      {
        rule: 'requiredDifferentValue',
        fieldsName: ['email', 'caregiverEmail'],
        message: "Caregiver's email should not be same as Patient's email",
      },
    ],
    caregiverContactNumber: [
      { rule: 'isRequired', message: 'Contact number is required' },
      { rule: 'isPhone', message: 'Contact number is invalid' },
      {
        rule: 'requiredDifferentValue',
        fieldsName: ['contactNumber', 'caregiverContactNumber'],
        message:
          "Caregiver's phone number should not be same as Patient's phone number",
      },
    ],
    complianceObtained: [
      {
        rule: 'isCheckboxRequired',
        message: "Patient Consent can't be left unchecked",
      },
    ],
  },
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SingleSelectWithoutBorder as CustomizedSelect } from 'components/SelectV2';
import { getSearchCategoryListRequest } from './actions';
import './style.scss';

const SelectCategory = ({ selectCategory, category }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSearchCategoryListRequest());
  }, []);

  const searchCategoryList = useSelector(
    state => state.selectCategory.categoryList
  );

  return (
    <CustomizedSelect
      id='select-category'
      className='form-group select-form-group'
      onChange={selectCategory}
      defaultValue={{
        value: category || 'All',
        label: searchCategoryList[category] || 'All',
      }}
      options={Object.keys(searchCategoryList).map(key => ({
        value: key,
        label: searchCategoryList[key],
      }))}
    />
  );
};

SelectCategory.propTypes = {
  selectCategory: PropTypes.func.isRequired,
  category: PropTypes.string,
};

SelectCategory.defaultProps = {
  category: '',
};

export default SelectCategory;

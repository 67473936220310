import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class HelpBlock extends PureComponent {
  render() {
    const customClass = this.props.className ? this.props.className : '';
    return (
      <>
        {this.props.value !== undefined && (
          <span
            style={{ color: 'red' }}
            className={`help-block ${customClass}`}
          >
            {this.props.value}
          </span>
        )}
      </>
    );
  }
}

HelpBlock.propTypes = {
  value: PropTypes.any,
  className: PropTypes.any,
};

export default HelpBlock;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, combineReducers } from 'redux';
import injectReducer from 'utils/injectReducer';
import { Modal } from 'react-bootstrap/lib';
import {
  SingleSelectWithoutBorder as CustomizedSelect,
  CustomMultiSelect,
  MultiSelect as Select,
} from 'components/SelectV2';

import RadioButton from 'components/RadioButton';
import HelpBlock from 'components/HelpBlock';
import validationConfig from './validator';
import validate, { clearErrorsForField } from 'common/validator';
import {
  onfetchAllLocationsOfTheDepartmentRequest,
  onAddNewLocationRequest,
  updateLocationAction,
  onfetchTimezonesRequest,
} from './actions';

import { locationAdministrationReducer } from './reducer';
import {
  getDepartmentProceduresRequest,
  getDepartmentProvidersRequest,
} from 'common/actions';
import {
  getDepartmentProceduresReducer,
  getDepartmentProvidersReducer,
} from 'common/reducer';
import Pagination from 'components/Pagination';
import { getItemFromStorage } from 'services/storage';

import Tab from 'react-bootstrap/lib/Tab';
import Nav from 'react-bootstrap/lib/Nav';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import NavItem from 'react-bootstrap/lib/NavItem';
import Emptyview from 'components/Emptyview';
import NoLocations from '../../assets/images/no_locations.png';

class LocationAdministration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddEditLocationModal: false,
      name: '',
      title: '',
      latitude: '',
      longitude: '',
      timezone: '',
      zipcode: '',
      city: '',
      state: '',
      code: '',
      email: '',
      phone: '',
      url: '',
      salesforceSiteName: '',
      errors: {},
      procedureId: '',
      procedures: [],
      neurosurgeons: [],
      isTips: false,
      isActive: false,
      disableTimezone: false,
      disableCode: false,
      canAddOnlyProviders: false,
      page: 1,
      filterName: '',
      filterProcedureIds: [],
      previousFilterData: {
        previousPage: 1,
        previousFilterName: '',
        previousFilterProcedureIds: [],
      },
    };
  }

  componentDidMount = () => {
    const currentUser = JSON.parse(getItemFromStorage('currentUser'));
    if (!(currentUser.hasSuperUserPrivileges && currentUser.isAdmin)) {
      this.props.history.push('/not-found');
    } else {
      window.addEventListener('scroll', this.scrollEvent);
      this.props.fetchAllLocationsOfTheDepartment({ page: this.state.page });
      this.props.fetchTimezones();
      this.props.getDepartmentProceduresRequest();
      this.props.getDepartmentProvidersRequest();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isRequestInProgress === false &&
      prevProps.isRequestInProgress === true
    ) {
      this.setState({
        showAddEditLocationModal: this.props.show,
      });
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.procedures !== nextProps.procedures) {
      this.setState({
        procedures: nextProps.procedures,
      });

      // this.props.fetchSurgicalSites({ procedure_id: procedureId });
    }

    if (this.state.canAddOnlyProviders !== nextProps.canAddOnlyProviders) {
      this.setState({
        canAddOnlyProviders: nextProps.canAddOnlyProviders,
        providerType: 'Provider',
      });
    }
  };

  scrollEvent = () => {
    let tableHeader = document.getElementById('main-header');
    let fixedHeader = document.getElementById('fixed-header');

    if (tableHeader != null && fixedHeader != null) {
      tableHeader = document
        .getElementById('main-header')
        .getBoundingClientRect();
      fixedHeader = document
        .getElementById('fixed-header')
        .getBoundingClientRect();

      if (tableHeader.top <= fixedHeader.top) {
        document.getElementById('fixed-header').style.visibility = 'visible';
      } else {
        document.getElementById('fixed-header').style.visibility = 'hidden';
      }
    }
  };

  onAddEditLocationModal = location => {
    const {
      id,
      name,
      title,
      latitude,
      longitude,
      timezone,
      zipcode,
      city,
      state,
      code,
      email,
      phone,
      url,
      isTips,
      isActive,
      salesforceSiteName,
    } = location || {};

    const selectedProcedures = this.getSelectedProcedures(id);
    const selectedProviders = this.getSelectedProviders(id);

    this.setState({
      locationId: id ? id : null,
      name: name ? name : '',
      title: title ? title : '',
      latitude: latitude ? latitude : '',
      longitude: longitude ? longitude : '',
      timezone: timezone ? { label: `${timezone}`, value: `${timezone}` } : '',
      zipcode: zipcode ? zipcode : '',
      city: city ? city : '',
      state: state ? state : '',
      code: code ? code : '',
      salesforceSiteName: salesforceSiteName ? salesforceSiteName : '',
      email: email ? email : '',
      phone: phone ? phone : '',
      url: url ? url : '',
      isTips: id ? isTips : false,
      isActive: id ? isActive : false,
      showAddEditLocationModal: true,
      procedures: selectedProcedures ? selectedProcedures : [],
      neurosurgeons: selectedProviders ? selectedProviders : [],
      disableTimezone: timezone ? true : false,
      disableCode: code ? true : false,
    });
  };

  onAddNewUser = () => {
    this.props.history.push('/users');
  };

  getSelectedProcedures = id => {
    const selectedLocation = this.props.locations.find(item => item.id === id);

    const selectedItems =
      selectedLocation &&
      selectedLocation.procedures.map(item => ({
        value: item.id,
        label: item.name,
      }));
    return selectedItems;
  };

  getSelectedProviders = id => {
    const selectedLocation = this.props.locations.find(item => item.id === id);

    const selectedItems =
      selectedLocation &&
      selectedLocation.providers.map(item => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      }));
    return selectedItems;
  };

  hideAddEditLocationModal = () => {
    this.setState({
      showAddEditLocationModal: false,
      errors: {},
    });
  };

  changeFieldHandler = e => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  changeCodeFieldHandler = e => {
    const obj = {};
    obj[e.target.name] = e.target.value.toUpperCase();
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  selectTimezone = selectedOption => {
    this.setState({
      timezone: selectedOption,
      errors: clearErrorsForField(this.state.errors, 'timezone'),
    });
  };

  isFilterUpdated = (currentData, previousData) => {
    return (
      previousData.previousPage !== currentData.page ||
      currentData.filterName.trim().toUpperCase() !==
        previousData.previousFilterName.trim().toUpperCase() ||
      JSON.stringify(currentData.filterProcedureIds.sort()) !==
        JSON.stringify(previousData.previousFilterProcedureIds.sort())
    );
  };

  selectProcedures = selectedValues => {
    this.setState({
      procedures: selectedValues,
      errors: clearErrorsForField(this.state.errors, 'procedures'),
    });
  };

  selectProviders = selectedValues => {
    this.setState({
      providers: selectedValues,
      errors: clearErrorsForField(this.state.errors, 'providers'),
    });
  };

  onAddLocation = () => {
    this.validateAddEditLocationFormValues();
  };

  onEditLocation = () => {
    this.validateAddEditLocationFormValues();
  };

  onIsTipsToggle = value => {
    this.setState({
      isTips: value,
    });
  };

  onIsActiveToggle = value => {
    this.setState({
      isActive: value,
    });
  };
  onChangeFilterProcedure = selectedValues => {
    this.setState({ filterProcedureIds: selectedValues });
  };

  handlePagination = page => {
    this.setState({ page }, () => {
      this.onSetFilter(true);
    });
  };

  validateAddEditLocationFormValues = () => {
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  extractIdsFromObjects = objects => objects.map(item => item.value);

  getPaginationObject = () => {
    const { filterName, page, filterProcedureIds } = this.state;
    return {
      page: page,
      name: filterName,
      procedure_ids: this.extractIdsFromObjects(filterProcedureIds),
    }
  }

  onFormValidationSuccess = () => {
    const params = {
      name: this.state.name.trim(' '),
      title: this.state.title,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      timezone: this.state.timezone.value,
      code: this.state.code?.trim() == '' ? null : this.state.code,
      zipcode: this.state.zipcode,
      is_tips: this.state.isTips,
      email: this.state.email,
      city: this.state.city,
      state: this.state.state,
      phone: this.state.phone,
      url: this.state.url,
      salesforce_site_name: this.state.salesforceSiteName,
      is_active: this.state.isActive
    };
    if (this.state.locationId) {
      params.id = this.state.locationId;
      this.props.updateLocation(params, this.getPaginationObject());
    } else {
      this.props.addLocation(params, this.getPaginationObject());
    }
  };

  changeFilterName = e => {
    this.setState({ filterName: e.target.value });
  };

  onSetFilter = isPagination => {
    const { filterName, filterProcedureIds } = this.state;

    const page = isPagination ? this.state.page : 1;

    let currentData = { filterName, filterProcedureIds, page: this.state.page };
    if (this.isFilterUpdated(currentData, this.state.previousFilterData)) {
      this.props.fetchAllLocationsOfTheDepartment(this.getPaginationObject());
      this.setState({
        page: page,
        previousFilterData: {
          previousPage: this.state.page,
          previousFilterName: this.state.filterName,
          previousFilterProcedureIds: this.state.filterProcedureIds,
        },
      });
    }
  };

  onResetFilter = () => {
    this.setState(
      {
        filterName: '',
        filterProcedureIds: [],
        page: 1,
        errors: {},
        previousFilterData: {
          previousPage: 1,
          previousFilterName: '',
          previousFilterProcedureIds: [],
        },
      },
      () => {
        this.props.fetchAllLocationsOfTheDepartment({ page: this.state.page });
      }
    );
  };

  renderFilters = () => {
    return (
      <div className='filters-container'>
        <div className='filters-label'>Filters</div>
        <div className='row filter-wrapper'>
          <div className='col-xs-12'>
            <label htmlFor=''>Facility Name</label>
            <input
              type='text'
              className='form-control'
              placeholder='Type Name here'
              onChange={this.changeFilterName}
              value={this.state.filterName}
              name='filterName'
            />
          </div>

          <div className='col-xs-12'>
            <label htmlFor='procedure'>Procedure</label>
            <CustomMultiSelect
              allowSelectAll
              isLoading={this.props.isFilterDataRequestInProgress}
              onChange={this.onChangeFilterProcedure}
              value={this.state.filterProcedureIds}
              isSearchable={false}
              placeholder={
                this.state.filterProcedureIds.length ? 'Selected' : 'Select'
              }
              options={this.props.procedures.map(item => ({
                value: item.id,
                label: `${item.name}`,
              }))}
            />
          </div>

          <div className='col-xs-12 btn-wrapper'>
            <button
              className={`btn btn-default clear-all ${
                this.state.isFilterApplied ? '' : 'cursor-pointer'
              }`}
              onClick={this.onResetFilter}
            >
              Clear All
            </button>
            <button
              className='btn btn-primary apply-button'
              onClick={() => this.onSetFilter(false)}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderData = () => {
    return (
      <>
        {this.props.locations.length > 0 ? (
          <div className='row'>
            <div className='col-sm-12'>
              <div className='ui-table'>
                <table className='table'>
                  <thead className='sticky-header'>
                    <tr>
                      <th>Facility Name</th>
                      <th>Salesforce Site Name</th>
                      <th style={{ minWidth: '120px' }}>Procedures</th>
                      <th style={{ minWidth: '90px' }}>Is Tips?</th>
                      <th style={{ minWidth: '90px' }}>Is Active?</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.locations.map(location => (
                      <tr key={location.id}>
                        <td>{location.name}</td>
                        <td>{location.salesforceSiteName}</td>
                        <td>
                          {location.procedures.length > 0
                            ? location.procedures.map(proc => (
                                <p key={proc.id}>{proc.name}</p>
                              ))
                            : '- -'}
                        </td>
                        <td>{location.isTips ? 'Yes' : 'No'}</td>
                        <td>{location.isActive ? 'Yes' : 'No'}</td>
                        <td>
                          <span
                            className='icon icon-font-a-write-message cursor-pointer'
                            onClick={() =>
                              this.onAddEditLocationModal(location)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div style={{ marginBottom: '20px' }}>
                {this.props.pagination && (
                  <Pagination
                    pagination={this.props.pagination}
                    title='Treatment Centers'
                    handlePagination={this.handlePagination}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <Emptyview
            imgSrc={NoLocations}
            className=''
            message='No Treatment Centers'
          />
        )}
      </>
    );
  };

  renderChildComponents = () => {
    const DISABLE_EDIT_FUNCTIONALITY = false;
    return (
      <React.Fragment>
        <div className='row'>
          <div
            className='col-sm-3 col-md-3 col-lg-3 filter-container-wrapper'
            style={{ position: 'fixed' }}
          >
            {this.renderFilters()}
          </div>
          <div
            className='col-sm-9 col-md-9 col-lg-9 record-list-wrapper'
            style={{ float: 'right' }}
          >
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <span
                className={`add-new-link btn ${
                  DISABLE_EDIT_FUNCTIONALITY ? 'disabled' : ''
                }`}
                onClick={
                  DISABLE_EDIT_FUNCTIONALITY
                    ? null
                    : this.onAddEditLocationModal
                }
              >
                <span className='icon icon-font-a-add-new-task'></span>
                Add New Location
              </span>
              <span className='pagination'>
                {this.props.pagination && (
                  <Pagination
                    pagination={this.props.pagination}
                    title='Locations'
                    handlePagination={this.handlePagination}
                  />
                )}
              </span>
            </div>
            <div className='clr'></div>
            {this.renderData()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const DISABLE_EDIT_FUNCTIONALITY = false;
    return (
      <>
        {this.props.manageLocationsEnabled && (
          <div className='col-xs-12 content-administration'>
            <div className='admin-title-container dashboard-container-fluid'>
              <Tab.Container id='admin-activity-tabs'>
                <React.Fragment>
                  <div className='admin-navigation-div'>
                    <div className='admin-navigation-section'>
                      <Nav bsStyle='pills' justified>
                        <LinkContainer to='/users' replace>
                          <NavItem eventKey='providers'>
                            <React.Fragment>
                              <i className='icon icon-font-a-patient-under-physician'></i>
                              <span className='icon-label'>
                                {this.state.canAddOnlyProviders
                                  ? 'Providers'
                                  : 'Users'}
                              </span>
                            </React.Fragment>
                          </NavItem>
                        </LinkContainer>
                        <LinkContainer to='/locations' replace>
                          <NavItem eventKey='locations'>
                            <React.Fragment>
                              <i className='icon icon-font-a-location'></i>
                              <span className='icon-label'>
                                Treatment Centers
                              </span>
                            </React.Fragment>
                          </NavItem>
                        </LinkContainer>
                      </Nav>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <Tab.Content>{this.renderChildComponents()}</Tab.Content>
                  </div>
                </React.Fragment>
              </Tab.Container>
              <Modal
                show={this.state.showAddEditLocationModal}
                onHide={this.hideAddEditLocationModal}
                container={document.body}
                autoFocus
                aria-labelledby='contained-modal-title'
                backdrop='static'
              >
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title'>
                    {this.state.locationId ? 'Edit Location' : 'Add Location'}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div id='add-location-modal'>
                    <div
                      className={`form-group ${
                        this.state.errors.name !== undefined ? 'has-error' : ''
                      }`}
                    >
                      <label htmlFor=''>Facility Name</label>
                      <input
                        type='text'
                        className='form-control'
                        name='name'
                        value={this.state.name}
                        onChange={this.changeFieldHandler}
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                      />
                      <HelpBlock value={this.state.errors.name} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.title !== undefined ? 'has-error' : ''
                      }`}
                    >
                      <label htmlFor=''>Address</label>
                      <input
                        type='text'
                        className='form-control'
                        name='title'
                        value={this.state.title}
                        onChange={this.changeFieldHandler}
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                      />
                      <HelpBlock value={this.state.errors.title} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.latitude !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label htmlFor=''>Latitude</label>
                      <input
                        type='number'
                        className='form-control no-spinner'
                        name='latitude'
                        value={this.state.latitude}
                        onChange={this.changeFieldHandler}
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                      />
                      <HelpBlock value={this.state.errors.latitude} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.longitude !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label htmlFor=''>Longitude</label>
                      <input
                        type='number'
                        className='form-control no-spinner'
                        name='longitude'
                        value={this.state.longitude}
                        onChange={this.changeFieldHandler}
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                      />
                      <HelpBlock value={this.state.errors.longitude} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.timezone !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label htmlFor=''>Timezone</label>
                      <CustomizedSelect
                        id='select-timezone'
                        placeholder='Select Timezone'
                        onChange={this.selectTimezone}
                        // isDisabled={this.state.disableTimezone}
                        isDisabled={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.timezone}
                        options={Object.keys(this.props.timezones).map(key => ({
                          value: this.props.timezones[key],
                          label: this.props.timezones[key],
                        }))}
                      />
                      <HelpBlock value={this.state.errors.timezone} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.zipcode !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label htmlFor=''>Zip Code</label>
                      <input
                        type='text'
                        className='form-control'
                        name='zipcode'
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.zipcode}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.zipcode} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.city !== undefined ? 'has-error' : ''
                      }`}
                    >
                      <label htmlFor=''>City</label>
                      <input
                        type='text'
                        className='form-control'
                        name='city'
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.city}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.city} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.state !== undefined ? 'has-error' : ''
                      }`}
                    >
                      <label htmlFor='state'>State</label>
                      {/*<CustomizedSelect*/}
                      {/*  id="select-state"*/}
                      {/*  placeholder="Select State"*/}
                      {/*  onChange={this.selectTimezone}*/}
                      {/*  isDisabled={DISABLE_EDIT_FUNCTIONALITY}*/}
                      {/*  value={this.state.state}*/}
                      {/*  options={Object.keys(this.props.timezones).map((key) => ({*/}
                      {/*    value: this.props.timezones[key],*/}
                      {/*    label: this.props.timezones[key],*/}
                      {/*  }))}*/}
                      {/*/>*/}
                      <input
                        type='text'
                        className='form-control'
                        name='state'
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.state}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.state} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.code !== undefined ? 'has-error' : ''
                      }`}
                    >
                      <label htmlFor=''>Code</label>
                      <input
                        type='text'
                        className='form-control'
                        name='code'
                        // readOnly={this.state.disableCode}
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.code}
                        onChange={this.changeCodeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.code} />
                    </div>

                    <div className={`form-group clearfix`}>
                      <div className='float-left'>
                        <label htmlFor='userType'>Is Location Active?</label>
                      </div>
                      <div className='float-right'>
                        <RadioButton
                          onToggle={this.onIsActiveToggle}
                          value={this.state.isActive}
                          defaultValue={this.state.isActive}
                          disabled={DISABLE_EDIT_FUNCTIONALITY}
                        />
                      </div>
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.email !== undefined ? 'has-error' : ''
                      }`}
                    >
                      <label htmlFor=''>Email</label>
                      <input
                        type='text'
                        className='form-control'
                        name='email'
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.email}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.email} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.phone !== undefined ? 'has-error' : ''
                      }`}
                    >
                      <label htmlFor=''>Contact Number</label>
                      <input
                        type='text'
                        className='form-control'
                        name='phone'
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.phone}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.phone} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.url !== undefined ? 'has-error' : ''
                      }`}
                    >
                      <label htmlFor=''>Url</label>
                      <input
                        type='text'
                        className='form-control'
                        name='url'
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.url}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.url} />
                    </div>

                    <div
                      className={`form-group ${
                        this.state.errors.salesforceSiteName !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label htmlFor=''>Salesforce Site Name</label>
                      <input
                        type='text'
                        className='form-control'
                        name='salesforceSiteName'
                        readOnly={DISABLE_EDIT_FUNCTIONALITY}
                        value={this.state.salesforceSiteName}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.salesforceSiteName} />
                    </div>

                    <div className={`form-group clearfix`}>
                      <div className='float-left'>
                        <label htmlFor='userType'>Is Tips?</label>
                      </div>
                      <div className='float-right'>
                        <RadioButton
                          onToggle={this.onIsTipsToggle}
                          value={this.state.isTips}
                          defaultValue={this.state.isTips}
                          disabled={DISABLE_EDIT_FUNCTIONALITY}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {this.state.locationId ? (
                    <button
                      className={`btn btn-primary ${
                        DISABLE_EDIT_FUNCTIONALITY ? 'disabled' : ''
                      }`}
                      onClick={
                        DISABLE_EDIT_FUNCTIONALITY ? null : this.onEditLocation
                      }
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className={`btn btn-primary ${
                        DISABLE_EDIT_FUNCTIONALITY ? 'disabled' : ''
                      }`}
                      onClick={
                        DISABLE_EDIT_FUNCTIONALITY ? null : this.onAddLocation
                      }
                    >
                      Save
                    </button>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
      </>
    );
  }
}
LocationAdministration.propTypes = {
  fetchAllLocationsOfTheDepartment: PropTypes.func,
  pagination: PropTypes.object,
};
const mapStateToProps = state => ({
  locations:
    state.locationAdministration.locationAdministrationReducer.locations,
  isRequestInProgress:
    state.locationAdministration.locationAdministrationReducer
      .isRequestInProgress,
  show: state.locationAdministration.locationAdministrationReducer.show,
  locations:
    state.locationAdministration.locationAdministrationReducer.locations,
  error: state.locationAdministration.locationAdministrationReducer.error,
  timezones:
    state.locationAdministration.locationAdministrationReducer.timezones,
  procedures:
    state.locationAdministration.getDepartmentProceduresReducer.procedures,
  providers:
    state.locationAdministration.getDepartmentProvidersReducer.providers,
  manageLocationsEnabled:
    state.currentUser.attributes.hasSuperUserPrivileges &&
    state.currentUser.attributes.isAdmin,
  pagination:
    state.locationAdministration.locationAdministrationReducer.pagination,
  canAddOnlyProviders: state.currentUser.attributes.canAddOnlyProviders,
});

const mapDispatchToProps = dispatch => ({
  fetchAllLocationsOfTheDepartment: (page = null) =>
    dispatch(onfetchAllLocationsOfTheDepartmentRequest(page)),
  addLocation: (params, pagination) => dispatch(onAddNewLocationRequest(params, pagination)),
  updateLocation: (params, pagination) => dispatch(updateLocationAction(params, pagination)),
  fetchTimezones: () => dispatch(onfetchTimezonesRequest()),
  getDepartmentProceduresRequest: () =>
    dispatch(getDepartmentProceduresRequest()),
  getDepartmentProvidersRequest: () =>
    dispatch(getDepartmentProvidersRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'locationAdministration',
  reducer: combineReducers({
    locationAdministrationReducer,
    getDepartmentProceduresReducer,
    getDepartmentProvidersReducer,
  }),
});

export default compose(withReducer, withConnect)(LocationAdministration);

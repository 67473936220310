import React from 'react';
import PropTypes from 'prop-types';
import { TOTAL_PAGES_OF_PROGRESS_BAR } from 'containers/Authorization/constants';

const progressBar = props => {
  const { page } = props;

  return [...Array(TOTAL_PAGES_OF_PROGRESS_BAR).keys()].map(
    (pageNum, index) => (
      <div
        key={pageNum}
        className={`eclipse-progress-dot ${
          page >= index + 2 ? 'completed-circle' : ''
        }`}
      />
    )
  );
};

progressBar.propTypes = {
  page: PropTypes.number.isRequired,
};

export default progressBar;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './patientRegister.scss';

const RESEND_TIME = 30;

const ResendCode = props => {
  const [seconds, setSeconds] = useState(RESEND_TIME);
  const [intervalId, setIntervalId] = useState(null);

  const createTimer = () => {
    return setInterval(() => {
      if (seconds > 0) {
        setSeconds(prev => prev - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    return () => {
      setIntervalId(null);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      setIntervalId(null);
      clearInterval(intervalId);
    } else if (seconds === RESEND_TIME) {
      setIntervalId(createTimer());
    }
  }, [seconds]);

  const resendCode = () => {
    const { generateOtp } = props;
    generateOtp();
    setSeconds(RESEND_TIME);
  };

  return (
    <div className='text-center'>
      <div className='resend-code'>
        {seconds > 0 ? (
          <div>
            <div className='timer'>
              Resend Code in{' '}
              <span className='timer-timer'>
                00:{seconds < 10 ? `0${seconds}` : seconds}
              </span>
            </div>
            <div className='resend-otp disabled'>Resend Code</div>
          </div>
        ) : (
          <div className='resend-otp' onClick={resendCode} aria-hidden='true'>
            Resend Code
          </div>
        )}
      </div>
    </div>
  );
};

ResendCode.propTypes = {
  generateOtp: PropTypes.func.isRequired,
};

export default ResendCode;

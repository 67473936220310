import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'containers/Messages';

class ConversationDetails extends React.Component {
  render() {
    return (
      <Messages
        conversationId={this.props.selectedConversationId}
        goToConversations={this.props.goToConversations}
      />
    );
  }
}

ConversationDetails.propTypes = {
  selectedConversationId: PropTypes.number,
  goToConversations: PropTypes.func,
};

export default ConversationDetails;

import React, { useState, useEffect, useMemo } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { sortData } from 'utils/common';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllPatients } from 'traditionalSiteApp/common/actions';

const useDataTableViewModel = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const pagination = useSelector(
    store => store.traditionalSiteReducer.pagination
  );

  useEffect(() => {
    setData(props.all_patients);
  }, [props.all_patients]);

  useEffect(() => {
    props.dataTableHeaders.map(header => {
      if (header.sortable) {
        props.setSortingStatus({ [header.key]: false });
      }
    });
  }, [props.dataTableHeaders]);

  const getHeaderColumn = (header, tabKey) => {
    let sortIcon = '';
    if (header?.sortable) {
      sortIcon = props.sortingStatus[header.key] ? (
        <ArrowUpwardIcon
          sx={{ fontSize: '16px' }}
          onClick={() => sortOrdersData(header.key, tabKey)}
        />
      ) : (
        <ArrowDownwardIcon
          sx={{ fontSize: '16px' }}
          onClick={() => sortOrdersData(header.key, tabKey)}
        />
      );
    }
    if (tabKey === 'archive' && !header.showOnarchieve) {
      return null;
    }

    if (tabKey === 'inbox' && !header.showOninbox && header.showOnarchieve) {
      return null;
    }

    return (
      <th>
        {header.displayName} {sortIcon}
      </th>
    );
  };

  const sortOrdersData = (field, tabKey) => {
    const direction = props.sortingStatus[field] === true ? 'desc' : 'asc';
    var sort = field.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    sort = 'patient_' + sort;

    const params = {
      archived: tabKey,
      sort: sort,
      direction: direction,
    };

    if (props.searchTerm.length > 0) {
      params.term = props.searchTerm;
    }

    dispatch(fetchAllPatients(params));

    props.handlePagination(1);
    props.setSortingStatus({
      [field]: !props.sortingStatus[field],
    });
  };

  return {
    dataTableHeaders: props.dataTableHeaders,
    data,
    getHeaderColumn,
    pagination,
  };
};

export default useDataTableViewModel;

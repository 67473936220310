/*
 *
 * PatientDetailsPage actions
 *
 */
import { setItemToStorage } from 'services/storage';
import {
  PATIENT_SEARCH_REQUEST_SENT,
  PATIENT_SEARCH_REQUEST_SUCCEED,
  PATIENT_SEARCH_REQUEST_FAILED,
} from './constants';

import { getPatientSearchRequest } from '../../services/patientSearch';

export const getPatientSearchSent = () => ({
  type: PATIENT_SEARCH_REQUEST_SENT,
});

export const getPatientSearchSucceeded = data => ({
  type: PATIENT_SEARCH_REQUEST_SUCCEED,
  data,
});

export const getPatientSearchFailed = () => ({
  type: PATIENT_SEARCH_REQUEST_FAILED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPatientSearch = params => dispatch => {
  dispatch(getPatientSearchSent());
  getPatientSearchRequest(params)
    .then(response => {
      dispatch(getPatientSearchSucceeded(response.data));
      setItemToStorage('facets', JSON.stringify(response.data.facets));
    })
    .catch(() => {
      dispatch(getPatientSearchFailed());
    });
};

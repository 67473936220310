import { getItemFromStorage } from 'services/storage';
import { proxyAuthHeaderKeys } from 'services/constants';

export const isMasquerading = () => {
  let isMasqueradingSession = true;
  proxyAuthHeaderKeys.forEach(key => {
    key = key === 'proxyAuthorization' ? 'proxyToken' : key;
    if (getItemFromStorage(key)) {
      isMasqueradingSession = isMasquerading && true;
    } else {
      isMasqueradingSession = false;
    }
  });
  return isMasqueradingSession;
};

export const isiOS =
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

const sortAsc = field => {
  return function (a, b) {
    console.log(a, b);
    if (a[field] > b[field]) {
      return 1;
    }
    if (a[field] < b[field]) {
      return -1;
    }
    return 0;
  };
};

const sortDesc = field => {
  return function (a, b) {
    console.log(a, b);
    if (a[field] > b[field]) {
      return -1;
    }
    if (a[field] < b[field]) {
      return 1;
    }
    return 0;
  };
};
// below function is used to sort a js object based on any particular key of object
// if order is true, it sorts in ascending order and vice versa
export const sortData = (data, field, order = true) => {
  let newData = [...data];
  if (order) {
    return [...newData].sort(sortAsc(field));
    // ;
  } else {
    return [...newData].sort(sortDesc(field));
    // setSortingStatus({ ...sortingStatus, [field]: true });
  }
};

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

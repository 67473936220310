import React from 'react';
import { useSelector } from 'react-redux';
import LightboxModal from 'components/LightboxModal';

const LoaderDisplay = () => {
  const apiCallCount = useSelector(store => store.loadingReducer.apiCallCount);
  if (apiCallCount > 0) {
    return <LightboxModal show />;
  }
};

export default LoaderDisplay;

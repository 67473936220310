import {
  GET_SURVEY_FEEDBACK_REQUEST_SENT,
  GET_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  GET_SURVEY_FEEDBACK_REQUEST_FAILED,
  SUBMIT_SURVEY_FEEDBACK_REQUEST_SENT,
  SUBMIT_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  SUBMIT_SURVEY_FEEDBACK_REQUEST_FAILED,
  CLEAR_SURVEY_FEEDBACK_QUESTIONS,
} from './constants';

const initialState = {
  isLoading: false,
  hasError: false,
  questions: [],
  isSubmitted: false,
  survey_result: null,
  survey_result_internal_name: null,
  is_site_selectable: null,
  survey_response: null,
  type: '',
  title: '',
  openingUrl: null,
  enable_ct_center_selection: false,
};

const surveyFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY_FEEDBACK_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        isSubmitted: false,
        hasError: false,
        type: '',
      };
    case GET_SURVEY_FEEDBACK_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSubmitted: action.data.isSubmitted,
        survey_result_internal_name: action.data.surveyResultInternalName,
        is_site_selectable: action.data.isSiteSelectable,
        survey_response: action.data.surveyResponse,
        questions: action.data.userExpectedQuestions,
        type: action.data.eventType,
        surveyResponse: action.data.surveyResponse,
        title: action.data.event.title,
        openingUrl: action.data.event.openingUrl,
        showSelectButton: action.data.showSelectButton,
      };
    case GET_SURVEY_FEEDBACK_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isSubmitted: false,
        hasError: true,
        type: '',
      };

    case SUBMIT_SURVEY_FEEDBACK_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SUBMIT_SURVEY_FEEDBACK_REQUEST_SUCCEED:
      return {
        ...state,
        survey_result: action.data.surveyResult,
        survey_result_internal_name: action.data.surveyResultInternalName,
        is_site_selectable: action.data.isSiteSelectable,
        survey_response: action.data.surveyResponse,
        hasError: false,
        isLoading: false,
      };
    case SUBMIT_SURVEY_FEEDBACK_REQUEST_FAILED:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case CLEAR_SURVEY_FEEDBACK_QUESTIONS:
      return initialState;
    default:
      return state;
  }
};

export default surveyFeedbackReducer;

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useCollapsingSideBarViewModel = props => {
  const [barOpenStatus, setBarOpenStatus] = useState(false);
  const [barTextShowStatus, setBarTextShowStatus] = useState('show');

  const history = useHistory();

  useEffect(() => {
    if (barOpenStatus) setBarTextShowStatus('hide');
    else setTimeout(() => setBarTextShowStatus('show'), 300);
  }, [barOpenStatus]);

  const onItemClick = (id, key) => {
    props.updateActiveTabData(id, key);
  };

  return {
    onItemClick,
    barOpenStatus,
    setBarOpenStatus,
    setBarTextShowStatus,
    barTextShowStatus,
  };
};

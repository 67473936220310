/*
 *
 * PatientDetails constants
 *
 */

export const GET_PATIENT_DETAILS_REQUEST_SENT =
  'app/PatientDetails/GET_PATIENT_DETAILS_REQUEST_SENT';
export const GET_PATIENT_DETAILS_REQUEST_SUCCEED =
  'app/PatientDetails/GET_PATIENT_DETAILS_REQUEST_SUCCEED';
export const GET_PATIENT_DETAILS_REQUEST_FAILED =
  'app/PatientDetails/GET_PATIENT_DETAILS_REQUEST_FAILED';

export const REMOVE_PATIENT_REQUEST_SENT =
  'app/PatientDetails/REMOVE_PATIENT_REQUEST_SENT';
export const REMOVE_PATIENT_REQUEST_SUCCEED =
  'app/PatientDetails/REMOVE_PATIENT_REQUEST_SUCCEED';
export const REMOVE_PATIENT_REQUEST_FAILED =
  'app/PatientDetails/REMOVE_PATIENT_REQUEST_FAILED';

export const UPDATE_NEEDS_ATTENTION_REQUEST_SENT =
  'app/PatientDetails/UPDATE_NEEDS_ATTENTION_REQUEST_SENT';
export const UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED =
  'app/PatientDetails/UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED';
export const UPDATE_NEEDS_ATTENTION_REQUEST_FAILED =
  'app/PatientDetails/UPDATE_NEEDS_ATTENTION_REQUEST_FAILED';
export const RESET_NEEDS_ATTENTION = 'app/PatientDetails/RESET_NEEDS_ATTENTION';

export const GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT =
  'app/PatientDetails/GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT';
export const GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED =
  'app/PatientDetails/GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED';
export const GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED =
  'app/PatientDetails/GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED';

export const UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT =
  'app/PatientDetails/UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT';
export const UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED =
  'app/PatientDetails/UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED';
export const UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED =
  'app/PatientDetails/UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED';

export const CHANGE_STAGE_REQUEST_SENT =
  'app/PatientDetails/CHANGE_STAGE_REQUEST_SENT';
export const CHANGE_STAGE_REQUEST_SUCCEED =
  'app/PatientDetails/CHANGE_STAGE_REQUEST_SUCCEED';
export const CHANGE_STAGE_REQUEST_FAILED =
  'app/PatientDetails/CHANGE_STAGE_REQUEST_FAILED';

export const UPDATE_LOCATION_REQUEST_SENT =
  'app/PatientDetails/UPDATE_LOCATION_REQUEST_SENT';
export const UPDATE_LOCATION_REQUEST_SUCCEED =
  'app/PatientDetails/UPDATE_LOCATION_REQUEST_SUCCEED';
export const UPDATE_LOCATION_REQUEST_FAILED =
  'app/PatientDetails/UPDATE_LOCATION_REQUEST_FAILED';

export const UPDATE_CT_CENTER_REQUEST_SENT =
  'app/PatientDetails/UPDATE_CT_CENTER_REQUEST_SENT';
export const UPDATE_CT_CENTER_REQUEST_SUCCEED =
  'app/PatientDetails/UPDATE_CT_CENTER_REQUEST_SUCCEED';
export const UPDATE_CT_CENTER_REQUEST_FAILED =
  'app/PatientDetails/UPDATE_CT_CENTER_REQUEST_FAILED';

export const RESEND_INVITE_REQUEST_SENT =
  'app/PatientDetails/RESEND_INVITE_REQUEST_SENT';
export const RESEND_INVITE_REQUEST_SUCCEED =
  'app/PatientDetails/RESEND_INVITE_REQUEST_SUCCEED';
export const RESEND_INVITE_REQUEST_FAILED =
  'app/PatientDetails/RESEND_INVITE_REQUEST_FAILED';

export const SET_PHASE_ID = 'app/PatientDetails/SET_PHASE_ID';

export const UPDATE_PATIENT_INFO_REQUEST_SENT =
  'app/PatientDetails/UPDATE_PATIENT_INFO_REQUEST_SENT';
export const UPDATE_PATIENT_INFO_REQUEST_SUCCEED =
  'app/PatientDetails/UPDATE_PATIENT_INFO_REQUEST_SUCCEED';
export const UPDATE_PATIENT_INFO_REQUEST_FAILED =
  'app/PatientDetails/UPDATE_PATIENT_INFO_REQUEST_FAILED';
export const RESET_PATIENT_DETAILS = 'app/PatientDetails/RESET_PATIENT_DETAILS';

export const TERMINATE_PROCEDURE_TRIAL_REQUEST_SENT =
  'app/PatientDetails/TERMINATE_PROCEDURE_TRIAL_REQUEST_SENT';
export const TERMINATE_PROCEDURE_TRIAL_REQUEST_SUCCEED =
  'app/PatientDetails/TERMINATE_PROCEDURE_TRIAL_REQUEST_SUCCEED';
export const TERMINATE_PROCEDURE_TRIAL_REQUEST_FAILED =
  'app/PatientDetails/TERMINATE_PROCEDURE_TRIAL_REQUEST_FAILED';

export const DELETE_PATIENT_REQUEST_SENT =
  'app/PatientDetails/DELETE_PATIENT_REQUEST_SENT';
export const DELETE_PATIENT_REQUEST_SUCCEED =
  'app/PatientDetails/DELETE_PATIENT_REQUEST_SUCCEED';
export const DELETE_PATIENT_REQUEST_FAILED =
  'app/PatientDetails/DELETE_PATIENT_REQUEST_FAILED';

export const PATIENT_CONTACTED_REQUEST_SUCCEEDED =
  'app/PatientDetails/PATIENT_CONTACTED_REQUEST_SUCCEEDED';

/*
 *
 * PatientDetailsPage actions
 *
 */
import {
  getProviderFacets,
  addNewPatient,
  getAppointmentsSearchResults,
  getAppointmentsSearchWithinRangeResults,
} from 'services/dashboard';

import { showToaster } from 'common/toasterActions';
import * as Constants from './constants';

export const getProviderSearchRequestSent = () => ({
  type: Constants.GET_PROVIDER_SEARCH_REQUEST_SENT,
});

export const getProviderSearchRequestSucceeded = payload => ({
  type: Constants.GET_PROVIDER_SEARCH_REQUEST_SUCCEED,
  payload,
});

export const getProviderSearchRequestFailed = () => ({
  type: Constants.GET_PROVIDER_SEARCH_REQUEST_FAILED,
});

export const getAppointmentsSearchRequestSent = () => ({
  type: Constants.GET_APPOINTMENTS_SEARCH_REQUEST_SENT,
});

export const getAppointmentsSearchRequestSucceeded = payload => ({
  type: Constants.GET_APPOINTMENTS_SEARCH_REQUEST_SUCCEED,
  payload,
});

export const getAppointmentsSearchRequestFailed = () => ({
  type: Constants.GET_APPOINTMENTS_SEARCH_REQUEST_FAILED,
});

export const getAppointmentsSearchWithinRangeRequestSent = () => ({
  type: Constants.GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_SENT,
});

export const getAppointmentsSearchWithinRangeRequestSucceeded = dates => ({
  type: Constants.GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_SUCCEED,
  dates,
});

export const getAppointmentsSearchWithinRangeRequestFailed = () => ({
  type: Constants.GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_FAILED,
});

export const getProviderFiltersRequestSent = () => ({
  type: Constants.GET_PROVIDER_FILTERS_REQUEST_SENT,
});

export const getProviderFiltersRequestSucceeded = payload => ({
  type: Constants.GET_PROVIDER_FILTERS_REQUEST_SUCCEED,
  payload,
});

export const getProviderFiltersRequestFailed = () => ({
  type: Constants.GET_PROVIDER_FILTERS_REQUEST_FAILED,
});

export const getProviderPatientDatesRequestSent = () => ({
  type: Constants.GET_PROVIDER_PATIENTS_RANGE_REQUEST_SENT,
});

export const getProviderPatientDatesSucceeded = dates => ({
  type: Constants.GET_PROVIDER_PATIENTS_RANGE_REQUEST_SUCCEED,
  dates,
});

export const getProviderPatientDatesRequestFailed = () => ({
  type: Constants.GET_PROVIDER_PATIENTS_RANGE_REQUEST_FAILED,
});

export const addNewPatientRequestSent = () => ({
  type: Constants.ADD_NEW_PATIENT_REQUEST_SENT,
});

export const addNewPatientSucceeded = () => ({
  type: Constants.ADD_NEW_PATIENT_REQUEST_SUCCEED,
});

export const addNewPatientRequestFailed = () => ({
  type: Constants.ADD_NEW_PATIENT_REQUEST_FAILED,
});

export const setSelectedFilter = (id, name) => ({
  type: Constants.SET_SELECTED_FILTER_ID,
  filterId: id,
  filterName: name,
});

export const resetState = () => ({
  type: Constants.RESET_STATE,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getAppointmentsResults = params => dispatch => {
  dispatch(getAppointmentsSearchRequestSent());
  getAppointmentsSearchResults(params)
    .then(response => {
      dispatch(getAppointmentsSearchRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getAppointmentsSearchRequestFailed());
    });
};

export const getAppointmentsWithinRangeResults = params => dispatch => {
  dispatch(getAppointmentsSearchWithinRangeRequestSent());
  getAppointmentsSearchWithinRangeResults(params)
    .then(response => {
      dispatch(getAppointmentsSearchWithinRangeRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getAppointmentsSearchWithinRangeRequestFailed());
    });
};

export const getProviderPatientsFacets = params => dispatch => {
  dispatch(getProviderFiltersRequestSent());
  getProviderFacets(params)
    .then(response => {
      dispatch(getProviderFiltersRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getProviderFiltersRequestFailed());
    });
};

export const onSaveNewPatientRequest = params => dispatch => {
  dispatch(addNewPatientRequestSent());
  addNewPatient(params)
    .then(() => {
      dispatch(addNewPatientSucceeded());
      dispatch(showToaster({ message: 'Patient added successfully.' }));
    })
    .catch(() => {
      dispatch(addNewPatientRequestFailed());
    });
};

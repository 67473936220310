import React, { useState, useRef } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import show from 'assets/images/show.png';
import hide from 'assets/images/hide.png';

const SignupInput = props => {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const {
    onChange,
    type,
    name,
    className,
    value,
    label,
    placeholder,
    mask,
    onNext,
  } = props;

  const handleLabelClick = () => {
    ref.current.focus();
  };

  const handlePhoneKeyPress = e => {
    if (e.key === 'Enter') {
      onNext();
    }
  };

  const showHidePassword = e => {
    e.preventDefault();
    e.stopPropagation();
    if (value.length > 0) {
      setShowPassword(!showPassword);
    }
  };

  return (
    <div className='signup-input-container'>
      <label
        className={`input-label ${
          isFocused || value !== ''
            ? 'input-placeholder-hide'
            : 'input-placeholder-show'
        }`}
        onClick={handleLabelClick}
        aria-hidden='true'
      >
        {placeholder || label}
        <sup className='mandatory-field'>*</sup>
      </label>
      <label
        className={`input-label ${
          isFocused || (value && label)
            ? 'input-label-top-show'
            : 'input-label-top-hide'
        }`}
      >
        {label}
        <sup className='mandatory-field'>*</sup>
      </label>
      {name === 'phone' ? (
        <div className='phone-input'>
          <InputMask
            mask={mask}
            maskChar=''
            value={value}
            onChange={onChange}
            type='tel'
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          >
            {() => (
              <input name={name} ref={ref} onKeyDown={handlePhoneKeyPress} />
            )}
          </InputMask>
        </div>
      ) : name === 'password' ? (
        <>
          <input
            type={showPassword ? 'text' : 'password'}
            name={name || 'name'}
            className={`input-text-password ${
              isFocused
                ? `input-control_before_password`
                : `input_control_after_password`
            }`}
            value={value || ''}
            onChange={onChange}
            onKeyDown={handlePhoneKeyPress}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            ref={ref}
          />
          <span
            role='button'
            tabIndex='-1'
            className={`password-show ${value.length === 0 ? 'disabled' : ''}`}
            onClick={showHidePassword}
            aria-hidden='true'
          >
            <img
              src={showPassword ? show : hide}
              height='20px;'
              width='20px'
              alt='show-password-cross'
            />
          </span>
        </>
      ) : (
        <input
          type={type || 'text'}
          name={name || 'name'}
          className={className || 'inupt-text'}
          value={value || ''}
          onChange={onChange}
          onKeyDown={handlePhoneKeyPress}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={ref}
        />
      )}
    </div>
  );
};

SignupInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.string,
};

SignupInput.defaultProps = {
  label: '',
  mask: '',
  placeholder: '',
  type: '',
  name: '',
  className: '',
};
export default SignupInput;

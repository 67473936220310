import { showToaster } from 'common/toasterActions';
import { isTBD, currentTimezoneName } from 'utils/dateUtils';
import {
  GET_SURVEY_FEEDBACK_REQUEST_SENT,
  GET_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  GET_SURVEY_FEEDBACK_REQUEST_FAILED,
  SUBMIT_SURVEY_FEEDBACK_REQUEST_SENT,
  SUBMIT_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  SUBMIT_SURVEY_FEEDBACK_REQUEST_FAILED,
  CLEAR_SURVEY_FEEDBACK_QUESTIONS,
} from './constants';

import { getPatientProcedureRequest } from '../../common/actions';

import { getPatientAppointmentsRequest } from '../Tasks/actions';

import {
  getSurveyFeedback,
  submitSuveryFeedback,
} from '../../../services/patient/patientDashboard';

export const getSurveyFeedbackRequestSent = () => ({
  type: GET_SURVEY_FEEDBACK_REQUEST_SENT,
});

export const getSurveyFeedbackRequestSucceeded = data => ({
  type: GET_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  data,
});

export const getSurveyFeedbackRequestFailed = () => ({
  type: GET_SURVEY_FEEDBACK_REQUEST_FAILED,
});

export const submitSuveryFeedbackRequestSent = () => ({
  type: SUBMIT_SURVEY_FEEDBACK_REQUEST_SENT,
});

export const submitSuveryFeedbackRequestSucceeded = data => ({
  type: SUBMIT_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  data,
});

export const submitSuveryFeedbackRequestFailed = () => ({
  type: SUBMIT_SURVEY_FEEDBACK_REQUEST_FAILED,
});

export const clearSurveyQuestionsRequest = () => ({
  type: CLEAR_SURVEY_FEEDBACK_QUESTIONS,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getSurveyFeedbackRequest = eventId => dispatch => {
  dispatch(getSurveyFeedbackRequestSent());
  getSurveyFeedback(eventId)
    .then(response => {
      dispatch(getSurveyFeedbackRequestSucceeded(response.data));
      dispatch(getPatientAppointmentsRequest(currentTimezoneName));
    })
    .catch(error => {
      dispatch(getSurveyFeedbackRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const submitSuveryFeedbackRequest = (eventId, params) => dispatch => {
  dispatch(submitSuveryFeedbackRequestSent());
  submitSuveryFeedback(params).then(response => {
    dispatch(submitSuveryFeedbackRequestSucceeded(response.data));
    dispatch(showToaster({ message: 'Your Response is recorded. Thank you' }));
    dispatch(getPatientProcedureRequest());
    dispatch(getSurveyFeedbackRequest(eventId));
  });
};

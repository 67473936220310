import React from 'react';
import { dateTimeStringToMMSDateFormat } from 'utils/dateUtils';
import Attachments from 'containers/Attachments';
import './messageListCard.scss';

class MessagesListCard extends React.Component {
  render() {
    return (
      <div className='message-list-row'>
        <div
          className={`row content ${
            this.props.item.sentBy.type === 'Patient' ? 'patient' : 'provider'
          }`}
        >
          <div className='font-weight-300'>{this.props.item.body}</div>
          <Attachments
            mediaResources={this.props.item.mediaResources}
            isAdded={false}
            notShowAttachmentsCount
          />
          <div className='margin-top-5 font-size-sm'>
            <span className='font-weight-300'>
              {this.props.item.sentBy.fullName}
            </span>
            <span className='dot' />
            <span className='sent-on'>Sent On: </span>
            <span className='date'>
              {dateTimeStringToMMSDateFormat(this.props.item.createdAt)}
            </span>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default MessagesListCard;

// Page orders
export const WELCOME_VIEW = 1;
export const PHONE_VIEW = 2;
export const OTP_VERIFICATION_VIEW = 3;
export const TREATMENT_OPTIONS_VIEW = 4;
export const NAME_VIEW = 5;
export const PASSWORD_VIEW = 6;
export const SUCCESS_VIEW = 7;
export const TOTAL_PAGES_OF_PROGRESS_BAR = 5;
export const FLOW_TYPE = 'sign_up';
export const OTP_LENGTH = 6;
export const EMAIL_VERIFICATION_VIEW = 6;
export const PASSWORD_VERIFICATION_VIEW = 7;
export const DEEP_LINK_URL = `${process.env.REACT_APP_API_URL}/patient/sign_ups/`;

export const GET_LOCATION_PROVIDERS_REQUEST_SENT =
  'app/PatientRegister/GET_LOCATION_PROVIDERS_REQUEST_SENT';
export const GET_LOCATION_PROVIDERS_REQUEST_SUCCEED =
  'app/PatientRegister/GET_LOCATION_PROVIDERS_REQUEST_SUCCEED';
export const GET_LOCATION_PROVIDERS_REQUEST_FAILED =
  'app/PatientRegister/GET_LOCATION_PROVIDERS_REQUEST_FAILED';

export const GET_SALESFORCE_USER_DATA_REQUEST_SENT =
  'app/PatientRegister/GET_SALESFORCE_USER_DATA_REQUEST_SENT';
export const GET_SALESFORCE_USER_DATA_REQUEST_SUCCEED =
  'app/PatientRegister/GET_SALESFORCE_USER_DATA_REQUEST_SUCCEED';
export const GET_SALESFORCE_USER_DATA_REQUEST_FAILED =
  'app/PatientRegister/GET_SALESFORCE_USER_DATA_REQUEST_FAILED';

export const VERIFY_FIRST_TIME_LOGIN_REQUEST_SENT =
  'VERIFY_FIRST_TIME_LOGIN_REQUEST_SENT';
export const VERIFY_FIRST_TIME_LOGIN_REQUEST_SUCCEED =
  'VERIFY_FIRST_TIME_LOGIN_REQUEST_SUCCEED';
export const VERIFY_FIRST_TIME_LOGIN_REQUEST_FAILED =
  'VERIFY_FIRST_TIME_LOGIN_REQUEST_FAILED';

export const GENERATE_OTP_REQUEST_SENT =
  'app/PatientRegister/GENERATE_OTP_REQUEST_SENT';
export const GENERATE_OTP_REQUEST_SUCCEED =
  'app/PatientRegister/GENERATE_OTP_REQUEST_SUCCEED';
export const GENERATE_OTP_REQUEST_FAILED =
  'app/PatientRegister/GENERATE_OTP_REQUEST_FAILED';

export const VERIFY_OTP_REQUEST_SENT =
  'app/PatientRegister/VERIFY_OTP_REQUEST_SENT';
export const VERIFY_OTP_REQUEST_SUCCEED =
  'app/PatientRegister/VERIFY_OTP_REQUEST_SUCCEED';
export const VERIFY_OTP_REQUEST_FAILED =
  'app/PatientRegister/VERIFY_OTP_REQUEST_FAILED';

export const CREATE_PATIENT_REQUEST_SENT =
  'app/PatientRegister/CREATE_PATIENT_REQUEST_SENT';
export const CREATE_PATIENT_REQUEST_SUCCEED =
  'app/PatientRegister/CREATE_PATIENT_REQUEST_SUCCEED';
export const CREATE_PATIENT_REQUEST_FAILED =
  'app/PatientRegister/CREATE_PATIENT_REQUEST_FAILED';

export const GET_FLOW_ID_REQUEST_SENT =
  'app/PatientRegister/GET_FLOW_ID_REQUEST_SENT';
export const GET_FLOW_ID_REQUEST_SUCCEED =
  'app/PatientRegister/GET_FLOW_ID_REQUEST_SUCCEED';
export const GET_FLOW_ID_REQUEST_FAILED =
  'app/PatientRegister/GET_FLOW_ID_REQUEST_FAILED';

export const PAGE_CHANGE = 'app/PatientRegister/PAGE_CHANGE';
export const SET_FLOW_TYPE = 'app/PatientRegister/SET_FLOW_TYPE';
export const SET_FLOW_ID_TO_BLANK = 'app/PatientRegister/SET_FLOW_ID_TO_BLANK';

export const PATIENT_FORGOT_PASSWORD_REQUEST_SENT =
  'PATIENT_FORGOT_PASSWORD_REQUEST_SENT';
export const PATIENT_FORGOT_PASSWORD_REQUEST_SUCCEED =
  'PATIENT_FORGOT_PASSWORD_REQUEST_SUCCEED';
export const PATIENT_FORGOT_PASSWORD_REQUEST_FAILED =
  'PATIENT_FORGOT_PASSWORD_REQUEST_FAILED';

import proxyInstance, { nonMasqueradingInstance } from './base';
import {
  GET_USER_INFO,
  UPDATE_USER_INFO,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  CONFIRM_PASSWORD,
  GET_USER_NOTIFICATIONS,
  GET_UNREAD_USER_NOTIFICATIONS_COUNT,
  POST_READ_NOTIFICATIONS,
  UPDATE_USER_EMAIL,
  PUT_UPDATE_NOTIFICATION_REMINDER,
  SET_PASSWORD,
  DELETE_USER_ACCOUNT,
  GET_PATIENTS_FOR_CAREGIVER,
  POST_CAREGIVER_MASQUERADE,
  GET_CURRENT_USER_INFO,
  UPDATE_USER_PROFILE_PICTURE,
} from './constants';

export const getUserInfo = () => nonMasqueradingInstance.get(GET_USER_INFO);

export const updateUserProfile = params =>
  nonMasqueradingInstance.post(UPDATE_USER_INFO, params);

export const updateUserProfilePicture = params =>
  nonMasqueradingInstance.post(UPDATE_USER_PROFILE_PICTURE, params);

export const forgotPasswordLink = params =>
  nonMasqueradingInstance.post(FORGOT_PASSWORD, params);

export const changePassword = params =>
  nonMasqueradingInstance.put(CHANGE_PASSWORD, params);

export const confirmPassword = params =>
  nonMasqueradingInstance.post(CONFIRM_PASSWORD, params);

export const updateUserEmailCall = params =>
  nonMasqueradingInstance.put(UPDATE_USER_EMAIL, params);

export const forceResetPassword = params =>
  nonMasqueradingInstance.put(SET_PASSWORD, params);

// Notifications
export const getUserNotifications = params =>
  proxyInstance.get(GET_USER_NOTIFICATIONS, { params });
export const getUnreadUserNotificationsCount = () =>
  proxyInstance.get(GET_UNREAD_USER_NOTIFICATIONS_COUNT);
export const markUnreadUserNotificationsRead = params =>
  proxyInstance.post(POST_READ_NOTIFICATIONS, params);
export const ignoreAddCaregiverNotification = reminderType => {
  const params = { reminder_type: reminderType };
  return nonMasqueradingInstance.put(PUT_UPDATE_NOTIFICATION_REMINDER, params);
};

export const deleteAccountService = params =>
  nonMasqueradingInstance.post(DELETE_USER_ACCOUNT, params);

export const patientsForCaregiverService = () =>
  nonMasqueradingInstance.get(GET_PATIENTS_FOR_CAREGIVER);

export const startCaregiverMasqueradingService = userId => {
  const params = {
    user_id: userId,
  };
  return nonMasqueradingInstance.post(POST_CAREGIVER_MASQUERADE, params);
};

export const getCurrentUserInfo = () =>
  nonMasqueradingInstance.get(GET_CURRENT_USER_INFO);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap/lib';
import ConversationDetails from 'containers/ConversationDetails';
import ConversationsListCard from 'components/ConversationsListCard';
import NoQuestions from 'assets/images/no_questions.png';
import Emptyview from 'components/Emptyview';
import HelpBlock from 'components/HelpBlock';
import Pagination from 'components/Pagination';
import validate, { clearErrorsForField } from 'common/validator';
import ConfirmationModal from 'patientApp/components/ConfirmationModal';
import AddAttachmentModal from 'containers/AddAttachmentModal';
import queryString from 'query-string';
import {
  getPatientConversations,
  showConversationDetails,
  showConversationsListView,
  startNewConversation,
  resolveConversation,
  clearConversationReducerData,
} from './action';

const validationConfig = {
  fields: ['newConversationSubject', 'newConversationMessage'],
  rules: {
    newConversationSubject: [
      { rule: 'isRequired', message: 'Subject is required' },
    ],
    newConversationMessage: [
      { rule: 'isRequired', message: 'Message is required' },
    ],
  },
};

class Conversations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStartConversationModal: false,
      errors: {},
      newConversationSubject: '',
      newConversationMessage: '',
      showConfirmResolutionModal: false,
      conversationToResolve: {},
      showAttachmentModal: false,
      showAttachments: false,
      attachments: [],
    };
  }

  componentDidMount() {
    const queryUrl = this.props.history.location.search;
    let queries = {};
    if (queryUrl) {
      queries = queryString.parse(queryUrl, { arrayFormat: 'bracket' });
    }
    if (queries.conversation_id) {
      this.props.showConversationDetails(parseInt(queries.conversation_id));
    } else if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.conversationId
    ) {
      this.props.showConversationDetails(
        this.props.match.params.conversationId
      );
    } else {
      const page = (this.props.pagination && this.props.pagination.page) || 1;
      this.props.fetchPatientConversations(
        this.props.compositeProcedureId,
        this.props.selectedPhaseId,
        page
      );
    }
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  onConversationClicked = conversationId => {
    window.location = `${window.location.pathname}?conversation_id=${conversationId}#messages`;
  };

  onResolveConversation = conversation => {
    this.setState({
      showConfirmResolutionModal: true,
      conversationToResolve: conversation,
    });
  };

  onConfirmResolveConversation = () => {
    this.props.resolveConversation(
      this.props.patientId,
      this.props.userProcedureId,
      this.state.conversationToResolve.id
    );
    this.setState({
      showConfirmResolutionModal: false,
      conversationToResolve: {},
    });
  };

  onModalClose = () => {
    this.setState({
      showConfirmResolutionModal: false,
      conversationToResolve: {},
    });
  };

  goToListView = () => {
    window.location = `${window.location.pathname}#messages`;
  };

  openSendMessageModal = () => {
    this.setState({
      newConversationSubject: '',
      newConversationMessage: '',
      showStartConversationModal: true,
    });
  };

  onHideStartConversationModal = () => {
    this.setState({
      showStartConversationModal: false,
    });
  };

  changeFieldHandler = e => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  onAddAttachment = () => {
    this.setState({ showAttachmentModal: true, showAttachments: true });
  };

  onAttachmentsAdded = attachments => {
    this.setState({
      showAttachmentModal: false,
      attachments,
    });
  };

  onSendMessage = () => {
    validate(
      validationConfig,
      this.state,
      this.onSendMessageValidationFailure,
      this.onSendMessageValidationSuccess
    );
  };

  onSendMessageValidationSuccess = () => {
    this.setState({ errors: {} });
    this.props.startNewConversation(
      this.props.userProcedureId,
      this.state.newConversationSubject,
      this.state.newConversationMessage,
      this.state.attachments
    );
    this.setState({
      showStartConversationModal: false,
      showAttachmentModal: false,
      showAttachments: false,
      attachments: [],
    });
  };

  onSendMessageValidationFailure = errors => {
    this.setState({ errors });
  };

  handlePagination = page => {
    this.props.fetchPatientConversations(
      this.props.compositeProcedureId,
      this.props.selectedPhaseId,
      page
    );
  };

  render() {
    const { patient } = this.props.patientInfo;

    if (this.props.isListView) {
      return (
        <React.Fragment>
          <div className='tab-details-title hidden-lg hidden-md visible-sm visible-xs'>
            Messages
          </div>
          <div
            className={
              patient.signInCount > 0
                ? 'pull-right cursor-pointer app-link'
                : 'pull-right link-disabled'
            }
            style={{ marginTop: '-10px' }}
            onClick={patient.signInCount > 0 ? this.openSendMessageModal : null}
          >
            <span className='icon icon-font-a-send-message aligned-icon'></span>
            &nbsp;
            <span>Send Message</span>
          </div>
          <br />
          <hr />
          <div className='conversations-list-container'>
            {this.props.isListView &&
            this.props.conversations &&
            this.props.conversations.length > 0 ? (
              this.props.conversations.map(item => (
                <ConversationsListCard
                  key={item.id}
                  item={item}
                  onConversationClicked={this.onConversationClicked}
                  onResolveConversationClicked={this.onResolveConversation}
                />
              ))
            ) : (
              <Emptyview
                imgSrc={NoQuestions}
                className=''
                message='No Messages'
              />
            )}
          </div>
          {this.props.isListView && this.props.pagination && (
            <Pagination
              pagination={this.props.pagination}
              title='Messages'
              handlePagination={this.handlePagination}
            />
          )}
          <Modal
            show={this.state.showStartConversationModal}
            onHide={this.onHideStartConversationModal}
            container={document.body}
            autoFocus
            aria-labelledby='contained-modal-title'
            backdrop='static'
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title'>
                Send a New Message
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='start-conversation-modal'>
                <div
                  className={`form-group ${
                    this.state.errors.newConversationSubject !== undefined
                      ? 'has-error'
                      : ''
                  }`}
                >
                  <label htmlFor='reason'>Subject</label>
                  <input
                    type='text'
                    name='newConversationSubject'
                    id='newConversationSubject'
                    className='form-control'
                    placeholder='Type a subject of the message'
                    value={this.state.newConversationSubject}
                    onChange={this.changeFieldHandler}
                  />
                  <HelpBlock value={this.state.errors.newConversationSubject} />
                </div>
                <div
                  className={`form-group ${
                    this.state.errors.newConversationMessage !== undefined
                      ? 'has-error'
                      : ''
                  }`}
                >
                  <label htmlFor='reason'>Message</label>
                  <textarea
                    name='newConversationMessage'
                    id='newConversationMessage'
                    className='form-control'
                    placeholder='Type a message to Patient here'
                    value={this.state.newConversationMessage}
                    onChange={this.changeFieldHandler}
                  />
                  <HelpBlock value={this.state.errors.newConversationMessage} />
                </div>
                <AddAttachmentModal
                  onAttachmentsAdded={this.onAttachmentsAdded}
                  showModal={this.state.showAttachmentModal}
                  onAttachmentRemoved={this.onAttachmentRemoved}
                  showAddedAttachments={this.state.showAttachments}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn btn-default btn-attachment add-attachment-button'
                onClick={this.onAddAttachment}
              >
                Add Attachment
              </button>
              <button
                className='btn btn-primary float-right'
                onClick={this.onSendMessage}
              >
                Send
              </button>
            </Modal.Footer>
          </Modal>
          <ConfirmationModal
            show={this.state.showConfirmResolutionModal}
            text='Marking this thread as answered will remove it from your dashboard as well as the dashboards of any other INSIGHTEC Connect users who are assigned to this patient.'
            title='Mark as Answered'
            onModalClose={this.onModalClose}
            onConfirmed={this.onConfirmResolveConversation}
            cancelBtnText='Cancel'
            okBtnText='Mark as Answered'
          />
        </React.Fragment>
      );
    }
    return (
      <div>
        <div className='app-link cursor-pointer' onClick={this.goToListView}>
          Go Back
        </div>
        <ConversationDetails
          selectedConversationId={this.props.selectedConversationId}
          goToConversations={this.goToListView}
        ></ConversationDetails>
      </div>
    );
  }
}

Conversations.propTypes = {
  patientId: PropTypes.number,
  isListView: PropTypes.bool,
  isLoading: PropTypes.bool,
  conversations: PropTypes.array,
  pagination: PropTypes.object,
  userProcedureId: PropTypes.number,
  compositeProcedureId: PropTypes.number,
  selectedPhaseId: PropTypes.number,
  selectedConversationId: PropTypes.number,
  fetchPatientConversations: PropTypes.func,
  showConversationDetails: PropTypes.func,
  showConversationsListView: PropTypes.func,
  startNewConversation: PropTypes.func,
  resolveConversation: PropTypes.func,
  resetData: PropTypes.func,
  patientInfo: PropTypes.object,
};

const mapStateToProps = state => ({
  isLoading: state.patientDetails.conversations.isLoading,
  selectedPhaseId: state.currentPatientInfo.selectedPhaseId,
  conversations: state.patientDetails.conversations.conversations,
  pagination: state.patientDetails.conversations.pagination,
  isListView: state.patientDetails.conversations.isListView,
  messages: state.patientDetails.messages,
  selectedConversationId:
    state.patientDetails.conversations.selectedConversationId,
  patientInfo: state.currentPatientInfo,
});

const mapDispatchToProps = dispatch => ({
  fetchPatientConversations: (
    compositeProcedureId,
    procedurePhaseId = null,
    page = null
  ) =>
    dispatch(
      getPatientConversations(compositeProcedureId, procedurePhaseId, page)
    ),
  showConversationDetails: conversationId =>
    dispatch(showConversationDetails(conversationId)),
  showConversationsListView: () => dispatch(showConversationsListView()),
  startNewConversation: (userProcedureId, subject, message, attachments) =>
    dispatch(
      startNewConversation(userProcedureId, subject, message, attachments)
    ),
  resolveConversation: (patientId, userProcedureId, conversationId) =>
    dispatch(resolveConversation(patientId, userProcedureId, conversationId)),
  resetData: () => dispatch(clearConversationReducerData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversations);

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import './patientRegister.scss';
import NextButton from 'components/Authorization/NextButton';
import HelpBlock from 'components/HelpBlock';
import * as Constans from '../../containers/Authorization/constants';
import ResendCode from './ResendCode';

const INITIAL_STATE = ['', '', '', '', '', ''];

const OtpVerificationView = props => {
  const [otp, setOtp] = useState([...INITIAL_STATE]);
  const inputRefs = useRef([]);

  const { handleResendOtp, phone, errors, onNext, phoneOtp, flowType } = props;

  const handleOtpChange = (index, event) => {
    const newOtp = event.target.value;

    if (
      newOtp.length > 0 &&
      !Number.isNaN(Number.parseInt(newOtp, 10)) &&
      otp[index] === ''
    ) {
      const prevOtp = otp;
      prevOtp[index] = newOtp.charAt(0);
      setOtp([...prevOtp]);

      if (index < inputRefs.current.length - 1 && otp[index + 1] === '') {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index >= 0) {
      const prevOtp = otp;
      prevOtp[index] = '';

      if (index > 0 && event.target.value === '') {
        inputRefs.current[index - 1].focus();
      }
      setOtp([...prevOtp]);
    }

    if (event.key === 'Enter') {
      onNext(otp.join(''));
    }
  };

  const formatContactNumber = number => {
    if (typeof number !== 'string' || number.length !== 10) {
      return 'Invalid contact number';
    }

    const firstThreeDigits = number.substring(0, 3);
    const nextThreeDigits = number.substring(3, 6);
    const lastFourDigits = number.substring(6);

    return `${firstThreeDigits} ${nextThreeDigits} ${lastFourDigits}`;
  };

  const handleRef = (el, index) => {
    inputRefs.current[index] = el;
  };

  const handlePaste = event => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    if (pastedData.length === 6 && otp.join('') === '') {
      setOtp(pastedData.split(''));
      inputRefs.current[pastedData.length - 1].focus();
    }
  };

  const handleHandleResendotp = () => {
    setOtp([...INITIAL_STATE]);
    handleResendOtp();
  };

  return (
    <div className='child-view-container otp-view'>
      <h2 className='input-heading'>Verify it&apos;s you</h2>
      <p className='input-text'>
        Please enter verification code sent to
        <b> +1 {formatContactNumber(phone)}</b>
      </p>
      <div className='otp-input-container'>
        <div className='otp-input-wrapper'>
          {[...Array(Constans.OTP_LENGTH).keys()].map((inputNum, index) => (
            <input
              className={`otp-input input-${index}`}
              name='phoneOtp'
              key={inputNum}
              type='text'
              value={otp[index] || ''}
              onChange={event => handleOtpChange(index, event)}
              onKeyDown={event => handleKeyDown(index, event)}
              onPaste={handlePaste}
              ref={el => handleRef(el, index)}
            />
          ))}
        </div>
        <HelpBlock value={errors.phoneOtp} />
        <ResendCode
          otp={phoneOtp}
          generateOtp={handleHandleResendotp}
          errors={errors}
        />
      </div>
      <NextButton
        onNext={() => onNext(otp.join(''))}
        text={flowType === 'sign_up' ? 'Next : Verification' : 'Next'}
        enable={otp.join('').length === 6}
      />
    </div>
  );
};

OtpVerificationView.propTypes = {
  onNext: PropTypes.func.isRequired,
  errors: PropTypes.shape({ phoneOtp: PropTypes.string }).isRequired,
  phoneOtp: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  handleResendOtp: PropTypes.func.isRequired,
  flowType: PropTypes.string,
};

OtpVerificationView.defaultProps = {
  flowType: null,
};

export default OtpVerificationView;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import HelpBlock from 'components/HelpBlock';
import FloatingLabelInput from 'components/FloatingLabelInput';
import { Modal } from 'react-bootstrap/lib';
import validate, { clearErrorsForField } from 'common/validator';
import ConfirmationModal from 'patientApp/components/ConfirmationModal';
import {
  getUserConsentFormRequest,
  agreeUserConsentRequest,
  disAgreeUserConsentRequest,
  clearUserConsentState,
} from './action';
import reducer from './reducer';
import {
  LAUNCHED_FROM_ADD_PROCEDURE,
  // LAUNCHED_FROM_MANAGE_PROCEDURE,
  LAUNCHED_FROM_DASHBOARD,
} from './constants';

import './user_consent_form.scss';

const validationConfig = {
  fields: ['signedName'],
  rules: {
    signedName: [{ rule: 'isRequired', message: 'Name is required' }],
  },
};
class UserConsentForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      consentFormText: '',
      showModal: true,
      // consentFormTextFallback: '\n' +
      //   '\n' +
      //   '<H1>Something went wrong</H1><br/>' +
      //   '</body></html>',
      signedName: '',
      errors: {},
      showConfirmDeclineConsentModal: false,
    };
  }

  componentDidMount() {
    this.props.getUserConsentText(this.props.procedureId);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.isRequestFailed &&
      nextProps.isConsentFormShown &&
      nextProps.isConsentFormDeclined
    ) {
      // Consent Form Declined
      this.dispatchConsentAction(false);
    } else if (
      !nextProps.isRequestFailed &&
      nextProps.isConsentFormShown &&
      nextProps.isConsentFormSigned
    ) {
      // Consent Form Accepted
      this.dispatchConsentAction(true);
    } else {
      this.setState({
        consentFormText: nextProps.consentFormText,
        signedName: nextProps.userFullName,
      });
    }
  }

  onDisagreed = () => {
    if (this.props.launchedFrom === LAUNCHED_FROM_DASHBOARD) {
      if (this.props.hasMultipleProcedures) {
        this.setState({
          showConfirmDeclineConsentModal: true,
          showModal: false,
        });
      } else {
        this.doPostDeclineUserConsent();
      }
    } else {
      this.dispatchConsentAction(false);
    }
  };

  onAgreed = () => {
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onConfirmationModalClosed = () => {
    this.setState({ showConfirmDeclineConsentModal: false, showModal: true });
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  onFormValidationSuccess = () => {
    const reqObj = {
      signed_name: this.state.signedName,
      procedure_id: this.props.procedureId,
    };
    this.props.postUserConsentAgreed(reqObj);
  };

  doPostDeclineUserConsent = () => {
    const reqObj = {
      procedure_id: this.props.procedureId,
    };
    this.props.postUserConsentDisagreed(reqObj);
    // this.setState({ showConfirmDeclineConsentModal: false, showModal: true });
  };

  dismissModal = () => {
    this.setState({
      showModal: false,
    });
  };

  dispatchConsentAction = consentAccepted => {
    if (consentAccepted) {
      if (this.props.onConsentAccepted) {
        this.props.onConsentAccepted();
      }
    } else if (this.props.onConsentDeclined) {
      this.props.onConsentDeclined(this.props.hasMultipleProcedures);
    }
    this.props.clearConsentState();
    this.dismissModal();
  };

  dispatchDeclinedConsentConfirmation = () => (
    <ConfirmationModal
      show={this.state.showConfirmDeclineConsentModal}
      text='This action will remove the procedure for which you have declined the consent. Do you wish to continue?'
      title='Attention'
      onModalClose={this.onConfirmationModalClosed}
      onConfirmed={this.doPostDeclineUserConsent}
      cancelBtnText='Cancel'
      okBtnText='Confirm'
    />
  );

  render() {
    const title = this.props.consentTitle
      ? this.props.consentTitle
      : 'PATIENT OPT-IN FORM';
    return (
      <React.Fragment>
        {!this.props.isLoading &&
          this.props.consentFormText &&
          !this.props.isRequestFailed && (
            <Modal
              show={this.state.showModal}
              onHide={this.onDisagreed}
              container={document.body}
              autoFocus
              backdrop='static'
              aria-labelledby='contained-modal-title'
              className='primary-modal'
            >
              <Modal.Header>
                <Modal.Title id='contained-modal-title'>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='consent-form'>
                  <div
                    className='consent-form-text'
                    dangerouslySetInnerHTML={{
                      __html: this.state.consentFormText,
                    }}
                  />
                  <div
                    className={`form-group ${
                      this.state.errors.signedName !== undefined
                        ? 'has-error'
                        : ''
                    }`}
                  >
                    <div className='consent-form-signed-name'>
                      <FloatingLabelInput
                        type='text'
                        className='form-control'
                        value={this.state.signedName}
                        onChange={e => {
                          this.setState({
                            signedName: e.target.value,
                            errors: clearErrorsForField(
                              this.state.errors,
                              'signedName'
                            ),
                          });
                        }}
                        name='signedName'
                        label='Full Name'
                      />
                      <HelpBlock value={this.state.errors.signedName} />
                    </div>
                  </div>
                  <div className='float-right consent-form-buttons'>
                    <button
                      className='btn btn-default '
                      onClick={this.onDisagreed}
                    >
                      Not Now
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button className='btn btn-primary' onClick={this.onAgreed}>
                      I Agree
                    </button>
                  </div>
                  <div className='clr'></div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        {this.state.showConfirmDeclineConsentModal &&
          this.dispatchDeclinedConsentConfirmation()}
      </React.Fragment>
    );
  }
}

UserConsentForm.propTypes = {
  isLoading: PropTypes.bool,
  procedureId: PropTypes.number,
  consentFormText: PropTypes.string,
  consentTitle: PropTypes.string,
  userFullName: PropTypes.string,
  getUserConsentText: PropTypes.func,
  postUserConsentAgreed: PropTypes.func,
  postUserConsentDisagreed: PropTypes.func,
  isRequestFailed: PropTypes.bool,
  isConsentFormShown: PropTypes.bool,
  isConsentFormSigned: PropTypes.bool,
  isConsentFormDeclined: PropTypes.bool,
  onConsentDeclined: PropTypes.func.isRequired,
  onConsentAccepted: PropTypes.func.isRequired,
  clearConsentState: PropTypes.func,
  hasMultipleProcedures: PropTypes.bool,
  launchedFrom: PropTypes.string,
};

UserConsentForm.defaultProps = {
  launchedFrom: LAUNCHED_FROM_ADD_PROCEDURE,
};

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.userConsent.isLoading,
  consentFormText: state.userConsent.consentFormText,
  consentTitle: state.userConsent.consentTitle,
  hasMultipleProcedures: state.userConsent.hasMultipleProcedures,
  userFullName: state.userConsent.userFullName,
  procedureId: ownProps.procedureId || state.userConsent.procedureId,
  isConsentFormSigned: state.userConsent.isConsentFormSigned,
  isConsentFormShown: state.userConsent.isConsentFormShown,
  isRequestFailed: state.userConsent.isRequestFailed,
  isConsentFormDeclined: state.userConsent.isConsentFormDeclined,
});

const mapDispatchToProps = dispatch => ({
  getUserConsentText: procedureId =>
    dispatch(getUserConsentFormRequest(procedureId)),
  postUserConsentAgreed: params => dispatch(agreeUserConsentRequest(params)),
  postUserConsentDisagreed: params =>
    dispatch(disAgreeUserConsentRequest(params)),
  clearConsentState: () => dispatch(clearUserConsentState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'userConsent',
  reducer,
});

export default compose(withReducer, withConnect)(UserConsentForm);

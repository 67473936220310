import React, { useState, useEffect, useHistory } from 'react';
import { Redirect, Switch, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';

import Media from 'react-media';

import { getActiveCompositeProcedureId } from 'services/storage';

import { PatientAuthorizedRoute } from '../../../../containers/AuthRoute';

import EmptyView from 'components/Emptyview';
import Pagination from 'components/Pagination';
import NoQuestions from 'assets/images/no_questions.png';
import ConversationsListCard from 'components/ConversationsListCard';
import CreateConversationModal from './CreateModal';

import CreateMessage from '../Show/CreateMessage';

import { conversationUrl } from 'patientApp/common/routeConstants';
import reducer from './reducers';
import {
  getConversationsRequest,
  getProcedureManagersRequest,
  createConversationsRequest,
} from '../actions';
import './index.scss';

import Show from '../Show';

const ConversationList = props => {
  return (
    <div className='col-md-offset-2 col-md-8 col-xs-12 conversations__container conversations-list-container conversation-height'>
      {props.conversations.length ? (
        <>
          <div
            role='button'
            tabIndex='0'
            className='fab visible-xs'
            onClick={props.handleCreateConversation}
          >
            +
          </div>
          <div className='clearfix'></div>

          {props.conversations.map(props.renderConversation)}

          {props.pagination && (
            <div className='mt-2'>
              <Pagination
                pagination={props.pagination}
                title='Messages'
                handlePagination={props.handlePagination}
              />
            </div>
          )}
        </>
      ) : (
        <EmptyView imgSrc={NoQuestions} className='' message='No Messages' />
      )}
    </div>
  );
};

const MessageHeader = props => {
  return (
    <div className='col-xs-12 messages-container no-padding conversation'>
      <div
        className='col-md-offset-2 col-md-8 col-xs-12 border-bottom messaging-header resources-navigation-section'
        style={{ backgroundColor: 'white' }}
      >
        {props.showCreateMessageButton && (
          <div
            className='cursor-pointer send-message-container'
            onClick={props.onCreateMessageClick}
          >
            <Media query={{ maxWidth: 700 }}>
              {screenIsSmall =>
                screenIsSmall ? (
                  <span
                    className='sendMessage pull-right'
                    style={{ color: '#0646B4' }}
                  >
                    <span
                      className='icon  icon-font-a-add-new-task'
                      style={{ fontSize: '18px', color: '#0646B4' }}
                    ></span>
                  </span>
                ) : (
                  <span
                    className='sendMessage pull-right'
                    style={{ color: '#0646B4' }}
                  >
                    <span
                      className='icon  icon-font-a-add-new-task'
                      style={{ fontSize: '18px', color: '#0646B4' }}
                    ></span>
                    &nbsp;Message an Educator
                  </span>
                )
              }
            </Media>
          </div>
        )}
      </div>
    </div>
  );
};

class ConversationsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateModal: false,
      showThread: false,
    };
  }

  componentDidMount() {
    this.props.fetchConversations({
      compositeProcedureId: this.props.compositeProcedureId,
      page: this.props.pagination.page,
    });
    this.props.fetchProcedureManagers();
  }

  handlePagination = targetPage => {
    this.props.fetchConversations({
      compositeProcedureId: this.props.compositeProcedureId,
      page: targetPage,
    });
  };

  renderChildComponents = () => {
    const { match } = this.props;
    return (
      <>
        <PatientAuthorizedRoute
          exact
          path={`${match.path}/show/:conversationId`}
          component={() => {
            return (
              <Show
                createConversation={this.props.createConversation}
                disclaimerText={this.props.disclaimerText}
              />
            );
          }}
        />
        <PatientAuthorizedRoute
          exact
          path={`${match.path}/message/create-a-message/:user_procedure_id?/:resource_type?/:resource_id?/:resource_title?/:resource_body?`}
          component={props => {
            return (
              <CreateMessage
                createConversation={this.props.createConversation}
                fetchConversations={this.handlePagination}
                disclaimerText={this.props.disclaimerText}
              />
            );
          }}
        />
        <PatientAuthorizedRoute
          exact
          path={`${match.path}`}
          component={() => {
            return (
              <ConversationList
                conversations={this.props.conversations}
                handleCreateConversation={this.props.handleCreateConversation}
                renderConversation={this.renderConversation}
                handlePagination={this.handlePagination}
                pagination={this.props.pagination}
                NoQuestions={NoQuestions}
              />
            );
          }}
        />
      </>
    );
  };

  handleCreateConversation = () => this.setState({ showCreateModal: true });

  handleConversationClick = id => {
    this.props.history.push(
      conversationUrl(this.props.compositeProcedureId, id)
    );
  };

  handleResolveConversationClick = () => {
    console.log('handleResolveConversationClick');
  };

  handleCreateMessageClick = () => {
    this.props.history.push(`${this.props.match.url}/message/create-a-message`);
  };

  renderConversation = conversation => (
    <ConversationsListCard
      key={conversation.id}
      item={conversation}
      onConversationClicked={this.handleConversationClick}
      onResolveConversationClicked={this.handleResolveConversationClick}
    />
  );

  render() {
    const { match } = this.props;
    return (
      <>
        {
          <>
            <PatientAuthorizedRoute
              exact
              path={`${match.path}`}
              component={() => {
                return (
                  <MessageHeader
                    showCreateMessageButton={true}
                    onCreateMessageClick={this.handleCreateMessageClick}
                  />
                );
              }}
            />
            <PatientAuthorizedRoute
              exact
              path={`${match.path}/show/:conversationId`}
              component={() => {
                return <MessageHeader showCreateMessageButton={false} />;
              }}
            />
            <PatientAuthorizedRoute
              exact
              path={`${match.path}/message/create-a-message/:user_procedure_id?/:resource_type?/:resource_id?/:resource_title?`}
              component={() => {
                return <MessageHeader showCreateMessageButton={false} />;
              }}
            />
          </>
        }
        {this.renderChildComponents()}

        <CreateConversationModal
          show={this.state.showCreateModal}
          compositeProcedureId={this.props.compositeProcedureId}
          procedureManagers={this.props.procedureManagers}
          createConversation={this.props.createConversation}
          onHide={() => this.setState({ showCreateModal: false })}
        />
      </>
    );
  }
}

ConversationsIndex.propTypes = {
  conversations: PropTypes.array,
  pagination: PropTypes.object,
  compositeProcedureId: PropTypes.number,
  procedureManagers: PropTypes.array,
  disclaimerText: PropTypes.string,
};

const mapStateToProps = state => ({
  conversations: state.conversations.conversations || [],
  pagination: state.conversations.pagination || { page: 1 },
  compositeProcedureId:
    state.activeProcedureDetails.compositeProcedureId ||
    parseInt(getActiveCompositeProcedureId(), 10),
  procedureManagers: state.conversations.procedureManagers || [],
  disclaimerText: state.conversations.disclaimerText,
});

const mapDispatchToProps = dispatch => ({
  fetchConversations: payload => dispatch(getConversationsRequest(payload)),
  fetchProcedureManagers: () => dispatch(getProcedureManagersRequest()),
  createConversation: payload => dispatch(createConversationsRequest(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'conversations',
  reducer,
});

export default compose(withReducer, withConnect)(ConversationsIndex);

export const GET_APPOINTMENTS_REQUEST_SENT =
  'app/Appointments/GET_APPOINTMENTS_REQUEST_SENT';
export const GET_APPOINTMENTS_REQUEST_SUCCEED =
  'app/Appointments/GET_APPOINTMENTS_REQUEST_SUCCEED';
export const GET_APPOINTMENTS_REQUEST_FAILED =
  'app/Appointments/GET_APPOINTMENTS_REQUEST_FAILED';

export const UPDATE_APPOINTMENT_REQUEST_SENT =
  'app/Appointments/UPDATE_APPOINTMENT_REQUEST_SENT';
export const UPDATE_APPOINTMENT_REQUEST_SUCCEED =
  'app/Appointments/UPDATE_APPOINTMENT_REQUEST_SUCCEED';
export const UPDATE_APPOINTMENT_REQUEST_FAILED =
  'app/Appointments/UPDATE_APPOINTMENT_REQUEST_FAILED';

export const CLEAR_HAS_PENDING_CONSENT_VALUE =
  'app/PatientApp/Appointments/CLEAR_HAS_PENDING_CONSENT_VALUE';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, combineReducers } from 'redux';
import injectReducer from 'utils/injectReducer';
import { getActiveCompositeProcedureId } from 'services/storage';
import LoadingIndicator from 'components/LoadingIndicator';

import MessageResponse from 'containers/MessageResponse';
import Emptyview from 'components/Emptyview';
import NoQuestions from 'assets/images/no_questions.png';

import { useParams, useHistory } from 'react-router-dom';
import MessagesListCard from 'components/MessagesListCard';
import messagesReducer from './reducers';
import { getConversationMessagesRequest, sendMessageRequest } from '../actions';

import './show.scss';

const ConversationsShow = props => {
  const [attachments, setAttachements] = useState([]);
  const params = useParams();
  const history = useHistory();
  const { conversationId } = params;

  const { messages, conversation } = props;
  const { fetchConversationMessages, sendMessage, isLoading } = props;
  const { resource, question } = props;

  const { questionId } = conversation;
  const { resourceId } = conversation;
  const dataExist = !!(resource || question);
  const data = resource ? resource.description : '';
  const { disclaimerText } = props;

  useEffect(() => {
    fetchConversationMessages(conversationId);
  }, []);

  const RenderDisclaimerText = () => (
    <div className='disclaimer-text'>
      <div className='message-disclaimer-text'>{disclaimerText}</div>
    </div>
  );

  const handleSendMessage = message => {
    const payload = {
      conversationId,
      message,
      attachments: [...attachments],
    };
    sendMessage(payload);
  };

  const RenderMessages = () => (
    <div className='message-text-area-control'>
      {messages.length ? (
        messages.map(item => (
          <MessagesListCard key={item.id} item={item} sent_by={item.sent_by} />
        ))
      ) : (
        <Emptyview imgSrc={NoQuestions} message='No Messages' />
      )}
    </div>
  );

  const RenderResourceDescription = () => (
    <div className='col-xs-12 description'>
      <div className='conversation type-title'>
        {resourceId ? 'EDUCATIONAL RESOURCE' : 'FAQ'}
      </div>
      <div className=''>
        {/* title */}
        <div className='description-title question-header h3'>
          {resourceId ? resource.title : questionId ? question.text : null}
        </div>
        {/* description */}
        <div className='description-body'>
          {questionId ? (
            <span className='answer-content'>{question.answer.text}</span>
          ) : (
            <span
              className='resource-body'
              dangerouslySetInnerHTML={{ __html: data }}
            />
          )}
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className='col-md-offset-2 col-md-8 col-xs-12 tab-content-wrapper'>
      <div className='col-xs-12 tab-content'>
        <span
          className='col-xs-2 conversation-back'
          style={{ paddingLeft: '0px' }}
        >
          <div
            className='back-btn'
            role='button'
            tabIndex='0'
            style={{ marginLeft: '0px' }}
            onClick={history.goBack}
          >
            <span
              className='icon icon-font-a-left-chevron back-icon'
              style={{ color: 'white' }}
            />
            <span className='btn-text'>Back</span>
          </div>
        </span>
        <span className='col-xs-8 title'>
          {questionId ? 'FAQ' : resourceId ? 'EDUCATIONAL RESOURCE' : null}
        </span>
        <span className='col-xs-2' />
        {disclaimerText ? <RenderDisclaimerText /> : null}
        <div className='col-xs-12 subject'>
          {conversation ? conversation.subject : ''}
          {conversation && conversation.startedWith && (
            <span>
              &nbsp;(Started With: {conversation.startedWith.fullName})
            </span>
          )}
        </div>
        <MessageResponse
          style={{ width: '95%' }}
          onSentMessage={handleSendMessage}
          onAttachmentsAdded={setAttachements}
        />
        <RenderMessages />
      </div>
      {dataExist ? <RenderResourceDescription /> : null}
    </div>
  );
};

ConversationsShow.propTypes = {
  isLoading: PropTypes.bool,
  compositeProcedureId: PropTypes.number,
  conversationId: PropTypes.number,
  messages: PropTypes.array,
  conversation: PropTypes.object,
  fetchConversationMessages: PropTypes.func,
  sendMessage: PropTypes.func,
  resource: PropTypes.object,
  question: PropTypes.object,
  disclaimerText: PropTypes.string,
};

const mapStateToProps = state => ({
  compositeProcedureId:
    state.activeProcedureDetails.compositeProcedureId ||
    parseInt(getActiveCompositeProcedureId(), 10),
  isLoading: state.patientMessages.isLoading,
  messages: state.patientMessages.conversation_details.messages,
  conversation: state.patientMessages.conversation_details.conversation,
  resource: state.patientMessages.conversation_details.resource,
  question: state.patientMessages.conversation_details.question,
});

const mapDispatchToProps = dispatch => ({
  fetchConversationMessages: id => dispatch(getConversationMessagesRequest(id)),
  sendMessage: payload => dispatch(sendMessageRequest(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'patientMessages',
  reducer: combineReducers({
    conversation_details: messagesReducer,
  }),
});

export default compose(withReducer, withConnect)(ConversationsShow);

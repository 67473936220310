import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EmptyView from 'components/Emptyview';
import Modal from 'react-bootstrap/lib/Modal';
import Media from 'react-media';
import NoProcedures from 'assets/images/no_appointments.png';
import ConfirmationModal from 'patientApp/components/ConfirmationModal';
import AddProcedure from 'patientApp/containers/AddProcedure';
import InviteCode from 'patientApp/containers/InviteCode';
import { clearAddProcedureStates } from 'common/actions';
import MasqueradingHeader from 'patientApp/Caregiver/MasqueradingHeader';
import BackNavigation from 'patientApp/components/BackNavigation';
import Strings from '../../../strings';
import {
  getPatientProcedureRequest,
  deleteProcedureRequest,
  clearInviteCodeStates,
  switchActiveProcedureRequest,
} from '../../common/actions';

import './manageProcedures.scss';

class ManageProcedures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      showAddProcedureModal: false,
      procedureToBeSwitched: 0,
      selectedProcedureName: '',
      activeProcedureName: '',
      showSwitchProcedureModal: false,
      compositeProcedureIdsToBeDeleted: [],
    };
    this.onAddProcedure = this.onAddProcedure.bind(this);
    this.onInviteCodeVerifyClicked = this.onInviteCodeVerifyClicked.bind(this);
  }

  componentDidMount() {
    this.props.fetchPatientProcedures();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isProceduredAdded && !this.props.isProceduredAdded) {
      this.onAddProcedureModalClose();
      this.props.fetchPatientProcedures();
    }
  }

  componentWillUnmount() {
    this.setState({ showAddProcedureModal: false });
    clearAddProcedureStates();
  }

  onAddProcedure() {
    this.setState({
      showAddProcedureModal: true,
    });
  }

  onDeleteProcedure(compositeProcedureId) {
    this.setState({
      showDeleteModal: true,
      compositeProcedureIdsToBeDeleted: [compositeProcedureId],
    });
  }

  onSwitchProcedure(compositeProcedureId) {
    const selectedProcedureName = this.props.compositeProcedures.find(
      item => item.id === compositeProcedureId
    ).name;
    const activeProcedure = this.props.compositeProcedures.find(
      item => item.isActive === true
    );
    this.setState({
      showSwitchProcedureModal: true,
      procedureToBeSwitched: compositeProcedureId,
      selectedProcedureName,
      activeProcedureName: activeProcedure.name,
    });
  }

  onSwitchProcedureConfirmed = () => {
    this.props.putSwitchActiveProcedure(this.state.procedureToBeSwitched);
  };

  onDeleteConfirmed = () => {
    this.props.doDeleteProcedure(this.state.compositeProcedureIdsToBeDeleted);
    this.onModalClose();
  };

  onModalClose = () => {
    this.setState({
      showDeleteModal: false,
      compositeProcedureIdsToBeDeleted: [],
      procedureToBeSwitched: 0,
      selectedProcedureName: '',
      activeProcedureName: '',
      showSwitchProcedureModal: false,
    });
  };

  onAddProcedureModalClose = () => {
    this.setState({ showAddProcedureModal: false });
    this.clearAddProcedureStates();
  };

  onAddProcedureClicked() {
    this.setState({ showAddProcedureModal: false });
  }

  onInviteCodeVerifyClicked(inviteCode) {
    this.props.verifyInviteCode(inviteCode);
  }

  clearAddProcedureStates() {
    this.props.clearInviteCodeState();
    // this.props.clearAddProcedureState();
  }

  renderProcedureItem(compositeProcedure) {
    return (
      <div className='row'>
        <div
          className={`col-xs-12 ${
            !compositeProcedure.isActive ? 'col-sm-6 col-md-8' : 'col-sm-12'
          }`}
        >
          <div className='procedure-name'>{compositeProcedure.name}</div>
          <div className='procedure-date'>
            {compositeProcedure.compositeProcedureDateInString}
          </div>
          {compositeProcedure.isActive && (
            <div className='is-active-procedure'>Currently Viewing</div>
          )}
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          {!compositeProcedure.isActive && (
            <div className='action-container'>
              <span
                role='button'
                tabIndex='0'
                className='switch-procedure-btn primary-text-color cursor-pointer'
                onClick={() => this.onSwitchProcedure(compositeProcedure.id)}
              >
                <span className='icon icon-font-a-manage-procedure' />
                <span className='event-task-label'>Switch Procedure</span>
              </span>
              <span
                role='button'
                tabIndex='0'
                className='no-padding cursor-pointer procedure-delete'
                onClick={() => this.onDeleteProcedure(compositeProcedure.id)}
              >
                <span className='icon icon-font-a-delete' />
                <span className='event-task-label'>Delete</span>
              </span>

              <div className='clr'></div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderAddProcedureModal() {
    return (
      <Modal
        show={this.state.showAddProcedureModal}
        onHide={this.onAddProcedureModalClose}
        container={document.body}
        autoFocus
        backdrop='static'
        aria-labelledby='contained-modal-title'
        className='primary-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title'>Add Procedure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.isInviteCodeValid ? (
            <AddProcedure
              shownInModal
              procedures={this.props.inviteCodeProcedures}
            />
          ) : (
            <InviteCode isForAddProcedure />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className='manage-procedures-container'>
          {/* <MasqueradingHeader /> */}
          {this.props.compositeProcedures &&
          this.props.compositeProcedures.length ? (
            <div>
              <Media query={{ maxWidth: 767 }}>
                {screenIsSmall =>
                  screenIsSmall ? (
                    <BackNavigation title='Manage Procedures' />
                  ) : (
                    <div className='manage-procedure-header-container'>
                      <span
                        className='col-xs-1 back-icon icon icon-font-a-left-chevron primary-text-color cursor-pointer'
                        onClick={() => this.props.history.goBack()}
                      />
                      <div className='col-xs-11 col-sm-10 manage-procedure-header'>
                        <div className='col-xs-10 manage-procedures-title text-center'>
                          Manage Procedures
                        </div>
                        <div className='col-xs-2 manage-procedures-add-procedure float-right hidden-xs'>
                          <button
                            className='btn btn-default'
                            onClick={this.onAddProcedure}
                          >
                            <i className='icon icon-font-a-add-caregiver procedure-action-buttons'></i>
                            <span className='manage-procedures-add-procedure-label'>
                              Add Procedure{' '}
                            </span>
                          </button>
                        </div>
                        <div className='clearfix' />
                      </div>
                      <div className='clearfix' />
                    </div>
                  )
                }
              </Media>
              <div
                role='button'
                tabIndex='0'
                className='fab visible-xs'
                onClick={this.onAddProcedure}
              >
                +
              </div>
              <div className='clearfix' />
              <div className='manage-procedures-data'>
                <div className='manage-procedures-item-container'>
                  {this.props.compositeProcedures.map(item => (
                    <div
                      className='col-xs-12 manage-procedures-item'
                      key={item.id}
                    >
                      {this.renderProcedureItem(item)}
                    </div>
                  ))}
                </div>
              </div>
              <div className='clearfix' />
              <ConfirmationModal
                show={this.state.showDeleteModal}
                text='Are you sure you want to delete the procedure?'
                title='Delete Procedure'
                onModalClose={this.onModalClose}
                onConfirmed={this.onDeleteConfirmed}
                cancelBtnText='Cancel'
                okBtnText='Delete'
              />
              {this.state.showSwitchProcedureModal && (
                <ConfirmationModal
                  show={this.state.showSwitchProcedureModal}
                  text={Strings.SWITCH_PROCEDURE_CONFIRMATION_MODAL.replace(
                    '%procedure1',
                    this.state.activeProcedureName
                  ).replace('%procedure2', this.state.selectedProcedureName)}
                  title='Switch Procedure'
                  onModalClose={this.onModalClose}
                  onConfirmed={this.onSwitchProcedureConfirmed}
                  cancelBtnText='Cancel'
                  okBtnText='Confirm'
                />
              )}
              {this.state.showAddProcedureModal &&
                this.renderAddProcedureModal()}
            </div>
          ) : (
            <EmptyView
              imgSrc={NoProcedures}
              className=''
              message='No Procedures'
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.profile.userDetails,
  isRequestInProgress: state.patientProcedures.isLoading,
  isDeleteRequestInProgress:
    state.patientProcedures.isDeleteProcedureRequestInProgress,
  compositeProcedures: state.patientProcedures.compositeProcedures,
  inviteCodeProcedures: state.patientProcedures.inviteCodeProcedures,
  isInviteCodeValid: state.patientProcedures.isInviteCodeValid,
  isProceduredAdded: state.addProcedureRequest.isAdded,
});

const mapDispatchToProps = dispatch => ({
  fetchPatientProcedures: () => dispatch(getPatientProcedureRequest()),
  doDeleteProcedure: userProcedureIds =>
    dispatch(deleteProcedureRequest(userProcedureIds)),
  putSwitchActiveProcedure: userProcedureId =>
    dispatch(switchActiveProcedureRequest(userProcedureId, true)),
  clearInviteCodeState: () => dispatch(clearInviteCodeStates()),
  clearAddProcedureState: () => dispatch(clearAddProcedureStates()),
});

ManageProcedures.propTypes = {
  fetchPatientProcedures: PropTypes.func,
  doDeleteProcedure: PropTypes.func,
  verifyInviteCode: PropTypes.func,
  compositeProcedures: PropTypes.array,
  inviteCodeProcedures: PropTypes.array,
  isRequestInProgress: PropTypes.bool,
  isDeleteRequestInProgress: PropTypes.bool,
  isInviteCodeValid: PropTypes.bool,
  history: PropTypes.func,
  clearInviteCodeState: PropTypes.func,
  isProceduredAdded: PropTypes.bool,
  putSwitchActiveProcedure: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProcedures);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, combineReducers } from 'redux';
import injectReducer from 'utils/injectReducer';
import {
  onfetchAllUsersOfTheDepartmentRequest,
  onAddNewProviderRequest,
  updateProviderInfoAction,
  onfetchPerformersOfUserTypeMasterRequest,
  onfetchLocationsRequest,
} from './actions';
import {
  getDepartmentProceduresRequest,
  getProcedureModifiersRequest,
} from 'common/actions';
import {
  getDepartmentProceduresReducer,
  getProcedureModifiersReducer,
} from 'common/reducer';
import { userAdministrationReducer } from './reducer';
import Select, {
  SingleSelectWithoutBorder as CustomizedSelect,
  CustomMultiSelect,
} from 'components/SelectV2';
import PhoneInput from 'components/PhoneInput';
import HelpBlock from 'components/HelpBlock';
import { Modal } from 'react-bootstrap/lib';
import validate, { clearErrorsForField } from 'common/validator';
import validationConfig from './validator';
import './user_administration.scss';
import Pagination from 'components/Pagination';
import { getItemFromStorage } from 'services/storage';
import Tab from 'react-bootstrap/lib/Tab';
import Nav from 'react-bootstrap/lib/Nav';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import NavItem from 'react-bootstrap/lib/NavItem';
import Emptyview from 'components/Emptyview';
import NoProviders from '../../assets/images/no_providers.png';
import {
  USER_TYPE_PATIENT_ADVOCATE,
  USER_TYPE_TRADITIONAL_SITE_USER,
} from '../../constants';

const userTypeOptions = [
  {
    value: 'master',
    label: 'Educator/Provider',
    type: USER_TYPE_PATIENT_ADVOCATE,
  },
  {
    value: 'master',
    label: 'Treatment Site User',
    type: USER_TYPE_TRADITIONAL_SITE_USER,
  },
];

const validUserTypes = [
  USER_TYPE_PATIENT_ADVOCATE,
  USER_TYPE_TRADITIONAL_SITE_USER,
];

class UserAdministration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddEditUserInfoModal: false,
      userId: null,
      isActive: { value: true, label: 'Yes' },
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      contactNumber: '',
      providerType: '',
      userType: '',
      emailDisabled: false,
      contactNumberDisabled: false,
      userTypeDisabled: false,
      users: [],
      primaryPhysicians: [],
      locations: [],
      location: { value: '', label: '' },
      errors: {},
      procedures: [],
      procedureModifiers: [],
      canAddOnlyProviders: false,
      page: 1,
      filterNameOrEmail: '',
      filterProcedureIds: [],
      filterLocationIds: [],
      previousFilterData: {
        previousFilterNameOrEmail: '',
        previousFilterProcedureIds: [],
        previousFilterLocationIds: [],
      },
    };
  }

  componentDidMount = () => {
    const currentUser = JSON.parse(getItemFromStorage('currentUser'));

    if (!(currentUser.hasSuperUserPrivileges && currentUser.isAdmin)) {
      this.props.history.push('/not-found');
    } else {
      window.addEventListener('scroll', this.scrollEvent);
      this.props.fetchAllUsersOfTheDepartment({ page: this.state.page });
      this.props.fetchLocations();
      this.props.fetchPerformersOfUserTypeMaster();
      this.props.getDepartmentProceduresRequest();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isRequestInProgress === false &&
      prevProps.isRequestInProgress === true
    ) {
      this.setState({
        showAddEditUserInfoModal: this.props.show,
      });
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.procedures !== nextProps.procedures) {
      this.setState({
        procedures: nextProps.procedures,
      });
    }

    if (this.state.canAddOnlyProviders !== nextProps.canAddOnlyProviders) {
      this.setState({
        canAddOnlyProviders: nextProps.canAddOnlyProviders,
        providerType: 'Provider',
      });
    }
  };

  extractIdsFromObjects = objects => objects.map(item => item.value);

  onSetFilter = isPagination => {
    const { filterNameOrEmail, filterLocationIds, filterProcedureIds } =
      this.state;

    const page = isPagination ? this.state.page : 1;

    let currentData = {
      filterNameOrEmail,
      filterProcedureIds,
      filterLocationIds,
      page: this.state.page,
    };
    if (this.isFilterUpdated(currentData, this.state.previousFilterData)) {
      this.props.fetchAllUsersOfTheDepartment({
        page: page,
        name: filterNameOrEmail,
        procedure_ids: this.extractIdsFromObjects(filterProcedureIds),
        location_ids: this.extractIdsFromObjects(filterLocationIds),
      });
      this.setState({
        page: page,
        previousFilterData: {
          previousPage: this.state.page,
          previousFilterNameOrEmail: this.state.filterNameOrEmail,
          previousFilterProcedureIds: this.state.filterProcedureIds,
          previousFilterLocationIds: this.state.filterLocationIds,
        },
      });
    }
  };

  onResetFilter = () => {
    this.setState(
      {
        filterNameOrEmail: '',
        resetTriggered: true,
        filterProcedureIds: [],
        filterLocationIds: [],
        page: 1,
        errors: {},
        previousFilterData: {
          previousPage: 1,
          previousFilterNameOrEmail: '',
          previousFilterProcedureIds: [],
          previousFilterLocationIds: [],
        },
      },
      () => {
        this.props.fetchAllUsersOfTheDepartment({ page: this.state.page });
      }
    );
  };

  scrollEvent = () => {
    let tableHeader = document.getElementById('main-header');
    let fixedHeader = document.getElementById('fixed-header');

    if (tableHeader != null && fixedHeader != null) {
      tableHeader = document
        .getElementById('main-header')
        .getBoundingClientRect();
      fixedHeader = document
        .getElementById('fixed-header')
        .getBoundingClientRect();

      if (tableHeader.top <= fixedHeader.top) {
        document.getElementById('fixed-header').style.visibility = 'visible';
      } else {
        document.getElementById('fixed-header').style.visibility = 'hidden';
      }
    }
  };

  onAddEditUserInfoModal = user => {
    const { id, firstName, lastName, email, contactNumber, type, userType } =
      user || {};

    const selectedPrimaryPhysicians = this.getSelectedPrimaryPhysicians(id);
    const selectedLocations = this.getSelectedLocations(id);
    const selectedProcedures = this.getSelectedProcedures(id);

    this.setState({
      userId: id ? id : null,
      userFirstName: firstName ? firstName : '',
      userLastName: lastName ? lastName : '',
      userEmail: email ? email : '',
      contactNumber: contactNumber ? contactNumber : '',
      providerType: type
        ? type
        : this.state.canAddOnlyProviders
        ? 'Provider'
        : '',
      userType:
        userType && type
          ? userTypeOptions.find(
              item => item.value === userType && item.type === type
            )
          : '',
      showAddEditUserInfoModal: true,
      isActive: user.isActive
        ? { value: true, label: 'Yes' }
        : { value: false, label: 'No' },
      primaryPhysicians: selectedPrimaryPhysicians
        ? selectedPrimaryPhysicians
        : [],
      locations: selectedLocations ? selectedLocations : [],
      location: selectedLocations
        ? selectedLocations[0]
        : { value: '', label: '' },
      procedures: selectedProcedures ? selectedProcedures : [],
      // procedureModifiers: selectedModifiers ? selectedModifiers : [],
      emailDisabled: email ? true : false,
      contactNumberDisabled: contactNumber ? true : false,
      userTypeDisabled: userType ? true : false,
      errors: {},
    });
  };

  onIsActiveChange = obj => {
    this.setState({ isActive: obj });
  };

  handlePagination = page => {
    this.setState({ page }, () => {
      this.onSetFilter(true);
    });
  };

  getSelectedPrimaryPhysicians = id => {
    const selectedUser = this.props.users.find(item => item.id === id);
    const selectedItems =
      selectedUser &&
      selectedUser.primaryPhysicians.map(item => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      }));
    return selectedItems;
  };

  getSelectedLocations = id => {
    const selectedUser = this.props.users.find(item => item.id === id);
    const selectedItems =
      selectedUser &&
      selectedUser.locations.map(item => ({
        value: item.id,
        label: item.name,
      }));
    return selectedItems;
  };

  getSelectedModifiers = id => {
    const selectedUser = this.props.users.find(item => item.id === id);
    const selectedItems =
      selectedUser &&
      selectedUser.procedureModifiers.map(item => ({
        value: item.id,
        label: item.value,
      }));
    return selectedItems;
  };

  getSelectedProcedures = id => {
    const selectedUser = this.props.users.find(item => item.id === id);
    const selectedItems =
      selectedUser &&
      selectedUser.procedures.map(item => ({
        value: item.id,
        label: item.value,
      }));
    return selectedItems;
  };

  changeFieldHandler = e => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  changeContactNumberHandler = e => {
    const contactNumber = e.target.value.trim();
    this.setState({
      contactNumber,
      errors: clearErrorsForField(this.state.errors, 'contactNumber'),
    });
  };

  selectUserType = selectedOption => {
    this.setState(prevState => ({
      userType: selectedOption,
      providerType: selectedOption.type,
      primaryPhysicians:
        selectedOption.value === 'master' ? [] : prevState.primaryPhysicians,
      // procedureModifiers: selectedOption.value === 'master' ? this.state.procedureModifiers : [],
      locations: selectedOption.value === 'master' ? prevState.locations : [],
      errors: clearErrorsForField(prevState.errors, 'userType'),
      errors: clearErrorsForField(prevState.errors, 'providerType'),
    }));
  };

  selectPrimaryPhysicians = selectedValues => {
    this.setState({
      primaryPhysicians: selectedValues,
      errors: clearErrorsForField(this.state.errors, 'primaryPhysicians'),
    });
  };

  selectProcedures = selectedValues => {
    this.setState({
      procedures: selectedValues,
      errors: clearErrorsForField(this.state.errors, 'procedures'),
    });

    // this.setState({
    //   procedure: selectedValues,
    //   errors: clearErrorsForField(this.state.errors, 'procedure')
    // }, () => {
    //   const params = {
    //     id: this.state.procedure.value,
    //     modifier_type: "Indication",
    //   };
    //   this.props.getProcedureModifiersRequest(params);
    // });
  };

  selectProcedureModifiers = selectedValues => {
    this.setState({
      procedureModifiers: selectedValues,
      errors: clearErrorsForField(this.state.errors, 'procedureModifiers'),
    });
  };

  selectLocations = selectedValues => {
    this.setState({
      locations: selectedValues,
      errors: clearErrorsForField(this.state.errors, 'locations'),
    });
  };

  selectALocation = selectedValue => {
    this.setState({
      location: selectedValue,
      errors: clearErrorsForField(this.state.errors, 'location'),
    });
  };

  onAddProviderInfo = () => {
    this.validateAddEditProviderFormValues();
  };

  onEditProviderInfo = () => {
    this.validateAddEditProviderFormValues();
  };

  validateAddEditProviderFormValues = () => {
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  changeFilterNameOrEmail = e => {
    this.setState({ filterNameOrEmail: e.target.value });
  };

  onChangeFilterProcedure = selectedValues => {
    this.setState({ filterProcedureIds: selectedValues });
  };

  onChangeFilterLocation = selectedValues => {
    this.setState({ filterLocationIds: selectedValues });
  };

  isFilterUpdated = (currentData, previousData) => {
    return (
      currentData.page !== previousData.previousPage ||
      currentData.filterNameOrEmail.trim().toUpperCase() !==
        previousData.previousFilterNameOrEmail.trim().toUpperCase() ||
      JSON.stringify(currentData.filterProcedureIds.sort()) !==
        JSON.stringify(previousData.previousFilterProcedureIds.sort()) ||
      JSON.stringify(currentData.filterLocationIds.sort()) !==
        JSON.stringify(previousData.previousFilterLocationIds.sort())
    );
  };

  onFormValidationSuccess = () => {
    const { primaryPhysicians, locations, procedures, procedureModifiers } =
      this.state;
    let contactNumber =
      this.props.country === 'AU'
        ? this.state.contactNumber.replace('+61', '')
        : this.state.contactNumber.replace('+1', '');
    contactNumber = contactNumber.replace(/ /g, '');

    const params = {
      first_name: this.state.userFirstName,
      last_name: this.state.userLastName,
      email: this.state.userEmail,
      contact_number: contactNumber ? contactNumber : null,
      provider_type: this.state.providerType,
      user_type: this.state.userType.value,
      is_active: this.state.isActive.value,
      pagination: {
        page: this.state.page,
        name: this.state.filterNameOrEmail,
        procedure_ids: this.extractIdsFromObjects(
          this.state.filterProcedureIds
        ),
        location_ids: this.extractIdsFromObjects(this.state.filterLocationIds),
      },
    };

    if (primaryPhysicians) {
      params.performer_ids = primaryPhysicians.map(item => item.value);
    }

    if (locations) {
      params.location_ids =
        this.state.userType.type === USER_TYPE_TRADITIONAL_SITE_USER
          ? [this.state.location.value]
          : locations.map(item => item.value);
    }

    if (procedures) {
      params.procedure_ids = procedures.map(item => item.value);
    }

    // if (procedureModifiers) {
    //   params.procedure_id = procedureId
    //   params.procedure_modifiers_ids = procedureModifiers.map((item) => item.value)
    // }

    if (this.state.userId) {
      params.id = this.state.userId;

      this.props.updateProviderInfo(params, () =>
        this.setState({ location: { value: '', label: '' } })
      );
    } else {
      this.props.addNewProvider(params, () =>
        this.setState({ location: { value: '', label: '' } })
      );
    }
  };

  hideAddEditUserInfoModal = () => {
    this.setState({
      showAddEditUserInfoModal: false,
      errors: {},
    });
  };

  renderFilters = () => {
    return (
      <div className='filters-container'>
        <div className='filters-label'>Filters</div>
        <div className='row filter-wrapper'>
          <div className='col-xs-12'>
            <label htmlFor='filterNameOrEmail'>Name/Email</label>
            <input
              type='text'
              className='form-control'
              placeholder='Type Name or Email here'
              onChange={this.changeFilterNameOrEmail}
              value={this.state.filterNameOrEmail}
              name='nameOrEmail'
            />
          </div>

          <div className='col-xs-12'>
            <label htmlFor='filterProcedure'>Procedure</label>
            <CustomMultiSelect
              allowSelectAll
              isLoading={this.props.isFilterDataRequestInProgress}
              onChange={this.onChangeFilterProcedure}
              value={this.state.filterProcedureIds}
              isSearchable={false}
              placeholder={
                this.state.filterProcedureIds.length ? 'Selected' : 'Select'
              }
              options={this.props.procedures.map(item => ({
                value: item.id,
                label: `${item.name}`,
              }))}
            />
          </div>
          <div className='col-xs-12'>
            <label htmlFor='filterLocation'>Location</label>
            <CustomMultiSelect
              allowSelectAll
              isLoading={this.props.isFilterDataRequestInProgress}
              onChange={this.onChangeFilterLocation}
              value={this.state.filterLocationIds}
              isSearchable={false}
              placeholder={
                this.state.filterLocationIds.length ? 'Selected' : 'Select'
              }
              options={[
                ...this.props.locations.map(item => ({
                  value: item.id,
                  label: `${item.name}`,
                })),
                ...this.props.nonTipsLocations.map(item => ({
                  value: item.id,
                  label: `${item.name}`,
                })),
              ]}
            />
          </div>
          <div className='col-xs-12 btn-wrapper'>
            <button
              className={`btn btn-default clear-all ${
                this.state.isFilterApplied ? '' : 'cursor-pointer'
              }`}
              onClick={this.onResetFilter}
            >
              Clear All
            </button>
            <button
              className='btn btn-primary apply-button'
              onClick={() => this.onSetFilter(false)}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderData = () => {
    return (
      <>
        {this.props.users.length > 0 ? (
          <div>
            <div className='ui-table'>
              <table className='table'>
                <thead className='sticky-header'>
                  <tr>
                    <th>Name</th>
                    <th>User type</th>
                    <th>Primary Users</th>
                    <th>Procedures</th>
                    <th>Locations</th>
                    <th style={{minWidth: '70px'}}>Active?</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.users.map(user => (
                    <tr key={user.id}>
                      <td>
                        {user.firstName} {user.lastName}
                      </td>
                      <td>
                        {validUserTypes.includes(user.type)
                          ? (() => {
                              const userTypeOption = userTypeOptions.find(
                                ust =>
                                  ust.value === user.userType &&
                                  ust.type === user.type
                              );
                              if (userTypeOption) {
                                return userTypeOption.label;
                              } else {
                                return '- -';
                              }
                            })()
                          : '- -'}
                      </td>
                      <td>
                        {user.primaryPhysicians.length > 0
                          ? user.primaryPhysicians.map(item => (
                              <p key={item.id}>
                                {item.firstName} {item.lastName}
                              </p>
                            ))
                          : '- -'}
                      </td>
                      <td>
                        {user.procedures.length > 0
                          ? user.procedures.map(proc => (
                              <p key={proc.id}>{proc.name}</p>
                            ))
                          : '- -'}
                      </td>
                      <td>
                        {user.locations.length > 0
                          ? user.locations.map(loc => (
                              <p key={loc.id}>{loc.name}</p>
                            ))
                          : '- -'}
                      </td>
                      <td>{user.isActive === true ? 'Yes' : 'No'}</td>
                      <td>
                        <span
                          className='icon icon-font-a-write-message cursor-pointer'
                          onClick={() => this.onAddEditUserInfoModal(user)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: '10px', paddingBottom: '10px' }}>
              {this.props.pagination && (
                <Pagination
                  pagination={this.props.pagination}
                  title='Providers'
                  handlePagination={this.handlePagination}
                />
              )}
            </div>
          </div>
        ) : (
          <Emptyview
            imgSrc={NoProviders}
            className=''
            message={`No ${
              this.state.canAddOnlyProviders ? 'Providers' : 'Users'
            }`}
          />
        )}
      </>
    );
  };

  renderChildComponents = () => {
    return (
      <React.Fragment>
        <div className='row'>
          <div
            className='col-sm-3 col-md-3 col-lg-3 filter-container-wrapper'
            style={{ position: 'fixed' }}
          >
            {this.renderFilters()}
          </div>
          <div
            className='col-sm-9 col-md-9 col-lg-9 record-list-wrapper'
            style={{ float: 'right' }}
          >
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <span
                className='add-new-link'
                onClick={this.onAddEditUserInfoModal}
              >
                <span className='icon icon-font-a-add-new-task'></span>
                Add New {this.state.canAddOnlyProviders ? 'Provider' : 'User'}
              </span>
              <span className='pagination'>
                {this.props.pagination && (
                  <Pagination
                    pagination={this.props.pagination}
                    title='Providers'
                    handlePagination={this.handlePagination}
                  />
                )}
              </span>
            </div>
            <div className='clr'></div>
            {this.renderData()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <>
        {this.props.manageUsersEnabled && (
          <div className='col-xs-12 content-administration'>
            <div className='admin-title-container container-fluid'>
              <Tab.Container id='admin-activity-tabs'>
                <React.Fragment>
                  <div className='admin-navigation-div'>
                    <div className='admin-navigation-section'>
                      <Nav bsStyle='pills' justified>
                        <LinkContainer to='/users' replace>
                          <NavItem eventKey='providers'>
                            <React.Fragment>
                              <i className='icon icon-font-a-patient-under-physician'></i>
                              <span className='icon-label'>
                                {this.state.canAddOnlyProviders
                                  ? 'Providers'
                                  : 'Users'}
                              </span>
                            </React.Fragment>
                          </NavItem>
                        </LinkContainer>
                        <LinkContainer to='/locations' replace>
                          <NavItem eventKey='locations'>
                            <React.Fragment>
                              <i className='icon icon-font-a-location'></i>
                              <span className='icon-label'>
                                Treatment Centers
                              </span>
                            </React.Fragment>
                          </NavItem>
                        </LinkContainer>
                      </Nav>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <Tab.Content>{this.renderChildComponents()}</Tab.Content>
                  </div>
                </React.Fragment>
              </Tab.Container>
            </div>

            <Modal
              show={this.state.showAddEditUserInfoModal}
              onHide={this.hideAddEditUserInfoModal}
              container={document.body}
              autoFocus
              aria-labelledby='contained-modal-title'
              backdrop='static'
            >
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title'>
                  {this.state.userId
                    ? `Edit ${
                        this.state.canAddOnlyProviders ? 'Providers' : 'User'
                      } Info`
                    : `Add New ${
                        this.state.canAddOnlyProviders ? 'Providers' : 'User'
                      }`}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div id='edit-user-modal'>
                  <div
                    className={`form-group ${
                      this.state.errors.userFirstName !== undefined
                        ? 'has-error'
                        : ''
                    }`}
                  >
                    <label htmlFor=''>First Name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='First Name'
                      name='userFirstName'
                      value={this.state.userFirstName}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.userFirstName} />
                  </div>

                  <div
                    className={`form-group ${
                      this.state.errors.userLastName !== undefined
                        ? 'has-error'
                        : ''
                    }`}
                  >
                    <label htmlFor=''>Last Name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Last Name'
                      name='userLastName'
                      value={this.state.userLastName}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.userLastName} />
                  </div>

                  <div
                    className={`form-group ${
                      this.state.errors.userEmail !== undefined
                        ? 'has-error'
                        : ''
                    }`}
                  >
                    <label htmlFor=''>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='Email'
                      name='userEmail'
                      // readOnly={this.state.emailDisabled}
                      value={this.state.userEmail}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.userEmail} />
                  </div>

                  <div
                    className={`form-group ${
                      this.state.errors.contactNumber !== undefined
                        ? 'has-error'
                        : ''
                    }`}
                  >
                    <label htmlFor='phone'>Mobile Phone Number</label>
                    <PhoneInput
                      className='form-control'
                      id='phone'
                      name='contactNumber'
                      placeholder={
                        this.props.country === 'AU'
                          ? '+61 02 3456 7890'
                          : '+1 123 456 7890'
                      }
                      // readOnly={this.state.contactNumberDisabled}
                      value={this.state.contactNumber}
                      onChange={this.changeContactNumberHandler}
                      country={this.props.country}
                    />
                    <HelpBlock value={this.state.errors.contactNumber} />
                  </div>

                  <div
                    className={`form-group ${
                      this.state.errors.userType !== undefined
                        ? 'has-error'
                        : ''
                    }`}
                  >
                    <label htmlFor='userType'>Select User Type</label>
                    <CustomizedSelect
                      id='select-user-type'
                      placeholder='Select User Type'
                      isDisabled={this.state.userTypeDisabled}
                      onChange={this.selectUserType}
                      value={userTypeOptions.filter(
                        item =>
                          item.value === this.state.userType.value &&
                          item.type === this.state.providerType
                      )}
                      options={
                        this.state.canAddOnlyProviders
                          ? userTypeOptions.filter(
                              item => item.type === this.state.providerType
                            )
                          : userTypeOptions
                      }
                    />
                    <HelpBlock value={this.state.errors.userType} />
                  </div>

                  {this.state.userType &&
                  this.state.userType.value !== 'master' &&
                  this.state.providerType ? (
                    <div
                      className={`form-group ${
                        this.state.errors.primaryPhysicians !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label htmlFor='userType'>Primary Users</label>
                      <CustomMultiSelect
                        id='select-primary-physicians'
                        isDisabled={
                          this.state.userType === '' ||
                          this.state.userType.value === 'master' ||
                          this.state.providerType === ''
                        }
                        placeholder=''
                        onChange={this.selectPrimaryPhysicians}
                        value={this.state.primaryPhysicians}
                        options={this.props.performersOfUserTypeMaster
                          .filter(item => item.type === this.state.providerType)
                          .map(item => ({
                            value: item.id,
                            label: `${item.firstName} ${item.lastName}`,
                          }))}
                      />
                      <HelpBlock value={this.state.errors.primaryPhysicians} />
                    </div>
                  ) : null}

                  {this.state.userType &&
                    this.state.userType.value === 'master' &&
                    this.state.userType.type ===
                      USER_TYPE_TRADITIONAL_SITE_USER && (
                      <div
                        className={`form-group ${
                          this.state.errors.locations !== undefined
                            ? 'has-error'
                            : ''
                        }`}
                      >
                        <label htmlFor='locations'>Location</label>
                        <Select
                          id='select-location'
                          isDisabled={
                            this.state.userType === '' ||
                            this.state.userType.value === 'support' ||
                            this.state.userTypeDisabled
                          }
                          placeholder=''
                          onChange={this.selectALocation}
                          value={this.state.location}
                          options={this.props.nonTipsLocations.map(item => ({
                            value: item.id,
                            label: `${item.name}`,
                          }))}
                        />
                        <HelpBlock value={this.state.errors.location} />
                      </div>
                    )}

                  {this.state.userId && (
                    <div className={`form-group`}>
                      <label htmlFor='is-active'>Active?</label>
                      <Select
                        id='select-is-active'
                        placeholder=''
                        onChange={this.onIsActiveChange}
                        value={this.state.isActive}
                        options={[
                          { value: true, label: 'Yes' },
                          { value: false, label: 'No' },
                        ]}
                      />
                    </div>
                  )}

                  {this.state.userType &&
                  this.state.userType.value === 'master' &&
                  this.state.providerType === 'Provider' ? (
                    <div
                      className={`form-group ${
                        this.state.errors.locations !== undefined
                          ? 'has-error'
                          : ''
                      }`}
                    >
                      <label htmlFor='locations'>Locations</label>
                      <CustomMultiSelect
                        id='select-locations'
                        isDisabled={
                          this.state.userType === '' ||
                          this.state.userType.value === 'support'
                        }
                        placeholder=''
                        onChange={this.selectLocations}
                        value={this.state.locations}
                        options={this.props.locations.map(item => ({
                          value: item.id,
                          label: `${item.name}`,
                        }))}
                      />
                      <HelpBlock value={this.state.errors.locations} />
                    </div>
                  ) : this.state.userType &&
                    this.state.userType.value === 'master' &&
                    this.state.providerType === 'PatientAdvocate' ? (
                    <>
                      <div
                        className={`form-group ${
                          this.state.errors.procedures !== undefined
                            ? 'has-error'
                            : ''
                        }`}
                      >
                        <label htmlFor='procedure'>Procedures</label>
                        <CustomMultiSelect
                          id='select-procedure'
                          isDisabled={
                            this.state.userType === '' ||
                            this.state.userType.value === 'support'
                          }
                          placeholder=''
                          onChange={this.selectProcedures}
                          value={this.state.procedures}
                          options={this.props.procedures.map(item => ({
                            value: item.id,
                            label: `${item.name}`,
                          }))}
                        />
                        <HelpBlock value={this.state.errors.procedures} />
                      </div>

                      {/*  code to take procedure modifier as a input from UI Side */}
                      {/* <div className={`form-group ${this.state.errors.procedureModifiers !== undefined ? 'has-error' : ''}`}>
                              <label htmlFor="procedureModifiers">Procedure Modifiers</label>
                              <CustomMultiSelect
                                id="select-procedureModifiers"
                                isDisabled={(this.state.userType === '' || this.state.userType.value === 'support')}
                                placeholder=""
                                onChange={this.selectProcedureModifiers}
                                value={this.state.procedureModifiers}
                                options={
                                  this.props.procedureModifiers.map((item) => ({
                                    value: item.id, label: `${item.value}`,
                                  }))
                                }
                              />
                              <HelpBlock value={this.state.errors.procedureModifiers} />
                            </div> */}
                    </>
                  ) : null}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {this.state.userId ? (
                  <button
                    className='btn btn-primary'
                    onClick={this.onEditProviderInfo}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className='btn btn-primary'
                    onClick={this.onAddProviderInfo}
                  >
                    Save
                  </button>
                )}
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

UserAdministration.propTypes = {
  users: PropTypes.array,
  show: PropTypes.bool,
  isRequestInProgress: PropTypes.bool,
  isGetAllPerformersRequestInProgress: PropTypes.bool,
  fetchAllUsersOfTheDepartment: PropTypes.func,
  addNewProvider: PropTypes.func,
  updateProviderInfo: PropTypes.func,
  fetchPerformersOfUserTypeMaster: PropTypes.func,
  performersOfUserTypeMaster: PropTypes.array,
  pagination: PropTypes.object,
};

const mapStateToProps = state => ({
  users: state.userAdministration.userAdministrationReducer.users,
  isRequestInProgress:
    state.userAdministration.userAdministrationReducer.isRequestInProgress,
  isGetAllPerformersRequestInProgress:
    state.userAdministration.userAdministrationReducer
      .isGetAllPerformersRequestInProgress,
  isGetAllLocationsRequestInProgress:
    state.userAdministration.userAdministrationReducer
      .isGetAllLocationsRequestInProgress,
  show: state.userAdministration.userAdministrationReducer.show,
  error: state.userAdministration.userAdministrationReducer.error,
  performersOfUserTypeMaster:
    state.userAdministration.userAdministrationReducer
      .performersOfUserTypeMaster,
  locations: state.userAdministration.userAdministrationReducer.locations,
  nonTipsLocations:
    state.userAdministration.userAdministrationReducer.nonTipsLocations,
  procedures:
    state.userAdministration.getDepartmentProceduresReducer.procedures,
  isGetAllProcedureModifiersRequestInProgress:
    state.userAdministration.getProcedureModifiersReducer.isLoading,
  procedureModifiers:
    state.userAdministration.getProcedureModifiersReducer.procedureModifiers,
  canAddOnlyProviders: state.currentUser.attributes.canAddOnlyProviders,
  manageUsersEnabled:
    state.currentUser.attributes.hasSuperUserPrivileges &&
    state.currentUser.attributes.isAdmin,
  pagination: state.userAdministration.userAdministrationReducer.pagination,
  country: state.currentUser.attributes.country,
});

const mapDispatchToProps = dispatch => ({
  fetchAllUsersOfTheDepartment: (page = null) =>
    dispatch(onfetchAllUsersOfTheDepartmentRequest(page)),
  addNewProvider: (params, callBack) =>
    dispatch(onAddNewProviderRequest(params, callBack)),
  updateProviderInfo: (params, callBack) =>
    dispatch(updateProviderInfoAction(params, callBack)),
  fetchPerformersOfUserTypeMaster: () =>
    dispatch(onfetchPerformersOfUserTypeMasterRequest()),
  fetchLocations: () => dispatch(onfetchLocationsRequest()),
  getDepartmentProceduresRequest: () =>
    dispatch(getDepartmentProceduresRequest()),
  fetchFilterOptionsData: () => dispatch(getProceduresProvidersAndLocations()),
  getProcedureModifiersRequest: params =>
    dispatch(getProcedureModifiersRequest(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'userAdministration',
  reducer: combineReducers({
    userAdministrationReducer,
    getDepartmentProceduresReducer,
    getProcedureModifiersReducer,
  }),
});

export default compose(withReducer, withConnect)(UserAdministration);

/*
 *
 * PatientDetailsPage actions
 *
 */
import { showToaster } from 'common/toasterActions';
import { selectCtCenter } from 'services/patient/patientDashboard';
import { getPatientAppointments } from 'containers/Appointments/action';
import { archivePatientCall } from 'services/traditionalSite/dashboardApiCalls';
import {
  GET_PATIENT_DETAILS_REQUEST_SENT,
  GET_PATIENT_DETAILS_REQUEST_SUCCEED,
  GET_PATIENT_DETAILS_REQUEST_FAILED,
  REMOVE_PATIENT_REQUEST_SENT,
  REMOVE_PATIENT_REQUEST_SUCCEED,
  REMOVE_PATIENT_REQUEST_FAILED,
  UPDATE_NEEDS_ATTENTION_REQUEST_SENT,
  UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED,
  UPDATE_NEEDS_ATTENTION_REQUEST_FAILED,
  RESET_NEEDS_ATTENTION,
  SET_PHASE_ID,
  RESEND_INVITE_REQUEST_SENT,
  RESEND_INVITE_REQUEST_SUCCEED,
  RESEND_INVITE_REQUEST_FAILED,
  UPDATE_PATIENT_INFO_REQUEST_SENT,
  UPDATE_PATIENT_INFO_REQUEST_SUCCEED,
  UPDATE_PATIENT_INFO_REQUEST_FAILED,
  RESET_PATIENT_DETAILS,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED,
  CHANGE_STAGE_REQUEST_SENT,
  CHANGE_STAGE_REQUEST_SUCCEED,
  CHANGE_STAGE_REQUEST_FAILED,
  UPDATE_LOCATION_REQUEST_SENT,
  UPDATE_LOCATION_REQUEST_SUCCEED,
  UPDATE_LOCATION_REQUEST_FAILED,
  UPDATE_CT_CENTER_REQUEST_SENT,
  UPDATE_CT_CENTER_REQUEST_SUCCEED,
  UPDATE_CT_CENTER_REQUEST_FAILED,
  DELETE_PATIENT_REQUEST_SENT,
  DELETE_PATIENT_REQUEST_SUCCEED,
  DELETE_PATIENT_REQUEST_FAILED,
  PATIENT_CONTACTED_REQUEST_SUCCEEDED
} from './constants';

import {
  getPatientDetails,
  removePatient,
  updateNeedsAttention,
  resendInvite,
  updatePatientInfo,
  updateNotMovingForward,
  getPatientNotMovingForwardReasons,
  changeStage,
  updateLocation,
  deletePatient,
} from '../../services/patientDetails';

export const getPatientDetailsRequestSent = () => ({
  type: GET_PATIENT_DETAILS_REQUEST_SENT,
});

export const getPatientDetailsRequestSucceeded = (
  patientInfo,
  userProcedureId
) => ({
  type: GET_PATIENT_DETAILS_REQUEST_SUCCEED,
  payload: {
    patientInfo,
    userProcedureId,
  },
});

export const getPatientDetailsRequestFailed = () => ({
  type: GET_PATIENT_DETAILS_REQUEST_FAILED,
});

export const setPhaseId = phaseId => ({
  type: SET_PHASE_ID,
  phaseId,
});

export const resetNeedsAttention = () => ({
  type: RESET_NEEDS_ATTENTION,
});

export const getPatientInfo = (id, userProcedureId) => dispatch => {
  dispatch(getPatientDetailsRequestSent());
  getPatientDetails(id, userProcedureId)
    .then(response => {
      dispatch(
        getPatientDetailsRequestSucceeded(response.data, userProcedureId)
      );
      dispatch(resetNeedsAttention());
    })
    .catch(() => {
      dispatch(getPatientDetailsRequestFailed());
    });
};

export const removePatientRequestSent = () => ({
  type: REMOVE_PATIENT_REQUEST_SENT,
});

export const removePatientRequestSucceeded = () => ({
  type: REMOVE_PATIENT_REQUEST_SUCCEED,
});

export const removePatientRequestFailed = () => ({
  type: REMOVE_PATIENT_REQUEST_FAILED,
});

export const removePatientAction = params => dispatch => {
  dispatch(removePatientRequestSent());
  removePatient(params)
    .then(() => {
      dispatch(removePatientRequestSucceeded());
      dispatch(showToaster({ message: 'Procedure removed successfully.' }));
    })
    .catch(() => {
      dispatch(removePatientRequestFailed());
    });
};

export const updateNeedsAttentionRequestSent = () => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_SENT,
});

export const updateNeedsAttentionRequestSucceeded = response => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED,
  response,
});

export const updateNeedsAttentionRequestFailed = response => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_FAILED,
  response,
});

export const updateNeedsAttentionAction = params => dispatch => {
  dispatch(updateNeedsAttentionRequestSent());
  updateNeedsAttention(params)
    .then(response => {
      dispatch(updateNeedsAttentionRequestSucceeded(response));
    })
    .catch(error => {
      dispatch(updateNeedsAttentionRequestFailed(error.response));
      dispatch(
        showToaster({ message: error.response.data.message, type: 'error' })
      );
    });
};

export const getNotMovingForwardReasonsRequestSent = () => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT,
});

export const getNotMovingForwardReasonsRequestSucceeded = response => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED,
  response,
});

export const getNotMovingForwardReasonsRequestFailed = () => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED,
});

export const getNotMovingForwardReasons = params => dispatch => {
  dispatch(getNotMovingForwardReasonsRequestSent());
  getPatientNotMovingForwardReasons(params)
    .then(response => {
      dispatch(getNotMovingForwardReasonsRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getNotMovingForwardReasonsRequestFailed());
    });
};

export const updateNotMovingForwardRequestSent = () => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT,
});

export const updateNotMovingForwardRequestSucceeded = response => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED,
  response,
});

export const updateNotMovingForwardRequestFailed = response => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED,
  response,
});

export const changeStageRequestSent = () => ({
  type: CHANGE_STAGE_REQUEST_SENT,
});

export const changeStageRequestSucceeded = response => ({
  type: CHANGE_STAGE_REQUEST_SUCCEED,
  response,
});

export const changeStageRequestFailed = response => ({
  type: CHANGE_STAGE_REQUEST_FAILED,
  response,
});

export const updateNotMovingForwardAction = params => dispatch => {
  dispatch(updateNotMovingForwardRequestSent());
  updateNotMovingForward(params)
    .then(response => {
      dispatch(updateNotMovingForwardRequestSucceeded(response));
    })
    .catch(error => {
      dispatch(updateNotMovingForwardRequestFailed(error.response));
      dispatch(
        showToaster({ message: error.response.data.message, type: 'error' })
      );
    });
};

export const changeStageAction = params => dispatch => {
  dispatch(changeStageRequestSent());
  changeStage(params)
    .then(response => {
      dispatch(changeStageRequestSucceeded(response));
      dispatch(getPatientInfo(params.id, params.user_procedure_id));
    })
    .catch(error => {
      dispatch(changeStageRequestFailed(error.response));
      dispatch(
        showToaster({ message: error.response.data.message, type: 'error' })
      );
    });
};

export const updateLocationRequestSent = () => ({
  type: UPDATE_LOCATION_REQUEST_SENT,
});

export const updateLocationRequestSucceeded = data => ({
  type: UPDATE_LOCATION_REQUEST_SUCCEED,
  data,
});

export const updateLocationRequestFailed = () => ({
  type: UPDATE_LOCATION_REQUEST_FAILED,
});

export const updateLocationRequest = params => dispatch => {
  dispatch(updateLocationRequestSent());
  updateLocation(params)
    .then(response => {
      dispatch(updateLocationRequestSucceeded(response.data));
      dispatch(getPatientInfo(params.id, params.user_procedure_id));
      dispatch(
        getPatientAppointments(params.id, params.composite_procedure_id)
      );
      dispatch(showToaster({ message: 'Location updated successfully.' }));
    })
    .catch(error => {
      dispatch(updateLocationRequestFailed());
      dispatch(
        showToaster({ message: error.response.data.message, type: 'error' })
      );
    });
};

export const updateCtCenterRequestSent = () => ({
  type: UPDATE_CT_CENTER_REQUEST_SENT,
});

export const updateCtCenterRequestSucceeded = data => ({
  type: UPDATE_CT_CENTER_REQUEST_SUCCEED,
  data,
});

export const updateCtCenterRequestFailed = () => ({
  type: UPDATE_CT_CENTER_REQUEST_FAILED,
});

export const updateCtCenterRequest = params => dispatch => {
  dispatch(updateCtCenterRequestSent());
  selectCtCenter(params)
    .then(response => {
      dispatch(updateCtCenterRequestSucceeded(response.data));
      dispatch(getPatientInfo(params.id, params.user_procedure_id));
      dispatch(showToaster({ message: 'CT Center updated successfully.' }));
    })
    .catch(error => {
      dispatch(updateCtCenterRequestFailed());
      dispatch(
        showToaster({ message: error.response.data.message, type: 'error' })
      );
    });
};

export const resetPatientDetails = () => ({
  type: RESET_PATIENT_DETAILS,
});

export const resendInviteRequestSent = () => ({
  type: RESEND_INVITE_REQUEST_SENT,
});

export const resendInviteRequestSucceeded = response => ({
  type: RESEND_INVITE_REQUEST_SUCCEED,
  response,
});

export const resendInviteRequestFailed = response => ({
  type: RESEND_INVITE_REQUEST_FAILED,
  response,
});

export const resendInviteAction = params => dispatch => {
  dispatch(resendInviteRequestSent());
  resendInvite(params)
    .then(response => {
      dispatch(resendInviteRequestSucceeded(response));
      dispatch(showToaster({ message: response.data.message }));
      dispatch(getPatientInfo(params.id, params.user_procedure_id));
    })
    .catch(error => {
      dispatch(resendInviteRequestFailed(error.response));
      dispatch(
        showToaster({ message: error.response.data.message, type: 'error' })
      );
    });
};

export const updatePatientInfoRequestSent = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_SENT,
});

export const updatePatientInfoRequestSucceeded = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_SUCCEED,
});

export const updatePatientInfoRequestFailed = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_FAILED,
});

export const updatePatientInfoAction =
  (id, userProcedureId, params) => dispatch => {
    dispatch(updatePatientInfoRequestSent());
    updatePatientInfo(params)
      .then(() => {
        dispatch(updatePatientInfoRequestSucceeded());
        dispatch(
          showToaster({ message: 'Patient details updated successfully.' })
        );
        dispatch(getPatientInfo(id, userProcedureId));
      })
      .catch(() => {
        dispatch(updatePatientInfoRequestFailed());
      });
  };

export const deletePatientRequestSent = () => ({
  type: DELETE_PATIENT_REQUEST_SENT,
});

export const deletePatientRequestSucceeded = () => ({
  type: DELETE_PATIENT_REQUEST_SUCCEED,
});

export const deletePatientRequestFailed = () => ({
  type: DELETE_PATIENT_REQUEST_FAILED,
});

export const deletePatientAction = params => dispatch => {
  dispatch(deletePatientRequestSent());
  deletePatient(params)
    .then(() => {
      dispatch(deletePatientRequestSucceeded());
      dispatch(showToaster({ message: 'Patient deleted successfully.' }));
    })
    .catch(() => {
      dispatch(deletePatientRequestFailed());
    });
};

export const patientContactedRequestSuccess = data => ({
  type: PATIENT_CONTACTED_REQUEST_SUCCEEDED,
  payload: data,
});

export const archivePatientByEducator = id => dispatch => {
  archivePatientCall(id)
    .then(res => {
      dispatch(showToaster({ message: res.data.message }));
      dispatch(patientContactedRequestSuccess(res.data.isArchived));
    })
    .catch(() => {});
};

import {
  GET_LOCATION_PROVIDERS_REQUEST_SUCCEED,
  VERIFY_FIRST_TIME_LOGIN_REQUEST_SUCCEED,
  VERIFY_FIRST_TIME_LOGIN_REQUEST_FAILED,
  GENERATE_OTP_REQUEST_FAILED,
  VERIFY_OTP_REQUEST_SUCCEED,
  VERIFY_OTP_REQUEST_FAILED,
  GET_FLOW_ID_REQUEST_SUCCEED,
  CREATE_PATIENT_REQUEST_SUCCEED,
  WELCOME_VIEW,
  PAGE_CHANGE,
  SET_FLOW_TYPE,
  GET_SALESFORCE_USER_DATA_REQUEST_SUCCEED,
  SET_FLOW_ID_TO_BLANK,
  EMAIL_VERIFICATION_VIEW,
  OTP_VERIFICATION_VIEW,
  PATIENT_FORGOT_PASSWORD_REQUEST_SENT,
  PATIENT_FORGOT_PASSWORD_REQUEST_SUCCEED,
  PATIENT_FORGOT_PASSWORD_REQUEST_FAILED,
} from './constants';

const initialState = {
  isLoading: false,
  page: window.location.hash
    ? parseInt(window.location.hash.replace('#', ''), 10)
    : WELCOME_VIEW,
  userInfo: {},
  flowId: '',
  hasError: undefined,
  flowType: 'sign_in',
  phone: '',
  otp: '',
  firstName: '',
  lastName: '',
};

// eslint-disable-next-line default-param-last
const patientRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_FIRST_TIME_LOGIN_REQUEST_SUCCEED:
      return {
        ...state,
        flowId: action.data.flow_id,
      };
    case VERIFY_FIRST_TIME_LOGIN_REQUEST_FAILED:
    case GENERATE_OTP_REQUEST_FAILED:
    case VERIFY_OTP_REQUEST_FAILED:
      return {
        ...state,
        hasError: true,
      };
    case GET_LOCATION_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        surgeonOptions: action.locationProviders.providers,
        procedure: action.locationProviders.procedure,
        generalCardiologists: action.locationProviders.generalCardiologists,
        isMfaEnabled: action.locationProviders.isMfaEnabled,
      };
    case GET_SALESFORCE_USER_DATA_REQUEST_SUCCEED:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case GET_FLOW_ID_REQUEST_SUCCEED:
      return {
        ...state,
        flowId: action.data.flow_id,
      };
    case CREATE_PATIENT_REQUEST_SUCCEED:
      return {
        ...state,
        firstName: action.data.first_name,
        lastName: action.data.last_name,
      };
    case SET_FLOW_ID_TO_BLANK:
      return {
        ...state,
        flowId: '',
      };
    case VERIFY_OTP_REQUEST_SUCCEED:
      return {
        ...state,
        hasError: false,
        userInfo: action.payload.user_data,
        phone: action.payload.contact_number,
        fromPasswordPage:
          state.page === EMAIL_VERIFICATION_VIEW ||
          state.page === OTP_VERIFICATION_VIEW,
      };
    case PAGE_CHANGE:
      return {
        ...state,
        page: action.page,
      };
    case SET_FLOW_TYPE:
      return {
        ...state,
        flowType: action.flowType,
      };
    case PATIENT_FORGOT_PASSWORD_REQUEST_SENT:
      return {
        ...state,
        page: action.page,
      };
    case PATIENT_FORGOT_PASSWORD_REQUEST_SUCCEED:
      return {
        ...state,
        flowId: action.payload,
      };
    case PATIENT_FORGOT_PASSWORD_REQUEST_FAILED:
      return {
        ...state,
        page: action.page,
      };
    default:
      return state;
  }
};

export default patientRegisterReducer;

/*
 *
 * PatientRegister Page actions
 *
 */
import {
  getLocationProviders as LocationProvidersApiCall,
  getSalesforceUserData as SalesforceUserInfoApiCall,
} from 'services/patientDetails';
import { showToaster } from 'common/toasterActions';
import { signInUser } from 'common/authThunkActions';
import {
  patientForgotPassword,
  verifyPatient,
  patientSetResetPassword,
  patientResendOtp,
  verifyFirstTimeLogin as verifyFirstTimeLoginCall,
  generateOtp as GenerateOtpApiCall,
  verifyOtp as VerifyOtpApiCall,
  createPatient as CreatePatientApiCall,
  getFlowId as FlowIdApiCall,
} from 'services/patient/authentication';
import * as Constants from './constants';

import {
  GET_LOCATION_PROVIDERS_REQUEST_SENT,
  GET_LOCATION_PROVIDERS_REQUEST_FAILED,
  VERIFY_FIRST_TIME_LOGIN_REQUEST_SENT,
  VERIFY_FIRST_TIME_LOGIN_REQUEST_SUCCEED,
  VERIFY_FIRST_TIME_LOGIN_REQUEST_FAILED,
  GENERATE_OTP_REQUEST_SENT,
  GENERATE_OTP_REQUEST_SUCCEED,
  GENERATE_OTP_REQUEST_FAILED,
  VERIFY_OTP_REQUEST_SENT,
  VERIFY_OTP_REQUEST_SUCCEED,
  VERIFY_OTP_REQUEST_FAILED,
  CREATE_PATIENT_REQUEST_SENT,
  CREATE_PATIENT_REQUEST_SUCCEED,
  CREATE_PATIENT_REQUEST_FAILED,
  GET_FLOW_ID_REQUEST_SENT,
  GET_FLOW_ID_REQUEST_SUCCEED,
  GET_FLOW_ID_REQUEST_FAILED,
  PAGE_CHANGE,
  SET_FLOW_TYPE,
  OTP_VERIFICATION_VIEW,
  GET_SALESFORCE_USER_DATA_REQUEST_SUCCEED,
  GET_SALESFORCE_USER_DATA_REQUEST_FAILED,
  GET_SALESFORCE_USER_DATA_REQUEST_SENT,
  SET_FLOW_ID_TO_BLANK,
  PATIENT_FORGOT_PASSWORD_REQUEST_SENT,
  PATIENT_FORGOT_PASSWORD_REQUEST_SUCCEED,
  PATIENT_FORGOT_PASSWORD_REQUEST_FAILED,
} from './constants';

import history from '../../configureHistory';

export const getLocationProvidersRequestSent = () => ({
  type: GET_LOCATION_PROVIDERS_REQUEST_SENT,
});
export const getLocationProvidersRequestSucceeded = (
  locationProviders,
  procedure,
  generalCardiologists
) => ({
  type: GET_SALESFORCE_USER_DATA_REQUEST_SUCCEED,
  locationProviders,
  procedure,
  generalCardiologists,
});

export const getLocationProvidersRequestFailed = () => ({
  type: GET_LOCATION_PROVIDERS_REQUEST_FAILED,
});

export const getSalesforceUserDataRequestSent = () => ({
  type: GET_SALESFORCE_USER_DATA_REQUEST_SENT,
});

export const getSalesforceUserDataRequestSucceeded = userInfo => ({
  type: GET_SALESFORCE_USER_DATA_REQUEST_SUCCEED,
  userInfo,
});

export const getSalesforceUserDataRequestFailed = () => ({
  type: GET_SALESFORCE_USER_DATA_REQUEST_FAILED,
});

export const verifyFirstTimeLoginRequestSent = () => ({
  type: VERIFY_FIRST_TIME_LOGIN_REQUEST_SENT,
});

export const verifyFirstTimeLoginRequestSucceeded = data => ({
  type: VERIFY_FIRST_TIME_LOGIN_REQUEST_SUCCEED,
  data,
});

export const verifyFirstTimeLoginRequestFailed = data => ({
  type: VERIFY_FIRST_TIME_LOGIN_REQUEST_FAILED,
  data,
});

export const generateOtpRequestSent = () => ({
  type: GENERATE_OTP_REQUEST_SENT,
});

export const generateOtpRequestSucceeded = () => ({
  type: GENERATE_OTP_REQUEST_SUCCEED,
});

export const generateOtpRequestFailed = () => ({
  type: GENERATE_OTP_REQUEST_FAILED,
});

export const verifyOtpRequestSent = () => ({
  type: VERIFY_OTP_REQUEST_SENT,
});

export const verifyOtpRequestSucceeded = data => ({
  type: VERIFY_OTP_REQUEST_SUCCEED,
  payload: data,
});

export const verifyOtpRequestFailed = data => ({
  type: VERIFY_OTP_REQUEST_FAILED,
  data,
});

export const createPatientRequestSent = () => ({
  type: CREATE_PATIENT_REQUEST_SENT,
});

export const createPatientRequestSucceeded = data => ({
  type: CREATE_PATIENT_REQUEST_SUCCEED,
  data,
});

export const createPatientRequestFailed = () => ({
  type: CREATE_PATIENT_REQUEST_FAILED,
});

export const getFlowIdRequestSent = () => ({
  type: GET_FLOW_ID_REQUEST_SENT,
});

export const getFlowIdRequestSucceeded = data => ({
  type: GET_FLOW_ID_REQUEST_SUCCEED,
  data,
});

export const getFlowIdRequestFailed = () => ({
  type: GET_FLOW_ID_REQUEST_FAILED,
});

export const pageChangeSucceeded = page => ({
  type: PAGE_CHANGE,
  page,
});

export const setFlowTypeSucceeded = flowType => ({
  type: SET_FLOW_TYPE,
  flowType,
});

export const setFlowIdToBlank = () => ({
  type: SET_FLOW_ID_TO_BLANK,
});

export const patientForgotPasswordSent = () => ({
  type: PATIENT_FORGOT_PASSWORD_REQUEST_SENT,
});

export const patientForgotPasswordSucceeded = data => ({
  type: PATIENT_FORGOT_PASSWORD_REQUEST_SUCCEED,
  payload: data,
});

export const patientForgotPasswordFailed = () => ({
  type: PATIENT_FORGOT_PASSWORD_REQUEST_FAILED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const pageChange = page => dispatch => {
  dispatch(pageChangeSucceeded(page));
};

export const setFlowType = flowType => dispatch => {
  dispatch(setFlowTypeSucceeded(flowType));
};

export const getLocationProviders =
  (locationCode, procedureCode) => dispatch => {
    dispatch(getLocationProvidersRequestSent());
    LocationProvidersApiCall(locationCode, procedureCode)
      .then(response => {
        dispatch(getLocationProvidersRequestSucceeded(response));
      })
      .catch(error => {
        dispatch(
          showToaster({ message: error.response.data.error, type: 'error' })
        );
      });
  };

export const getSalesforceUserData = uuid => dispatch => {
  dispatch(getSalesforceUserDataRequestSent());
  SalesforceUserInfoApiCall(uuid)
    .then(response => {
      dispatch(getSalesforceUserDataRequestSucceeded(response));
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const generateOtp = (params, nextPage) => dispatch => {
  GenerateOtpApiCall(params)
    .then(response => {
      dispatch(generateOtpRequestSucceeded());
      if (nextPage === OTP_VERIFICATION_VIEW) {
        dispatch(pageChange(nextPage));
      }
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const verifyOtp = (params, nextPage, nextPage2) => dispatch => {
  VerifyOtpApiCall(params)
    .then(response => {
      dispatch(verifyOtpRequestSucceeded(response.data));
      if (response.data.Hgy7jT8GfTb === 'v7UhT6YhGt') {
        history.push({
          pathname: '/patients',
        });
        dispatch(pageChange(Constants.PASSWORD_VERIFICATION_VIEW));
      } else if (response.data.DHvRaJGVi9EWgA === 'xniBfGegwSZmrA') {
        dispatch(pageChange(nextPage2));
      } else {
        dispatch(pageChange(nextPage));
      }
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const createPatient = (params, nextPage) => dispatch => {
  dispatch(createPatientRequestSent());
  CreatePatientApiCall(params)
    .then(response => {
      dispatch(createPatientRequestSucceeded(response.data));
      dispatch(showToaster({ message: response.data.message }));
      dispatch(pageChange(nextPage));
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const getFlowId = (params, nextPage) => dispatch => {
  FlowIdApiCall(params)
    .then(response => {
      dispatch(getFlowIdRequestSucceeded(response.data));
      dispatch(pageChange(nextPage));
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const verifyFirstTimeLogin = params => dispatch => {
  verifyFirstTimeLoginCall(params)
    .then(response => {
      dispatch(verifyFirstTimeLoginRequestSucceeded(response.data));
      if (response.data.message) {
        dispatch(showToaster({ message: response.data.message }));
      }

      if (response.data.DHvRaJGVi9EWgA === 'xniBfGegwSZmrA') {
        dispatch(pageChange(Constants.OTP_VERIFICATION_VIEW));
        dispatch(setFlowType('set_password'));
      } else {
        dispatch(pageChange(Constants.PASSWORD_VERIFICATION_VIEW));
      }
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const patientForgotPasswordRequest = (params, nextPage) => dispatch => {
  dispatch(setFlowType('reset_password'));
  patientForgotPassword(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
      dispatch(patientForgotPasswordSucceeded(response.data.flow_id));
      dispatch(pageChange(nextPage));
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const verifyPatientRequest = (params, nextPage) => dispatch => {
  verifyPatient(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
      dispatch(pageChange(nextPage));
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const patientSetResetPasswordRequest = params => dispatch => {
  patientSetResetPassword(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
      const newParams = {
        contact_number: params.contact_number,
        has_accepted_terms_and_condition:
          params.has_accepted_terms_and_condition,
        password: params.password,
        tnc_url: params.tnc_url,
        flow_type: params.flow_type,
      };

      if (params.flowType != 'sign_up') {
        dispatch(signInUser(newParams));
      }
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const patientResendOtpRequest = params => dispatch => {
  patientResendOtp(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(error => {
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

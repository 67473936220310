import camelcaseKeys from 'change-case-object';
import { showToaster } from 'common/toasterActions';

import {
  getPatientConversations,
  startConversation,
  getConversationMessages,
  postSendMessage,
} from 'services/patientDetails';

import { getProcedureManagers } from 'services/patient/patientDashboard';

import { createConversation } from 'services/patient/patientConversations';
import * as Constants from './constants';

// Get Conversations
const getConversationsRequestSent = () => ({
  type: Constants.GET_CONVERSATIONS_REQUEST_SENT,
});

const getConversationsRequestSucceeded = data => ({
  type: Constants.GET_CONVERSATIONS_REQUEST_SUCCEED,
  data,
});

const getConversationsRequestFailed = () => ({
  type: Constants.GET_CONVERSATIONS_REQUEST_FAILED,
});

export const getConversationsRequest =
  ({ compositeProcedureId, selectedPhaseId, page = 1 }) =>
  dispatch => {
    dispatch(getConversationsRequestSent());
    getPatientConversations(compositeProcedureId, selectedPhaseId, page)
      .then(response => {
        dispatch(getConversationsRequestSucceeded(response.data));
      })
      .catch(() => {
        dispatch(getConversationsRequestFailed());
      });
  };

// Create a conversations
const createConversationsRequestSent = () => ({
  type: Constants.CREATE_CONVERSATIONS_REQUEST_SENT,
});

const createConversationsRequestSucceeded = () => ({
  type: Constants.CREATE_CONVERSATIONS_REQUEST_SUCCEED,
});

const createConversationsRequestFailed = () => ({
  type: Constants.CREATE_CONVERSATIONS_REQUEST_FAILED,
});

export const createConversationsRequest = params => dispatch => {
  dispatch(createConversationsRequestSent());
  createConversation(params)
    .then(response => {
      dispatch(createConversationsRequestSucceeded());
      dispatch(showToaster({ message: 'Message Sent successfully.' }));
    })
    .catch(() => {
      dispatch(createConversationsRequestFailed());
    });
};

// Get Messages
const getMessagesRequestSent = () => ({
  type: Constants.GET_CONVERSATION_MESSAGES_REQUEST_SENT,
});

const getMessagesRequestSucceed = data => ({
  type: Constants.GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED,
  data,
});

const getMessagesRequestFailed = () => ({
  type: Constants.GET_CONVERSATION_MESSAGES_REQUEST_FAILED,
});

export const toggleMessageSentFlag = () => ({
  type: Constants.ALTER_SEND_MESSAGE_FLAG,
});

export const getConversationMessagesRequest = conversationId => dispatch => {
  dispatch(getMessagesRequestSent());
  getConversationMessages(conversationId)
    .then(response => {
      dispatch(getMessagesRequestSucceed(response.data));
      //     // dispatch(showToaster({ message: "Message Sent successfully." }));
    })
    .catch(() => {
      dispatch(getMessagesRequestFailed());
    });
};

// Send Messages
const sendMessagesRequestSent = () => ({
  type: Constants.SEND_MESSAGE_REQUEST_SENT,
});

const sendMessagesRequestSucceed = message => ({
  type: Constants.SEND_MESSAGE_REQUEST_SUCCEED,
  message,
});

const sendMessagesRequestFailed = () => ({
  type: Constants.SEND_MESSAGE_REQUEST_FAILED,
});

export const sendMessageRequest =
  ({ conversationId, message, attachments }) =>
  dispatch => {
    dispatch(sendMessagesRequestSent());
    postSendMessage(conversationId, message, attachments)
      .then(response => {
        const data = camelcaseKeys.camelCase(response.data);
        dispatch(sendMessagesRequestSucceed(data));
        dispatch(
          showToaster({ message: 'Message Sent Successfully', type: 'success' })
        );
        dispatch(getConversationMessagesRequest(conversationId));
      })
      .catch(() => {
        dispatch(sendMessagesRequestFailed());
      });
  };

// Get Procedure Managers
const getProcedureManagersRequestSucceed = data => ({
  type: Constants.GET_PROCEDURE_MANAGERS_REQUEST_SUCCEED,
  data,
});

export const getProcedureManagersRequest = () => dispatch =>
  getProcedureManagers().then(response => {
    dispatch(getProcedureManagersRequestSucceed(response.data));
  });

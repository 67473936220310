/*
 *
 * NavigationBar Constants
 *
 */

export const PERFORMER_FILTER_CHANGE =
  'app/NavBar/SelectedPerformer/PERFORMER_FILTER_CHANGE';
export const RESET_PERFORMER_FILTER =
  'app/NavBar/SelectedPerformer/RESET_PERFORMER_FILTER';

export const GET_PATIENTS_FOR_CAREGIVER_REQUEST_SENT =
  'app/caregiver/GET_PATIENTS_FOR_CAREGIVERT_REQUEST_SENT';

export const GET_PATIENTS_FOR_CAREGIVER_REQUEST_SUCCEEDED =
  'app/caregiver/GET_PATIENTS_FOR_CAREGIVERT_REQUEST_SUCCEEDED';

export const GET_PATIENTS_FOR_CAREGIVER_REQUEST_FAILED =
  'app/caregiver/GET_PATIENTS_FOR_CAREGIVER_REQUEST_FAILED';

export const POST_START_CAREGIVER_MASQUERADING_REQUEST_SENT =
  'app/caregiver/POST_START_CAREGIVER_MASQUERADING_REQUEST_SENT';

export const POST_START_CAREGIVER_MASQUERADING_REQUEST_SUCCEEDED =
  'app/caregiver/POST_START_CAREGIVER_MASQUERADING_REQUEST_SUCCEEDED';

export const POST_START_CAREGIVER_MASQUERADING_REQUEST_FAILED =
  'app/caregiver/POST_START_CAREGIVER_MASQUERADINGREQUEST_FAILED';

export const CLEAR_MASQUERADING_FLAGS =
  'app/caregiver/CLEAR_MASQUERADING_FLAGS';

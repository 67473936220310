import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import UserId from 'components/UserId';
import AvatarImage from 'assets/images/default_patient.png';
import './selectPatientCard.scss';

class SelectPatientCard extends PureComponent {
  onSelectPatient = (event, patientId) => {
    this.props.onPatientSelect(patientId);
  };

  render() {
    const { item, currentlyViewing } = this.props;
    const profilePhotoUrl = item.profilePhotoUrl || AvatarImage;
    return (
      <div
        role={!currentlyViewing ? 'button' : ''}
        tabIndex=''
        className={`row select-patient-card ${
          currentlyViewing ? 'disabled' : ''
        } ${this.props.isShownModaly ? 'modaly' : ''}`}
        onClick={
          !currentlyViewing
            ? e => {
                this.onSelectPatient(e, item.id);
              }
            : null
        }
      >
        <div className='col-xs-1 profile-cell hidden-xs'>
          <img src={profilePhotoUrl} alt='' />
        </div>
        <div className='col-xs-9 col-sm-8 col-md-8 col-lg-8 patient-info-container'>
          <div className=''>
            <UserId id={item.id} />
          </div>
          <div className=''>{`${item.firstName} ${item.lastName}`}</div>
          <div className=''>{item.email}</div>
          {currentlyViewing && (
            <div className='currently-viewing'>Currently Viewing</div>
          )}
        </div>
        {currentlyViewing && (
          <div className='col-sm-1 col-xs-1 no-padding-left primary-text-color float-right tick-container'>
            <span className='icon icon-font-a-tick float-right'></span>
          </div>
        )}
      </div>
    );
  }
}

SelectPatientCard.propTypes = {
  item: PropTypes.object.isRequired,
  onPatientSelect: PropTypes.func.isRequired,
  currentlyViewing: PropTypes.bool,
  isShownModaly: PropTypes.bool,
};

SelectPatientCard.defaultProps = {
  currentlyViewing: false,
  isShownModaly: false,
};

export default SelectPatientCard;

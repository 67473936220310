import defaultAxios from 'axios';
import { BASE_AUTH_URL, API_URL } from 'envConstants';
import { nonMasqueradingInstance } from './base';

export const registerUserService = userRegistrationDetails => {
  const { email, password, passwordConfirmation, firstName, lastName } =
    userRegistrationDetails;

  const data = {
    email,
    password,
    password_confirmation: passwordConfirmation,
    first_name: firstName,
    last_name: lastName,
  };

  return defaultAxios.post(BASE_AUTH_URL, data);
};

export const signInUserService = userCredentials =>
  defaultAxios.post(`${BASE_AUTH_URL}/sign_in`, userCredentials);

export const generateOtpUserService = userCredentials =>
  defaultAxios.post(`${API_URL}/user_verifications`, userCredentials);

export const signOutService = () =>
  nonMasqueradingInstance.delete(`${BASE_AUTH_URL}/sign_out`);

export const getTermsAndConditionUrlService = params =>
  defaultAxios.get(`${API_URL}/logins/terms_and_condition_url`, { params });

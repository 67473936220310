import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { dateTimeStringToMMSDateFormat } from '../../utils/dateUtils';

class NavNotificationCard extends React.PureComponent {
  isNotificationClickable = notification =>
    (notification &&
      notification.data &&
      [
        'open_resource_details',
        'resource_educational',
        'call',
        'task_home',
        'open_resource',
        'faqs',
        'event',
        'telehealth_join_call',
        'survey',
        'url',
      ].includes(notification.data.linkEntityType)) ||
    notification.data.taskId ||
    notification.data.eventId;

  onNotificationClicked(notification) {
    if (notification && this.isNotificationClickable(notification)) {
      if (notification.data.linkEntityType === 'resource_educational') {
        this.props.history.push(
          `/mydashboard/${notification.compositeProcedure}/resources/education/detail/${notification.data.linkEntityIdentifier}`
        );
      } else if (notification.data.linkEntityType === 'open_resource_details') {
        this.props.history.push(
          `/mydashboard/${notification.compositeProcedure}/resources/education/detail/${notification.data.linkEntityIdentifier}`
        );
      } else if (notification.data.eventId > 0) {
        if (notification.data.isSurveyEvent) {
          this.props.history.push(
            `/surveys/${notification.compositeProcedure}/feedback/${notification.data.eventId}`
          );
        } else {
          this.props.history.push(
            `/mydashboard/${notification.compositeProcedure}/procedures/appointments`
          );
        }
      } else if (
        notification.data.taskId > 0 &&
        notification.compositeProcedure
      ) {
        this.props.history.push(
          `/mydashboard/${notification.compositeProcedure}/tasks/${notification.data.taskId}`
        );
      } else if (notification.data.linkEntityType === 'task_home') {
        this.props.history.push(
          `/mydashboard/${notification.compositeProcedure}/tasks/tasks`
        );
      } else if (notification.data.linkEntityType === 'faqs') {
        this.props.history.push(
          `/mydashboard/${notification.compositeProcedure}/resources/questions/faqs`
        );
      } else if (
        notification.data.linkEntityType === 'event' ||
        notification.data.linkEntityType === 'telehealth_join_call'
      ) {
        this.props.history.push(
          `/mydashboard/${notification.compositeProcedure}/procedures`
        );
      } else if (notification.data.linkEntityType === 'url') {
        this.props.history.push(`${notification.data.linkEntityIdentifier}`);
      } else if (notification.data.linkEntityType === 'survey') {
        if (parseInt(notification.data.linkEntityIdentifier, 10) === 0) {
          this.props.history.push(
            `/mydashboard/${notification.compositeProcedure}/tasks/surveys`
          );
        } else {
          this.props.history.push(
            `/mydashboard/${notification.compositeProcedure}/feedback/${notification.data.linkEntityIdentifier}`
          );
        }
      }
    }
  }
  render() {
    return (
      <div
        className={`nav-notification-card ${
          this.props.notification.readOn == null ? 'unread' : ''
        }`}
        data-index={this.props.index}
        onClick={() => this.onNotificationClicked(this.props.notification)}
      >
        {this.props.notification.data.linkEntityType === 'open_resource' ? (
          <a
            href={this.props.notification.data.linkEntityIdentifier}
            target={'_blank'}
            className='content no-underline'
            dangerouslySetInnerHTML={{
              __html: this.props.notification.subject
                ? `${this.props.notification.subject} - ${this.props.notification.body}`
                : this.props.notification.body,
            }}
          ></a>
        ) : (
          <div
            className='content'
            dangerouslySetInnerHTML={{
              __html: this.props.notification.subject
                ? `${this.props.notification.subject} - ${this.props.notification.body}`
                : this.props.notification.body,
            }}
          ></div>
        )}
        <div className='timestamp'>
          {dateTimeStringToMMSDateFormat(this.props.notification.sentTime)}
        </div>
      </div>
    );
  }
}

NavNotificationCard.propTypes = {
  notification: PropTypes.object,
  index: PropTypes.any,
  history: PropTypes.object,
};

export default withRouter(NavNotificationCard);

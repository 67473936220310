import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import NextButton from 'components/Authorization/NextButton';
import HelpBlock from 'components/HelpBlock';
import SignupInput from './SignupInput';

const NameView = props => {
  const { errors, firstName, lastName, email, zipCode, onNext, onChange } =
    props;

  const userData = useSelector(state => state.patientRegister.userInfo);

  const inputFields = [
    {
      name: 'firstName',
      className: `form-control name-input ${
        errors.firstName !== undefined ? 'single-select__control' : ''
      } `,
      value: firstName,
      label: 'First Name',
    },
    {
      name: 'lastName',
      className: `form-control name-input ${
        errors.lastName !== undefined ? 'single-select__control' : ''
      } `,
      value: lastName,
      label: 'Last Name',
    },
    {
      name: 'email',
      className: `form-control name-input ${
        errors.email !== undefined ? 'single-select__control' : ''
      } `,
      value: email,
      label: 'Email Address',
    },
    {
      name: 'zipCode',
      className: `form-control name-input ${
        errors.zipCode !== undefined ? 'single-select__control' : ''
      } `,
      value: zipCode,
      label: 'Zip Code',
    },
  ];

  return (
    <div className='child-view-container name-view'>
      <h2 className='input-heading'>Let&apos;s get you set up</h2>
      <p className='input-text'>
        We need the following info to create your account
      </p>
      {userData.firstName ||
      userData.lastName ||
      userData.email ||
      userData.zipCode ? (
        <p className='auto-populate-msg'>
          Your personal details were pre-filled from the online quiz. You can
          change it as needed.
        </p>
      ) : (
        ''
      )}
      <div className='name-view-input-container'>
        {inputFields.map(input => (
          <div
            className={`form-group ${
              errors[input.name] !== undefined ? 'has-error' : ''
            }`}
            key={input.name}
          >
            <SignupInput
              name={input.name}
              onChange={onChange}
              onNext={onNext}
              className={input.className}
              placeholder={input.placeholder}
              value={input.value}
              label={input.label}
            />
            <HelpBlock value={errors[input.name]} />
          </div>
        ))}
      </div>
      <NextButton
        onNext={onNext}
        text='Next : Set Password'
        enable={
          firstName !== '' && lastName !== '' && email !== '' && zipCode !== ''
        }
      />
    </div>
  );
};

NameView.propTypes = {
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  zipCode: PropTypes.string,
};

NameView.defaultProps = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  zipCode: PropTypes.string,
};

export default NameView;

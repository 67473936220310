export const FETCH_TRADITIONAL_SITE_USER_INFO =
  '/traditional_site/traditional_site_user_info';

export const GET_LOGGED_IN_STATUS_CHECK =
  '/traditional_site/get_logged_in_status';

export const FETCH_PATIENT_DETAILS = '/traditional_site/get_patient_details/';

export const ARCHIVE_PATIENT = '/traditional_site/archive_patient';

export const FETCH_TRADITIONAL_SITE_PATIENTS =
  '/traditional_site/get_traditional_sites_patients_list';

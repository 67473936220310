import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';

import NextButton from 'components/Authorization/NextButton';
import { useSelector, useDispatch } from 'react-redux';
import { getProcedureModifiersRequest } from 'common/actions';
import './patientRegister.scss';
import InsNotSupported from 'assets/images/ins_not_supported_avatar.png';

const TreatmentOptionsView = props => {
  const dispatch = useDispatch();
  const { onNext, diagnosedDisease, condition } = props;
  const [selectedOption, setSelectedOption] = useState('');
  const [showNotSupportedModal, setShowNotSupportedModal] = useState(false);

  const procedureModifiers = useSelector(
    state => state.getProcedureModifiers.procedureModifiers
  );

  useEffect(() => {
    dispatch(getProcedureModifiersRequest({ modifier_type: 'Indication' }));
    setSelectedOption(diagnosedDisease);
  }, []);

  useEffect(() => {
    procedureModifiers.forEach(modifier => {
      if (
        condition &&
        diagnosedDisease === '' &&
        condition === modifier.modifierIdentifier
      ) {
        setSelectedOption(modifier.modifierIdentifier);
      } else if (modifier.isDefault && diagnosedDisease === '' && !condition) {
        setSelectedOption(modifier.modifierIdentifier);
      }
    });
  }, [procedureModifiers, condition]);

  useEffect(() => {
    procedureModifiers.forEach(modifier => {
      if (
        modifier.modifierIdentifier === selectedOption &&
        modifier.isNotSupported
      ) {
        setShowNotSupportedModal(true);
      }
    });
  }, [selectedOption]);

  const handleClick = option => {
    setSelectedOption(option);
  };

  const handleCloseNotSupportedModal = () => {
    setSelectedOption('');
    setShowNotSupportedModal(false);
  };

  return (
    <div className='child-view-container treatment-options-view'>
      <h2 className='input-heading'>Welcome</h2>
      <p className='input-text'>
        What are you seeking treatment options for?
        <sup className='mandatory-field'>*</sup>
      </p>
      {condition ? (
        <p className='auto-populate-msg'>
          Your option was auto-selected from the online quiz.
        </p>
      ) : (
        ''
      )}
      <div className='treatment-container-outer'>
        <div className='treatment-container-inner'>
          {procedureModifiers.map(elm => {
            return (
              <div
                key={elm.modifierIdentifier}
                className={`treatment-option ${
                  selectedOption === elm.modifierIdentifier
                    ? 'selected-option'
                    : ''
                }`}
                onClick={() =>
                  handleClick(elm.modifierIdentifier, elm.isNotSupported)
                }
                aria-hidden='true'
              >
                {elm.displayName}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <NextButton
          onNext={() => onNext(selectedOption)}
          text='Next : Personal Details'
          enable={selectedOption !== ''}
        />
      </div>
      <Modal
        show={showNotSupportedModal}
        onHide={handleCloseNotSupportedModal}
        aria-labelledby='contained-modal-title'
        className='patient-register-container'
        style={{
          marginTop:
            window.innerWidth <= '375px' && window.innerHeight <= '667px'
              ? '25%'
              : '0%',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title'>Contact Us</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='delete-custom-filter-modal-body'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                height='155px'
                width='169px'
                alt='Not-Supported Display'
                src={InsNotSupported}
              />
            </div>
          </div>
          <div className='not-supported-body'>
            Thank you for your interest in the incision-free Focused Ultrasound
            treatment to help address your <br />
            Tremor-dominant Parkinson’s Disease.
          </div>
          <div className='not-supported-body-text'>
            Please call an INSIGHTEC Educator at 1-888-343-0850 to learn more
            about the treatment and discuss if you might be a candidate.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              className='btn btn-primary'
              onClick={handleCloseNotSupportedModal}
              type='submit'
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

TreatmentOptionsView.propTypes = {
  onNext: PropTypes.func.isRequired,
  diagnosedDisease: PropTypes.string.isRequired,
  condition: PropTypes.string,
};

TreatmentOptionsView.defaultProps = {
  condition: null,
};

export default TreatmentOptionsView;

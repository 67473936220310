import React, { PureComponent } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SelectPatient from '../SelectPatient';

import './switchPatient.scss';

class SwitchPatient extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isMasqueradingSuccessful) {
      this.setState({
        showModal: false,
      });
    }
  }

  onModalClose = () => {
    this.setState({
      showModal: false,
    });
  };

  onSwitchPatientClicked = () => {
    this.setState({
      showModal: true,
    });
  };

  renderSwitchPatientView = () => (
    <div>
      <span
        className='primary-text-color icon icon-font-a-switch-patient'
        onClick={this.onSwitchPatientClicked}
      />
      {this.state.showModal && (
        <Modal
          show={this.state.showModal}
          onHide={this.onModalClose}
          container={document.body}
          autoFocus
          // backdrop="static"
          aria-labelledby='contained-modal-title'
          className='primary-modal'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title'>Switch Patient</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='switch-patient-modal'>
              <SelectPatient isShownModaly />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );

  render() {
    return this.renderSwitchPatientView()
  }
}

SwitchPatient.propTypes = {
  isMasqueradingSuccessful: PropTypes.bool,
};
const mapStateToProps = state => ({
  isMasqueradingSuccessful: state.caregiver
    ? state.caregiver.isMasqueradingSuccessful
    : false,
});

const mapDispatchToProps = () => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SwitchPatient);

import axios from 'axios';
import { nonMasqueradingInstance } from '../base';
import {
  SIGNUP_URL,
  GET_VERIFICATION_CODE,
  VERIFY_CODE,
  PATIENT_FORGOT_PASSWORD,
  VERIFY_PATIENT,
  PATIENT_SET_RESET_PASSWORD,
  PATIENT_RESEND_OTP,
  VERIFY_FIRST_TIME_LOGIN,
  GENERATE_OTP,
  VERIFY_OTP,
  CREATE_PATIENT,
  GET_FLOW_ID,
} from '../constants';

export const registerNewUser = params => axios.post(SIGNUP_URL, params);

export const getVerificationCode = params =>
  nonMasqueradingInstance.post(GET_VERIFICATION_CODE, params);

export const verifyCode = params =>
  nonMasqueradingInstance.post(VERIFY_CODE, params);

export const patientForgotPassword = params =>
  axios.post(PATIENT_FORGOT_PASSWORD, params);

export const verifyPatient = params => axios.post(VERIFY_PATIENT, params);

export const patientSetResetPassword = params =>
  axios.post(PATIENT_SET_RESET_PASSWORD, params);

export const patientResendOtp = params =>
  axios.post(PATIENT_RESEND_OTP, params);

export const verifyFirstTimeLogin = params =>
  axios.post(VERIFY_FIRST_TIME_LOGIN, params);

export const getFlowId = params => axios.get(GET_FLOW_ID, { params });

export const generateOtp = params => {
  const newParams = { ...params };
  if (newParams.contact_number) {
    newParams.contact_number = newParams.contact_number
      .replace(/ /g, '')
      .replace('+1', '');
  }
  return axios.post(GENERATE_OTP, newParams);
};

export const verifyOtp = params => {
  const newParams = { ...params };
  if (newParams.contact_number) {
    newParams.contact_number = newParams.contact_number
      .replace(/ /g, '')
      .replace('+1', '');
  }
  return axios.post(VERIFY_OTP, newParams);
};

export const createPatient = patient => {
  const params = {
    first_name: patient.firstName,
    last_name: patient.lastName,
    email: patient.email,
    contact_number: patient.phone.replace(/ /g, '').replace('+1', ''),
    procedure_modifier_id: patient.diagnosedDisease,
    zip_code: patient.zipCode,
    contact_number_verification_code: patient.phoneOtp,
    flow_type: patient.flowType,
    flow_id: patient.flowId,
    password: patient.password,
    password_confirmation: patient.password,
    signup_platform: patient.signupPlatform,
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  return axios.post(CREATE_PATIENT, params, { headers });
};

import React from 'react';
import PropTypes from 'prop-types';
import PreLoginFooter from 'components/PreLoginFooter';
import HeroThemeSelector from './HeroThemeSelector';
import ProgressBar from './ProgressBar';

const SplitScreenWrapper = props => {
  const {
    page,
    handleBack,
    children,
    hasProgressBar,
    heroImageNo,
    flowType,
    heroTheme,
  } = props;

  const themeSelected = heroTheme ? heroTheme : 'patientTheme';

  // const  'patientTheme'

  return (
    <div className='signup-conatiner'>
      <HeroThemeSelector
        handleBack={handleBack}
        heroImageNo={heroImageNo}
        flowType={flowType}
        themeSelected={themeSelected}
      />
      <div className='child-container'>
        <div className='non-footer-container'>
          {hasProgressBar ? (
            <div className='progress-bar-container'>
              <ProgressBar page={page} />
            </div>
          ) : null}
          <div className='input-container text-align-center'>{children}</div>
        </div>
        <PreLoginFooter />
      </div>
    </div>
  );
};

SplitScreenWrapper.propTypes = {
  page: PropTypes.number.isRequired,
  handleBack: PropTypes.func,
  children: PropTypes.element.isRequired,
  hasProgressBar: PropTypes.bool,
  flowType: PropTypes.string,
};

SplitScreenWrapper.defaultProps = {
  hasProgressBar: false,
  handleBack: null,
  flowType: 'sign_in',
};

export default SplitScreenWrapper;

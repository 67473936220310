import React, { useState } from 'react';
import HelpBlock from 'components/HelpBlock';
import './search_bar.scss';
import WatchClickOutside from 'patientApp/components/WatchClickOutside';

const SearchBar = props => {
  const [errors, setErrors] = useState({});

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      props.onSearchSubmit();
    }
  };

  const handleReset = () => {
    setErrors({});
  };

  return (
    <div
      className={`navbar-form lookup ${
        errors.searchValue !== undefined ? 'has-error' : ''
      }`}
    >
      <WatchClickOutside onClickOutside={handleReset}>
        <div style={{ display: 'flex' }}>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              value={props.searchTerm}
              onKeyPress={handleKeyPress}
              onChange={e => {e.preventDefault();props.setSearchTerm(e.target.value)}}
              placeholder={props.placeholder}
              autoComplete='new-password'
            />
            <HelpBlock value={errors.searchValue} />
          </div>
          <button
            className='btn btn-primary'
            onClick={props.onSearchSubmit}
          >
            Search
          </button>
        </div>
      </WatchClickOutside>
    </div>
  );
};

export default SearchBar;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Proptypes from 'prop-types';
import Media from 'react-media';
import { getCurrentUser, isLoggedIn } from 'services/storage';
import UnSupportedPageView from 'containers/UnSupportedPageView';
import {
  defaultLandingPageRouteGenerator,
  isValidatedUsersInitialProcessCompleted,
} from 'common/authGuard';
import {
  USER_TYPE_PROVIDER,
  USER_TYPE_PATIENT_ADVOCATE,
  USER_TYPE_PATIENT,
  USER_TYPE_CAREGIVER,
  USER_TYPE_TRADITIONAL_SITE_USER,
} from '../../constants';

/**
 * AuthenticatedRoute is a base component which checks for an authenticated user.
 * @param {allowedRoles} - Array of user types. checks whether the signedIn user is authorized to visit the route.
 *
 * example : <AuthenticatedRoute path="/" allowedRoles={["Provider"]}/>
 * @class AuthenticatedRoute
 * @extends {React.Component}
 */
class AuthenticatedRoute extends React.Component {
  isSignedIn = isLoggedIn();

  currentUser = getCurrentUser();

  userType = this.currentUser !== null ? this.currentUser.type : '';

  isAuthorized = () => {
    if (this.props.allowedRoles) {
      return this.props.allowedRoles.some(
        item => item.toLowerCase() === this.userType.toLowerCase()
      );
    }
    return true;
  };

  renderAuthenticatedComponents = (Container, props) => {
    if (
      this.isAuthorized() &&
      isValidatedUsersInitialProcessCompleted(props.location)
    ) {
      return <Container {...props} />;
    }

    const redirectRoute = defaultLandingPageRouteGenerator(props.location);
    return (
      <React.Fragment>
        <Redirect to={redirectRoute} replace />
      </React.Fragment>
    );
  };

  render() {
    const { component, ...rest } = this.props;
    const Container = component;
    return (
      <Media query={{ maxWidth: 767 }}>
        {screenIsSmall =>
          screenIsSmall ? (
            <Route
              {...rest}
              render={props => {
                // if (this.isSignedIn && this.userType) {
                //   if (
                //     this.userType.toLowerCase() !== USER_TYPE_PATIENT.toLowerCase() &&
                //     this.userType.toLowerCase() !== USER_TYPE_CAREGIVER.toLowerCase()
                //   ) {
                //     return (<UnSupportedPageView />);
                //   }
                // }
                return this.renderAuthenticatedComponents(Container, props);
              }}
            />
          ) : (
            <Route
              {...rest}
              render={props =>
                this.renderAuthenticatedComponents(Container, props)
              }
            />
          )
        }
      </Media>
    );
  }
}

AuthenticatedRoute.propTypes = {
  component: Proptypes.any,
  allowedRoles: Proptypes.array,
};

export default AuthenticatedRoute;

export const MedStaffAuthorizedRoute = props => (
  <AuthenticatedRoute
    allowedRoles={[USER_TYPE_PATIENT_ADVOCATE, USER_TYPE_PROVIDER]}
    {...props}
  />
);

export const PatientAuthorizedRoute = props => (
  <AuthenticatedRoute
    allowedRoles={[USER_TYPE_PATIENT, USER_TYPE_CAREGIVER]}
    {...props}
  />
);

export const TraditionalSiteUserAuthorizedRoute = props => (
  <AuthenticatedRoute
    allowedRoles={[USER_TYPE_TRADITIONAL_SITE_USER]}
    {...props}
  />
);
export const SuperUserAuthorizedRoute = props => {
  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return <Redirect to={{ pathname: '/signin' }} />;
  }

  // if (currentUser.isSupportUser && !currentUser.hasSuperUserPrivileges) {
  //   return (<UnSupportedPageView />);
  // }
  return (
    <AuthenticatedRoute
      allowedRoles={[USER_TYPE_PATIENT_ADVOCATE, USER_TYPE_PROVIDER]}
      {...props}
    />
  );
};

import React, { useState, useEffect, useMemo } from 'react';

export const BackButton = props => {
  return (
    <div
      className='back-btn'
      role='button'
      onClick={() => {
        props.goBack();
      }}
    >
      <span className='icon icon-font-a-left-chevron back-icon' />
      <span className='btn-text'>Back</span>
    </div>
  );
};

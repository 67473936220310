import React from 'react';
import PropTypes from 'prop-types';
import { DEEP_LINK_URL } from 'containers/Authorization/constants';
import { isMobile } from 'utils/common';

import './patientRegister.scss';
import InsBgLogo from 'assets/images/patient-signup-landing-img.png';

const WelcomeView = props => {
  const { onNext } = props;

  const handleNext = () => {
    if (isMobile || window.innerWidth < 1200) {
      window.location.replace(DEEP_LINK_URL);
    } else {
      onNext();
    }
  };

  return (
    <>
      <div className='welcome-section text-center'>
        <img
          src={InsBgLogo}
          className='secondary-logo'
          alt='Ins'
          display='block'
        />
      </div>
      <div className='col-xs-12'>
        <div className='content-text text-align-left'>
          <span>
            <div className='h1'>Looking for education and tremor relief? </div>
          </span>
          <div className='main-text'>We have an app for that.</div>
          <br />
          <div className='intro-text'>
            We’d like to introduce you to our new app, <b>INSIGHTEC Connect</b>.
            It’s made to keep you informed and determine your eligibility for
            our latest tremor treatments.
          </div>
        </div>
        <div className='button-container text-align-center'>
          <button
            className='join-btn btn btn-primary'
            onClick={handleNext}
            type='button'
          >
            Sign Up for INSIGHTEC Connect
          </button>
        </div>
        <br />
      </div>
    </>
  );
};

WelcomeView.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default WelcomeView;

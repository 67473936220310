import React from 'react';

import Modal from 'react-bootstrap/lib/Modal';
import './preloginFooter.scss';
import strings from '../../strings';

class PreLoginFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showContactviewModal: false,
    };
  }

  onContactUsClick = () => {
    this.setState({
      showContactviewModal: true,
    });
  };

  hideContactviewModal = () => {
    this.setState({
      showContactviewModal: false,
    });
  };

  render() {
    return (
      <div className='col-xs-12 pre-login-footer-container'>
        <div
          className='contact-us cursor-pointer'
          onClick={this.onContactUsClick}
        >
          Need help?
        </div>
        <div className='copy-right-container'>
          <div className='copy-right-label'>
            &copy; Higgs Boson Inc, All Rights Reserved
          </div>
        </div>

        <Modal
          show={this.state.showContactviewModal}
          onHide={this.hideContactviewModal}
          container={document.body}
          aria-labelledby='contained-modal-title'
          className='primary-modal'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title'>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='footer-content-container'>
              <div className='text'>
                Feel free to contact us via email or phone number.
              </div>
              <div className='text'>
                We would be happy to address your queries.
              </div>
              <div className='phone-container'>
                <i className='icon icon-font-a-contact'></i>
                <span className='phone'>{strings.CONTACT_FOR_QUERIES}</span>
              </div>
              <div className='email-container'>
                <i className='icon icon-font-a-email'></i>
                <span className='email'>support@insightecconnect.com</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PreLoginFooter;

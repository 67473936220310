import camelcaseKeys from 'change-case-object';
import * as Constants from './constants';

const getSearchCategoryListInitialState = {
  categoryList: {},
};

const getSearchCategoryListReducer = (
  // eslint-disable-next-line default-param-last
  state = getSearchCategoryListInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED:
      return {
        ...state,
        categoryList: camelcaseKeys.snakeCase(action.data.categoryList),
      };
    default:
      return state;
  }
};

export default getSearchCategoryListReducer;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import validate, { clearErrorsForField } from 'common/validator';

import { Modal } from 'react-bootstrap/lib';
import AddAttachmentModal from 'containers/AddAttachmentModal';
import HelpBlock from 'components/HelpBlock';
import Select from 'components/SelectV2';

const validationSchema = {
  fields: ['started_with_id', 'subject', 'message'],
  rules: {
    started_with_id: [{ rule: 'isRequired', message: 'User is required' }],
    subject: [{ rule: 'isRequired', message: 'Subject is required' }],
    message: [{ rule: 'isRequired', message: 'Message is required' }],
  },
};

const defaultFormValues = {
  started_with_id: null,
  subject: '',
  message: '',
};

const defaultAttachmentsState = {
  showModal: false,
  showFiles: false,
  files: [],
};

const CreateConversationModal = props => {
  const {
    show,
    onHide,
    compositeProcedureId,
    procedureManagers,
    createConversation,
  } = props;

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [errors, setFormErrors] = useState({});
  const [attachments, setAttachements] = useState(defaultAttachmentsState);

  const handleFieldChange = ({ name, value }) => {
    const newFormValues = { ...formValues };
    newFormValues[name] = value;
    setFormValues(newFormValues);

    setFormErrors(clearErrorsForField(errors, name));
  };

  const onAttachmentsAdded = files =>
    setAttachements({ ...attachments, showModal: false, files });

  const onAttachmentRemoved = files => console.log(files, 'REMOVED FILES');

  const onAddAttachmentsClick = () =>
    setAttachements({ ...attachments, showModal: true, showFiles: true });

  const clearStateAndHide = () => {
    setFormErrors({});
    setFormValues(defaultFormValues);
    setAttachements(defaultAttachmentsState);

    onHide();
  };

  const onSendMessageClick = () =>
    validate(validationSchema, formValues, setFormErrors, onValidationSuccess);

  const onValidationSuccess = () => {
    setFormErrors({});

    const payload = {
      user_procedure_id: compositeProcedureId,
      subject: formValues.subject,
      message_body: formValues.message,
      started_with_id: formValues.started_with_id,
    };

    createConversation(payload);
    clearStateAndHide();
  };

  const convertUserToOption = user => ({
    label: `${user.fullName} - ${user.type}`,
    value: user.id,
  });

  const toUserOptions = useMemo(() => {
    return procedureManagers.map(convertUserToOption);
  }, [procedureManagers]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      container={document.body}
      autoFocus
      aria-labelledby='contained-modal-title'
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title'>Send a message</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='conversation-modal'>
          <div className='start-conversation-modal'>
            <div
              className={`form-group ${
                errors.started_with_id ? 'has-error' : ''
              }`}
            >
              <label htmlFor='reason'>To</label>
              <Select
                id='started_with_id'
                placeholder='To'
                onChange={option =>
                  handleFieldChange({
                    name: 'started_with_id',
                    value: option.value,
                  })
                }
                options={toUserOptions}
              />
              <HelpBlock value={errors.started_with_id} />
            </div>

            <div className={`form-group ${errors.subject ? 'has-error' : ''}`}>
              <label htmlFor='reason'>Subject</label>
              <input
                type='text'
                name='subject'
                id='subject'
                className='form-control'
                placeholder='Subject'
                value={formValues.subject}
                onChange={event => handleFieldChange(event.target)}
              />
              <HelpBlock value={errors.subject} />
            </div>

            <div className={`form-group ${errors.message ? 'has-error' : ''}`}>
              <label htmlFor='reason'>Message</label>
              <textarea
                name='message'
                id='message'
                className='form-control'
                placeholder='Type a message here'
                value={formValues.message}
                onChange={event => handleFieldChange(event.target)}
              />
              <HelpBlock value={errors.message} />
            </div>

            <AddAttachmentModal
              showModal={attachments.showModal}
              onAttachmentsAdded={onAttachmentsAdded}
              onAttachmentRemoved={onAttachmentRemoved}
              showAddedAttachments={attachments.showFiles}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className='float-right action-button-container'>
          <button className='btn btn-default' onClick={clearStateAndHide}>
            Back
          </button>
          <button
            className='btn btn-default btn-attachment add-attachment-button'
            onClick={onAddAttachmentsClick}
          >
            Add Attachment
          </button>
          <button
            className='btn btn-primary float-right'
            onClick={onSendMessageClick}
          >
            Send
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

CreateConversationModal.propTypes = {
  open: PropTypes.array,
  onHide: PropTypes.func,
  procedureManagers: PropTypes.array,
  createConversation: PropTypes.func,
  compositeProcedureId: PropTypes.number,
};

export default CreateConversationModal;

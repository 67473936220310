// App.js
import React from 'react';
import './dashboard.scss';
import { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTraditionalSiteUserDetails,
  fetchAllPatients,
  getLoggedInStatusCheck,
} from 'traditionalSiteApp/common/actions';
import { getPatientSearch } from 'containers/PatientSearch/actions';
import Lookup from 'components/Lookup';
import { useCollapsingSideBarViewModel } from 'traditionalSiteApp/components/SideBar/viewModel';
import Emptyview from 'components/Emptyview';
import noOrdersImage from 'assets/images/hero_image 1.png';
import TraditionalSiteHeader from 'traditionalSiteApp/components/Header';
import DataTable from '../../components/DataTable';
import PatientArchiveModal from './components/PatientArchiveModal';
import { dataTableHeaders, sideBarMenu } from './configs';
import usePatientSupportDashboardViewModel from './viewModel';
import NoPatients from '../../../assets/images/no_patients.png';
import Sidebar from '../../components/SideBar';
import PatientDetailsModal from './components/PatientDetailsModal';

export default () => {
  const dispatch = useDispatch();
  const locSearch = new URLSearchParams(location.search);

  const [page, setPage] = useState(1);
  const [sortingStatus, setSortingStatus] = useState({});
  const sort = useSelector(store => store.traditionalSiteReducer.sort);
  const direction = useSelector(
    store => store.traditionalSiteReducer.direction
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    dispatch(fetchTraditionalSiteUserDetails(page));
    onSearchSubmit();
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const {
    activeTab,
    updateActiveTabData,
    isLoading,
    siteName,
    showArchiveModal,
    onArchiveModalHide,
    all_patients,
    setShowArchiveModal,
    pagination,
    empty_message,
    showPatientDetailsModal,
    onPatientDetailsModalHide,
    setShowPatientDetailsModal,
  } = usePatientSupportDashboardViewModel();

  const activeTabKey = () => {
    const active_tab = parseInt(locSearch.get('active_tab_id'));
    const load_tab = active_tab ? active_tab : 1;

    let activeTabKey = sideBarMenu.find(menu => menu.id === load_tab);
    return activeTabKey.key;
  };

  useEffect(() => {
    setSearchTerm('');
    setSortingStatus({});
  }, [activeTab.activeTabId]);

  let refreshInterval = null;

  useEffect(() => {
    refreshInterval = setInterval(() => {
      dispatch(getLoggedInStatusCheck());
    }, 30000);
  }, []);

  useLayoutEffect(() => {
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const onSearchSubmit = () => {
    const params = {
      archived: activeTabKey(),
      page,
      sort,
      direction,
    };

    if (searchTerm.length > 0) {
      params.term = searchTerm;
    }

    dispatch(fetchAllPatients(params));
  };

  return (
    <div className='dashboard-wrapper'>
      <Sidebar
        menu={sideBarMenu}
        activeTab={activeTab.activeTabId}
        updateActiveTabData={updateActiveTabData}
      />

      <div className='data-show-wrapper'>
        <TraditionalSiteHeader
          siteName={siteName}
          searchTerm={searchTerm}
          onSearchSubmit={onSearchSubmit}
          setSearchTerm={term => {
            setSearchTerm(term);
          }}
        />

        {all_patients?.length > 0 ? (
          <DataTable
            handlePagination={setPage}
            pagination={pagination}
            dataTableHeaders={dataTableHeaders}
            all_patients={all_patients}
            activeTab={activeTab}
            sortingStatus={sortingStatus}
            setSortingStatus={setSortingStatus}
            searchTerm={searchTerm}
          />
        ) : (
          <div className='no-order-image-section'>
            <Emptyview className='' message={empty_message} />
          </div>
        )}
      </div>
      <PatientArchiveModal
        show={showArchiveModal}
        title='Contacted Patient'
        onHide={onArchiveModalHide}
        setShowArchiveModal={setShowArchiveModal}
        activeTab={activeTab}
        onSearchSubmit={onSearchSubmit}
      />

      <PatientDetailsModal
        show={showPatientDetailsModal}
        title='Patient Details'
        onHide={onPatientDetailsModalHide}
        setShowArchiveModal={setShowPatientDetailsModal}
        activeTab={activeTab}
      />
    </div>
  );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import Media from 'react-media';
import { getActiveCompositeProcedureId } from 'services/storage';
import { withRouter } from 'react-router';
import { isMasquerading } from 'utils/common';
import './navbar.scss';

class NavigationComponent extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 3,
      toggleMoreDropDown: false,
    };
  }
  onToggle = () => {
    this.setState(prevState => ({
      toggleMoreDropDown: !prevState.toggleMoreDropDown,
    }));
  };

  handleSelectNavigationOption = key => {
    this.setState({ activeKey: key });
  };

  render() {
    const compositeProcedureId =
      this.props.match.params.compositeProcedureId ||
      getActiveCompositeProcedureId();
    return (
      <div className='patient-navigation-container'>
        <div className='col-md-offset-2 col-md-8 col-xs-12'>
          <Nav
            bsStyle='pills'
            justified
            onSelect={this.handleSelectNavigationOption}
          >
            <LinkContainer
              to={`/mydashboard/${compositeProcedureId}/resources`}
            >
              <NavItem eventKey={1}>
                <i className='icon icon-font-z-book-open'></i>
                <span className='tab-label title-container body'>
                  Resources
                </span>
              </NavItem>
            </LinkContainer>
            <LinkContainer
              to={`/mydashboard/${compositeProcedureId}/conversations`}
            >
              <NavItem eventKey={2}>
                <i className='icon icon-font-z-mail'></i>
                <span className='tab-label body'>Messages</span>
              </NavItem>
            </LinkContainer>
            <LinkContainer to={`/mydashboard/${compositeProcedureId}/tasks`}>
              <NavItem eventKey={3}>
                <i className='icon icon-font-z-tasks'></i>
                <span className='tab-label'>Tasks</span>
              </NavItem>
            </LinkContainer>

            {
              <Media query={{ maxWidth: 767 }}>
                {screenIsSmall =>
                  screenIsSmall ? (
                    isMasquerading() ? (
                      <LinkContainer to={`/mydashboard/${compositeProcedureId}/tasks`}>
                        <NavItem eventKey={3}>
                          <i className='icon icon-font-z-tasks'></i>
                          <span className='tab-label'>Tasks</span>
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      <NavDropdown
                        eventKey='4'
                        onToggle={this.onToggle}
                        title={
                          <span>
                            <span
                              className='icon icon-font-a-more'
                              aria-hidden='true'
                            ></span>
                            <span className='more-label body'>More</span>
                          </span>
                        }
                        active={
                          !this.state.toggleMoreDropDown &&
                          (this.props.location.pathname.includes(
                            `/mydashboard/${compositeProcedureId}/myteam`
                          ) ||
                            this.props.location.pathname.includes(
                              `/mydashboard/${compositeProcedureId}/caregivers`
                            ))
                        }
                        id='nav-dropdown'
                        className='tab-drop-down custom-more-nav'
                      >
                        <LinkContainer
                          to={`/mydashboard/${compositeProcedureId}/procedures`}
                        >
                          <NavItem eventKey={4.1}>
                            <i className='icon icon-font-a-procedure-unfilled'></i>
                            <span className='tab-label'>Appointments</span>
                          </NavItem>
                        </LinkContainer>
                        <LinkContainer
                          to={`/mydashboard/${compositeProcedureId}/caregivers`}
                          exact
                        >
                          <MenuItem eventKey={4.2}>
                            <i className='icon icon icon-font-a-caregiver'></i>
                            <span className='tab-label body'>Caregivers</span>
                          </MenuItem>
                        </LinkContainer>
                      </NavDropdown>
                    )
                  ) : (
                    <React.Fragment>
                      <LinkContainer
                        to={`/mydashboard/${compositeProcedureId}/procedures`}
                      >
                        <NavItem eventKey={5}>
                          <i className='icon icon-font-a-procedure-unfilled'></i>
                          <span className='tab-label'>Appointments</span>
                        </NavItem>
                      </LinkContainer>

                      {!isMasquerading() && (
                        <LinkContainer
                          to={`/mydashboard/${compositeProcedureId}/caregivers`}
                        >
                          <NavItem eventKey={6}>
                            <i className='icon icon icon-font-a-caregiver'></i>
                            <span className='tab-label body'>Caregivers</span>
                          </NavItem>
                        </LinkContainer>
                      )}
                    </React.Fragment>
                  )
                }
              </Media>
            }
          </Nav>
        </div>
        <div className='clearfix'></div>
      </div>
    );
  }
}

NavigationComponent.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(NavigationComponent);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import lodash from 'lodash';
import Media from 'react-media';

import queryString from 'query-string';
import {
  signInUser,
  getTermsAndConditionsUrl,
  generateOtp,
  closeOtpModal,
} from 'common/authThunkActions';
import { defaultLandingPageRouteGenerator } from 'common/authGuard';
import { getItemFromStorage } from 'services/storage';
import { showToaster } from 'common/toasterActions';
import isEmpty from 'lodash/isEmpty';

import validate, { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import Wrapper from '../../components/Authorization/Wrapper';
import ContactView from '../../components/Authorization/ContactView';

import Modal from 'react-bootstrap/lib/Modal';
import InviteCode from 'patientApp/containers/InviteCode';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelLoginInput from 'components/FloatingLabelLoginInput';
import PreLoginFooter from 'components/PreLoginFooter';
import WelcomeSection from './WelcomeSection';

import './login.scss';
import { stopHeartBeats } from 'services/heartbeats';
import EmailInput from './EmailInput';
import LoginInput from './LoginInput';

import '../Authorization/patientRegister.scss';

import backgroundImg from 'assets/images/hero_image_v2_ins.png';
import strings from '../../strings';

const validationConfig = {
  fields: ['emailOrMobileNumber', 'password'],
  rules: {
    emailOrMobileNumber: [
      { rule: 'isEmail', message: 'Mobile Number or Email is invalid' },
      { rule: 'isRequired', message: 'Mobile Number or Email is required' },
    ],
    password: [{ rule: 'isRequired', message: 'Password is required' }],
  },
};

const phoneRule = [
  { rule: 'isPhone', message: 'Mobile Number or Email is invalid' },
  { rule: 'isRequired', message: 'Mobile Number or Email is required' },
];

const emailRule = [
  { rule: 'isEmail', message: 'Mobile Number or Email is invalid' },
  { rule: 'isRequired', message: 'Mobile Number or Email is required' },
];

class Login extends React.Component {
  constructor(props) {
    super(props);
    stopHeartBeats({});
    const queryStringObject = props.location.search
      ? queryString.parse(props.location.search)
      : {};
    this.state = {
      emailOrMobileNumber: props.location.state
        ? props.location.state.emailId
        : '',
      password: '',
      type: 'password',
      otp: '',
      errors: {},
      showInviteCodeModal: false,
      showTermsAndConditionsModal: false,
      acceptTermAndConditionValue: false,
      showOtpModal: false,
      errorInTermAccept: false,
      showSignInForm: queryStringObject.show_signin_form === '1',
      showVideoModal: false,
      videoUrl: 'https://www.youtube.com/embed/gToQePm2TxU?rel=0&autoplay=1',
      showContactviewModal: false,
      redirectUri: queryStringObject.redirect_uri,
      seconds: 60,
    };
    this.onFormValidationSuccess = this.onFormValidationSuccess.bind(this);
  }

  componentDidMount() {
    this.props.closeOtpModal();
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.otpSent !== nextProps.otpSent) {
      this.setState(
        {
          showOtpModal: nextProps.otpSent,
          seconds: 60,
        },
        () => this.countDownTimer()
      );
    }
  };

  onEmailChange = e => {
    this.setState({
      emailOrMobileNumber: e.target.value.trim(),
      errors: clearErrorsForField(this.state.errors, 'emailOrMobileNumber'),
    });
  };

  onPasswordChange = e => {
    const password = e.target.value;
    this.setState({
      password: password.trim(),
      errors: clearErrorsForField(this.state.errors, 'password'),
      type: password.length === 0 ? 'password' : this.state.type,
    });
  };

  onOtpChange = e => {
    this.setState({ otp: e.target.value.trim() });
  };

  onForgotPasswordEvent = e => {
    e.preventDefault();
    this.props.history.push('/forgot-password');
  };

  onFormValidationSuccess = () => {
    this.setState({
      errors: {},
    });
    this.props.getTermsAndConditionsUrl(this.state.emailOrMobileNumber);
    this.setState({
      showTermsAndConditionsModal: true,
    });
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  onSignUpClick = () => {
    this.setState({
      showInviteCodeModal: true,
    });
  };

  onContactUsClick = () => {
    this.setState({
      showContactviewModal: true,
    });
  };

  onNotNowClick = () => {
    this.setState({
      showTermsAndConditionsModal: false,
    });
  };

  onIAgreeClick = () => {
    this.setState({
      showTermsAndConditionsModal: false,
    });

    const { emailOrMobileNumber, password } = this.state;
    const params = {
      password,
    };
    if (emailOrMobileNumber.includes('@')) {
      params.email = emailOrMobileNumber;
    } else {
      const contactNumber = emailOrMobileNumber.replace(/[^a-zA-z0-9]/g, '');
      params.contact_number = contactNumber;
    }
    params.has_accepted_terms_and_condition = true;
    params.tnc_url = this.props.termsAndConditionsUrl;
    params.login_type = 'provider_login';
    if (this.props.twoFactorAuthentication) {
      this.props.generateOtp(params);
    } else {
      this.props.signInUser(params);
    }
  };

  onOtpSumitClick = () => {
    const { emailOrMobileNumber, password, otp } = this.state;
    const params = {
      password,
      code: otp,
    };
    if (emailOrMobileNumber.includes('@')) {
      params.email = emailOrMobileNumber;
    } else {
      const contactNumber = emailOrMobileNumber.replace(/[^a-zA-z0-9]/g, '');
      params.contact_number = contactNumber;
    }
    params.has_accepted_terms_and_condition = true;
    params.tnc_url = this.props.termsAndConditionsUrl;
    this.props.signInUser(params);
  };

  acceptTermAndCondition = () => {
    this.setState({
      acceptTermAndConditionValue: !this.state.acceptTermAndConditionValue,
      errorInTermAccept: false,
    });
  };

  showSignInForm = () => {
    this.setState({
      showSignInForm: true,
    });
  };

  backToLogin = () => {
    this.setState({
      showSignInForm: false,
    });
    this.props.history.replace('/signin');
  };

  submitForm = e => {
    if (!this.state.emailOrMobileNumber.includes('@')) {
      validationConfig.rules.emailOrMobileNumber = phoneRule;
    } else {
      validationConfig.rules.emailOrMobileNumber = emailRule;
    }
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  closeInviteCodeModal = () => {
    this.setState({
      showInviteCodeModal: false,
    });
  };

  closeTermsAndConditionsModal = () => {
    this.setState({
      showTermsAndConditionsModal: false,
    });
  };

  closeOtpModal = () => {
    this.setState({
      otp: '',
    });
    this.props.closeOtpModal();
  };

  hideContactviewModal = () => {
    this.setState({
      showContactviewModal: false,
    });
  };

  countDownTimer = () => {
    setInterval(() => {
      const { seconds } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        clearInterval(this.myInterval);
      }
    }, 1200);
  };

  resendCode = () => {
    const { emailOrMobileNumber, password } = this.state;
    const params = {
      password,
    };
    if (emailOrMobileNumber.includes('@')) {
      params.email = emailOrMobileNumber;
    } else {
      const contactNumber = emailOrMobileNumber.replace(/[^a-zA-z0-9]/g, '');
      params.contact_number = contactNumber;
    }
    params.has_accepted_terms_and_condition = true;
    params.tnc_url = this.props.termsAndConditionsUrl;
    this.props.generateOtp(params);
    this.setState(
      {
        showOtpModal: true,
        seconds: 60,
      },
      () => this.countDownTimer()
    );
  };

  renderLogin = () => {
    if (this.props.isSignedIn) {
      if (this.state.redirectUri) {
          if (this.state.redirectUri === '/unread-message-filter') {
            return <Redirect to={{ pathname: "/search/patients/", hash: this.props.location.hash, state: { page: 1, sort: "patient_id", direction: "desc", messages: "unanswered_messages" } }} replace />
          }
          else {
            return (
            <Redirect
              to={{
                pathname: this.state.redirectUri,
                hash: this.props.location.hash,
              }}
              replace
            />)
          }
      } else {
        return <Redirect to={defaultLandingPageRouteGenerator()} replace />;
      }
    }
    const shouldShowTermsAndConditionsModal =
      this.state.showTermsAndConditionsModal &&
      !lodash.isEmpty(this.props.termsAndConditionsUrl);
    const shouldShowOtpModal = this.state.showOtpModal && this.props.otpSent;
    const flowType = 'educator_sign_up';
    const subtitleText = 'Sign in to INSIGHTEC Connect';
    const heroTheme = 'educatorTheme';

    return (
      <div className='signin-container'>
        <Wrapper page={2} heroTheme={heroTheme} heroImageNo={2}>
          <ContactView
            contact={this.state.emailOrMobileNumber}
            onChange={this.onEmailChange}
            onNext={this.submitForm}
            errors={this.state.errors}
            flowType={flowType}
            onForgotPasswordEvent={this.onForgotPasswordEvent}
            onPasswordChange={this.onPasswordChange}
            showHide={this.showHide}
            password={this.state.password}
            subtitleText={subtitleText}
          />
        </Wrapper>
        <Modal
          show={shouldShowTermsAndConditionsModal}
          onHide={this.closeTermsAndConditionsModal}
          container={document.body}
          aria-labelledby='contained-modal-title'
          className='primary-modal terms-and-conditions-modal'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title'>
              Agree to Terms & Conditions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='text-center term-and-condition-container'>
              <div className='term-and-condition-label'>
                I have read and agree to the
              </div>
              <div className='term-and-condition-link'>
                <a
                  rel='noopener'
                  href={`${this.props.termsAndConditionsUrl}`}
                  target='_blank'
                >
                  <span>Terms and Conditions</span>
                </a>
              </div>
              <div className='text-center'>
                <React.Fragment>
                  <div className='buttons-center'>
                    {
                      <div>
                        <button
                          className='btn btn-default'
                          onClick={this.onNotNowClick}
                        >
                          Not Now
                        </button>
                      </div>
                    }
                    {
                      <div>
                        <button
                          className='btn btn-primary'
                          onClick={this.onIAgreeClick}
                        >
                          I Agree
                        </button>
                      </div>
                    }
                  </div>
                </React.Fragment>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={shouldShowOtpModal}
          onHide={this.closeOtpModal}
          container={document.body}
          aria-labelledby='contained-modal-title'
          className='primary-modal otp-modal'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title'>
              Two Factor Authentication
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='text-center'>
              <div className='col-md-offset-3 col-md-6 col-xs-12'>
                {this.state.seconds > 0 ? (
                  <div className='timer'>
                    Resend Code in {this.state.seconds}s
                  </div>
                ) : (
                  <div className='resend-code btn' onClick={this.resendCode}>
                    Resend Code
                  </div>
                )}
              </div>
              <div className='form-group col-md-offset-3 col-md-6 col-xs-12'>
                <FloatingLabelInput
                  type='text'
                  className='form-control'
                  value={this.state.otp}
                  label='Enter verification code'
                  onChange={this.onOtpChange}
                  name='otp'
                />
              </div>
              <div className='clearfix'></div>
              <div>
                <button
                  className='btn btn-primary'
                  disabled={!this.state.otp}
                  onClick={this.onOtpSumitClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showInviteCodeModal}
          onHide={this.closeInviteCodeModal}
          container={document.body}
          aria-labelledby='contained-modal-title'
          className='primary-modal'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title'>
              Enter Invite Code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InviteCode />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showContactviewModal}
          onHide={this.hideContactviewModal}
          container={document.body}
          aria-labelledby='contained-modal-title'
          className='primary-modal'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title'>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='footer-content-container'>
              <div className='text'>
                Feel free to contact us via email or phone number.
              </div>
              <div className='text'>
                We would be happy to address your queries.
              </div>
              <div className='phone-container'>
                <i className='icon icon-font-a-contact'></i>
                <span className='phone'>{strings.CONTACT_FOR_QUERIES}</span>
              </div>
              <div className='email-container'>
                <i className='icon icon-font-a-email'></i>
                <span className='email'>support@insightecconnect.com</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  handleMobileScreen = () => {
    return (
      <div className='mobile-deep-linking'>
        Please use the web app to access the login feature.
      </div>
    );
  };

  render() {
    return (
      <Media
        queries={{
          screenIsSmall: '(max-width: 1024px)',
          deviceOrientation: '(orientation: landscape)',
          screenIsMedium: '(max-width: 1260px)',
        }}
      >
        {matches =>
          (matches.deviceOrientation && matches.screenIsMedium) ||
          matches.screenIsSmall
            ? this.handleMobileScreen()
            : this.renderLogin()
        }
      </Media>
    );
  }
}

Login.propTypes = {
  signInUser: PropTypes.func,
  generateOtp: PropTypes.func,
  getTermsAndConditionsUrl: PropTypes.func,
  history: PropTypes.object,
  isSignedIn: PropTypes.bool.isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
  otpSent: PropTypes.bool.isRequired,
  location: PropTypes.object,
  termsAndConditionsUrl: PropTypes.string,
  twoFactorAuthentication: PropTypes.bool,
};

const mapStateToProps = state => ({
  userType: state.currentUser.attributes.type,
  isRequestInProgress: state.currentUser.isLoading,
  otpSent: state.currentUser.otpSent,
  termsAndConditionsUrl: state.currentUser.termsAndConditionsUrl,
  twoFactorAuthentication: state.currentUser.twoFactorAuthentication,
  isInviteCodeValid: state.patientProcedures.isInviteCodeValid,
  isSignedIn:
    state.currentUser.isSignedIn || !isEmpty(getItemFromStorage('token')),
});

const mapDispatchToProps = dispatch => ({
  signInUser: params => dispatch(signInUser(params)),
  generateOtp: params => dispatch(generateOtp(params)),
  showToaster: params => dispatch(showToaster(params)),
  getTermsAndConditionsUrl: emailOrContactNumber =>
    dispatch(getTermsAndConditionsUrl(emailOrContactNumber)),
  closeOtpModal: () => dispatch(closeOtpModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Appointments from 'patientApp/containers/Appointments/Loadable';
import MasqueradingHeader from 'patientApp/Caregiver/MasqueradingHeader';
import AuthenticatedRoute, {
  PatientAuthorizedRoute,
} from '../../../containers/AuthRoute';
import Resources from '../Resources/Loadable';
import Questions from '../Questions/Loadable';
import Education from '../Education/Loadable';
import Tasks from '../Tasks/Loadable';
import Conversation from '../Conversation/Index/index.js';
import Caregivers from '../Caregivers/Loadable';
import NavigationComponent from '../../components/NavigationComponent';
import ManageProcedureSection from '../ManageProcedureSection';
import selectLoaders from './selectors';
import { USER_TYPE_PATIENT } from '../../../constants';
import './dashboard.scss';

class Dashboard extends Component {
  componentDidMount() {
    if (
      this.props.match.path === '/mydashboard/:compositeProcedureId([0-9]+)' &&
      this.props.match.isExact
    ) {
      this.props.history.replace(`${this.props.match.url}/tasks`);
    }
  }

  setChildRoutesToRenderChildren = () => {
    const { match } = this.props;
    return (
      <React.Fragment>
        <PatientAuthorizedRoute
          path={`${match.path}/procedures/:appointmentType?`}
          component={Appointments}
        />
        <PatientAuthorizedRoute
          path={`${match.path}/tasks/:tasksType?`}
          component={Tasks}
        />
        <PatientAuthorizedRoute
          path={`${match.path}/conversations`}
          component={Conversation}
        />
        <PatientAuthorizedRoute
          path={`${match.path}/education/:educationType?`}
          component={Education}
        />
        {/* <PatientAuthorizedRoute path={`${match.path}/questions/:questionType?`} component={Questions} /> */}
        <PatientAuthorizedRoute
          path={`${match.path}/resources/:resourceType?`}
          component={Resources}
        />
        <AuthenticatedRoute
          allowedRoles={[USER_TYPE_PATIENT]}
          path={`${match.path}/caregivers`}
          component={Caregivers}
        />
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className='dashboard-container-fluid'>
        <div className='dashboard-container row'>
          <div className='clearfix'></div>
          {/* <MasqueradingHeader /> */}
          <div className='col-xs-12 no-padding'>
            <ManageProcedureSection history={this.props.history} />
            <NavigationComponent />

            <div className='details-container'>
              {this.setChildRoutesToRenderChildren()}
              <div className='clearfix'></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: selectLoaders(state),
});

Dashboard.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps)(Dashboard);

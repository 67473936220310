import camelcaseKeys from 'change-case-object';
import {
  getPatientConversations as ConversationsApiCall,
  startConversation as StartConversationsApiCall,
  resolveConversation as ResolveConversationApiCall,
} from 'services/patientDetails';

import { showToaster } from 'common/toasterActions';
import { getPatientInfo } from 'containers/PatientDetails/actions';
import {
  GET_CONVERSATIONS_REQUEST_SENT,
  GET_CONVERSATIONS_REQUEST_SUCCEED,
  GET_CONVERSATIONS_REQUEST_FAILED,
  SHOW_CONVERSATION_DETAILS,
  SHOW_CONVERSATIONS_LIST,
  START_CONVERSATION_REQUEST_SENT,
  START_CONVERSATION_REQUEST_SUCCEED,
  START_CONVERSATION_REQUEST_FAILED,
  RESOLVE_CONVERSATION_REQUEST_FAILED,
  RESOLVE_CONVERSATION_REQUEST_SENT,
  RESOLVE_CONVERSATION_REQUEST_SUCCEED,
  RESET_DATA_REQUEST,
} from './constants';

export const getConversationsRequestSent = () => ({
  type: GET_CONVERSATIONS_REQUEST_SENT,
});

export const getConversationRequestSucceed = data => ({
  type: GET_CONVERSATIONS_REQUEST_SUCCEED,
  pagination: { ...data.pagination, avoidScroll: true },
  conversations: data.conversations,
});

export const getConversationsRequestFailed = () => ({
  type: GET_CONVERSATIONS_REQUEST_FAILED,
});

export const getPatientConversations =
  (compositeProcedureId, selectedPhaseId, page) => dispatch => {
    dispatch(getConversationsRequestSent());
    ConversationsApiCall(compositeProcedureId, selectedPhaseId, page)
      // ApiCall(id, userProcedureId)
      .then(response => {
        const data = camelcaseKeys.camelCase(response.data);
        dispatch(getConversationRequestSucceed(data));
      })
      .catch(() => {
        dispatch(getConversationsRequestFailed());
      });
  };

export const showConversationDetails = conversationId => ({
  type: SHOW_CONVERSATION_DETAILS,
  conversationId,
});

export const showConversationsListView = () => ({
  type: SHOW_CONVERSATIONS_LIST,
});

export const startNewConversationsRequestSent = () => ({
  type: START_CONVERSATION_REQUEST_SENT,
});

export const startNewConversationRequestSucceed = conversation => ({
  type: START_CONVERSATION_REQUEST_SUCCEED,
  conversation,
});

export const startNewConversationsRequestFailed = () => ({
  type: START_CONVERSATION_REQUEST_FAILED,
});

export const startNewConversation =
  (userProcedureId, subject, message, attachments) => dispatch => {
    dispatch(startNewConversationsRequestSent());
    StartConversationsApiCall(userProcedureId, subject, message, attachments)
      // ApiCall(id, userProcedureId)
      .then(response => {
        const data = camelcaseKeys.camelCase(response.data);
        dispatch(startNewConversationRequestSucceed(data));
      })
      .catch(() => {
        dispatch(startNewConversationsRequestFailed());
      });
  };

export const resolveConversationRequestSent = () => ({
  type: RESOLVE_CONVERSATION_REQUEST_SENT,
});

export const resolveConversationRequestSucceed = conversation => ({
  type: RESOLVE_CONVERSATION_REQUEST_SUCCEED,
  conversation,
});

export const resolveConversationRequestFailed = () => ({
  type: RESOLVE_CONVERSATION_REQUEST_FAILED,
});

export const resolveConversation =
  (patientId, userProcedureId, conversationId) => dispatch => {
    dispatch(resolveConversationRequestSent());
    ResolveConversationApiCall(conversationId)
      // ApiCall(id, userProcedureId)
      .then(response => {
        const data = camelcaseKeys.camelCase(response.data);
        dispatch(resolveConversationRequestSucceed(data));
        dispatch(showToaster({ message: 'Thread marked as Resolved.' }));
        dispatch(getPatientInfo(patientId, userProcedureId));
      })
      .catch(() => {
        dispatch(resolveConversationRequestFailed());
      });
  };

export const clearConversationReducerData = () => ({
  type: RESET_DATA_REQUEST,
});

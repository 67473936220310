import React from 'react';
import Logo from 'assets/images/mms_logo.png';
import SearchBar from '../SearchBar';
import useHeaderViewModel from './viewModel';
import './header.scss';
import LogoutIcon from '@mui/icons-material/Logout';

const TraditionalSiteHeader = props => {
  const { onLogout } = useHeaderViewModel();

  return (
    <>
      <div className='header-grid'>
        <div className='search-bar-section'>
          <SearchBar
            placeholder='Search by name, phone-number, email'
            searchTerm={props.searchTerm}
            setSearchTerm = { props.setSearchTerm }
            onSearchSubmit={props.onSearchSubmit}
          />
        </div>
        <div className='header-grid-item title-signout'>{props.siteName}</div>
        <div className='header-grid-item logout'>
          <div className='logout-container' onClick={() => onLogout()}>
            <span className='logout-icon'>
              <LogoutIcon sx={{ fontSize: '24px' }} />
            </span>
            <span className={`logout-text`}>Sign out</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TraditionalSiteHeader;

/**
 * Outcomes Report Reducers
 */
import {
  SHARE_OUTCOMES_REPORT_REQUEST_SENT,
  SHARE_OUTCOMES_REPORT_REQUEST_SUCCEED,
  SHARE_OUTCOMES_REPORT_REQUEST_FAILED,
  GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SENT,
  GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SUCCEED,
  GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_FAILED,
} from './constants';

const initialState = {
  hasError: false,
  isRequestInProgress: false,
  sharedOutcomesReportsHistory: [],
};

function shareOutcomesReportReducer(state = initialState, action) {
  switch (action.type) {
    case SHARE_OUTCOMES_REPORT_REQUEST_SENT:
      return {
        ...state,
        hasError: false,
        isRequestInProgress: true,
      };
    case SHARE_OUTCOMES_REPORT_REQUEST_SUCCEED:
      return {
        ...state,
        hasError: false,
        isRequestInProgress: false,
      };
    case SHARE_OUTCOMES_REPORT_REQUEST_FAILED:
      return {
        ...state,
        hasError: true,
        isRequestInProgress: false,
      };
    case GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SENT:
      return {
        ...state,
        hasError: false,
        isRequestInProgress: true,
      };
    case GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SUCCEED:
      return {
        ...state,
        hasError: false,
        isRequestInProgress: false,
        sharedOutcomesReportsHistory: action.data.sharedOutcomesRequests,
      };
    case GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_FAILED:
      return {
        ...state,
        hasError: true,
        isRequestInProgress: false,
      };
    default:
      return state;
  }
}

export default shareOutcomesReportReducer;

export const GET_SURVEY_FEEDBACK_REQUEST_SENT =
  'app/patientApp/SurveyFeedback/GET_SURVEY_FEEDBACK_REQUEST_SENT';
export const GET_SURVEY_FEEDBACK_REQUEST_SUCCEED =
  'app/patientApp/SurveyFeedback/GET_SURVEY_FEEDBACK_REQUEST_SUCCEED';
export const GET_SURVEY_FEEDBACK_REQUEST_FAILED =
  'app/patientApp/SurveyFeedback/GET_SURVEY_FEEDBACK_REQUEST_FAILED';

export const SUBMIT_SURVEY_FEEDBACK_REQUEST_SENT =
  'app/patientApp/SurveyFeedback/SUBMIT_SURVEY_FEEDBACK_REQUEST_SENT';
export const SUBMIT_SURVEY_FEEDBACK_REQUEST_SUCCEED =
  'app/patientApp/SurveyFeedback/SUBMIT_SURVEY_FEEDBACK_REQUEST_SUCCEED';
export const SUBMIT_SURVEY_FEEDBACK_REQUEST_FAILED =
  'app/patientApp/SurveyFeedback/SUBMIT_SURVEY_FEEDBACK_REQUEST_FAILED';

export const CLEAR_SURVEY_FEEDBACK_QUESTIONS =
  'app/patientApp/SurveyFeedback/CLEAR_SURVEY_FEEDBACK_QUESTIONS';

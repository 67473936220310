import {
  GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT,
  GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
  GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED,
  ADD_NEW_PROVIDER_REQUEST_SENT,
  ADD_NEW_PROVIDER_REQUEST_SUCCEED,
  ADD_NEW_PROVIDER_REQUEST_FAILED,
  UPDATE_PROVIDER_INFO_REQUEST_SENT,
  UPDATE_PROVIDER_INFO_REQUEST_SUCCEED,
  UPDATE_PROVIDER_INFO_REQUEST_FAILED,
  GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT,
  GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED,
  GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED,
  GET_ALL_LOCATIONS_REQUEST_SENT,
  GET_ALL_LOCATIONS_REQUEST_SUCCEED,
  GET_ALL_LOCATIONS_REQUEST_FAILED,
} from './constants';

const initialState = {
  show: false,
  isRequestInProgress: false,
  isGetAllPerformersRequestInProgress: false,
  isGetAllLocationsRequestInProgress: false,
  isGetAllProcedureModifiersRequestInProgress: false,
  infoUpdated: false,
  users: [],
  performersOfUserTypeMaster: [],
  locations: [],
  nonTipsLocations: [],
  procedureModifiers: [],
  error: '',
  pagination: null,
};

export const userAdministrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT:
      return {
        ...state,
        isRequestInProgress: true,
        error: '',
        show: false,
      };
    case GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isRequestInProgress: false,
        users: action.data.users,
        pagination: action.data.pagination,
      };
    case GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED:
      return {
        ...state,
        isRequestInProgress: false,
        error: action.data.error,
      };
    case ADD_NEW_PROVIDER_REQUEST_SENT:
      return {
        ...state,
        show: true,
        isRequestInProgress: true,
      };
    case ADD_NEW_PROVIDER_REQUEST_SUCCEED:
      return {
        ...state,
        show: false,
        isRequestInProgress: false,
      };
    case ADD_NEW_PROVIDER_REQUEST_FAILED:
      return {
        ...state,
        show: true,
        isRequestInProgress: false,
      };
    case UPDATE_PROVIDER_INFO_REQUEST_SENT:
      return {
        ...state,
        isRequestInProgress: true,
        show: true,
      };
    case UPDATE_PROVIDER_INFO_REQUEST_SUCCEED:
      return {
        ...state,
        isRequestInProgress: false,
        show: false,
      };
    case UPDATE_PROVIDER_INFO_REQUEST_FAILED:
      return {
        ...state,
        isRequestInProgress: false,
        show: true,
      };
    case GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT:
      return {
        ...state,
        isGetAllPerformersRequestInProgress: true,
        error: '',
      };
    case GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED:
      return {
        ...state,
        isGetAllPerformersRequestInProgress: false,
        performersOfUserTypeMaster: action.data.performers,
      };
    case GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED:
      return {
        ...state,
        isGetAllPerformersRequestInProgress: false,
        error: 'Error',
      };
    case GET_ALL_LOCATIONS_REQUEST_SENT:
      return {
        ...state,
        isGetAllLocationsRequestInProgress: true,
        error: '',
      };
    case GET_ALL_LOCATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isGetAllLocationsRequestInProgress: false,
        locations: action.data.locations,
        nonTipsLocations: action.data.nonTipsLocations,
      };
    case GET_ALL_LOCATIONS_REQUEST_FAILED:
      return {
        ...state,
        isGetAllLocationsRequestInProgress: false,
        error: 'Error',
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import { getActiveCompositeProcedureId } from 'services/storage';

import HelpBlock from 'components/HelpBlock';

import { Modal } from 'react-bootstrap/lib';

import AddAttachmentModal from 'containers/AddAttachmentModal';

import validate, { clearErrorsForField } from 'common/validator';
import {
  getConversationMessagesRequest,
  sendMessageRequest,
  createConversationsRequest,
  toggleMessageSentFlag,
} from '../actions';
import reducer from './reducers';

import { BackButton } from '../../../components/UtilityComponents';
import { switchActiveProcedureRequest } from '../../../common/actions';

import './show.scss';

const validationSchema = {
  fields: ['subject', 'message'],
  rules: {
    subject: [{ rule: 'isRequired', message: 'Subject is required' }],
    message: [{ rule: 'isRequired', message: 'Message is required' }],
  },
};

const defaultFormValues = {
  procedure: null,
  subject: '',
  message: '',
};

const defaultAttachmentsState = {
  showModal: false,
  showFiles: false,
  files: [],
};

const CreateMessage = props => {
  const {
    show,
    onHide,
    procedureManagers,
    compositeProcedureId,
    createConversation,
    fetchConversations,
    disclaimerText,
  } = props;

  const history = useHistory();

  const {
    user_procedure_id,
    resource_type,
    resource_id,
    resource_title,
    resource_body,
  } = useParams();
  const compositeId = useParams().compositeProcedureId;

  useEffect(() => {
    if (props.isMessageSent) {
      history.goBack();
      props.toggleIsMessageSentFlag();
      if (!resource_id) {
        fetchConversations();
      }
    }
  }, [props.isMessageSent]);

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [errors, setFormErrors] = useState({});
  const [attachments, setAttachements] = useState(defaultAttachmentsState);
  const [showOptions, setShowOptions] = useState(false);

  defaultFormValues.subject = resource_title
    ? resource_type === '1'
      ? `Resource Follow up: ${resource_title}`
      : resource_type === '2'
      ? `FAQ Follow up: ${resource_title}`
      : resource_type === '3'
      ? resource_title
      : ''
    : '';

  defaultFormValues.message = resource_body
    ? resource_type === '3'
      ? resource_body
      : ''
    : '';

  defaultFormValues.procedure = compositeProcedureId;
  const onShowOptions = () => {
    setShowOptions(true);
  };

  const handleFieldChange = ({ name, value }) => {
    const newFormValues = { ...formValues };
    newFormValues[name] = value;
    setFormValues(newFormValues);
    setFormErrors(clearErrorsForField(errors, name));
  };

  const onAttachmentsAdded = files =>
    setAttachements({ ...attachments, showModal: false, files });

  const onAttachmentRemoved = files => console.log(files, 'REMOVED FILES');

  const onAddAttachmentsClick = () => {
    setAttachements({ ...attachments, showModal: true, showFiles: true });
  };

  const clearStateAndHide = () => {
    setFormErrors({});
    setFormValues(defaultFormValues);
    setAttachements(defaultAttachmentsState);
  };

  const onSendMessageClick = () =>
    validate(validationSchema, formValues, setFormErrors, onValidationSuccess);

  const onValidationSuccess = () => {
    setFormErrors({});

    const payload = {
      subject: formValues.subject,
      message_body: formValues.message,
      resource_id: resource_type === '1' ? resource_id : null,
      question_id: resource_type === '2' ? resource_id : null,
      mediaResources: attachments.files,
    };
    // 1 = Educational
    // 2 = FAQ
    // 3 = Send message to Educator (Tremor recording)

    createConversation(payload);
    clearStateAndHide();
  };

  const convertUserToOption = item => ({
    label: `${item.name}`,
    value: item.id,
  });

  const toUserOptions = props.compositeProcedures.map(convertUserToOption);

  const [newMessage, setNewMessage] = useState('');
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [showAttachments, setShowAttachments] = useState(true);

  const changeFieldHandler = e => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(errors, e.target.name);
    this.setState(obj);
  };

  const onSendMessage = () => {
    validate(
      validationSchema,
      this.state,
      this.onSendMessageValidationFailure,
      this.onSendMessageValidationSuccess
    );
  };

  const onAddAttachment = () => {
    setShowAttachmentModal(true);
    setShowAttachments(true);
  };

  const onProcedureChange = event => {
    const compositeProcedureId = parseInt(event.target.dataset.id, 10);
    if (compositeProcedureId !== props.compositeProcedureId) {
      props.putSwitchActiveProcedure(compositeProcedureId);
    }

    setShowOptions(false);
  };

  const RenderDisclaimerText = () => (
    <div className='disclaimer-text'>{disclaimerText}</div>
  );

  const activeCompositeProcedureDetails = props.compositeProcedures.find(
    item => item.id === props.compositeProcedureId
  );

  return (
    <div className='col-md-offset-2 col-md-8 col-xs-12 conversations__container conversations-list-container conversation-height'>
      <span>
        <span className='col-xs-2 back-btn-margin'>
          <div
            className='back-btn'
            role='button'
            tabIndex='0'
            onClick={history.goBack}
          >
            <span className='icon icon-font-a-left-chevron back-icon' />
            <span className='btn-text'>Back</span>
          </div>
        </span>
        <span
          className='col-xs-8'
          style={{ textAlign: 'center', fontSize: '28px' }}
        >
          Message an Educator
        </span>
        <span className='col-xs-2' />
      </span>
      <span className='col-xs-2' />
      {disclaimerText ? <RenderDisclaimerText /> : null}
      <div className='messaging_block'>
        <div
          className={`form-group input-control-class ${
            errors.subject ? 'has-error' : ''
          }`}
        >
          <input
            type='text'
            name='subject'
            id='subject'
            className='form-control'
            placeholder='Subject'
            value={formValues.subject}
            onChange={event => handleFieldChange(event.target)}
          />
          <HelpBlock value={errors.subject} />
        </div>

        <div
          className={`form-group message-text-area-control ${
            errors.message ? 'has-error' : ''
          }`}
        >
          <textarea
            name='message'
            id='message'
            className='form-control text_area'
            placeholder='Type a message here'
            value={formValues.message}
            onChange={event => handleFieldChange(event.target)}
          />
          <HelpBlock value={errors.message} />
        </div>

        <AddAttachmentModal
          showModal={attachments.showModal}
          onAttachmentsAdded={onAttachmentsAdded}
          onAttachmentRemoved={onAttachmentRemoved}
          showAddedAttachments={attachments.showFiles}
        />

        <div className='attachment-btn-container'>
          <span
            className='icon attachment-btn icon-font-a-attachment-circle'
            onClick={() => onAddAttachmentsClick()}
          />

          <button
            className='btn btn-primary float-right'
            onClick={onSendMessageClick}
          >
            Send
          </button>
        </div>
        <div className='clearfix' />
      </div>
    </div>
  );
};

CreateMessage.propTypes = {
  compositeProcedureId: PropTypes.number,
  isMessageSent: PropTypes.bool,
  disclaimerText: PropTypes.string,
};

const mapStateToProps = state => ({
  compositeProcedureId:
    state.activeProcedureDetails.compositeProcedureId ||
    parseInt(getActiveCompositeProcedureId(), 10),
  compositeProcedures: state.patientProcedures.compositeProcedures,
  procedureManagers: state.conversations.procedureManagers || [],
  isMessageSent: state.patientMessages.isMessageSent,
});

const mapDispatchToProps = dispatch => ({
  fetchConversationMessages: id => dispatch(getConversationMessagesRequest(id)),
  sendMessage: payload => dispatch(sendMessageRequest(payload)),
  putSwitchActiveProcedure: userProcedureId =>
    dispatch(switchActiveProcedureRequest(userProcedureId)),
  toggleIsMessageSentFlag: () => dispatch(toggleMessageSentFlag()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'patientMessages',
  reducer,
});

export default compose(withReducer, withConnect)(CreateMessage);

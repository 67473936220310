import axios from '../base';
import {
  GET_ALL_TASKS,
  DELETE_COMPOSITE_PROCEDURE,
  GET_RESOURCES_METADATA,
  POST_MARK_TASK_COMPLETE,
  POST_SUBMIT_SURVEY_FEEDBACK,
  GET_APPOINTMENTS,
  POST_CREATE_TASK,
  GET_USER_CONSENT_TEXT,
  POST_USER_CONSENT,
  POST_DECLINE_USER_CONSENT,
  GET_RESOURCES_DETAILS,
  PUT_UPDATE_TASK_ATTACHMENTS,
  PATIENT_GET_PROCEDURE_MANAGERS,
  GET_TREMOR_SITES,
  GET_TREMOR_SITES_STATES,
  GET_CITY_CENTERS_STATES,
  GET_TREMOR_SITES_CITIES,
  GET_CITY_CENTERS,
  SELECT_TREMOR_SITE,
  CT_CENTERS_SELECT,
  GET_CITY_CENTERS_CITIES,
} from '../constants';
import { currentTimezoneName } from '../../utils/dateUtils';

export const getAllTaskService = () => {
  const params = {
    timezone: currentTimezoneName,
  };
  return axios.get(GET_ALL_TASKS, { params });
};

export const deleteProcedure = compositeProcedureIds => {
  const params = {
    composite_procedure_ids: compositeProcedureIds,
  };
  return axios.put(DELETE_COMPOSITE_PROCEDURE, params);
};

export const getResourcesMetadataService = () =>
  axios.get(GET_RESOURCES_METADATA);

export const getResourceDetailsService = (userProcedureId, resourceType) => {
  const params = {
    user_procedure_id: userProcedureId,
    resource_type: resourceType,
  };
  return axios.get(`${GET_RESOURCES_METADATA}${userProcedureId}`, { params });
};

export const getResources = type => {
  const params = { resource_type: type };
  // const params = {
  //   "resource_type":"educational"
  // }
  return axios.get(GET_RESOURCES_DETAILS, { params });
};
export const getResourceDetail = resource_id => {
  return axios.get(`v2/resources/${resource_id}`);
};
export const addResourceReview = resource_review => {
  return axios.post(
    `v2/resources/${resource_review.resource_review.resource_id}/add_review`,
    resource_review
  );
};

export const markResourceViewed = resource_id => {
  return axios.post(`v2/resources/${resource_id}/mark_viewed`);
};

export const postMarkTaskCompleteService = reqObj =>
  axios.post(POST_MARK_TASK_COMPLETE, reqObj);

export const getSurveyFeedback = eventId =>
  axios.get(`/v2/events/${eventId}/expected_questions`);

export const submitSuveryFeedback = params =>
  axios.post(POST_SUBMIT_SURVEY_FEEDBACK, params);

export const getAppointments = timezone => {
  const params = {
    timezone,
  };

  return axios.get(GET_APPOINTMENTS, {
    params,
  });
};

export const createTask = reqObj => axios.post(POST_CREATE_TASK, reqObj);

export const updateTaskAttachment = (taskId, reqObj) =>
  axios.put(`${PUT_UPDATE_TASK_ATTACHMENTS}/${taskId}`, reqObj);

export const getUserConsentForm = procedureId => {
  const params = {
    procedure_id: procedureId,
  };
  return procedureId
    ? axios.get(GET_USER_CONSENT_TEXT, { params })
    : axios.get(GET_USER_CONSENT_TEXT);
};

export const agreeUserConsent = params => axios.post(POST_USER_CONSENT, params);

export const disAgreeUserConsent = params =>
  axios.post(POST_DECLINE_USER_CONSENT, params);

export const getProcedureManagers = () =>
  axios.get(PATIENT_GET_PROCEDURE_MANAGERS);

export const getTremorSites = params => axios.get(GET_TREMOR_SITES, { params });

export const getTremorSitesStates = () => axios.get(GET_TREMOR_SITES_STATES);

export const getTremorSitesCities = () => axios.get(GET_TREMOR_SITES_CITIES);

export const selectTremorSite = params =>
  axios.post(SELECT_TREMOR_SITE, params);

export const getCityCenterStates = () => axios.get(GET_CITY_CENTERS_STATES);

export const getCityCenterCities = () => axios.get(GET_CITY_CENTERS_CITIES);

export const getCityCenter = params => axios.get(GET_CITY_CENTERS, { params });

export const selectCtCenter = params => axios.post(CT_CENTERS_SELECT, params);

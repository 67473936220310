import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AddProcedure from 'patientApp/containers/AddProcedure';
import { getUserInfo } from 'common/authThunkActions';
import MasqueradingHeader from 'patientApp/Caregiver/MasqueradingHeader';
import {
  getPatientProcedureRequest,
  switchActiveProcedureRequest,
} from '../../common/actions';
import SelectProcedureCard from '../../components/SelectProcedureCard';
import Strings from '../../../strings';
import './styles.scss';
import {
  getActiveCompositeProcedureId,
  setItemToStorage,
} from '../../../services/storage';

class SelectProcedure extends Component {
  componentDidMount() {
    this.initAPIs();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isProceduredAdded && !this.props.isProceduredAdded) {
      this.initAPIs();
    }
    if (
      this.props.masqueradingPatient &&
      nextProps.masqueradingPatient &&
      this.props.masqueradingPatient.id !== nextProps.masqueradingPatient.id
    ) {
      this.initAPIs();
    }
  }

  componentDidUpdate(prevProps) {
    const { compositeProcedureId } = this.props;
    if (
      prevProps.compositeProcedureId !== compositeProcedureId &&
      compositeProcedureId !== 0
    ) {
      this.props.history.replace(
        `/mydashboard/${this.props.compositeProcedureId}`
      );
    }
  }

  onProcedureSelect = compositeProcedureId => {
    this.props.putSwitchActiveProcedure(compositeProcedureId);
  };

  initAPIs = () => {
    this.props.fetchPatientProcedures();
    this.props.fetchUserInfo();
  };

  renderSelectProcedure(compositeProcedures) {
    return (
      <React.Fragment>
        <div className='center-block select-procedure-container'>
          <div className='lbl-select-procedure'>Select Procedure</div>
          <div className='select-procedure-note'>
            {Strings.SELECT_PROCEDURE_NOTE}
          </div>
          <div className='procedure-container'>
            {compositeProcedures.map(procedure => (
              <SelectProcedureCard
                item={procedure}
                key={procedure.id}
                onProcedureSelect={this.onProcedureSelect}
              />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderAddProcedure = () => {
    if (
      this.props.userDetails.departmentProcedures &&
      this.props.userDetails.departmentProcedures.length
    ) {
      const showWelcomeModal =
        this.props.userDetails.info &&
        this.props.userDetails.info.signInCount <= 1;
      return (
        <AddProcedure
          showWelcomeModal={showWelcomeModal}
          shownInModal={false}
          procedures={this.props.userDetails.departmentProcedures}
        />
      );
    }
    return null;
  };

  render() {
    const {
      isLoading,
      isFetchPatientProcedureRequestSent,
      compositeProcedures,
    } = this.props;

    if (
      this.props.userDetails &&
      this.props.userDetails.info &&
      this.props.userDetails.info.isProcedureCompleted
    ) {
      return <Redirect to='/post-complete-procedure' />;
    }

    if (this.props.userDetails && this.props.userDetails.info) {
      if (compositeProcedures.length === 1) {
        // Note : Set to validate initial routing check from auththunkactions.js which validates url for redirection
        setItemToStorage(
          'activeCompositeProcedureId',
          compositeProcedures[0].id
        );
        return (
          <Redirect
            to={`/mydashboard/${compositeProcedures[0].id}/tasks/tasks`}
          />
        );
      }
      if (getActiveCompositeProcedureId()) {
        return (
          <Redirect
            to={`/mydashboard/${getActiveCompositeProcedureId()}/tasks/tasks`}
          />
        );
      }
      return (
        <div
          className='select-procedure-main-container'
          style={{ marginTop: '-20px' }}
        >
          {/* <MasqueradingHeader /> */}
          <div className='col-sm-offset-2 col-sm-8 col-xs-12 select-procedure-details-container no-padding'>
            {compositeProcedures && compositeProcedures.length > 1
              ? this.renderSelectProcedure(compositeProcedures)
              : this.renderAddProcedure()}
          </div>
        </div>
      );
    }
    // TODO show error message/try again
    return null;
  }
}

const mapStateToProps = state => ({
  userDetails: state.profile.userDetails,
  isLoading:
    state.activeProcedureDetails.isLoading || state.profile.isRequestInProgress,
  isFetchPatientProcedureRequestSent: state.patientProcedures.isLoading,
  compositeProcedures: state.patientProcedures.compositeProcedures,
  compositeProcedureId: state.activeProcedureDetails.compositeProcedureId,
  isProceduredAdded: state.addProcedureRequest.isAdded,
  masqueradingPatient: state.caregiver
    ? state.caregiver.masqueradingPatient
    : null,
});

const mapDispatchToProps = dispatch => ({
  fetchUserInfo: () => dispatch(getUserInfo()),
  fetchPatientProcedures: () => dispatch(getPatientProcedureRequest()),
  putSwitchActiveProcedure: compositeProcedureId =>
    dispatch(switchActiveProcedureRequest(compositeProcedureId)),
});

SelectProcedure.propTypes = {
  fetchUserInfo: PropTypes.func.isRequired,
  fetchPatientProcedures: PropTypes.func.isRequired,
  isFetchPatientProcedureRequestSent: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userDetails: PropTypes.object.isRequired,
  putSwitchActiveProcedure: PropTypes.func,
  compositeProcedureId: PropTypes.number,
  compositeProcedures: PropTypes.array,
  history: PropTypes.object,
  isProceduredAdded: PropTypes.bool,
  masqueradingPatient: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProcedure);
